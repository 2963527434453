@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Spectral:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, dialog, figure, footer, header,
hgroup, menu, nav, section,
time, mark, audio, video, button {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: top;
  background: transparent;
}

html {
  touch-action: manipulation;
  -ms-touch-action: manipulation;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-overflow-scrolling: touch;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -moz-box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Sora, sans-serif, YuGothic, "游ゴシック体", "Yu Gothic Medium", Meiryo, "游ゴシック Medium", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", "ＭＳ Ｐゴシック", "MS PGothic";
  color: #2F333E;
  width: 100%;
  background: #f5f6f8;
  font-size: 14px;
  word-wrap: break-word;
  line-height: 1.2;
}

@media (max-width: 768px) {
  .pc-only {
    display: none;
  }
}

.sp-only {
  display: none;
}
@media (max-width: 768px) {
  .sp-only {
    display: block;
  }
}

video,
iframe {
  width: 100%;
}

/** --a-- **/
a {
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
  text-decoration: none;
  color: #0246C6;
}
a:hover {
  text-decoration: none;
}

/** --img-- **/
img {
  max-width: 100%;
  vertical-align: middle;
}

/** --ul-- **/
ul {
  margin: 0;
  list-style: none;
}

iframe {
  width: 100%;
}

/** common ***/
.wrap {
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 100px;
}
@media (max-width: 1280px) {
  .wrap {
    padding: 0 40px;
  }
}
@media (max-width: 768px) {
  .wrap {
    padding: 0 16px;
  }
}

.wrap-full {
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 100px;
}
@media (max-width: 1280px) {
  .wrap-full {
    max-width: 1104px;
    padding: 0 40px;
  }
}
@media (max-width: 768px) {
  .wrap-full {
    padding: 0 16px;
  }
}

.wrap-fullRight {
  padding-left: calc((100vw - 1616px) / 2);
}
.wrap-fullRight .wrap-content {
  padding-left: 100px;
}
@media (max-width: 1280px) {
  .wrap-fullRight .wrap-content {
    padding-left: 40px;
  }
}
@media (max-width: 768px) {
  .wrap-fullRight .wrap-content {
    padding-left: 16px;
  }
}

.wrap-fullLeft {
  padding-right: calc((100vw - 1616px) / 2);
}
.wrap-fullLeft .wrap-content {
  padding-right: 100px;
}
@media (max-width: 1280px) {
  .wrap-fullLeft .wrap-content {
    padding-right: 40px;
  }
}
@media (max-width: 768px) {
  .wrap-fullLeft .wrap-content {
    padding-right: 16px;
  }
}

.main {
  padding-top: 93px;
}
@media (max-width: 1280px) {
  .main {
    padding-top: 55px;
  }
}

/*******/
.slick-dots {
  max-width: 1500px;
  padding-right: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 1280px) {
  .slick-dots {
    padding-right: 40px;
  }
}
@media (max-width: 768px) {
  .slick-dots {
    padding-right: 16px;
  }
}
.slick-dots li {
  margin: 0;
  width: 100%;
  height: 3px;
}
@media (max-width: 768px) {
  .slick-dots li {
    height: 2px;
  }
}
.slick-dots li.slick-active button {
  background: #0246C6;
}
.slick-dots li button {
  background: #FFF;
  width: 100%;
  height: 3px;
}
@media (max-width: 768px) {
  .slick-dots li button {
    height: 2px;
  }
}

.slick-arrow.slick-disabled {
  cursor: no-drop !important;
  opacity: 0.6;
}

/******/
.title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  /*&.title--fs36 {
  	.title-text {
  		font-size: 36px;
  		@media (max-width: $viewport_desktop) {
  			font-size: 29px;
  		}
  		@media (max-width: $viewport_mobile) {
  			font-size: 23px;
  		}
  	}
  }*/
}
.title.title--main {
  padding: 80px 0 56px;
}
@media (max-width: 1280px) {
  .title.title--main {
    padding: 64px 0;
  }
}
@media (max-width: 768px) {
  .title.title--main {
    padding: 64px 0 32px;
  }
}
.title.title--main .title-text {
  font-size: 41px;
}
@media (max-width: 1280px) {
  .title.title--main .title-text {
    font-size: 29px;
  }
}
@media (max-width: 768px) {
  .title.title--main .title-text {
    font-size: 26px;
  }
}
.title .title-text {
  color: #0246C6;
  font-size: 32px;
  font-weight: 600;
}
@media (max-width: 1280px) {
  .title .title-text {
    font-size: 23px;
  }
}
@media (max-width: 768px) {
  .title .title-text {
    font-size: 20px;
  }
}
.title .title-text .title-gray {
  color: #2F333E;
}
@media (max-width: 768px) {
  .title .btn-more {
    display: none;
  }
}
.title .btn-slider {
  min-width: 119px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 768px) {
  .title .btn-slider {
    display: none;
  }
}
.title .btn-slider .btn {
  padding: 0;
  width: 50px;
  height: 50px;
  border: none;
  margin-left: 8px;
  border-radius: 50%;
}
.title .btn-slider .btn.btn-next {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.readMore {
  display: inline-block;
  position: relative;
  color: #0246C6;
  font-size: 18px;
}
.readMore:hover::after {
  width: 100%;
}
.readMore::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 0;
  height: 1px;
  background: #0246C6;
  -webkit-transition: 400ms all;
  transition: 400ms all;
}

.thumb-scale .thumb-hover {
  display: block;
}
.thumb-scale .thumb-hover:hover .thumb-title {
  text-decoration: underline;
}
.thumb-scale .thumb-hover:hover .thumb-picture img {
  -webkit-transform: scale(1.1) rotateZ(0deg);
          transform: scale(1.1) rotateZ(0deg);
}
.thumb-scale .thumb-picture {
  overflow: hidden;
}
.thumb-scale .thumb-picture img {
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  width: 100%;
}

.span-label {
  border-radius: 4px;
  padding: 1px 12px;
  font-size: 16px;
  display: inline-block;
}
@media (max-width: 768px) {
  .span-label {
    padding: 1px 8px;
    font-size: 14px;
  }
}
.span-label.span-label--blue {
  background: #0246C6;
  color: #fff;
}

.filter-tag {
  padding: 6px 8px 6px 40px;
  border: 1px solid #0246C6;
  border-radius: 100px;
  display: inline-block;
  position: relative;
  font-size: 18px;
}
@media (max-width: 1280px) {
  .filter-tag {
    font-size: 16px;
  }
}
.filter-tag .filter-close {
  background: #F1F2F2;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  top: calc(50% - 12px);
  left: 8px;
  cursor: pointer;
}

table {
  width: 100%;
}
table tr th,
table tr td {
  padding: 10px 10px;
  line-height: 20px;
  font-size: 16px;
  border: 1px solid #E3E5E8;
  vertical-align: middle;
}
table tr th {
  color: #37393D;
}

.table-sticky thead tr th {
  position: sticky;
  z-index: 1;
  top: 0;
  border: none;
  background: #F2F3F5;
  white-space: nowrap;
}
.table-sticky thead tr th::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 1px;
  background: #E3E5E8;
}
.table-sticky tbody tr td {
  border: none;
  padding-top: 16px;
  padding-bottom: 16px;
  background: #fff;
}

button {
  cursor: pointer;
}
button:focus {
  outline: none;
}

.btn {
  font-weight: 400;
  border-radius: 6px;
  padding: 0 24px;
  border: none;
}
@media (max-width: 768px) {
  .btn {
    padding: 0 16px;
  }
}
.btn.btn-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.btn.btn-hg74 {
  font-size: 26px;
  height: 74px;
}
@media (max-width: 1280px) {
  .btn.btn-hg74 {
    font-size: 18px;
    height: 64px;
  }
}
@media (max-width: 768px) {
  .btn.btn-hg74 {
    font-size: 14px;
    height: 48px;
  }
}
.btn.btn-hg74 .btn-item {
  margin-left: 12px;
  min-width: 46px;
  width: 46px;
  height: 46px;
}
@media (max-width: 1280px) {
  .btn.btn-hg74 .btn-item {
    margin-left: 8px;
    min-width: 40px;
    width: 40px;
    height: 40px;
  }
}
@media (max-width: 768px) {
  .btn.btn-hg74 .btn-item {
    min-width: 32px;
    width: 32px;
    height: 32px;
  }
}
.btn.btn-hg74 .btn-item::after, .btn.btn-hg74 .btn-item::before {
  width: 46px;
  height: 46px;
}
@media (max-width: 1280px) {
  .btn.btn-hg74 .btn-item::after, .btn.btn-hg74 .btn-item::before {
    width: 40px;
    height: 40px;
  }
}
@media (max-width: 768px) {
  .btn.btn-hg74 .btn-item::after, .btn.btn-hg74 .btn-item::before {
    width: 32px;
    height: 32px;
  }
}
.btn.btn-hg64 {
  font-size: 16px;
  height: 64px;
}
@media (max-width: 768px) {
  .btn.btn-hg64 {
    font-size: 14px;
    height: 48px;
  }
}
.btn.btn-hg64 .btn-item {
  margin-left: 8px;
  min-width: 40px;
  width: 40px;
  height: 40px;
}
@media (max-width: 768px) {
  .btn.btn-hg64 .btn-item {
    min-width: 32px;
    width: 32px;
    height: 32px;
  }
}
.btn.btn-hg64 .btn-item::after, .btn.btn-hg64 .btn-item::before {
  width: 40px;
  height: 40px;
}
@media (max-width: 768px) {
  .btn.btn-hg64 .btn-item::after, .btn.btn-hg64 .btn-item::before {
    width: 32px;
    height: 32px;
  }
}
.btn.btn-hg60 {
  height: 60px;
  font-size: 23px;
}
@media (max-width: 1280px) {
  .btn.btn-hg60 {
    height: 38px;
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .btn.btn-hg60 {
    height: 36px;
    font-size: 14px;
  }
}
.btn.btn-hg58 {
  height: 58px;
  font-size: 21px;
  font-weight: 700;
}
.btn.btn-hg56 {
  height: 56px;
  font-size: 14px;
}
.btn.btn-hg56 .btn-item {
  margin-left: 8px;
  min-width: 32px;
  width: 32px;
  height: 32px;
}
.btn.btn-hg56 .btn-item::after, .btn.btn-hg56 .btn-item::before {
  width: 32px;
  height: 32px;
}
.btn.btn-hg52 {
  height: 52px;
  font-size: 23px;
}
@media (max-width: 1280px) {
  .btn.btn-hg52 {
    height: 38px;
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .btn.btn-hg52 {
    height: 36px;
    font-size: 14px;
  }
}
.btn.btn-hg48 {
  height: 48px;
  font-size: 14px;
}
.btn.btn-hg48 .btn-item {
  margin-left: 8px;
  min-width: 32px;
  width: 32px;
  height: 32px;
}
.btn.btn-hg48 .btn-item::after, .btn.btn-hg48 .btn-item::before {
  width: 32px;
  height: 32px;
}
.btn.btn-hg35 {
  height: 35px;
  font-size: 16px;
}
@media (max-width: 768px) {
  .btn.btn-hg35 {
    height: 36px;
    font-size: 14px;
  }
}
.btn.btn-tag {
  background: #fff;
  border-radius: 100px;
  border: 1px solid #C3C4D2;
  color: #0E144C;
}
@media (max-width: 768px) {
  .btn.btn-tag {
    padding: 0 8px;
  }
}
.btn.btn-tag:hover {
  background: #E0E0E8;
  color: #2F333E;
}
.btn.btn-tag:focus {
  background: #2F333E;
  color: #fff;
}
.btn.btn-tag--active {
  background: #2F333E;
  color: #fff;
}
.btn.btn-white {
  border-radius: 100px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: #FFF;
  color: #2F333E;
}
.btn.btn-white:hover .btn-item::after {
  left: 100%;
}
.btn.btn-white:hover .btn-item::before {
  left: 0;
}
.btn.btn-white .btn-item {
  background: #0246C6;
}
.btn.btn-white .btn-item::after, .btn.btn-white .btn-item::before {
  position: absolute;
  content: "";
  background: url(../../images/common/icon-arrow-btn.svg) no-repeat center;
  background-size: 16px auto;
  top: 0;
  -webkit-transition: 400ms all;
  transition: 400ms all;
}
.btn.btn-white .btn-item::after {
  left: 0;
}
.btn.btn-white .btn-item::before {
  left: -100%;
}
.btn.btn-systems {
  border-radius: 100px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: var(--blue-color-b-30, #DDE8F5);
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  color: #173773;
}
.btn.btn-systems:hover {
  background: #d5e1ef;
}
.btn.btn-subscribe {
  border-radius: 100px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: var(--blue-neutral-b-900, #0246C6);
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  color: #fff;
  font-size: 14px;
}
.btn.btn-primary {
  border-radius: 100px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: var(--blue-neutral-b-900, #0246C6);
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  color: #fff;
}
.btn.btn-primary:hover {
  background: #1c57c5;
}
.btn.btn-primary:hover .btn-item::after {
  left: 100%;
}
.btn.btn-primary:hover .btn-item::before {
  left: 0;
}
.btn.btn-primary .btn-item {
  background: rgba(255, 255, 255, 0.1);
}
.btn.btn-primary .btn-item::after, .btn.btn-primary .btn-item::before {
  position: absolute;
  content: "";
  background: url(../../images/common/icon-arrow-btn.svg) no-repeat center;
  background-size: 16px auto;
  top: 0;
  -webkit-transition: 400ms all;
  transition: 400ms all;
}
.btn.btn-primary .btn-item::after {
  left: 0;
}
.btn.btn-primary .btn-item::before {
  left: -100%;
}
.btn.btn-primary-subtle {
  border-radius: 100px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  color: #fff;
}
.btn.btn-primary-subtle.btn-colorGray {
  color: #2F333E;
}
.btn.btn-primary-subtle:hover .btn-item::after {
  left: 100%;
}
.btn.btn-primary-subtle:hover .btn-item::before {
  left: 0;
}
.btn.btn-primary-subtle .btn-item {
  background: #0246C6;
}
.btn.btn-primary-subtle .btn-item::after, .btn.btn-primary-subtle .btn-item::before {
  position: absolute;
  content: "";
  background: url(../../images/common/icon-arrow-btn.svg) no-repeat center;
  background-size: 16px auto;
  top: 0;
  -webkit-transition: 400ms all;
  transition: 400ms all;
}
.btn.btn-primary-subtle .btn-item::after {
  left: 0;
}
.btn.btn-primary-subtle .btn-item::before {
  left: -100%;
}
.btn.btn-primary-gradient {
  background: linear-gradient(45deg, rgb(12, 182, 255) 0%, rgb(153, 221, 255) 51%, rgb(190, 255, 243) 100%) !important;
  color: #020D30;
  border-radius: 4px;
  padding: 0 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.btn.btn-primary-gradient:hover, .btn.btn-primary-gradient:active {
  background: -webkit-gradient(linear, left top, right top, from(rgb(0, 16, 165)), to(rgb(7, 63, 208))) !important;
  background: linear-gradient(to right, rgb(0, 16, 165) 0%, rgb(7, 63, 208) 100%) !important;
  color: #fff;
}
.btn.btn-primary-gradient:hover .btnItem .svg path, .btn.btn-primary-gradient:active .btnItem .svg path {
  stroke: #fff;
}
.btn.btn-primary-gradient .btnItem {
  width: 22px;
  height: 22px;
  min-width: 22px;
  margin-left: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.btn.btn-primary-gradient .btnItem .svg path {
  -webkit-transition: 200ms all;
  transition: 200ms all;
}
.btn .btn-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}

input[type=number] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}

input[type=text]::-webkit-search-decoration,
input[type=text]::-webkit-search-cancel-button,
input[type=text]::-webkit-search-results-button,
input[type=text]::-webkit-search-results-decoration,
input[type=password]::-webkit-search-decoration,
input[type=password]::-webkit-search-cancel-button,
input[type=password]::-webkit-search-results-button,
input[type=password]::-webkit-search-results-decoration,
input[type=search]::-webkit-search-results-decoration {
  display: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  appearance: none;
}

select.form-control:not([size]):not([multiple]) {
  height: 42px;
}

.form-control {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  appearance: none;
  /*padding: 28px 24px 0;*/
  padding: 0 16px 0;
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  border: none;
  border-radius: 6px 6px 0px 0px;
  height: 64px;
  background: rgba(246, 248, 252, 0.1);
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
}
@media (max-width: 1280px) {
  .form-control {
    height: 56px;
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .form-control {
    height: 48px;
    padding: 0 16px;
    border-width: 2px;
    font-size: 14px;
  }
}
.form-control.form-control--hg56 {
  height: 56px;
  padding: 0 10px;
  font-size: 16px;
}
@media (max-width: 1280px) {
  .form-control.form-control--hg56 {
    height: 48px;
    font-size: 14px;
  }
}
.form-control.form-control--hg48 {
  height: 48px;
  padding: 0 10px;
  font-size: 14px;
}
.form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.2);
  font-weight: 400;
}
.form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.2);
  font-weight: 400;
}
.form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.2);
  font-weight: 400;
}
.form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.2);
  font-weight: 400;
}
.form-control::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.2);
  font-weight: 400;
}
.form-control::placeholder {
  color: rgba(255, 255, 255, 0.2);
  font-weight: 400;
}
.form-control:hover {
  background: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.4);
}
@media (max-width: 768px) {
  .form-control:hover {
    border-width: 2px;
  }
}
.form-control:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  background: rgba(246, 248, 252, 0.1);
  border-color: #0246C6;
  color: #fff;
}
@media (max-width: 768px) {
  .form-control:focus {
    border-width: 2px;
  }
}
.form-control.valid {
  border-color: #0BCB68;
}
.form-control.error {
  border-color: #DF0F0F;
}

/*// select {
//   &.form-control {
//     background: url(../../images/common/icon-selectBox.svg) no-repeat scroll right 6px center #F2F3F5;
//     padding-right: 20px;
//     &:focus {
//       background: url(../../images/common/icon-selectBox.svg) no-repeat scroll right 6px center #F2F3F5;
//     }
//     &:disabled {
//       background: url(../../images/common/icon-selectBox.svg) no-repeat scroll right 6px center #F2F2F2;
//     }
//   }
// }*/
select::-ms-expand {
  display: none;
}

textarea.form-control {
  resize: none;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: auto;
  scrollbar-width: thin;
}
textarea.form-control::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
}
textarea.form-control::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
}
textarea.form-control::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 8px;
}

/****/
.groupForm {
  position: relative;
}
.groupForm .groupForm-label {
  color: #fff;
  font-size: 18px;
  font-weight: normal;
  padding-bottom: 8px;
  font-weight: 400;
}
@media (max-width: 1280px) {
  .groupForm .groupForm-label {
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .groupForm .groupForm-label {
    font-size: 14px;
  }
}
.groupForm .groupForm-label--fs16 {
  font-size: 16px;
}
@media (max-width: 1280px) {
  .groupForm .groupForm-label--fs16 {
    font-size: 14px;
  }
}
.groupForm .groupForm-label--fs16 ~ .iti .iti__flag-container .iti__selected-flag .iti__selected-dial-code {
  font-size: 16px;
}
@media (max-width: 1280px) {
  .groupForm .groupForm-label--fs16 ~ .iti .iti__flag-container .iti__selected-flag .iti__selected-dial-code {
    font-size: 14px;
  }
}
.groupForm .groupForm-label--fs14 {
  font-size: 14px;
}
.groupForm .groupForm-label--fs14 ~ .iti .iti__flag-container .iti__selected-flag .iti__selected-dial-code {
  font-size: 14px;
}
.groupForm .bootstrap-select.dropup ~ .groupForm-label, .groupForm .bootstrap-select.show ~ .groupForm-label {
  top: 10px;
  font-size: 16px;
  color: #6C6F77;
}
@media (max-width: 768px) {
  .groupForm .bootstrap-select.dropup ~ .groupForm-label, .groupForm .bootstrap-select.show ~ .groupForm-label {
    font-size: 14px;
  }
}
.groupForm.groupForm--code .iti .iti__flag-container {
  left: 10px;
}
.groupForm.groupForm--code .iti .form-control {
  padding-left: 100px !important;
}
.groupForm .iti {
  display: block !important;
}
.groupForm .iti .form-control {
  padding-left: 100px !important;
}
@media (max-width: 768px) {
  .groupForm .iti .form-control {
    padding-left: 96px !important;
  }
}
.groupForm .iti .iti__flag-container {
  padding: 0;
  left: 16px;
  top: calc(50% - 14px);
  bottom: inherit;
}
@media (max-width: 768px) {
  .groupForm .iti .iti__flag-container {
    left: 16px;
  }
}
.groupForm .iti .iti__flag-container .iti__selected-flag {
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.2);
  padding: 8px 2px;
  min-width: 72px;
  height: 28px;
}
.groupForm .iti .iti__flag-container .iti__selected-flag[aria-expanded=true] .iti__arrow::after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.groupForm .iti .iti__flag-container .iti__selected-flag .iti__flag {
  display: none;
}
.groupForm .iti .iti__flag-container .iti__selected-flag .iti__selected-dial-code {
  font-size: 18px;
  color: #fff;
  width: 100%;
}
@media (max-width: 1280px) {
  .groupForm .iti .iti__flag-container .iti__selected-flag .iti__selected-dial-code {
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .groupForm .iti .iti__flag-container .iti__selected-flag .iti__selected-dial-code {
    font-size: 14px;
  }
}
.groupForm .iti .iti__flag-container .iti__selected-flag .iti__arrow {
  position: absolute;
  right: 4px;
  top: 2px;
  width: 24px;
  height: 24px;
  border: none;
}
.groupForm .iti .iti__flag-container .iti__selected-flag .iti__arrow::after {
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  width: 24px;
  height: 24px;
  background: url(../../images/common/icon-arrow-down.svg) no-repeat center;
  -webkit-transition: 400ms all;
  transition: 400ms all;
}

.iti--container {
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.iti__country-list {
  -webkit-box-shadow: none;
          box-shadow: none;
  border: none;
  background: #444751;
  border-radius: 4px;
  padding: 16px 8px 16px 0px;
  max-height: 300px !important;
  max-width: 216px;
  min-width: 216px;
  overflow: auto;
  scrollbar-width: thin;
}
@media (max-width: 768px) {
  .iti__country-list {
    max-width: 300px;
  }
}
.iti__country-list::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
}
.iti__country-list::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
}
.iti__country-list::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 8px;
}
.iti__country-list li {
  padding: 6px 16px;
  border-bottom: 1px solid #575962;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.iti__country-list li:hover {
  background: #575962;
}
.iti__country-list li .iti__flag-box {
  display: none;
}
.iti__country-list li.iti__divider {
  display: none;
}
.iti__country-list li .iti__country-name {
  color: #C7C8CB;
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
  white-space: normal;
  margin-right: 0;
}
.iti__country-list li .iti__dial-code {
  font-size: 16px;
  color: #fff;
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
  min-width: 48px;
  margin-right: 8px;
}

/****/
.select-control.select-control--white .btn.dropdown-toggle {
  background: rgba(246, 248, 252, 0.1);
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(16px);
          backdrop-filter: blur(16px);
  border-radius: 0;
  font-size: 20px;
  height: 56px;
}
@media (max-width: 1280px) {
  .select-control.select-control--white .btn.dropdown-toggle {
    font-size: 18px;
    height: 46px;
  }
}
@media (max-width: 768px) {
  .select-control.select-control--white .btn.dropdown-toggle {
    font-size: 16px;
    padding-right: 46px;
  }
}
.select-control.select-control--white .btn.dropdown-toggle:hover, .select-control.select-control--white .btn.dropdown-toggle:focus, .select-control.select-control--white .btn.dropdown-toggle:active {
  border-bottom: 1px solid #C1C1C1 !important;
  background: rgba(255, 255, 255, 0.1);
}
.select-control.select-control--white .btn.dropdown-toggle::before {
  background: none;
}
.select-control.select-control--white .btn.dropdown-toggle::after {
  background: url(../../images/common/icon-selectBox01.svg) no-repeat center;
  right: 10px;
}
.select-control.select-control--white .btn.dropdown-toggle.bs-placeholder .filter-option-inner-inner {
  color: #fff;
}
.select-control.select-control--white .dropdown-menu {
  border-radius: 3px;
  background: #fff;
  -webkit-box-shadow: 0px 12px 16px 0px rgba(0, 0, 0, 0.5);
          box-shadow: 0px 12px 16px 0px rgba(0, 0, 0, 0.5);
}
.select-control.select-control--white .dropdown-menu .dropdown-menu {
  background: none;
}
.select-control.select-control--white .dropdown-menu .inner {
  scrollbar-width: thin;
}
.select-control.select-control--white .dropdown-menu .inner::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.select-control.select-control--white .dropdown-menu .inner::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.select-control.select-control--white .dropdown-menu .inner::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
.select-control.select-control--white .dropdown-menu .inner ul li {
  border-bottom: 1px solid #F1F2F2;
}
.select-control.select-control--white .dropdown-menu .inner ul li a {
  font-size: 18px;
  color: #1D212D;
}
@media (max-width: 768px) {
  .select-control.select-control--white .dropdown-menu .inner ul li a {
    font-size: 14px;
  }
}
.select-control.select-control--white .dropdown-menu .inner ul li a:hover {
  background: #f8f8f8;
}
.select-control.select-control--white .dropdown-menu .inner ul li a::after {
  background: url(../../images/common/icon-checkBox-gray.svg) no-repeat;
  top: 14px;
}
.select-control.select-control--hg48 .btn.dropdown-toggle {
  height: 48px;
  padding-left: 10px;
  font-size: 14px;
}
.select-control.select-control--hg48 .btn.dropdown-toggle::before, .select-control.select-control--hg48 .btn.dropdown-toggle::after {
  right: 10px;
}
.select-control.select-control--hg48 .dropdown-menu .inner ul li a {
  font-size: 16px;
}
.select-control.select-control--hg48 .dropdown-menu .inner ul li a::after {
  top: 14px;
}
.select-control.select-control--hg56 .btn.dropdown-toggle {
  height: 56px;
  padding-left: 10px;
  font-size: 16px;
}
@media (max-width: 1280px) {
  .select-control.select-control--hg56 .btn.dropdown-toggle {
    height: 48px;
    font-size: 14px;
  }
}
.select-control.select-control--hg56 .btn.dropdown-toggle::before, .select-control.select-control--hg56 .btn.dropdown-toggle::after {
  right: 10px;
}
.select-control.select-control--hg56 .dropdown-menu .inner ul li a {
  font-size: 16px;
}
.select-control.select-control--hg56 .dropdown-menu .inner ul li a::after {
  top: 14px;
}
.select-control .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 100%;
}
.select-control select {
  display: none;
}
.select-control .form-control:hover {
  border: none;
}
.select-control .filter-option-inner {
  overflow: hidden;
}
.select-control .btn.dropdown-toggle {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  appearance: none;
  padding: 0 66px 0 24px;
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  border: none;
  border-radius: 6px 6px 0px 0px;
  height: 64px;
  background: #444751;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
  outline: none;
}
@media (max-width: 1280px) {
  .select-control .btn.dropdown-toggle {
    height: 56px;
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .select-control .btn.dropdown-toggle {
    padding: 0 56px 0 16px;
    font-size: 14px;
    height: 48px;
  }
}
.select-control .btn.dropdown-toggle:hover {
  background: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.4) !important;
}
@media (max-width: 768px) {
  .select-control .btn.dropdown-toggle:hover {
    border-width: 2px;
  }
}
.select-control .btn.dropdown-toggle:focus, .select-control .btn.dropdown-toggle:active {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  background: rgba(246, 248, 252, 0.1);
  border: none;
  border-color: #0246C6;
  color: #fff;
  outline: none !important;
}
@media (max-width: 768px) {
  .select-control .btn.dropdown-toggle:focus, .select-control .btn.dropdown-toggle:active {
    border-width: 2px;
  }
}
.select-control .btn.dropdown-toggle[aria-expanded=true] {
  border-color: #0246C6;
}
.select-control .btn.dropdown-toggle[aria-expanded=true]:hover {
  border-color: #0246C6 !important;
}
@media (max-width: 768px) {
  .select-control .btn.dropdown-toggle[aria-expanded=true]:hover {
    border-width: 2px !important;
  }
}
@media (max-width: 768px) {
  .select-control .btn.dropdown-toggle[aria-expanded=true] {
    border-width: 2px;
  }
}
.select-control .btn.dropdown-toggle.bs-placeholder .filter-option-inner-inner {
  color: rgba(255, 255, 255, 0.2);
  font-weight: 400;
}
.select-control .btn.dropdown-toggle[aria-expanded=true]::after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.select-control .btn.dropdown-toggle::after {
  position: absolute;
  top: calc(50% - 16px);
  right: 24px;
  content: "";
  background: url(../../images/common/icon-selectBox.svg) no-repeat center;
  width: 32px;
  height: 32px;
  border: none;
  -webkit-transition: 400ms all;
  transition: 400ms all;
  margin: 0;
}
@media (max-width: 768px) {
  .select-control .btn.dropdown-toggle::after {
    right: 16px;
  }
}
.select-control .btn.dropdown-toggle::before {
  position: absolute;
  top: calc(50% - 16px);
  right: 24px;
  content: "";
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.1);
}
@media (max-width: 768px) {
  .select-control .btn.dropdown-toggle::before {
    right: 16px;
  }
}
.select-control .error + .btn {
  border-color: #DF0F0F;
}
.select-control .valid + .btn {
  border-color: #0BCB68;
}
.select-control .filter-option-inner-inner {
  text-overflow: ellipsis;
}
.select-control .filter-option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.select-control .dropdown-menu {
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-radius: 6px;
  width: 100%;
  padding: 20px;
  max-height: 300px;
  background: #444751;
  overflow: auto;
  margin: 0;
}
.select-control .dropdown-menu .inner {
  scrollbar-width: thin;
}
.select-control .dropdown-menu .inner::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
}
.select-control .dropdown-menu .inner::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
}
.select-control .dropdown-menu .inner::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 8px;
}
.select-control .dropdown-menu .inner ul {
  overflow: inherit !important;
}
.select-control .dropdown-menu .inner ul li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.select-control .dropdown-menu .inner ul li.selected a::after {
  background: url(../../images/common/icon-checkBox-checked.svg) no-repeat;
}
.select-control .dropdown-menu .inner ul li a {
  padding: 16px 8px 16px 42px;
  font-size: 16px;
  color: #fff;
  position: relative;
  white-space: initial;
}
@media (max-width: 1280px) {
  .select-control .dropdown-menu .inner ul li a {
    font-size: 14px;
  }
}
.select-control .dropdown-menu .inner ul li a::after {
  position: absolute;
  top: 14px;
  left: 4px;
  content: "";
  width: 24px;
  height: 24px;
  background: url(../../images/common/icon-checkBox.svg) no-repeat;
}
@media (max-width: 768px) {
  .select-control .dropdown-menu .inner ul li a::after {
    top: 13px;
  }
}
.select-control .dropdown-menu .inner ul li a:hover {
  background: #575962;
}
.select-control .dropdown-menu .inner ul li a:active, .select-control .dropdown-menu .inner ul li a:focus {
  background: none;
}
.select-control .dropdown-menu .inner ul li a .check-mark {
  display: none !important;
}

/*****/
label.error {
  color: #E43838;
  font-size: 12px;
  padding: 8px 0 0 23px;
  background: url(../../images/common/icon-validate.svg) no-repeat 0 6px;
}

/*****/
.control-tel {
  position: relative;
}
.control-tel.control-tel--hg56 .control-search {
  width: 120px;
  top: calc(50% - 16px);
}
@media (max-width: 1280px) {
  .control-tel.control-tel--hg56 .control-search {
    width: 100px;
    top: calc(50% - 13px);
  }
}
.control-tel.control-tel--hg56 .control-search .control-wrap {
  position: relative;
}
.control-tel.control-tel--hg56 .control-search .form-control {
  height: 32px;
  font-size: 16px;
}
@media (max-width: 1280px) {
  .control-tel.control-tel--hg56 .control-search .form-control {
    font-size: 14px;
    height: 26px;
  }
}
.control-tel.control-tel--hg56 .control-search .control-item {
  right: 8px;
}
.control-tel.control-tel--hg56 .control-search .control-sub {
  top: calc(100% + 12px) !important;
}
@media (max-width: 1280px) {
  .control-tel.control-tel--hg56 .control-search .control-sub {
    top: calc(100% + 11px) !important;
  }
}
.control-tel.control-tel--hg56 .control-number .form-control {
  padding-left: 144px;
}
@media (max-width: 1280px) {
  .control-tel.control-tel--hg56 .control-number .form-control {
    padding-left: 124px;
  }
}
.control-tel .control-search {
  position: absolute;
  top: calc(50% - 18px);
  left: 16px;
  width: 160px;
}
@media (max-width: 1280px) {
  .control-tel .control-search {
    top: calc(50% - 16px);
    width: 100px;
  }
}
@media (max-width: 768px) {
  .control-tel .control-search {
    top: calc(50% - 13px);
  }
}
.control-tel .control-search .form-control {
  height: 37px;
  padding: 6px 34px 6px 8px;
  color: #000;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid transparent;
  border-radius: 4px;
  color: #fff;
}
@media (max-width: 1280px) {
  .control-tel .control-search .form-control {
    height: 32px;
    padding: 6px 26px 6px 8px;
  }
}
@media (max-width: 768px) {
  .control-tel .control-search .form-control {
    height: 26px;
  }
}
.control-tel .control-search .form-control:focus {
  border: 1px solid rgb(255, 255, 255);
}
.control-tel .control-search .form-control::-webkit-input-placeholder {
  color: rgb(255, 255, 255);
  font-weight: 400;
}
.control-tel .control-search .form-control::-webkit-input-placeholder {
  color: rgb(255, 255, 255);
  font-weight: 400;
}
.control-tel .control-search .form-control::-moz-placeholder {
  color: rgb(255, 255, 255);
  font-weight: 400;
}
.control-tel .control-search .form-control:-ms-input-placeholder {
  color: rgb(255, 255, 255);
  font-weight: 400;
}
.control-tel .control-search .form-control::-ms-input-placeholder {
  color: rgb(255, 255, 255);
  font-weight: 400;
}
.control-tel .control-search .form-control::placeholder {
  color: rgb(255, 255, 255);
  font-weight: 400;
}
.control-tel .control-search .form-control[aria-expanded=true] + .control-item {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.control-tel .control-search .control-item {
  position: absolute;
  top: calc(50% - 4px);
  right: 16px;
  width: 12px;
  height: 8px;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: 400ms all;
  transition: 400ms all;
}
@media (max-width: 1280px) {
  .control-tel .control-search .control-item {
    right: 8px;
  }
}
.control-tel .control-search .control-sub {
  position: absolute;
  top: calc(100% + 13px) !important;
  left: 0;
  width: 260px;
  background: #444751;
  z-index: 2;
  border-radius: 6px;
  border: none;
  margin: 0;
  -webkit-transform: none !important;
          transform: none !important;
}
@media (max-width: 1280px) {
  .control-tel .control-search .control-sub {
    top: calc(100% + 12px) !important;
  }
}
@media (max-width: 768px) {
  .control-tel .control-search .control-sub {
    top: calc(100% + 11px) !important;
  }
}
.control-tel .control-search .control-sub ul {
  max-height: 238px;
  padding-right: 20px;
  overflow: auto;
  scrollbar-width: thin;
}
.control-tel .control-search .control-sub ul::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
}
.control-tel .control-search .control-sub ul::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
}
.control-tel .control-search .control-sub ul::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 8px;
}
.control-tel .control-search .control-sub ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 7px 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;
}
.control-tel .control-search .control-sub ul li:last-child {
  border: none;
}
.control-tel .control-search .control-sub ul li.active, .control-tel .control-search .control-sub ul li:hover {
  background: rgba(255, 255, 255, 0.1);
}
.control-tel .control-search .control-sub ul li .control-sub__text {
  font-size: 14px;
  font-weight: 300;
  color: #C7C8CB;
}
.control-tel .control-search .control-sub ul li .control-sub__number {
  min-width: 48px;
  white-space: nowrap;
  font-size: 16px;
  color: #fff;
}
.control-tel .control-number .form-control {
  padding-left: 192px;
}
@media (max-width: 1280px) {
  .control-tel .control-number .form-control {
    padding-left: 124px;
  }
}

/**** radio checkbox ****/
.custom-control {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 24px;
  padding-left: 24px;
  position: relative;
  /*&.custom-radio {
    .custom-control-indicator {
      background: url(../../images/common/icon-radio.svg) no-repeat 50%;
      width: 20px;
      height: 20px;
      top: calc(50% - 10px);
    }
    .custom-control-input {
      &:checked ~ .custom-control-indicator {
        background: url(../../images/common/icon-radio-checked.svg) no-repeat 50%;
        top: calc(50% - 10px);
      }
      &:checked:disabled ~ .custom-control-indicator {
        background: url(../../images/common/icon-radio-checked-disabled.svg) no-repeat;
      }
      &:disabled ~ .custom-control-indicator {
        background: url(../../images/common/icon-radio-disabled.svg) no-repeat 50%;
      }
      &:focus ~ .custom-control-indicator {
        box-shadow: 0 0 0 .2rem rgba(0,123,255,.25);
        border-radius: 50%;
      }
    }
  }*/
}
.custom-control .custom-control-input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.custom-control .custom-control-input:disabled ~ .custom-control-indicator {
  cursor: not-allowed;
}
.custom-control .custom-control-input:disabled ~ .custom-control-description {
  cursor: not-allowed;
}
.custom-control .custom-control-description {
  cursor: pointer;
  font-size: 18px;
  line-height: 120%;
  color: #999BA1;
  padding-left: 20px;
}
@media (max-width: 1280px) {
  .custom-control .custom-control-description {
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .custom-control .custom-control-description {
    font-size: 14px;
  }
}
.custom-control .custom-control-indicator {
  display: block;
  left: 0;
  position: absolute;
  cursor: pointer;
}
.custom-control.custom-checkbox .custom-control-indicator {
  width: 24px;
  height: 24px;
  background: url(../../images/common/icon-checkBox.svg) no-repeat;
}
.custom-control.custom-checkbox .custom-control-input {
  /*
  &:checked:disabled ~ .custom-control-indicator {
    background: url(../../images/common/icon-checkBox-checked-disabled.svg) no-repeat;
  }
  &:disabled ~ .custom-control-indicator {
    background: url(../../images/common/icon-checkBox-disabled.svg) no-repeat;
  }
  &:focus ~ .custom-control-indicator {
    box-shadow: 0 0 0 .2rem rgba(0,123,255,.25);
    border-radius: 4px;
  }
  */
}
.custom-control.custom-checkbox .custom-control-input:checked ~ .custom-control-indicator {
  background: url(../../images/common/icon-checkBox-checked.svg) no-repeat;
}
.custom-control.custom-checkbox--gray .custom-control-indicator {
  width: 24px;
  height: 24px;
  background: url(../../images/common/icon-checkBox-gray.svg) no-repeat;
}
.custom-control.custom-checkbox--gray .custom-control-description {
  font-size: 18px;
  color: #383C46;
  padding-left: 10px;
}
@media (max-width: 1280px) {
  .custom-control.custom-checkbox--gray .custom-control-description {
    font-size: 16px;
    padding-top: 3px;
  }
}

/*****/
.switch {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
}
.switch .switch-label {
  cursor: pointer;
  margin: 0;
}
.switch .switch-checkbox {
  opacity: 0;
  position: absolute;
}
.switch .switch-checkbox:checked + .switch-toggle:before {
  left: calc(100% - 16px);
  background: #fff;
}
.switch .switch-checkbox:checked + .switch-toggle {
  background: #2D6FD2;
  border: 2px solid #2D6FD2;
}
.switch .switch-toggle {
  border-radius: 100px;
  display: inline-block;
  background: #fff;
  border: 2px solid #E3E5E8;
  width: 44px;
  height: 24px;
  position: relative;
}
.switch .switch-toggle:before {
  background: #E3E5E8;
  border-radius: 50%;
  content: "";
  display: block;
  left: 2px;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s cubic-bezier(0.17, 0.67, 0.53, 1) 0s;
  transition: all 0.3s cubic-bezier(0.17, 0.67, 0.53, 1) 0s;
}

.breadcrumb {
  background: none;
  padding: 8px 0;
  margin: 0;
  display: inherit;
}
@media (max-width: 768px) {
  .breadcrumb {
    display: none;
  }
}
.breadcrumb ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.breadcrumb ul li {
  font-size: 13px;
  color: #0246C6;
  padding-right: 20px;
  position: relative;
}
.breadcrumb ul li:first-of-type {
  text-transform: uppercase;
}
.breadcrumb ul li::after {
  position: absolute;
  top: 3px;
  right: 7px;
  content: "";
  width: 5px;
  height: 8px;
  background: url(../../images/common/icon-arrow-breadcrumb.svg) no-repeat;
}
.breadcrumb ul li:last-child {
  padding-right: 0;
}
.breadcrumb ul li:last-child::after {
  display: none;
}
.breadcrumb ul li a {
  color: #3F424D;
}

.pagination {
  display: block;
  padding-top: 40px;
}
.pagination--center ul {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.pagination ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
@media (max-width: 768px) {
  .pagination ul {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.pagination ul li {
  padding-right: 8px;
}
@media (max-width: 768px) {
  .pagination ul li {
    padding-right: 4px;
  }
}
.pagination ul li:last-child {
  padding-right: 0;
}
.pagination ul li.prev a, .pagination ul li.next a {
  border: none;
}
.pagination ul li.active a {
  color: #fff;
  background: #1C57C5;
  border: 1px solid #1C57C5;
}
.pagination ul li a {
  border: 1px solid #C7C8CB;
  border-radius: 50%;
  color: #2F333E;
  font-size: 18px;
  min-width: 38px;
  height: 38px;
  padding: 0 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 768px) {
  .pagination ul li a {
    font-size: 14px;
    min-width: 29px;
    height: 29px;
  }
}
.pagination ul li span {
  height: 38px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #2F333E;
  font-size: 18px;
}
@media (max-width: 768px) {
  .pagination ul li span {
    height: 29px;
    font-size: 14px;
  }
}

.modal {
  padding: 16px;
}
.modal .modal-dialog {
  margin: 0 auto;
}
.modal .modal-dialog--mwfull {
  max-width: 100%;
  width: 100%;
}
.modal .modal-dialog--mw770 {
  max-width: 770px;
}
.modal .modal-dialog--mw712 {
  max-width: 712px;
}
.modal .modal-dialog--mw574 {
  max-width: 574px;
}
.modal .modal-dialog--alertForm {
  max-width: 578px;
}
@media (max-width: 1280px) {
  .modal .modal-dialog--alertForm {
    max-width: 460px;
  }
}
.modal .modal-dialog--alertForm .modal-content {
  background: #fff;
  border-radius: 8px;
}
.modal .modal-dialog--alertForm .modal-body {
  padding: 0;
}
.modal .modal-dialog--mw400 {
  max-width: 400px;
}
.modal .modal-content {
  border-radius: 0;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  background: #2F333E;
}
.modal .modal-body {
  border-top: none;
  padding: 32px 16px;
}
.modal .modal-header {
  padding: 36px 36px 0;
  border: none;
}
.modal .modal-header .modal-title {
  font-size: 18px;
  font-weight: 700;
  color: #252C6D;
  text-transform: uppercase;
}
.modal .modal-header .close {
  margin: 0;
  padding: 0;
  opacity: 1;
}

.title-modal .tlt {
  color: #252C6D;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}

.btnFooter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  max-width: 664px;
  margin: 0 auto;
  padding-top: 36px;
}
.btnFooter .btn {
  width: 320px;
  margin-right: 24px;
}
.btnFooter .btn:last-child {
  margin-right: 0;
}

/* -- modal -- */
.form-modal .title-modal {
  padding-bottom: 16px;
}
.form-modal .title-modal .title-modal__tlt {
  font-size: 20px;
  color: #F1F2F2;
  font-weight: 600;
}
.form-modal .title-modal .title-modal__text {
  color: #999BA1;
  padding-top: 16px;
}
.form-modal .groupForm {
  padding-top: 16px;
}
.form-modal .groupForm-check {
  padding-top: 8px;
}
.form-modal .groupForm-check ul li {
  padding-top: 24px;
}
.form-modal .groupForm-check ul li .custom-control-description {
  font-size: 14px;
}
.form-modal .groupForm-btn {
  max-width: 260px;
  margin: 0 auto;
  padding-top: 32px;
}
.form-modal .groupForm-btn .btn {
  width: 100%;
}

.groupForm-col2 > ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-left: -16px;
}
.groupForm-col2 > ul > li {
  padding-left: 16px;
  width: 50%;
}
@media (max-width: 768px) {
  .groupForm-col2 > ul > li {
    width: 100%;
  }
}

.modal--videofull {
  padding: 0 !important;
}
.modal--videofull .modal-dialog--mwfull {
  margin: 0;
}
.modal--videofull .modal-dialog--mwfull .modal-body {
  padding: 0;
}
.modal--videofull .modal-dialog--mwfull .modal-video {
  position: relative;
}
.modal--videofull .modal-dialog--mwfull .modal-video .video-slide,
.modal--videofull .modal-dialog--mwfull .modal-video iframe {
  height: 100vh;
}
.modal--videofull .modal-dialog--mwfull .modal-video .close-video {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
}
.modal--videofull .modal-dialog--mwfull .modal-video .close-video .btn {
  background: rgba(255, 255, 255, 0.1);
  width: 40px;
  height: 40px;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
}
.modal--videofull .modal-dialog--mwfull .modal-video .close-video .btn svg rect {
  fill: #fff;
}

.alertForm {
  padding: 32px 16px;
  position: relative;
}
.alertForm .close-modal {
  position: absolute;
  top: 10px;
  right: 12px;
  width: 40px;
  height: 40px;
}
.alertForm .close-modal .btn {
  width: 40px;
  height: 40px;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: #F1F2F2;
  border: 1px solid #C7C8CB;
  border-radius: 8px;
}
.alertForm .alertForm-content {
  text-align: center;
}
.alertForm .alertForm-content img {
  max-width: 56px;
}
@media (max-width: 768px) {
  .alertForm .alertForm-content img {
    max-width: 40px;
  }
}
.alertForm .alertForm-content .alertForm-title {
  color: #2F333E;
  font-size: 26px;
  font-weight: 700;
  padding-top: 16px;
}
@media (max-width: 768px) {
  .alertForm .alertForm-content .alertForm-title {
    font-size: 18px;
  }
}
.alertForm .alertForm-content .alertForm-text {
  padding-top: 16px;
  font-size: 18px;
  color: #2F333E;
}
@media (max-width: 768px) {
  .alertForm .alertForm-content .alertForm-text {
    font-size: 14px;
    padding-top: 8px;
  }
}

.modal .modal-content--ldp {
  background: -webkit-gradient(linear, left top, right top, from(rgb(1, 12, 45)), to(rgb(18, 27, 113)));
  background: linear-gradient(to right, rgb(1, 12, 45) 0%, rgb(18, 27, 113) 100%);
  position: relative;
  overflow: hidden;
}
.modal .modal-content--ldp::before {
  position: absolute;
  bottom: 0;
  right: 0;
  content: "";
  width: 45%;
  height: 100%;
  background: url(../../images/data/bg-ellipse-modal.svg) no-repeat;
}
@media (max-width: 768px) {
  .modal .modal-content--ldp::before {
    width: 364px;
  }
}
.modal .modal-content--ldp::after {
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  width: 406px;
  height: 100%;
  background: url(../../images/data/bg-mask-group.svg) no-repeat;
}
@media (max-width: 768px) {
  .modal .modal-content--ldp::after {
    background-size: cover;
    right: -100px;
  }
}
.modal .modal-content--ldp .modal-body {
  padding: 30px 38px;
  position: relative;
  z-index: 1;
}
@media (max-width: 768px) {
  .modal .modal-content--ldp .modal-body {
    padding: 32px 24px;
  }
}
.modal .modal-content--ldp .modal-body::after {
  position: absolute;
  bottom: -120px;
  right: 0;
  content: "";
  width: 390px;
  height: 436px;
  background: url(../../images/data/bg-dot-group.svg) no-repeat bottom left;
}
@media (max-width: 768px) {
  .modal .modal-content--ldp .modal-body::after {
    display: none;
  }
}
.modal .modal-content--ldp .modal-body .btn-close {
  position: absolute;
  top: 0;
  right: 0;
}
.modal .modal-content--ldp .modal-body .btn-close .btn {
  border-radius: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: #A11818;
  width: 40px;
  height: 40px;
}
.modal .modal-content--ldp .modal-body .btn-close .btn .svg path {
  stroke: #fff;
}
.modal .modal-content--ldp .modal-body .seminar-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  position: relative;
  z-index: 2;
}
.modal .modal-content--ldp .modal-body .seminar-row .seminar-col:nth-child(1) {
  width: 358px;
}
@media (max-width: 768px) {
  .modal .modal-content--ldp .modal-body .seminar-row .seminar-col:nth-child(1) {
    width: 100%;
  }
}
.modal .modal-content--ldp .modal-body .seminar-row .seminar-col:nth-child(2) {
  width: calc(100% - 358px);
  padding-left: 8px;
}
@media (max-width: 768px) {
  .modal .modal-content--ldp .modal-body .seminar-row .seminar-col:nth-child(2) {
    width: 100%;
    padding: 0;
  }
}
.modal .modal-content--ldp .modal-body .seminar-row .seminar-col:nth-child(2) .btn-register {
  display: none;
  margin-top: 10px;
}
@media (max-width: 768px) {
  .modal .modal-content--ldp .modal-body .seminar-row .seminar-col:nth-child(2) .btn-register {
    display: block;
  }
}
.modal .modal-content--ldp .modal-body .seminar-row .seminar-col:nth-child(2) .btn-register .btn {
  width: 100%;
}
@media (max-width: 768px) {
  .modal .modal-content--ldp .modal-body .seminar-row .seminar-col:nth-child(2) .btn-register .btn {
    font-size: 16px;
    height: 40px;
  }
}
.modal .modal-content--ldp .modal-body .seminar-row .seminar-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.modal .modal-content--ldp .modal-body .seminar-row .seminar-head .seminar-label {
  padding: 4px 16px;
  border-radius: 4px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(12, 182, 255, 0.2)), color-stop(35%, rgba(153, 221, 255, 0.2)), to(rgba(190, 255, 243, 0.2)));
  background: linear-gradient(to right, rgba(12, 182, 255, 0.2) 0%, rgba(153, 221, 255, 0.2) 35%, rgba(190, 255, 243, 0.2) 100%);
  color: #fff;
  font-size: 16px;
  margin-right: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 768px) {
  .modal .modal-content--ldp .modal-body .seminar-row .seminar-head .seminar-label {
    padding: 8px;
    font-size: 12px;
  }
}
.modal .modal-content--ldp .modal-body .seminar-row .seminar-head .seminar-label .seminar-span {
  font-weight: 600;
}
.modal .modal-content--ldp .modal-body .seminar-row .seminar-head .seminar-label .seminar-spanText {
  color: rgba(207, 224, 240, 0.8);
}
.modal .modal-content--ldp .modal-body .seminar-row .seminar-head .seminar-language ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 7px 6px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(12, 182, 255, 0.2)), color-stop(35%, rgba(153, 221, 255, 0.2)), to(rgba(190, 255, 243, 0.2)));
  background: linear-gradient(to right, rgba(12, 182, 255, 0.2) 0%, rgba(153, 221, 255, 0.2) 35%, rgba(190, 255, 243, 0.2) 100%);
  border-radius: 4px;
}
@media (max-width: 768px) {
  .modal .modal-content--ldp .modal-body .seminar-row .seminar-head .seminar-language ul {
    padding: 4px;
  }
}
.modal .modal-content--ldp .modal-body .seminar-row .seminar-head .seminar-language ul li {
  margin-right: 4px;
}
.modal .modal-content--ldp .modal-body .seminar-row .seminar-head .seminar-language ul li:last-child {
  margin-right: 0;
}
.modal .modal-content--ldp .modal-body .seminar-row .seminar-info {
  padding-top: 34px;
}
@media (max-width: 768px) {
  .modal .modal-content--ldp .modal-body .seminar-row .seminar-info {
    padding-top: 24px;
  }
}
.modal .modal-content--ldp .modal-body .seminar-row .seminar-info .seminar-title {
  font-size: 28px;
  font-weight: 600;
  line-height: 40px;
}
@media (max-width: 768px) {
  .modal .modal-content--ldp .modal-body .seminar-row .seminar-info .seminar-title {
    font-size: 20px;
    line-height: 28px;
  }
}
.modal .modal-content--ldp .modal-body .seminar-row .seminar-info .seminar-title .seminar-gradient {
  background: linear-gradient(45deg, rgb(12, 182, 255) 0%, rgb(153, 221, 255) 51%, rgb(190, 255, 243) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}
.modal .modal-content--ldp .modal-body .seminar-row .seminar-info .seminar-title .seminar-span {
  color: #fff;
  display: block;
}
.modal .modal-content--ldp .modal-body .seminar-row .seminar-info .seminar-text {
  color: #C6D8F2;
  font-size: 20px;
  margin-top: 20px;
  line-height: 30px;
}
@media (max-width: 768px) {
  .modal .modal-content--ldp .modal-body .seminar-row .seminar-info .seminar-text {
    font-size: 16px;
    line-height: 24px;
    margin-top: 16px;
  }
}
.modal .modal-content--ldp .modal-body .seminar-row .seminar-info .btn-register {
  padding-top: 80px;
  max-width: 278px;
}
@media (max-width: 768px) {
  .modal .modal-content--ldp .modal-body .seminar-row .seminar-info .btn-register {
    display: none;
  }
}
.modal .modal-content--ldp .modal-body .seminar-row .seminar-info .btn-register .btn {
  width: 100%;
}
.modal .modal-content--ldp .modal-body .seminar-row .seminar-date {
  padding-top: 68px;
}
@media (max-width: 768px) {
  .modal .modal-content--ldp .modal-body .seminar-row .seminar-date {
    margin-left: 0;
    padding-top: 20px;
  }
}
.modal .modal-content--ldp .modal-body .seminar-row .seminar-date ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin-left: -4px;
}
@media (max-width: 768px) {
  .modal .modal-content--ldp .modal-body .seminar-row .seminar-date ul {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}
.modal .modal-content--ldp .modal-body .seminar-row .seminar-date ul li {
  background: #206EB6;
  border-radius: 4px;
  margin: 0 0 0 4px;
  padding: 4px 2px;
  text-align: center;
  overflow: hidden;
  position: relative;
  min-width: 68px;
}
.modal .modal-content--ldp .modal-body .seminar-row .seminar-date ul li::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(rgb(12, 182, 255)), color-stop(50%, rgb(153, 221, 255)), to(rgb(190, 255, 243)));
  background: linear-gradient(to right, rgb(12, 182, 255) 0%, rgb(153, 221, 255) 50%, rgb(190, 255, 243) 100%);
}
.modal .modal-content--ldp .modal-body .seminar-row .seminar-date ul li span {
  display: block;
  color: #fff;
}
.modal .modal-content--ldp .modal-body .seminar-row .seminar-date ul li span.seminar-number {
  font-size: 28px;
  font-weight: 500;
}
.modal .modal-content--ldp .modal-body .seminar-row .seminar-date ul li span.seminar-item {
  font-size: 16px;
  position: relative;
  padding-top: 3px;
}
.modal .modal-content--ldp .modal-body .seminar-row .seminar-date ul li span.seminar-item::after {
  position: absolute;
  top: 0;
  left: calc(50% - 10px);
  content: "";
  width: 20px;
  height: 1px;
  background: #fff;
}
.modal .modal-content--ldp .modal-body .seminar-row .seminar-thumb {
  margin: 24px 0 0;
}
@media (max-width: 768px) {
  .modal .modal-content--ldp .modal-body .seminar-row .seminar-thumb {
    margin: 0;
  }
}

/* -- page-ldpShowcase -- */
.page-ldpShowcase {
  /************/
  /*********/
}
.page-ldpShowcase .banner-ldp {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top right;
  height: 811px;
  overflow: hidden;
  position: relative;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .banner-ldp {
    height: 640px;
  }
}
@media (max-width: 900px) {
  .page-ldpShowcase .banner-ldp {
    background-position: 70%;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .banner-ldp {
    background-position: 57%;
  }
}
.page-ldpShowcase .banner-ldp::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), color-stop(11%, rgba(0, 0, 0, 0)), color-stop(78%, rgba(0, 0, 0, 0.65)), to(rgba(0, 0, 0, 0.65)));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 11%, rgba(0, 0, 0, 0.65) 78%, rgba(0, 0, 0, 0.65) 100%);
}
.page-ldpShowcase .banner-ldp .banner-content {
  position: relative;
  z-index: 1;
  padding: 72px 0;
}
.page-ldpShowcase .banner-ldp .banner-label {
  font-size: 18px;
  font-weight: 300;
  color: #fff;
  padding-left: 38px;
  position: relative;
}
@media (max-width: 768px) {
  .page-ldpShowcase .banner-ldp .banner-label {
    font-size: 12px;
    padding-left: 23px;
  }
}
.page-ldpShowcase .banner-ldp .banner-label::after {
  position: absolute;
  top: 4px;
  left: 0;
  content: "";
  width: 14px;
  height: 14px;
  background: #fff;
}
@media (max-width: 768px) {
  .page-ldpShowcase .banner-ldp .banner-label::after {
    width: 7px;
    height: 7px;
    top: 3px;
  }
}
.page-ldpShowcase .banner-ldp .banner-title {
  margin-top: 64px;
  font-size: 64px;
  font-weight: 500;
  line-height: 120%;
  color: #fff;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.6);
}
@media (max-width: 1280px) {
  .page-ldpShowcase .banner-ldp .banner-title {
    font-size: 48px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .banner-ldp .banner-title {
    font-size: 32px;
  }
}
.page-ldpShowcase .banner-ldp .banner-text {
  font-size: 24px;
  color: #F1F2F2;
  margin-top: 32px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .banner-ldp .banner-text {
    font-size: 21px;
    margin-top: 24px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .banner-ldp .banner-text {
    font-size: 16px;
  }
}
.page-ldpShowcase .ourShowcase .ourShowcase-title {
  background: #e8f0f8;
  padding: 56px 0;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .ourShowcase .ourShowcase-title {
    padding: 26px 0;
  }
}
.page-ldpShowcase .ourShowcase .ourShowcase-title .tlt {
  color: #2F333E;
  font-size: 41px;
  text-align: center;
  font-weight: 500;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .ourShowcase .ourShowcase-title .tlt {
    font-size: 23px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .ourShowcase .ourShowcase-title .tlt {
    font-size: 20px;
  }
}
.page-ldpShowcase .ourShowcase .ourShowcase-title .tlt .tlt-span {
  color: #0246C6;
}
.page-ldpShowcase .ourShowcase .our-row {
  max-width: 1272px;
  margin: 0 auto;
  padding: 64px 0 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
@media (max-width: 768px) {
  .page-ldpShowcase .ourShowcase .our-row {
    display: none;
  }
}
.page-ldpShowcase .ourShowcase .our-row .our-col:nth-child(1), .page-ldpShowcase .ourShowcase .our-row .our-col:nth-child(3) {
  width: 28%;
}
@media (max-width: 900px) {
  .page-ldpShowcase .ourShowcase .our-row .our-col:nth-child(1), .page-ldpShowcase .ourShowcase .our-row .our-col:nth-child(3) {
    width: 30%;
  }
}
.page-ldpShowcase .ourShowcase .our-row .our-col:nth-child(2) {
  width: 44%;
  position: relative;
  z-index: 2;
  text-align: center;
}
@media (max-width: 900px) {
  .page-ldpShowcase .ourShowcase .our-row .our-col:nth-child(2) {
    width: 40%;
  }
}
.page-ldpShowcase .ourShowcase .our-row .our-col:nth-child(2) img {
  width: 100%;
}
.page-ldpShowcase .ourShowcase .our-row .our-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 100%;
}
.page-ldpShowcase .ourShowcase .our-row .our-nav.our-nav--left {
  padding-right: 48px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .ourShowcase .our-row .our-nav.our-nav--left {
    padding-right: 28px;
  }
}
@media (max-width: 900px) {
  .page-ldpShowcase .ourShowcase .our-row .our-nav.our-nav--left {
    padding-right: 18px;
  }
}
@media (max-width: 900px) {
  .page-ldpShowcase .ourShowcase .our-row .our-nav.our-nav--left {
    padding-right: 10px;
  }
}
.page-ldpShowcase .ourShowcase .our-row .our-nav.our-nav--left li:nth-child(1) .our-wrap::before {
  -webkit-transform: rotate(26deg);
          transform: rotate(26deg);
  bottom: -45px;
  right: -190px;
}
.page-ldpShowcase .ourShowcase .our-row .our-nav.our-nav--left li:nth-child(2) .our-wrap::before {
  -webkit-transform: rotate(26deg);
          transform: rotate(26deg);
  bottom: -45px;
  right: -190px;
}
.page-ldpShowcase .ourShowcase .our-row .our-nav.our-nav--left li:nth-child(3) .our-wrap::before {
  bottom: -1px;
  right: -200px;
}
.page-ldpShowcase .ourShowcase .our-row .our-nav.our-nav--left li:nth-child(4) .our-wrap::before {
  -webkit-transform: rotate(-26deg);
          transform: rotate(-26deg);
  bottom: 43px;
  right: -190px;
}
.page-ldpShowcase .ourShowcase .our-row .our-nav.our-nav--left li:nth-child(5) .our-wrap::before {
  -webkit-transform: rotate(-26deg);
          transform: rotate(-26deg);
  bottom: 43px;
  right: -190px;
}
.page-ldpShowcase .ourShowcase .our-row .our-nav.our-nav--left li .our-round::after {
  left: 0;
}
.page-ldpShowcase .ourShowcase .our-row .our-nav.our-nav--right li {
  padding-left: 48px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .ourShowcase .our-row .our-nav.our-nav--right li {
    padding-left: 28px;
  }
}
@media (max-width: 900px) {
  .page-ldpShowcase .ourShowcase .our-row .our-nav.our-nav--right li {
    padding-left: 18px;
  }
}
@media (max-width: 900px) {
  .page-ldpShowcase .ourShowcase .our-row .our-nav.our-nav--right li {
    padding-left: 10px;
  }
}
.page-ldpShowcase .ourShowcase .our-row .our-nav.our-nav--right li:nth-child(1) .our-wrap::before {
  -webkit-transform: rotate(-26deg);
          transform: rotate(-26deg);
  bottom: -45px;
  left: -190px;
}
.page-ldpShowcase .ourShowcase .our-row .our-nav.our-nav--right li:nth-child(2) .our-wrap::before {
  -webkit-transform: rotate(-26deg);
          transform: rotate(-26deg);
  bottom: -45px;
  left: -190px;
}
.page-ldpShowcase .ourShowcase .our-row .our-nav.our-nav--right li:nth-child(3) .our-wrap::before {
  bottom: -1px;
  left: -200px;
}
.page-ldpShowcase .ourShowcase .our-row .our-nav.our-nav--right li:nth-child(4) .our-wrap::before {
  -webkit-transform: rotate(26deg);
          transform: rotate(26deg);
  bottom: 43px;
  left: -190px;
}
.page-ldpShowcase .ourShowcase .our-row .our-nav.our-nav--right li:nth-child(5) .our-wrap::before {
  -webkit-transform: rotate(26deg);
          transform: rotate(26deg);
  bottom: 43px;
  left: -190px;
}
.page-ldpShowcase .ourShowcase .our-row .our-nav.our-nav--right li .our-round::after {
  right: 0;
}
.page-ldpShowcase .ourShowcase .our-row .our-nav li {
  position: relative;
}
.page-ldpShowcase .ourShowcase .our-row .our-nav li .our-wrap {
  position: relative;
  padding-bottom: 6px;
  border-bottom: 1px solid #252C6D;
}
.page-ldpShowcase .ourShowcase .our-row .our-nav li .our-wrap::before {
  position: absolute;
  content: "";
  width: 200px;
  height: 1px;
  background: #252C6D;
  z-index: 1;
}
.page-ldpShowcase .ourShowcase .our-row .our-nav li .our-round {
  position: relative;
}
.page-ldpShowcase .ourShowcase .our-row .our-nav li .our-round::after {
  position: absolute;
  content: "";
  bottom: -9px;
  background: #252C6D;
  width: 6px;
  height: 6px;
  min-height: 6px;
  border-radius: 50%;
}
.page-ldpShowcase .ourShowcase .our-row .our-nav li a {
  padding: 8px 24px 8px 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #383C46;
  max-width: 308px;
  position: relative;
  border: 1px solid transparent;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .ourShowcase .our-row .our-nav li a {
    font-size: 14px;
  }
}
@media (max-width: 900px) {
  .page-ldpShowcase .ourShowcase .our-row .our-nav li a {
    padding: 4px;
  }
}
.page-ldpShowcase .ourShowcase .our-row .our-nav li a:hover {
  background: #fff;
  border-radius: 4px;
  border: 1px solid rgba(37, 44, 109, 0.2);
}
.page-ldpShowcase .ourShowcase .our-row .our-nav li a::after {
  position: absolute;
  bottom: 0;
  left: 0;
}
.page-ldpShowcase .ourShowcase .our-row .our-nav li a .our-item {
  width: 32px;
  height: 32px;
  min-width: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 16px;
}
@media (max-width: 900px) {
  .page-ldpShowcase .ourShowcase .our-row .our-nav li a .our-item {
    margin-right: 10px;
    width: 24px;
    height: 24px;
    min-width: 24px;
    margin-right: 6px;
  }
}
@media (max-width: 900px) {
  .page-ldpShowcase .ourShowcase .our-row .our-nav li a .our-item .svg {
    max-width: 10px;
  }
}
.page-ldpShowcase .ourShowcase .our-row .our-nav li a .our-item.our-item--bg01 {
  background: #3737CC;
}
.page-ldpShowcase .ourShowcase .our-row .our-nav li a .our-item.our-item--bg02 {
  background: #0A79A0;
}
.page-ldpShowcase .ourShowcase .our-row .our-nav li a .our-item.our-item--bg03 {
  background: #1AA87F;
}
.page-ldpShowcase .ourShowcase .our-row .our-nav li a .our-item.our-item--bg04 {
  background: #272772;
}
.page-ldpShowcase .ourShowcase .our-sp {
  display: none;
  padding: 16px 0;
}
@media (max-width: 768px) {
  .page-ldpShowcase .ourShowcase .our-sp {
    display: block;
  }
}
.page-ldpShowcase .ourShowcase .our-sp .our-group {
  padding-top: 16px;
}
.page-ldpShowcase .ourShowcase .our-sp .our-box {
  overflow: hidden;
  border-radius: 4px;
}
.page-ldpShowcase .ourShowcase .our-sp .our-box.our-box--bg01 .our-head {
  background: #4848E5;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}
.page-ldpShowcase .ourShowcase .our-sp .our-box.our-box--bg01 .our-body:nth-child(2) {
  background: #3737CC;
}
.page-ldpShowcase .ourShowcase .our-sp .our-box.our-box--bg01 .our-body:nth-child(3) {
  background: #14148C;
}
.page-ldpShowcase .ourShowcase .our-sp .our-box.our-box--bg02 .our-head {
  background: #1B8DBF;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}
.page-ldpShowcase .ourShowcase .our-sp .our-box.our-box--bg02 .our-body:nth-child(2) {
  background: #0A79A0;
}
.page-ldpShowcase .ourShowcase .our-sp .our-box.our-box--bg02 .our-body:nth-child(3) {
  background: #01587A;
}
.page-ldpShowcase .ourShowcase .our-sp .our-box.our-box--bg03 .our-head {
  background: #33C4A1;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}
.page-ldpShowcase .ourShowcase .our-sp .our-box.our-box--bg03 .our-body:nth-child(2) {
  background: #1AA87F;
}
.page-ldpShowcase .ourShowcase .our-sp .our-box.our-box--bg03 .our-body:nth-child(3) {
  background: #0A7F58;
}
.page-ldpShowcase .ourShowcase .our-sp .our-box.our-box--bg04 .our-head {
  background: #3E3189;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}
.page-ldpShowcase .ourShowcase .our-sp .our-box.our-box--bg04 .our-body:nth-child(2) {
  background: #272772;
}
.page-ldpShowcase .ourShowcase .our-sp .our-box.our-box--bg04 .our-body:nth-child(3) {
  background: #1E1E5E;
}
.page-ldpShowcase .ourShowcase .our-sp .our-box .our-head {
  padding: 8px;
}
.page-ldpShowcase .ourShowcase .our-sp .our-box .our-body {
  border-top: 1px solid #fff;
}
.page-ldpShowcase .ourShowcase .our-sp .our-box .our-body ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.page-ldpShowcase .ourShowcase .our-sp .our-box .our-body ul li {
  width: 100%;
  padding: 8px;
  font-size: 12px;
  color: #fff;
  border-right: 1px solid #fff;
}
.page-ldpShowcase .ourShowcase .our-sp .our-box .our-body ul li:last-child {
  border: none;
}
.page-ldpShowcase .ourShowcase .our-sp .our-navigation ul li {
  padding-top: 8px;
}
.page-ldpShowcase .ourShowcase .our-sp .our-navigation ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 8px;
  color: #383C46;
  font-weight: 500;
  border: 1px solid transparent;
}
.page-ldpShowcase .ourShowcase .our-sp .our-navigation ul li a:hover {
  background: #fff;
  border-radius: 4px;
  border: 1px solid rgba(37, 44, 109, 0.2);
}
.page-ldpShowcase .ourShowcase .our-sp .our-navigation ul li a .our-item {
  width: 32px;
  min-width: 32px;
  height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  margin-right: 16px;
}
.page-ldpShowcase .ourShowcase .our-sp .our-navigation ul li a .our-item.our-item--bg01 {
  background: #3737CC;
}
.page-ldpShowcase .ourShowcase .our-sp .our-navigation ul li a .our-item.our-item--bg02 {
  background: #0A79A0;
}
.page-ldpShowcase .ourShowcase .our-sp .our-navigation ul li a .our-item.our-item--bg03 {
  background: #1AA87F;
}
.page-ldpShowcase .highlight {
  padding: 64px 0;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .highlight {
    padding: 32px 0;
  }
}
.page-ldpShowcase .highlight .highlight-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 480px) {
  .page-ldpShowcase .highlight .highlight-title {
    display: block;
  }
}
.page-ldpShowcase .highlight .highlight-title .title-label {
  color: #6C6F77;
  font-size: 16px;
}
@media (max-width: 768px) {
  .page-ldpShowcase .highlight .highlight-title .title-label {
    font-size: 12px;
  }
}
.page-ldpShowcase .highlight .highlight-title .title-tlt {
  font-size: 72px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .highlight .highlight-title .title-tlt {
    font-size: 41px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .highlight .highlight-title .title-tlt {
    font-size: 26px;
    text-align: right;
  }
}
@media (max-width: 480px) {
  .page-ldpShowcase .highlight .highlight-title .title-tlt {
    margin-top: 24px;
  }
}
.page-ldpShowcase .highlight .highlight-title .title-tlt .title-span {
  color: #0246C6;
}
@media (max-width: 768px) {
  .page-ldpShowcase .highlight .highlight-statistical {
    padding-top: 36px;
  }
}
.page-ldpShowcase .highlight .highlight-statistical ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-left: -64px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .highlight .highlight-statistical ul {
    margin-left: -18px;
  }
}
.page-ldpShowcase .highlight .highlight-statistical ul li {
  width: calc(50% - 64px);
  margin: 32px 0 0 64px;
  padding: 48px;
  border-radius: 8px;
  min-height: 259px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  color: #fff;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .highlight .highlight-statistical ul li {
    width: calc(50% - 18px);
    margin: 32px 0 0 18px;
    min-height: 178px;
    padding: 32px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .highlight .highlight-statistical ul li {
    width: calc(100% - 18px);
    margin: 8px 0 0 18px;
    min-height: initial;
    padding: 16px;
    border-radius: 4px;
  }
}
.page-ldpShowcase .highlight .highlight-statistical ul li:nth-child(1) {
  background: url(../../images/data/highlight-ellipse01.svg) no-repeat bottom right #26559B;
}
.page-ldpShowcase .highlight .highlight-statistical ul li:nth-child(2) {
  background: url(../../images/data/highlight-ellipse02.svg) no-repeat bottom right #1F7285;
}
.page-ldpShowcase .highlight .highlight-statistical ul li .highlight-number {
  font-size: 64px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .highlight .highlight-statistical ul li .highlight-number {
    font-size: 48px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .highlight .highlight-statistical ul li .highlight-number {
    font-size: 26px;
  }
}
.page-ldpShowcase .highlight .highlight-statistical ul li .highlight-label {
  font-size: 32px;
  padding-top: 8px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .highlight .highlight-statistical ul li .highlight-label {
    font-size: 20px;
    text-align: right;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .highlight .highlight-statistical ul li .highlight-label {
    font-size: 13px;
    padding-top: 16px;
  }
}
.page-ldpShowcase .highlight .highlight-start {
  background: -webkit-gradient(linear, left top, left bottom, from(rgb(18, 27, 113)), to(rgb(26, 59, 143)));
  background: linear-gradient(to bottom, rgb(18, 27, 113) 0%, rgb(26, 59, 143) 100%);
  position: relative;
  border-radius: 16px;
  margin-top: 64px;
  padding: 64px 12px;
  overflow: hidden;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .highlight .highlight-start {
    padding: 32px 12px;
    margin-top: 32px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .highlight .highlight-start {
    padding: 24px;
    border-radius: 8px;
  }
}
.page-ldpShowcase .highlight .highlight-start::before, .page-ldpShowcase .highlight .highlight-start::after {
  position: absolute;
  content: "";
  width: 229px;
  height: 230px;
}
.page-ldpShowcase .highlight .highlight-start::before {
  background: url(../../images/data/highlight-ellipse03.svg) no-repeat;
  left: 0;
  top: 0;
}
.page-ldpShowcase .highlight .highlight-start::after {
  background: url(../../images/data/highlight-ellipse04.svg) no-repeat;
  right: 0;
  bottom: 0;
}
.page-ldpShowcase .highlight .highlight-start .highlight-digital {
  position: relative;
  z-index: 1;
}
.page-ldpShowcase .highlight .highlight-start .highlight-digital .highlight-digital__text {
  font-size: 32px;
  color: #fff;
  text-align: center;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .highlight .highlight-start .highlight-digital .highlight-digital__text {
    font-size: 24px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .highlight .highlight-start .highlight-digital .highlight-digital__text {
    font-size: 18px;
  }
}
.page-ldpShowcase .highlight .highlight-start .highlight-digital__btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 48px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .highlight .highlight-start .highlight-digital__btn {
    margin-top: 32px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .highlight .highlight-start .highlight-digital__btn {
    margin-top: 24px;
  }
}
.page-ldpShowcase .deepDive {
  background: #FBFBFB;
  padding: 56px 0 104px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .deepDive {
    padding: 32px 0 64px;
  }
}
@media (max-width: 1280px) {
  .page-ldpShowcase .deepDive .deep-list .deep-info .deep-content .deep-title {
    min-height: 43px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .deepDive .deep-list .deep-info .deep-content .deep-title {
    min-height: 40px;
  }
}
@media (max-width: 1280px) {
  .page-ldpShowcase .deepDive .deep-list .deep-info .deep-content .deep-title a {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .deepDive .deep-list .deep-info .deep-content .deep-title a {
    font-size: 16px;
  }
}
.page-ldpShowcase .hospitalClinic {
  padding: 56px 0 96px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .hospitalClinic {
    padding: 32px 0 64px;
  }
}
.page-ldpShowcase .unlock {
  background: linear-gradient(135deg, rgb(191, 215, 255) 0%, rgb(255, 255, 255) 37%, rgb(255, 255, 255) 64%, rgb(214, 244, 255) 100%);
  padding: 80px 0 96px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .unlock {
    padding: 56px 0 64px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .unlock {
    padding: 32px 0;
  }
}
.page-ldpShowcase .unlock .unlock-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: -8.43%;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .unlock .unlock-row {
    margin-left: -4%;
  }
}
.page-ldpShowcase .unlock .unlock-row .unlock-col {
  width: 50%;
  padding-left: 8.43%;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .unlock .unlock-row .unlock-col {
    padding-left: 4%;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .unlock .unlock-row .unlock-col {
    width: 100%;
  }
}
.page-ldpShowcase .unlock .title .title-text {
  font-size: 41px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .unlock .title .title-text {
    font-size: 23px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .unlock .title .title-text {
    font-size: 20px;
  }
}
.page-ldpShowcase .unlock .unlock-box {
  background: -webkit-gradient(linear, left top, right top, from(rgb(255, 255, 255)), to(rgba(255, 255, 255, 0)));
  background: linear-gradient(to right, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
  margin-top: 104px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .unlock .unlock-box {
    margin-top: 48px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .unlock .unlock-box {
    margin-top: 24px;
  }
}
.page-ldpShowcase .unlock .unlock-wrap .unlock-info:first-of-type .unlock-header {
  border-bottom: none;
}
.page-ldpShowcase .unlock .unlock-wrap .unlock-info .unlock-header {
  padding: 32px;
  border-top: 1px solid #F1F2F2;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .unlock .unlock-wrap .unlock-info .unlock-header {
    padding: 24px 32px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .unlock .unlock-wrap .unlock-info .unlock-header {
    padding: 16px;
  }
}
.page-ldpShowcase .unlock .unlock-wrap .unlock-info .unlock-header .unlock-number {
  color: #0246C6;
  font-size: 24px;
  font-weight: 300;
  min-width: 82px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .unlock .unlock-wrap .unlock-info .unlock-header .unlock-number {
    min-width: 50px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .unlock .unlock-wrap .unlock-info .unlock-header .unlock-number {
    min-width: 42px;
    font-size: 16px;
  }
}
.page-ldpShowcase .unlock .unlock-wrap .unlock-info .unlock-header .unlock-label {
  font-size: 24px;
  color: #383C46;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .unlock .unlock-wrap .unlock-info .unlock-header .unlock-label {
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .unlock .unlock-wrap .unlock-info .unlock-header .unlock-label {
    font-size: 14px;
  }
}
.page-ldpShowcase .unlock .unlock-wrap .unlock-info .unlock-main .unlock-team {
  padding: 0 32px 32px 114px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .unlock .unlock-wrap .unlock-info .unlock-main .unlock-team {
    padding: 0 32px 32px 82px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .unlock .unlock-wrap .unlock-info .unlock-main .unlock-team {
    padding: 0 16px 16px 58px;
  }
}
.page-ldpShowcase .unlock .unlock-wrap .unlock-info .unlock-main .unlock-team .unlock-team__text {
  font-size: 18px;
  line-height: 160%;
  color: #383C46;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .unlock .unlock-wrap .unlock-info .unlock-main .unlock-team .unlock-team__text {
    font-size: 16px;
    line-height: 140%;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .unlock .unlock-wrap .unlock-info .unlock-main .unlock-team .unlock-team__text {
    font-size: 14px;
  }
}
.page-ldpShowcase .unlock .unlockSystems {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 768px) {
  .page-ldpShowcase .unlock .unlockSystems {
    margin-top: 24px;
  }
}
.page-ldpShowcase .unlock .unlockSystems .unlockSystems__thumb {
  width: calc(100% - 72px);
}
.page-ldpShowcase .unlock .unlockSystems .unlockSystems__text {
  width: 72px;
}
.page-ldpShowcase .unlock .unlockSystems .sliderThumb-unlock .slick-list {
  padding: 120px 0 !important;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .unlock .unlockSystems .sliderThumb-unlock .slick-list {
    padding: 100px 0 !important;
  }
}
@media (max-width: 480px) {
  .page-ldpShowcase .unlock .unlockSystems .sliderThumb-unlock .slick-list {
    padding: 80px 0 !important;
  }
}
.page-ldpShowcase .unlock .unlockSystems .sliderThumb-unlock .slick-slide {
  padding: 40px 0 40px 0;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .unlock .unlockSystems .sliderThumb-unlock .slick-slide {
    padding: 30px 0 30px 0;
  }
}
@media (max-width: 480px) {
  .page-ldpShowcase .unlock .unlockSystems .sliderThumb-unlock .slick-slide {
    padding: 20px 0 20px 0;
  }
}
.page-ldpShowcase .unlock .unlockSystems .sliderThumb-unlock .slick-slide .thumb-nail {
  opacity: 0.4;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.page-ldpShowcase .unlock .unlockSystems .sliderThumb-unlock .slick-slide .thumb-nail img {
  padding-right: 12%;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.page-ldpShowcase .unlock .unlockSystems .sliderThumb-unlock .slick-slide.slick-active .thumb-nail {
  opacity: 1;
}
.page-ldpShowcase .unlock .unlockSystems .sliderThumb-unlock .slick-slide.slick-active .thumb-nail img {
  -webkit-transform: scale(1.3) rotateZ(0deg);
          transform: scale(1.3) rotateZ(0deg);
}
.page-ldpShowcase .unlock .unlockSystems .sliderText-unlock .slick-list,
.page-ldpShowcase .unlock .unlockSystems .sliderText-unlock .slick-track {
  height: 100% !important;
}
.page-ldpShowcase .unlock .unlockSystems .sliderText-unlock .slick-slide {
  padding: 12px 0;
}
.page-ldpShowcase .unlock .unlockSystems .sliderText-unlock .slick-slide.slick-current.slick-active .unlock-text {
  color: #121B71;
}
.page-ldpShowcase .unlock .unlockSystems .sliderText-unlock .unlock-text {
  font-size: 32px;
  font-weight: 700;
  color: #E3E9F3;
  -webkit-writing-mode: vertical-rl;
      -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;
  text-orientation: mixed;
}
.page-ldpShowcase .banner-service {
  background: -webkit-gradient(linear, left top, left bottom, from(rgb(0, 14, 66)), to(rgb(0, 47, 102)));
  background: linear-gradient(to bottom, rgb(0, 14, 66) 0%, rgb(0, 47, 102) 100%);
  border-radius: 0 0 32px 32px;
  padding: 96px 0 192px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .banner-service {
    padding: 48px 0 144px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .banner-service {
    border-radius: 0 0 8px 8px;
    padding: 48px 0 96px;
  }
}
.page-ldpShowcase .banner-service .service-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.page-ldpShowcase .banner-service .service-wrap .service-info {
  width: 54%;
  padding-right: 3%;
}
@media (max-width: 768px) {
  .page-ldpShowcase .banner-service .service-wrap .service-info {
    width: 100%;
    padding-right: 0;
  }
}
.page-ldpShowcase .banner-service .service-wrap .service-thumb {
  width: 46%;
}
@media (max-width: 768px) {
  .page-ldpShowcase .banner-service .service-wrap .service-thumb {
    width: 100%;
    padding-top: 32px;
  }
}
.page-ldpShowcase .banner-service .service-wrap .service-container {
  max-width: 648px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .banner-service .service-wrap .service-container {
    max-width: 476px;
  }
}
.page-ldpShowcase .banner-service .service-wrap .service-container .service-container__label {
  color: #C7C8CB;
  font-size: 18px;
}
@media (max-width: 768px) {
  .page-ldpShowcase .banner-service .service-wrap .service-container .service-container__label {
    font-size: 13px;
  }
}
.page-ldpShowcase .banner-service .service-wrap .service-container .service-container__title {
  margin-top: 24px;
  color: #fff;
  font-size: 41px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .banner-service .service-wrap .service-container .service-container__title {
    font-size: 32px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .banner-service .service-wrap .service-container .service-container__title {
    font-size: 23px;
  }
}
.page-ldpShowcase .banner-service .service-wrap .service-container .service-container__text {
  color: #C7C8CB;
  line-height: 160%;
  font-weight: 300;
  font-size: 16px;
  margin-top: 40px;
}
@media (max-width: 768px) {
  .page-ldpShowcase .banner-service .service-wrap .service-container .service-container__text {
    font-size: 14px;
    line-height: 140%;
    font-weight: 400;
  }
}
.page-ldpShowcase .banner-service .service-wrap .service-container .btn {
  margin-top: 32px;
}
.page-ldpShowcase .system-about {
  border-radius: 16px;
  margin-top: -96px;
  position: relative;
  padding-top: 6px;
  overflow: hidden;
  -webkit-box-shadow: 0 0 16px rgba(103, 103, 103, 0.1);
          box-shadow: 0 0 16px rgba(103, 103, 103, 0.1);
}
@media (max-width: 768px) {
  .page-ldpShowcase .system-about {
    margin-top: -64px;
    border-radius: 8px;
  }
}
.page-ldpShowcase .system-about::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  height: 30px;
  width: 100%;
  background: -webkit-gradient(linear, left top, right top, from(rgb(0, 129, 248)), color-stop(25%, rgb(117, 194, 255)), color-stop(50%, rgb(81, 98, 255)), color-stop(75%, rgb(10, 127, 255)), to(rgb(0, 179, 255)));
  background: linear-gradient(to right, rgb(0, 129, 248) 0%, rgb(117, 194, 255) 25%, rgb(81, 98, 255) 50%, rgb(10, 127, 255) 75%, rgb(0, 179, 255) 100%);
}
.page-ldpShowcase .system-about .system-box {
  border-radius: 16px;
  padding: 64px 80px;
  background: #fff;
  position: relative;
  z-index: 1;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .system-about .system-box {
    padding: 32px 48px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .system-about .system-box {
    padding: 32px 16px;
    border-radius: 8px;
  }
}
.page-ldpShowcase .system-about .system-box .system-text {
  font-size: 24px;
  line-height: 150%;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .system-about .system-box .system-text {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .system-about .system-box .system-text {
    font-size: 16px;
  }
}
.page-ldpShowcase .basicFeatures {
  background: #fff;
  border-radius: 16px;
  -webkit-box-shadow: 0 0 16px rgba(103, 103, 103, 0.1);
          box-shadow: 0 0 16px rgba(103, 103, 103, 0.1);
  position: relative;
  margin-top: 32px;
  padding: 48px 80px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .basicFeatures {
    padding: 32px 48px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .basicFeatures {
    border-radius: 8px;
    padding: 32px 14px;
    margin-top: 16px;
  }
}
.page-ldpShowcase .basicFeatures .title {
  padding-bottom: 24px;
  border-bottom: 1px solid #C7C8CB;
}
.page-ldpShowcase .basicFeatures .title-basic {
  padding: 48px 0 24px;
  border-bottom: 1px solid #C7C8CB;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .basicFeatures .title-basic {
    padding: 32px 0 24px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .basicFeatures .title-basic {
    padding: 16px 0;
  }
}
.page-ldpShowcase .basicFeatures .title-basic .title-text {
  font-size: 26px;
  font-weight: 700;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .basicFeatures .title-basic .title-text {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .basicFeatures .title-basic .title-text {
    font-size: 20px;
    font-weight: 500;
  }
}
.page-ldpShowcase .basicFeatures .basicContent .basic-group {
  padding-bottom: 32px;
}
.page-ldpShowcase .basicFeatures .basicContent .basic-group:last-child {
  padding-bottom: 0;
}
.page-ldpShowcase .basicFeatures .basicList > ul > li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: -2.5%;
  padding-top: 40px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .basicFeatures .basicList > ul > li {
    padding-top: 24px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .basicFeatures .basicList > ul > li {
    padding-top: 48px;
  }
}
.page-ldpShowcase .basicFeatures .basicList > ul > li:nth-of-type(2n + 2) .basicList-thumb {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}
@media (max-width: 768px) {
  .page-ldpShowcase .basicFeatures .basicList > ul > li:nth-of-type(2n + 2) .basicList-thumb {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
}
.page-ldpShowcase .basicFeatures .basicList > ul > li:nth-of-type(2n + 2) .basicList-thumb .basicList-mw {
  margin-left: auto;
}
.page-ldpShowcase .basicFeatures .basicList > ul > li:nth-of-type(2n + 2) .basicList-info {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}
@media (max-width: 768px) {
  .page-ldpShowcase .basicFeatures .basicList > ul > li:nth-of-type(2n + 2) .basicList-info {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
}
.page-ldpShowcase .basicFeatures .basicList > ul > li:nth-of-type(2n + 2) .basicList-info .basicList-mw {
  margin-left: 0;
}
.page-ldpShowcase .basicFeatures .basicList > ul > li .basicList-thumb,
.page-ldpShowcase .basicFeatures .basicList > ul > li .basicList-info {
  width: 50%;
  padding-left: 2.5%;
}
@media (max-width: 768px) {
  .page-ldpShowcase .basicFeatures .basicList > ul > li .basicList-thumb,
  .page-ldpShowcase .basicFeatures .basicList > ul > li .basicList-info {
    width: 100%;
  }
}
.page-ldpShowcase .basicFeatures .basicList > ul > li .basicList-mw {
  max-width: 568px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .basicFeatures .basicList > ul > li .basicList-mw {
    max-width: 432px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .basicFeatures .basicList > ul > li .basicList-mw {
    max-width: 100%;
  }
}
.page-ldpShowcase .basicFeatures .basicList > ul > li .basicList-thumb img {
  width: 100%;
}
@media (max-width: 768px) {
  .page-ldpShowcase .basicFeatures .basicList > ul > li .basicList-info {
    padding-top: 16px;
  }
}
.page-ldpShowcase .basicFeatures .basicList > ul > li .basicList-info .basicList-mw {
  margin-left: auto;
}
.page-ldpShowcase .basicFeatures .basicList > ul > li .basicList-info .basicList-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-bottom: 32px;
  border-bottom: 1px solid #0246C6;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .basicFeatures .basicList > ul > li .basicList-info .basicList-title {
    padding-bottom: 24px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .basicFeatures .basicList > ul > li .basicList-info .basicList-title {
    padding-bottom: 16px;
  }
}
.page-ldpShowcase .basicFeatures .basicList > ul > li .basicList-info .basicList-title .basicList-number {
  min-width: 64px;
  padding-right: 8px;
  color: #0246C6;
  font-size: 32px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .basicFeatures .basicList > ul > li .basicList-info .basicList-title .basicList-number {
    font-size: 24px;
  }
}
.page-ldpShowcase .basicFeatures .basicList > ul > li .basicList-info .basicList-title .basicList-label {
  font-size: 23px;
  font-weight: 700;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .basicFeatures .basicList > ul > li .basicList-info .basicList-title .basicList-label {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .basicFeatures .basicList > ul > li .basicList-info .basicList-title .basicList-label {
    font-size: 16px;
  }
}
.page-ldpShowcase .basicFeatures .basicList > ul > li .basicList-info .basicList-content {
  padding: 32px 0 0 64px;
}
@media (max-width: 768px) {
  .page-ldpShowcase .basicFeatures .basicList > ul > li .basicList-info .basicList-content {
    padding: 16px 0 0 64px;
  }
}
.page-ldpShowcase .basicFeatures .basicList > ul > li .basicList-info .basicList-content .basicList-text {
  color: #5A5D66;
  font-size: 16px;
  line-height: 160%;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .basicFeatures .basicList > ul > li .basicList-info .basicList-content .basicList-text {
    line-height: 150%;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .basicFeatures .basicList > ul > li .basicList-info .basicList-content .basicList-text {
    font-size: 14px;
  }
}
.page-ldpShowcase .basicFeatures .basicBtn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-top: 40px;
}
.page-ldpShowcase .benefitsBag {
  background: linear-gradient(135deg, rgb(165, 199, 255) 0%, rgb(255, 255, 255) 55%, rgb(255, 255, 255) 55%, rgb(227, 248, 255) 100%, rgb(211, 244, 255) 100%);
  padding: 176px 0 48px;
  margin-top: -96px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .benefitsBag {
    padding: 120px 0 48px;
    margin-top: -80px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .benefitsBag {
    padding: 96px 0 32px;
    margin-top: -32px;
  }
}
.page-ldpShowcase .benefitsBag .benefitsBag-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-top: 48px;
  margin-left: -7.1%;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .benefitsBag .benefitsBag-row {
    margin-left: -5.2%;
  }
}
@media (max-width: 900px) {
  .page-ldpShowcase .benefitsBag .benefitsBag-row {
    margin-left: -2%;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .benefitsBag .benefitsBag-row {
    padding-top: 24px;
  }
}
.page-ldpShowcase .benefitsBag .benefitsBag-row .benefitsBag-col {
  width: 50%;
  padding-left: 7.1%;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .benefitsBag .benefitsBag-row .benefitsBag-col {
    padding-left: 5.2%;
  }
}
@media (max-width: 900px) {
  .page-ldpShowcase .benefitsBag .benefitsBag-row .benefitsBag-col {
    padding-left: 2%;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .benefitsBag .benefitsBag-row .benefitsBag-col {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .benefitsBag .benefitsBag-row .benefitsBag-col:nth-child(1) {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .benefitsBag .benefitsBag-row .benefitsBag-col:nth-child(2) {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
}
.page-ldpShowcase .benefitsBag .benefitsBag-row .benefitsBag-thumb {
  border-radius: 16px;
  overflow: hidden;
  position: relative;
}
.page-ldpShowcase .benefitsBag .benefitsBag-row .benefitsBag-thumb::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 6px;
  background: -webkit-gradient(linear, left top, right top, from(rgb(0, 129, 248)), color-stop(25%, rgb(117, 194, 255)), color-stop(50%, rgb(81, 98, 255)), color-stop(75%, rgb(10, 127, 255)), to(rgb(0, 179, 255)));
  background: linear-gradient(to right, rgb(0, 129, 248) 0%, rgb(117, 194, 255) 25%, rgb(81, 98, 255) 50%, rgb(10, 127, 255) 75%, rgb(0, 179, 255) 100%);
}
.page-ldpShowcase .benefitsBag .benefitsBag-row .benefitsBag-thumb img {
  width: 100%;
}
.page-ldpShowcase .benefitsBag .bagBtn {
  padding-top: 74px;
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .benefitsBag .bagBtn {
    padding-top: 64px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .benefitsBag .bagBtn {
    padding-top: 32px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .bag-wrap {
    padding-top: 16px;
  }
}
.page-ldpShowcase .bag-wrap .bag-info {
  padding-bottom: 16px;
}
@media (max-width: 768px) {
  .page-ldpShowcase .bag-wrap .bag-info {
    padding-bottom: 8px;
  }
}
.page-ldpShowcase .bag-wrap .bag-info:last-child {
  padding-bottom: 0;
}
.page-ldpShowcase .bag-wrap .bag-info .bag-header {
  background: #fff;
  padding: 32px 24px;
  border-radius: 8px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
  position: relative;
  min-height: 76px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .bag-wrap .bag-info .bag-header {
    padding: 16px 24px;
    border-radius: 4px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .bag-wrap .bag-info .bag-header {
    padding: 16px;
  }
}
.page-ldpShowcase .bag-wrap .bag-info .bag-header::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 6px;
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}
.page-ldpShowcase .bag-wrap .bag-info .bag-header .bag-item {
  background: #EEF4FF;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 0;
  width: 0;
  min-width: 0;
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
  position: relative;
  z-index: 1;
}
.page-ldpShowcase .bag-wrap .bag-info .bag-header .bag-label {
  color: #383C46;
  font-size: 20px;
  position: relative;
  z-index: 1;
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .bag-wrap .bag-info .bag-header .bag-label {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .bag-wrap .bag-info .bag-header .bag-label {
    font-size: 16px;
  }
}
.page-ldpShowcase .bag-wrap .bag-info .bag-header:hover {
  background: #0246C6;
}
.page-ldpShowcase .bag-wrap .bag-info .bag-header:hover .bag-label {
  color: #fff;
}
.page-ldpShowcase .bag-wrap .bag-info .bag-header[aria-expanded=true] {
  border-radius: 8px 8px 0 0;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .bag-wrap .bag-info .bag-header[aria-expanded=true] {
    border-radius: 4px 4px 0 0;
  }
}
.page-ldpShowcase .bag-wrap .bag-info .bag-header[aria-expanded=true]::after {
  background: -webkit-gradient(linear, left top, right top, from(rgb(0, 129, 248)), color-stop(25%, rgb(117, 194, 255)), color-stop(50%, rgb(81, 98, 255)), color-stop(75%, rgb(10, 127, 255)), to(rgb(0, 179, 255)));
  background: linear-gradient(to right, rgb(0, 129, 248) 0%, rgb(117, 194, 255) 25%, rgb(81, 98, 255) 50%, rgb(10, 127, 255) 75%, rgb(0, 179, 255) 100%);
}
.page-ldpShowcase .bag-wrap .bag-info .bag-header[aria-expanded=true] .bag-item {
  width: 36px;
  min-width: 36px;
  height: 36px;
  margin-right: 24px;
}
@media (max-width: 900px) {
  .page-ldpShowcase .bag-wrap .bag-info .bag-header[aria-expanded=true] .bag-item {
    margin-right: 12px;
  }
}
.page-ldpShowcase .bag-wrap .bag-info .bag-header[aria-expanded=true] .bag-label {
  color: #0246C6;
  font-weight: 700;
}
@media (max-width: 768px) {
  .page-ldpShowcase .bag-wrap .bag-info .bag-header[aria-expanded=true] .bag-label {
    font-size: 16px;
    font-weight: 500;
  }
}
.page-ldpShowcase .bag-wrap .bag-info .bag-header[aria-expanded=true]:hover .bag-label {
  color: #fff;
}
.page-ldpShowcase .bag-wrap .bag-info .bag-main {
  background: #fff;
  border-radius: 0 0 8px 8px;
}
.page-ldpShowcase .bag-wrap .bag-info .bag-main .bag-team {
  padding: 24px 24px 32px;
  position: relative;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .bag-wrap .bag-info .bag-main .bag-team {
    padding: 16px 24px 24px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .bag-wrap .bag-info .bag-main .bag-team {
    padding: 16px 16px 24px;
  }
}
.page-ldpShowcase .bag-wrap .bag-info .bag-main .bag-team::after {
  position: absolute;
  top: 0;
  left: 24px;
  content: "";
  width: calc(100% - 48px);
  height: 1px;
  background: #F5F6F8;
}
@media (max-width: 768px) {
  .page-ldpShowcase .bag-wrap .bag-info .bag-main .bag-team::after {
    left: 16px;
    width: calc(100% - 32px);
  }
}
.page-ldpShowcase .bag-wrap .bag-info .bag-main .bag-team .bag-team__text {
  font-size: 16px;
  color: #5A5D66;
  line-height: 150%;
}
@media (max-width: 768px) {
  .page-ldpShowcase .bag-wrap .bag-info .bag-main .bag-team .bag-team__text {
    font-size: 14px;
  }
}
.page-ldpShowcase .title.title--change {
  display: block;
  text-align: center;
  padding-bottom: 32px;
  border-bottom: 1px solid #C7C8CB;
}
@media (max-width: 768px) {
  .page-ldpShowcase .title.title--change {
    padding-bottom: 16px;
  }
}
.page-ldpShowcase .title.title--change .title-label {
  color: #5A5D66;
  font-size: 24px;
  padding-top: 8px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .title.title--change .title-label {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .title.title--change .title-label {
    font-size: 16px;
  }
}
.page-ldpShowcase .weOffer {
  padding: 64px 0;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .weOffer {
    padding: 48px 0;
  }
}
.page-ldpShowcase .weOffer .weOffer-list ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-left: -32px;
}
@media (max-width: 900px) {
  .page-ldpShowcase .weOffer .weOffer-list ul {
    margin-left: -16px;
  }
}
.page-ldpShowcase .weOffer .weOffer-list ul li {
  width: calc(50% - 32px);
  margin: 32px 0 0 32px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  min-height: 220px;
}
@media (max-width: 900px) {
  .page-ldpShowcase .weOffer .weOffer-list ul li {
    width: calc(50% - 16px);
    margin: 16px 0 0 16px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .weOffer .weOffer-list ul li {
    width: calc(100% - 16px);
    min-height: initial;
  }
}
.page-ldpShowcase .weOffer .weOffer-list ul li::after, .page-ldpShowcase .weOffer .weOffer-list ul li::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  right: 0;
  top: 0;
}
.page-ldpShowcase .weOffer .weOffer-list ul li:nth-child(1) {
  background: linear-gradient(135deg, rgb(12, 61, 134) 0%, rgb(35, 82, 152) 90%, rgb(53, 104, 180) 100%);
}
.page-ldpShowcase .weOffer .weOffer-list ul li:nth-child(1)::before {
  background: url(../../images/data/weOffer-ellipse.svg) no-repeat top right;
}
@media (max-width: 768px) {
  .page-ldpShowcase .weOffer .weOffer-list ul li:nth-child(1)::before {
    background: url(../../images/data/weOffer-ellipseSp.svg) no-repeat bottom right;
  }
}
.page-ldpShowcase .weOffer .weOffer-list ul li:nth-child(1)::after {
  padding-right: 10px;
  background: url(../../images/data/thumb-weOfferPc.svg) no-repeat bottom right;
}
@media (max-width: 768px) {
  .page-ldpShowcase .weOffer .weOffer-list ul li:nth-child(1)::after {
    display: none;
  }
}
.page-ldpShowcase .weOffer .weOffer-list ul li:nth-child(1) .weOffer-container {
  background: linear-gradient(135deg, rgb(12, 61, 134) 0%, rgb(35, 82, 152) 90%, rgb(53, 104, 180) 100%);
}
@media (max-width: 768px) {
  .page-ldpShowcase .weOffer .weOffer-list ul li:nth-child(1) .weOffer-container {
    background: none;
  }
}
.page-ldpShowcase .weOffer .weOffer-list ul li:nth-child(2) {
  background: linear-gradient(135deg, rgb(31, 114, 133) 0%, rgb(11, 134, 160) 100%);
}
@media (max-width: 768px) {
  .page-ldpShowcase .weOffer .weOffer-list ul li:nth-child(2) {
    background: #1F7285;
  }
}
.page-ldpShowcase .weOffer .weOffer-list ul li:nth-child(2)::before {
  background: url(../../images/data/weOffer-ellipse01.svg) no-repeat top left;
}
@media (max-width: 768px) {
  .page-ldpShowcase .weOffer .weOffer-list ul li:nth-child(2)::before {
    background: url(../../images/data/weOffer-ellipseSp01.svg) no-repeat bottom left;
  }
}
.page-ldpShowcase .weOffer .weOffer-list ul li:nth-child(2)::after {
  background: url(../../images/data/thumb-weOfferPc01.svg) no-repeat bottom right;
}
@media (max-width: 768px) {
  .page-ldpShowcase .weOffer .weOffer-list ul li:nth-child(2)::after {
    display: none;
  }
}
.page-ldpShowcase .weOffer .weOffer-list ul li:nth-child(2) .weOffer-container {
  background: linear-gradient(135deg, rgb(31, 114, 133) 0%, rgb(11, 134, 160) 100%);
}
@media (max-width: 768px) {
  .page-ldpShowcase .weOffer .weOffer-list ul li:nth-child(2) .weOffer-container {
    background: none;
  }
}
.page-ldpShowcase .weOffer .weOffer-list ul li:nth-child(3) {
  background: linear-gradient(135deg, rgb(1, 109, 170) 0%, rgb(29, 132, 226) 100%);
}
.page-ldpShowcase .weOffer .weOffer-list ul li:nth-child(3)::before {
  background: url(../../images/data/weOffer-ellipse02.svg) no-repeat top right;
}
@media (max-width: 768px) {
  .page-ldpShowcase .weOffer .weOffer-list ul li:nth-child(3)::before {
    background: url(../../images/data/weOffer-ellipseSp02.svg) no-repeat top right;
  }
}
.page-ldpShowcase .weOffer .weOffer-list ul li:nth-child(3)::after {
  background: url(../../images/data/thumb-weOfferPc02.svg) no-repeat bottom right;
}
@media (max-width: 768px) {
  .page-ldpShowcase .weOffer .weOffer-list ul li:nth-child(3)::after {
    display: none;
  }
}
.page-ldpShowcase .weOffer .weOffer-list ul li:nth-child(3) .weOffer-container {
  background: linear-gradient(135deg, rgb(1, 109, 170) 0%, rgb(29, 132, 226) 100%);
}
@media (max-width: 768px) {
  .page-ldpShowcase .weOffer .weOffer-list ul li:nth-child(3) .weOffer-container {
    background: none;
  }
}
.page-ldpShowcase .weOffer .weOffer-list ul li:nth-child(4) {
  background: linear-gradient(135deg, rgb(12, 61, 134) 0%, rgb(53, 104, 180) 100%);
}
@media (max-width: 768px) {
  .page-ldpShowcase .weOffer .weOffer-list ul li:nth-child(4) {
    background: linear-gradient(135deg, rgb(12, 61, 134) 0%, rgb(35, 82, 152) 50%, rgb(53, 104, 180) 100%);
  }
}
.page-ldpShowcase .weOffer .weOffer-list ul li:nth-child(4)::before {
  background: url(../../images/data/weOffer-ellipse03.svg) no-repeat top left;
}
@media (max-width: 768px) {
  .page-ldpShowcase .weOffer .weOffer-list ul li:nth-child(4)::before {
    background: url(../../images/data/weOffer-ellipseSp03.svg) no-repeat top left;
  }
}
.page-ldpShowcase .weOffer .weOffer-list ul li:nth-child(4)::after {
  background: url(../../images/data/thumb-weOfferPc03.svg) no-repeat bottom right;
}
@media (max-width: 768px) {
  .page-ldpShowcase .weOffer .weOffer-list ul li:nth-child(4)::after {
    display: none;
  }
}
.page-ldpShowcase .weOffer .weOffer-list ul li:nth-child(4) .weOffer-container {
  background: linear-gradient(135deg, rgb(12, 61, 134) 0%, rgb(53, 104, 180) 100%);
}
@media (max-width: 768px) {
  .page-ldpShowcase .weOffer .weOffer-list ul li:nth-child(4) .weOffer-container {
    background: none;
  }
}
.page-ldpShowcase .weOffer .weOffer-list ul li:hover .weOffer-title .tlt {
  opacity: 0;
}
.page-ldpShowcase .weOffer .weOffer-list ul li:hover .weOffer-container {
  opacity: 1;
}
.page-ldpShowcase .weOffer .weOffer-list .weOffer-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  padding: 32px;
  opacity: 0;
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}
@media (max-width: 900px) {
  .page-ldpShowcase .weOffer .weOffer-list .weOffer-container {
    padding: 32px 16px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .weOffer .weOffer-list .weOffer-container {
    position: static;
    padding: 24px 32px;
    opacity: 1;
  }
}
.page-ldpShowcase .weOffer .weOffer-list .weOffer-container .weOfferTitle {
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  position: relative;
  z-index: 1;
}
.page-ldpShowcase .weOffer .weOffer-list .weOffer-container .weOfferText {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 16px;
  position: relative;
  z-index: 1;
}
@media (max-width: 900px) {
  .page-ldpShowcase .weOffer .weOffer-list .weOffer-container .weOfferText {
    font-size: 14px;
    color: #fff;
  }
}
.page-ldpShowcase .weOffer .weOffer-list .weOffer-title {
  position: relative;
  z-index: 1;
  padding: 32px;
}
@media (max-width: 900px) {
  .page-ldpShowcase .weOffer .weOffer-list .weOffer-title {
    padding: 32px 16px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .weOffer .weOffer-list .weOffer-title {
    display: none;
  }
}
.page-ldpShowcase .weOffer .weOffer-list .weOffer-title .tlt {
  font-size: 23px;
  font-weight: 700;
  color: #fff;
  line-height: 140%;
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .weOffer .weOffer-list .weOffer-title .tlt {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .weOffer .weOffer-list .weOffer-title .tlt {
    font-size: 16px;
  }
}
.page-ldpShowcase .weOffer .weOffer-btn {
  padding-top: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 768px) {
  .page-ldpShowcase .weOffer .weOffer-btn {
    padding-top: 16px;
  }
}
.page-ldpShowcase .work {
  padding-bottom: 64px;
}
.page-ldpShowcase .work .work-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-left: -5.2%;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .work .work-row {
    margin-left: -2.7%;
  }
}
.page-ldpShowcase .work .work-row.work-row--title {
  padding-top: 50px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .work .work-row.work-row--title {
    padding-top: 44px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .work .work-row.work-row--title {
    display: none;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .work .work-row.work-row--content {
    padding-top: 36px;
  }
}
.page-ldpShowcase .work .work-row.work-row--content .work-title {
  display: none;
}
@media (max-width: 768px) {
  .page-ldpShowcase .work .work-row.work-row--content .work-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.page-ldpShowcase .work .work-row .work-col {
  width: 33.333333%;
  padding-left: 5.2%;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .work .work-row .work-col {
    padding-left: 2.7%;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .work .work-row .work-col {
    width: 100%;
    padding-bottom: 36px;
  }
  .page-ldpShowcase .work .work-row .work-col:last-child {
    padding-bottom: 0;
  }
}
.page-ldpShowcase .work .work-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.page-ldpShowcase .work .work-title .work-number {
  color: #0246C6;
  font-size: 48px;
  min-width: 84px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .work .work-title .work-number {
    font-size: 32px;
    min-width: 64px;
  }
}
.page-ldpShowcase .work .work-title .work-text {
  font-size: 20px;
  font-weight: 700;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .work .work-title .work-text {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .work .work-title .work-text {
    font-size: 16px;
  }
}
.page-ldpShowcase .work .work-list {
  padding-top: 18px;
}
@media (max-width: 768px) {
  .page-ldpShowcase .work .work-list {
    padding-top: 20px;
  }
}
.page-ldpShowcase .work .work-list > ul > li {
  margin-top: 16px;
}
@media (max-width: 768px) {
  .page-ldpShowcase .work .work-list > ul > li {
    margin-top: 8px;
  }
}
.page-ldpShowcase .work .work-list .work-head {
  background: #1B4B92;
  padding: 24px 16px;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .work .work-list .work-head {
    padding: 16px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .work .work-list .work-head {
    padding: 8px 16px;
  }
}
.page-ldpShowcase .work .work-list .work-head .work-head__text {
  font-size: 23px;
  font-weight: 700;
  color: #fff;
  padding-right: 8px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .work .work-list .work-head .work-head__text {
    font-size: 18px;
    font-weight: 400;
  }
}
.page-ldpShowcase .work .work-list .work-head .work-head__item {
  min-width: 32px;
  height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.page-ldpShowcase .work .work-list .work-sub .work-info {
  margin-top: 8px;
  background: #fff;
  border-radius: 4px;
  padding: 24px 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .work .work-list .work-sub .work-info {
    padding: 16px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .work .work-list .work-sub .work-info {
    margin-top: 4px;
    padding: 8px 16px;
  }
}
.page-ldpShowcase .work .work-list .work-sub .work-info .work-info__item {
  min-width: 36px;
  height: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-right: 12px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .work .work-list .work-sub .work-info .work-info__item {
    margin-right: 8px;
  }
}
.page-ldpShowcase .work .work-list .work-sub .work-info .work-info__text {
  font-size: 18px;
  font-weight: 700;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .work .work-list .work-sub .work-info .work-info__text {
    font-size: 16px;
    font-weight: 400;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .work .work-list .work-sub .work-info .work-info__text {
    font-size: 14px;
  }
}
.page-ldpShowcase .work .work-btn {
  padding-top: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .work .work-btn {
    padding-top: 24px;
  }
}
.page-ldpShowcase .regulations {
  padding: 48px 0 88px;
  background: #fff;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .regulations {
    padding: 32px 0 48px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .regulations {
    padding: 24px 0 48px;
  }
}
.page-ldpShowcase .regulations .qual {
  padding-bottom: 56px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .regulations .qual {
    padding-bottom: 24px;
  }
}
.page-ldpShowcase .regulations .qual .qual-logo {
  padding-top: 72px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .regulations .qual .qual-logo {
    padding-top: 42px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .regulations .qual .qual-logo {
    display: none;
  }
}
.page-ldpShowcase .regulations .qual .qual-logo ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-left: -12px;
}
.page-ldpShowcase .regulations .qual .qual-logo ul li {
  padding-left: 12px;
}
.page-ldpShowcase .regulations .slick--qual {
  display: none;
  width: calc(100% + 32px);
  margin-left: -16px;
}
@media (max-width: 768px) {
  .page-ldpShowcase .regulations .slick--qual {
    display: block;
    padding-top: 26px;
  }
}
.page-ldpShowcase .regulations .slick--qual .slick-slide {
  padding: 0 10px;
}
.page-ldpShowcase .regulations .slick--qual .qual-thumb img {
  max-height: 30px;
}
.page-ldpShowcase .regulations .slick--our {
  padding-top: 72px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .regulations .slick--our {
    padding-top: 42px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .regulations .slick--our {
    padding-top: 18px;
  }
}
.page-ldpShowcase .regulations .slick--our .slick-slide {
  padding: 0 24px;
}
@media (max-width: 768px) {
  .page-ldpShowcase .regulations .slick--our .slick-slide {
    padding: 0 8px;
  }
}
@media (max-width: 1280px) {
  .page-ldpShowcase .regulations .slick--our .qual-thumb img {
    max-height: 96px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .regulations .slick--our .qual-thumb img {
    max-height: 50px;
  }
}
.page-ldpShowcase .regulations .techStack {
  border-top: 1px solid #999BA1;
  padding-top: 56px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .regulations .techStack {
    padding-top: 24px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .regulations .techStack {
    padding-top: 16px;
  }
}
.page-ldpShowcase .regulations .techStack .stack {
  padding-top: 48px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .regulations .techStack .stack {
    padding-top: 32px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .regulations .techStack .stack {
    padding-top: 24px;
  }
}
.page-ldpShowcase .regulations .techStack .stack ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-left: -12px;
}
.page-ldpShowcase .regulations .techStack .stack ul li {
  padding-left: 12px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .regulations .techStack .stack ul li img {
    max-height: 62px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .regulations .techStack .stack ul li img {
    max-height: 45px;
  }
}
.page-ldpShowcase .frequently {
  padding: 48px 0 88px;
}
@media (max-width: 768px) {
  .page-ldpShowcase .frequently {
    padding: 32px 0 64px;
  }
}
.page-ldpShowcase .frequently .qa-wrap {
  padding-top: 32px;
}
@media (max-width: 768px) {
  .page-ldpShowcase .frequently .qa-wrap {
    padding-top: 24px;
  }
}
.page-ldpShowcase .frequently .qa-wrap .qa-info {
  margin-bottom: 16px;
}
@media (max-width: 768px) {
  .page-ldpShowcase .frequently .qa-wrap .qa-info {
    margin-bottom: 8px;
  }
}
.page-ldpShowcase .frequently .qa-wrap .qa-info:last-child {
  margin-bottom: 0;
}
.page-ldpShowcase .frequently .qa-wrap .qa-info .qa-header {
  padding: 25px 54px 25px 24px;
  background: #fff;
  cursor: pointer;
  position: relative;
}
@media (max-width: 768px) {
  .page-ldpShowcase .frequently .qa-wrap .qa-info .qa-header {
    padding: 16px 40px 16px 16px;
  }
}
.page-ldpShowcase .frequently .qa-wrap .qa-info .qa-header::after {
  position: absolute;
  top: calc(50% - 12px);
  right: 24px;
  content: "";
  width: 24px;
  height: 24px;
  background: url(../../images/common/icon-plus.svg) no-repeat center;
  -webkit-transition: 400ms all;
  transition: 400ms all;
}
@media (max-width: 768px) {
  .page-ldpShowcase .frequently .qa-wrap .qa-info .qa-header::after {
    width: 16px;
    height: 16px;
    top: calc(50% - 8px);
    right: 16px;
    background-size: 16px auto;
  }
}
.page-ldpShowcase .frequently .qa-wrap .qa-info .qa-header[aria-expanded=true]::after {
  background: url(../../images/common/icon-minus.svg) no-repeat center;
}
@media (max-width: 768px) {
  .page-ldpShowcase .frequently .qa-wrap .qa-info .qa-header[aria-expanded=true]::after {
    background-size: 16px auto;
  }
}
.page-ldpShowcase .frequently .qa-wrap .qa-info .qa-header .qa-label {
  font-size: 18px;
  font-weight: 500;
}
@media (max-width: 768px) {
  .page-ldpShowcase .frequently .qa-wrap .qa-info .qa-header .qa-label {
    font-size: 16px;
  }
}
.page-ldpShowcase .frequently .qa-wrap .qa-info .qa-main {
  background: #fff;
}
.page-ldpShowcase .frequently .qa-wrap .qa-info .qa-main .qa-team {
  padding: 24px;
  border-top: 1px solid #C7C8CB;
}
@media (max-width: 768px) {
  .page-ldpShowcase .frequently .qa-wrap .qa-info .qa-main .qa-team {
    padding: 16px;
  }
}
.page-ldpShowcase .frequently .qa-wrap .qa-info .qa-main .qa-team .qa-team__text {
  font-size: 16px;
  line-height: 160%;
  color: #5A5D66;
}
@media (max-width: 768px) {
  .page-ldpShowcase .frequently .qa-wrap .qa-info .qa-main .qa-team .qa-team__text {
    font-size: 14px;
    line-height: 140%;
  }
}
.page-ldpShowcase .main-body {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(1%, rgb(214, 245, 255)), color-stop(39%, rgb(255, 255, 255)), color-stop(85%, rgb(235, 239, 255)), to(rgb(197, 227, 255)));
  background: linear-gradient(to bottom, rgb(214, 245, 255) 1%, rgb(255, 255, 255) 39%, rgb(235, 239, 255) 85%, rgb(197, 227, 255) 100%);
}
.page-ldpShowcase .banner-digital {
  background: -webkit-gradient(linear, left top, right top, from(rgb(1, 12, 45)), to(rgb(18, 27, 113)));
  background: linear-gradient(to right, rgb(1, 12, 45) 0%, rgb(18, 27, 113) 100%);
  position: relative;
  padding: 8px 0 264px;
  border-radius: 0 0 32px 32px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .banner-digital {
    padding: 8px 0 200px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .banner-digital {
    padding: 8px 0 96px;
    border-radius: 0 0 8px 8px;
  }
}
.page-ldpShowcase .banner-digital::after {
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  width: 100%;
  height: 100%;
  background: url(../../images/data/bg-banner-ellipse.svg) no-repeat top right;
}
.page-ldpShowcase .banner-digital .banner-wrap {
  position: relative;
  z-index: 2;
}
.page-ldpShowcase .banner-digital .notification {
  margin-top: 16px;
  padding: 24px 56px;
  border-radius: 8px;
  background: linear-gradient(45deg, rgba(12, 182, 255, 0.2) 0%, rgba(153, 221, 255, 0.2) 51%, rgba(190, 255, 243, 0.2) 100%);
  -webkit-backdrop-filter: blur(32%);
          backdrop-filter: blur(32%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .banner-digital .notification {
    padding: 24px 48px;
    margin-top: 24px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .banner-digital .notification {
    display: block;
    padding: 16px;
    margin-bottom: 8px;
  }
}
.page-ldpShowcase .banner-digital .notification .notification-item {
  background: rgba(124, 216, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  min-width: 52px;
  width: 52px;
  height: 52px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-right: 48px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .banner-digital .notification .notification-item {
    margin-right: 32px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .banner-digital .notification .notification-item {
    min-width: 32px;
    width: 32px;
    height: 32px;
    margin: 0 auto;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .banner-digital .notification .notification-item img {
    max-width: 16px;
  }
}
.page-ldpShowcase .banner-digital .notification .notification-info {
  padding-left: 48px;
  position: relative;
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: -1.1px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .banner-digital .notification .notification-info {
    padding-left: 32px;
    font-size: 20px;
    line-height: 30px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .banner-digital .notification .notification-info {
    font-size: 16px;
    padding: 10px 0 0;
    margin-top: 10px;
  }
}
.page-ldpShowcase .banner-digital .notification .notification-info::after {
  position: absolute;
  top: calc(50% - 24px);
  left: 0;
  content: "";
  height: 48px;
  width: 2px;
  border-radius: 4px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(12, 182, 255, 0.6)), color-stop(50%, rgba(153, 221, 255, 0.6)), to(rgba(190, 255, 243, 0.6)));
  background: linear-gradient(to bottom, rgba(12, 182, 255, 0.6) 0%, rgba(153, 221, 255, 0.6) 50%, rgba(190, 255, 243, 0.6) 100%);
}
@media (max-width: 768px) {
  .page-ldpShowcase .banner-digital .notification .notification-info::after {
    top: 0;
    width: 100%;
    height: 2px;
    background: -webkit-gradient(linear, left top, right top, from(rgba(12, 182, 255, 0.6)), color-stop(50%, rgba(153, 221, 255, 0.6)), to(rgba(190, 255, 243, 0.6)));
    background: linear-gradient(to right, rgba(12, 182, 255, 0.6) 0%, rgba(153, 221, 255, 0.6) 50%, rgba(190, 255, 243, 0.6) 100%);
  }
}
.page-ldpShowcase .banner-digital .banner-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-top: 40px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .banner-digital .banner-row {
    padding-top: 24px;
  }
}
.page-ldpShowcase .banner-digital .banner-row .banner-col:nth-child(1) {
  width: 53.7%;
  padding-right: 20px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .banner-digital .banner-row .banner-col:nth-child(1) {
    width: 60.8%;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .banner-digital .banner-row .banner-col:nth-child(1) {
    width: 100%;
    padding-right: 0;
  }
}
.page-ldpShowcase .banner-digital .banner-row .banner-col:nth-child(2) {
  width: 46.3%;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .banner-digital .banner-row .banner-col:nth-child(2) {
    width: 39.2%;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .banner-digital .banner-row .banner-col:nth-child(2) {
    width: 100%;
  }
}
.page-ldpShowcase .banner-digital .bannerInfo {
  max-width: 612px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .banner-digital .bannerInfo {
    max-width: 500px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .banner-digital .bannerInfo {
    max-width: 100%;
  }
}
.page-ldpShowcase .banner-digital .bannerInfo .bannerAbout .bannerAbout-label {
  color: #fff;
  font-size: 24px;
  padding-bottom: 8px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .banner-digital .bannerInfo .bannerAbout .bannerAbout-label {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .banner-digital .bannerInfo .bannerAbout .bannerAbout-label {
    font-size: 16px;
  }
}
.page-ldpShowcase .banner-digital .bannerInfo .bannerAbout .bannerAbout-label .bannerAbout-span {
  font-weight: 700;
}
.page-ldpShowcase .banner-digital .bannerInfo .bannerAbout .bannerAbout-title {
  color: #fff;
  font-size: 42px;
  font-weight: 600;
  line-height: 48px;
  padding-bottom: 24px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .banner-digital .bannerInfo .bannerAbout .bannerAbout-title {
    font-size: 38px;
    line-height: 48px;
  }
}
@media (max-width: 1000px) {
  .page-ldpShowcase .banner-digital .bannerInfo .bannerAbout .bannerAbout-title {
    font-size: 4vw;
    line-height: normal;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .banner-digital .bannerInfo .bannerAbout .bannerAbout-title {
    font-size: 32px;
    font-weight: 600;
  }
}
.page-ldpShowcase .banner-digital .bannerInfo .bannerAbout .bannerAbout-title .bannerAbout-span {
  background: linear-gradient(45deg, rgb(12, 182, 255) 0%, rgb(153, 221, 255) 51%, rgb(190, 255, 243) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: block;
}
.page-ldpShowcase .banner-digital .bannerInfo .bannerAbout .bannerAbout-text {
  font-size: 18px;
  color: #A0AAD4;
  line-height: 30px;
  padding-bottom: 2px;
}
@media (max-width: 900px) {
  .page-ldpShowcase .banner-digital .bannerInfo .bannerAbout .bannerAbout-text {
    font-size: 16px;
    line-height: 26px;
  }
}
.page-ldpShowcase .banner-digital .bannerInfo .bannerAbout .bannerAbout-add {
  padding-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.page-ldpShowcase .banner-digital .bannerInfo .bannerAbout .bannerAbout-add .bannerAbout-item {
  background: rgba(0, 133, 255, 0.2);
  border-radius: 2px;
  min-width: 28px;
  height: 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-right: 18px;
}
@media (max-width: 900px) {
  .page-ldpShowcase .banner-digital .bannerInfo .bannerAbout .bannerAbout-add .bannerAbout-item {
    margin-right: 16px;
  }
}
.page-ldpShowcase .banner-digital .bannerInfo .bannerAbout .bannerAbout-add .bannerAbout-info .bannerAbout-day {
  background: linear-gradient(45deg, rgb(12, 182, 255) 0%, rgb(153, 221, 255) 51%, rgb(190, 255, 243) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  padding-right: 36px;
  position: relative;
}
@media (max-width: 900px) {
  .page-ldpShowcase .banner-digital .bannerInfo .bannerAbout .bannerAbout-add .bannerAbout-info .bannerAbout-day {
    padding-right: 20px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .banner-digital .bannerInfo .bannerAbout .bannerAbout-add .bannerAbout-info .bannerAbout-day {
    padding-right: 0;
  }
}
.page-ldpShowcase .banner-digital .bannerInfo .bannerAbout .bannerAbout-add .bannerAbout-info .bannerAbout-day::after {
  position: absolute;
  top: 2px;
  right: 18px;
  content: "";
  width: 1px;
  height: 20px;
  background: #fff;
}
@media (max-width: 900px) {
  .page-ldpShowcase .banner-digital .bannerInfo .bannerAbout .bannerAbout-add .bannerAbout-info .bannerAbout-day::after {
    right: 10px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .banner-digital .bannerInfo .bannerAbout .bannerAbout-add .bannerAbout-info .bannerAbout-day::after {
    display: none;
  }
}
.page-ldpShowcase .banner-digital .bannerInfo .bannerAbout .bannerAbout-add .bannerAbout-info .bannerAbout-day,
.page-ldpShowcase .banner-digital .bannerInfo .bannerAbout .bannerAbout-add .bannerAbout-info .bannerAbout-date,
.page-ldpShowcase .banner-digital .bannerInfo .bannerAbout .bannerAbout-add .bannerAbout-info .bannerAbout-maps {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  display: inline-block;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .banner-digital .bannerInfo .bannerAbout .bannerAbout-add .bannerAbout-info .bannerAbout-day,
  .page-ldpShowcase .banner-digital .bannerInfo .bannerAbout .bannerAbout-add .bannerAbout-info .bannerAbout-date,
  .page-ldpShowcase .banner-digital .bannerInfo .bannerAbout .bannerAbout-add .bannerAbout-info .bannerAbout-maps {
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .banner-digital .bannerInfo .bannerAbout .bannerAbout-add .bannerAbout-info .bannerAbout-day,
  .page-ldpShowcase .banner-digital .bannerInfo .bannerAbout .bannerAbout-add .bannerAbout-info .bannerAbout-date,
  .page-ldpShowcase .banner-digital .bannerInfo .bannerAbout .bannerAbout-add .bannerAbout-info .bannerAbout-maps {
    display: block;
  }
}
.page-ldpShowcase .banner-digital .bannerInfo .bannerAbout .bannerAbout-add .bannerAbout-info .bannerAbout-date,
.page-ldpShowcase .banner-digital .bannerInfo .bannerAbout .bannerAbout-add .bannerAbout-info .bannerAbout-maps {
  color: #fff;
}
.page-ldpShowcase .banner-digital .bannerInfo .bannerAbout .bannerAbout-ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 768px) {
  .page-ldpShowcase .banner-digital .bannerInfo .bannerAbout .bannerAbout-ul {
    display: inline-block;
  }
}
.page-ldpShowcase .banner-digital .bannerInfo .bannerAbout .bannerAbout-ul li {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  padding-right: 16px;
  position: relative;
  margin-top: 4px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .banner-digital .bannerInfo .bannerAbout .bannerAbout-ul li {
    font-size: 16px;
  }
}
.page-ldpShowcase .banner-digital .bannerInfo .bannerAbout .bannerAbout-ul li:last-child {
  padding-right: 0;
}
.page-ldpShowcase .banner-digital .bannerInfo .bannerAbout .bannerAbout-ul li:last-child::after {
  display: none;
}
.page-ldpShowcase .banner-digital .bannerInfo .bannerAbout .bannerAbout-ul li::after {
  position: absolute;
  top: 1px;
  right: 8px;
  content: "";
  width: 1px;
  height: 20px;
  background: #fff;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .banner-digital .bannerInfo .bannerAbout .bannerAbout-ul li::after {
    top: 1px;
  }
}
.page-ldpShowcase .banner-digital .bannerInfo .bannerAbout .bannerAbout-ul li.bannerAbout-time {
  padding-right: 6px;
}
.page-ldpShowcase .banner-digital .bannerInfo .bannerAbout .bannerAbout-ul li.bannerAbout-time::after {
  display: none;
}
.page-ldpShowcase .banner-digital .bannerInfo .bannerAbout .bannerAbout-ul li.bannerAbout-day {
  background: linear-gradient(45deg, rgb(12, 182, 255) 0%, rgb(153, 221, 255) 51%, rgb(190, 255, 243) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.page-ldpShowcase .banner-digital .bannerInfo .banner-hybrid {
  background: rgba(255, 255, 255, 0.1);
  display: inline-block;
  border-radius: 4px;
}
.page-ldpShowcase .banner-digital .bannerInfo .banner-hybrid span {
  padding: 8px 16px;
  display: block;
  font-size: 20px;
  font-weight: 600;
  background: linear-gradient(45deg, rgb(12, 182, 255) 0%, rgb(153, 221, 255) 51%, rgb(190, 255, 243) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .banner-digital .bannerInfo .banner-hybrid span {
    font-size: 16px;
    padding: 4px;
  }
}
.page-ldpShowcase .banner-digital .bannerInfo .banner-thumb {
  padding-top: 24px;
}
.page-ldpShowcase .banner-digital .banner-form {
  background: linear-gradient(45deg, rgb(12, 182, 255) 0%, rgb(153, 221, 255) 51%, rgb(190, 255, 243) 100%);
  border-radius: 8px;
  padding: 1px;
}
@media (max-width: 768px) {
  .page-ldpShowcase .banner-digital .banner-form {
    margin-top: 12px;
  }
}
.page-ldpShowcase .banner-digital .banner-form .banner-box {
  background: rgb(11, 20, 78);
  padding: 24px 32px 32px;
  border-radius: 8px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .banner-digital .banner-form .banner-box {
    padding: 24px 16px 24px;
  }
}
.page-ldpShowcase .banner-digital .banner-form .banner-box .form-title {
  font-size: 32px;
  font-weight: 500;
  color: #fff;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .banner-digital .banner-form .banner-box .form-title {
    font-size: 24px;
  }
}
.page-ldpShowcase .banner-digital .banner-form .banner-box .formGroup {
  padding-top: 24px;
}
.page-ldpShowcase .banner-digital .banner-form .banner-box .formGroup .formGroup-col > ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-left: -16px;
}
.page-ldpShowcase .banner-digital .banner-form .banner-box .formGroup .formGroup-col > ul > li {
  width: 50%;
  padding-left: 16px;
}
@media (max-width: 768px) {
  .page-ldpShowcase .banner-digital .banner-form .banner-box .formGroup .formGroup-col > ul > li {
    width: 100%;
  }
}
.page-ldpShowcase .banner-digital .banner-form .banner-box .formGroup .groupForm {
  margin-bottom: 8px;
}
.page-ldpShowcase .banner-digital .banner-form .banner-box .formGroup .btn-reserve {
  padding-top: 16px;
}
.page-ldpShowcase .banner-digital .banner-form .banner-box .formGroup .btn-reserve .btn {
  width: 100%;
}
.page-ldpShowcase .why-you {
  margin-top: -220px;
  position: relative;
  z-index: 1;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .why-you {
    margin-top: -148px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .why-you {
    margin-top: -75px;
  }
}
.page-ldpShowcase .why-you .why-box {
  background: #fff;
  padding: 48px 40px;
  border-radius: 8px;
  -webkit-box-shadow: 0 6px 14px rgba(18, 27, 58, 0.1);
          box-shadow: 0 6px 14px rgba(18, 27, 58, 0.1);
}
@media (max-width: 1280px) {
  .page-ldpShowcase .why-you .why-box {
    padding: 32px 40px;
  }
}
@media (max-width: 900px) {
  .page-ldpShowcase .why-you .why-box {
    padding: 24px 16px;
  }
}
.page-ldpShowcase .why-you .why-title {
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  color: #030E34;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .why-you .why-title {
    font-size: 32px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .why-you .why-title {
    font-size: 24px;
  }
}
.page-ldpShowcase .why-you .why-list {
  max-width: 1200px;
  margin: 0 auto;
}
.page-ldpShowcase .why-you .why-list > ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-left: -24px;
}
@media (max-width: 900px) {
  .page-ldpShowcase .why-you .why-list > ul {
    margin-left: -10px;
  }
}
.page-ldpShowcase .why-you .why-list > ul > li {
  width: calc(33.333333% - 24px);
  margin: 32px 0 0 24px;
  padding: 32px;
  min-height: 258px;
  border-radius: 4px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .why-you .why-list > ul > li {
    margin-top: 24px;
    min-height: 248px;
    padding: 24px;
  }
}
@media (max-width: 900px) {
  .page-ldpShowcase .why-you .why-list > ul > li {
    width: calc(50% - 10px);
    margin: 24px 0 0 10px;
    padding: 16px;
    min-height: initial;
  }
  .page-ldpShowcase .why-you .why-list > ul > li:last-child {
    width: calc(100% - 10px);
    margin-top: 10px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .why-you .why-list > ul > li {
    width: calc(100% - 10px);
    padding: 16px;
  }
}
.page-ldpShowcase .why-you .why-list > ul > li:nth-child(1) {
  background: -webkit-gradient(linear, left top, left bottom, from(rgb(42, 154, 217)), to(rgb(42, 112, 217)));
  background: linear-gradient(to bottom, rgb(42, 154, 217) 0%, rgb(42, 112, 217) 100%);
}
.page-ldpShowcase .why-you .why-list > ul > li:nth-child(2) {
  background: -webkit-gradient(linear, left top, left bottom, from(rgb(61, 164, 142)), to(rgb(57, 191, 220)));
  background: linear-gradient(to bottom, rgb(61, 164, 142) 0%, rgb(57, 191, 220) 100%);
}
.page-ldpShowcase .why-you .why-list > ul > li:nth-child(3) {
  background: -webkit-gradient(linear, left top, left bottom, from(rgb(42, 171, 217)), to(rgb(42, 206, 217)));
  background: linear-gradient(to bottom, rgb(42, 171, 217) 0%, rgb(42, 206, 217) 100%);
}
.page-ldpShowcase .why-you .why-list .why-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.page-ldpShowcase .why-you .why-list .why-head .why-item {
  width: 48px;
  height: 48px;
  min-width: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 2px;
  margin-right: 14px;
}
@media (max-width: 900px) {
  .page-ldpShowcase .why-you .why-list .why-head .why-item {
    margin-right: 12px;
  }
}
.page-ldpShowcase .why-you .why-list .why-head .why-tlt {
  font-size: 24px;
  font-weight: 600;
  color: #fff;
}
@media (max-width: 900px) {
  .page-ldpShowcase .why-you .why-list .why-head .why-tlt {
    font-size: 22px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .why-you .why-list .why-head .why-tlt {
    font-size: 20px;
  }
}
.page-ldpShowcase .why-you .why-list .why-content {
  padding-top: 32px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .why-you .why-list .why-content {
    padding-top: 24px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .why-you .why-list .why-content {
    padding-top: 16px;
  }
}
.page-ldpShowcase .why-you .why-list .why-content .why-text {
  font-size: 18px;
  color: #fff;
  line-height: 23px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .why-you .why-list .why-content .why-text {
    font-size: 16px;
    line-height: 20px;
  }
}
.page-ldpShowcase .why-you .why-list .why-content .why-ul li {
  margin-bottom: 4px;
  padding: 16px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  background: linear-gradient(45deg, rgb(0, 100, 143) 0%, rgba(0, 100, 143, 0.38) 100%);
  border-radius: 8px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .why-you .why-list .why-content .why-ul li {
    font-size: 16px;
  }
}
.page-ldpShowcase .why-you .why-list .why-content .why-ul li:last-child {
  margin-bottom: 0;
}
.page-ldpShowcase .why-you .why-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-top: 32px;
}
.page-ldpShowcase .why-you .why-btn .btn {
  min-width: 316px;
}
.page-ldpShowcase .topics .topics-box {
  background: #fff;
  border-radius: 8px;
  margin-top: 80px;
  padding: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .topics .topics-box {
    margin-top: 56px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .topics .topics-box {
    padding: 16px;
  }
}
.page-ldpShowcase .topics .topics-box .topics-thumb {
  width: 50%;
  position: relative;
}
@media (max-width: 900px) {
  .page-ldpShowcase .topics .topics-box .topics-thumb {
    width: 43%;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .topics .topics-box .topics-thumb {
    width: 100%;
  }
}
.page-ldpShowcase .topics .topics-box .topics-thumb::after, .page-ldpShowcase .topics .topics-box .topics-thumb::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.page-ldpShowcase .topics .topics-box .topics-thumb::before {
  top: -24px;
  left: -24px;
  background: linear-gradient(135deg, rgb(68, 200, 245) 0%, rgba(68, 200, 245, 0.38) 100%);
}
@media (max-width: 768px) {
  .page-ldpShowcase .topics .topics-box .topics-thumb::before {
    top: -16px;
    left: -16px;
  }
}
.page-ldpShowcase .topics .topics-box .topics-thumb::after {
  bottom: -24px;
  right: -24px;
  background: linear-gradient(135deg, rgba(96, 195, 173, 0.38) 0%, rgb(96, 195, 173) 100%);
}
@media (max-width: 768px) {
  .page-ldpShowcase .topics .topics-box .topics-thumb::after {
    bottom: -16px;
    right: -16px;
  }
}
.page-ldpShowcase .topics .topics-box .topics-thumb .topics-thumbnail {
  height: 432px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
@media (max-width: 768px) {
  .page-ldpShowcase .topics .topics-box .topics-thumb .topics-thumbnail {
    height: 214px;
  }
}
.page-ldpShowcase .topics .topics-box .topics-thumb .topics-thumbnail img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.page-ldpShowcase .topics .topics-box .topics-thumb .topics-thumbnail .topics-leading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 48px 34px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 900px) {
  .page-ldpShowcase .topics .topics-box .topics-thumb .topics-thumbnail .topics-leading {
    padding: 24px 15px;
  }
}
.page-ldpShowcase .topics .topics-box .topics-thumb .topics-thumbnail .topics-leading .topics-text {
  font-size: 48px;
  font-weight: 600;
  color: #fff;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .topics .topics-box .topics-thumb .topics-thumbnail .topics-leading .topics-text {
    font-size: 32px;
  }
}
@media (max-width: 900px) {
  .page-ldpShowcase .topics .topics-box .topics-thumb .topics-thumbnail .topics-leading .topics-text {
    font-size: 24px;
  }
}
.page-ldpShowcase .topics .topics-box .topics-thumb .topics-thumbnail .topics-leading .btn {
  width: 100%;
}
.page-ldpShowcase .topics .topics-box .topics-thumb .topics-thumbnail .topics-leading .btn img {
  width: initial;
  height: initial;
}
.page-ldpShowcase .topics .topics-box .topics-info {
  width: 50%;
  padding-left: 7%;
}
@media (max-width: 900px) {
  .page-ldpShowcase .topics .topics-box .topics-info {
    width: 57%;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .topics .topics-box .topics-info {
    width: 100%;
    padding-left: 0;
  }
}
.page-ldpShowcase .topics .topics-box .topics-info .topics-content {
  max-width: 548px;
  margin-left: auto;
}
@media (max-width: 900px) {
  .page-ldpShowcase .topics .topics-box .topics-info .topics-content {
    max-width: 384px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .topics .topics-box .topics-info .topics-content {
    max-width: 100%;
    padding-top: 48px;
  }
}
.page-ldpShowcase .topics .topics-box .topics-info .topics-content .topics-title {
  font-size: 32px;
  font-weight: 600;
  color: #252C6D;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .topics .topics-box .topics-info .topics-content .topics-title {
    font-size: 24px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .topics .topics-box .topics-info .topics-content .topics-title {
    font-size: 20px;
  }
}
.page-ldpShowcase .topics .topics-box .topics-info .topics-content .topics-list {
  padding-top: 28px;
}
@media (max-width: 768px) {
  .page-ldpShowcase .topics .topics-box .topics-info .topics-content .topics-list {
    padding-top: 12px;
  }
}
.page-ldpShowcase .topics .topics-box .topics-info .topics-content .topics-list li {
  padding: 12px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid #EAEAEA;
}
.page-ldpShowcase .topics .topics-box .topics-info .topics-content .topics-list li:last-child {
  border-bottom: none;
}
.page-ldpShowcase .topics .topics-box .topics-info .topics-content .topics-list li .topics-numb {
  background: linear-gradient(45deg, rgb(12, 182, 255) 0%, rgb(153, 221, 255) 100%);
  border-radius: 4px;
  width: 24px;
  height: 24px;
  min-width: 24px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-right: 8px;
}
.page-ldpShowcase .topics .topics-box .topics-info .topics-content .topics-list li .topics-text {
  color: #4B4B4B;
  font-size: 18px;
  line-height: 23px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .topics .topics-box .topics-info .topics-content .topics-list li .topics-text {
    font-size: 16px;
  }
}
.page-ldpShowcase .speaker .speaker-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  padding: 72px 0 85px;
}
@media (max-width: 768px) {
  .page-ldpShowcase .speaker .speaker-row {
    padding: 32px 0 48px;
  }
}
.page-ldpShowcase .speaker .speaker-row .speaker-info {
  width: 51.7%;
  position: relative;
  z-index: 1;
}
@media (max-width: 768px) {
  .page-ldpShowcase .speaker .speaker-row .speaker-info {
    width: 100%;
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    margin-top: -20px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .speaker .speaker-row .speaker-info .speaker-title {
    display: none;
  }
}
.page-ldpShowcase .speaker .speaker-row .speaker-thumb {
  width: 48.3%;
  text-align: center;
  margin-top: 40px;
  position: relative;
}
@media (max-width: 768px) {
  .page-ldpShowcase .speaker .speaker-row .speaker-thumb {
    width: 100%;
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    margin-top: 0;
  }
}
.page-ldpShowcase .speaker .speaker-row .speaker-thumb::after {
  position: absolute;
  bottom: -20px;
  left: -48px;
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(45, 170, 240, 0.6) 0%, rgba(68, 245, 224, 0.6) 100%);
  border-radius: 16px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .speaker .speaker-row .speaker-thumb::after {
    left: -16px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .speaker .speaker-row .speaker-thumb::after {
    left: 0;
    bottom: 0;
    height: calc(100% - 80px);
    display: none;
  }
}
.page-ldpShowcase .speaker .speaker-row .speaker-thumb::before {
  position: absolute;
  bottom: -72px;
  right: 0;
  content: "";
  width: 428px;
  height: 356px;
  background: linear-gradient(135deg, rgba(45, 170, 240, 0.4) 0%, rgba(68, 245, 224, 0.4) 100%);
  border-radius: 16px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .speaker .speaker-row .speaker-thumb::before {
    bottom: -36px;
    width: 320px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .speaker .speaker-row .speaker-thumb::before {
    display: none;
  }
}
.page-ldpShowcase .speaker .speaker-row .speaker-thumb .speaker-thumbNail {
  margin-top: -76px;
  position: relative;
  z-index: 1;
}
@media (max-width: 768px) {
  .page-ldpShowcase .speaker .speaker-row .speaker-thumb .speaker-thumbNail {
    margin-top: 0;
  }
  .page-ldpShowcase .speaker .speaker-row .speaker-thumb .speaker-thumbNail::after {
    position: absolute;
    left: 0;
    bottom: 0;
    content: "";
    width: 100%;
    height: calc(100% - 80px);
    background: linear-gradient(135deg, rgba(45, 170, 240, 0.6) 0%, rgba(68, 245, 224, 0.6) 100%);
    border-radius: 8px;
  }
}
.page-ldpShowcase .speaker .speaker-row .speaker-thumb .speaker-thumbNail img {
  position: relative;
  top: 20px;
  z-index: 1;
}
@media (max-width: 768px) {
  .page-ldpShowcase .speaker .speaker-row .speaker-thumb .speaker-thumbNail img {
    top: initial;
  }
}
.page-ldpShowcase .speaker .speaker-row .speaker-thumb .speaker-title {
  display: none;
}
@media (max-width: 768px) {
  .page-ldpShowcase .speaker .speaker-row .speaker-thumb .speaker-title {
    display: block;
  }
}
.page-ldpShowcase .speaker .speaker-title {
  padding: 0 128px 24px 0;
  text-align: right;
  color: #252C6D;
  font-size: 80px;
  font-weight: 700;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .speaker .speaker-title {
    text-align: left;
    padding: 0 20px 24px 0;
    font-size: 64px;
  }
}
@media (max-width: 900px) {
  .page-ldpShowcase .speaker .speaker-title {
    font-size: 48px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .speaker .speaker-title {
    text-align: center;
    padding: 0;
  }
}
.page-ldpShowcase .speaker .speaker-box {
  padding: 32px 48px;
  background: #fff;
  border-radius: 16px;
  -webkit-box-shadow: 0 10px 14px rgba(18, 27, 58, 0.1);
          box-shadow: 0 10px 14px rgba(18, 27, 58, 0.1);
}
@media (max-width: 1280px) {
  .page-ldpShowcase .speaker .speaker-box {
    padding: 32px;
  }
}
@media (max-width: 900px) {
  .page-ldpShowcase .speaker .speaker-box {
    padding: 24px 16px;
    border-radius: 8px;
  }
}
.page-ldpShowcase .speaker .speaker-box .speaker-ceo {
  color: #252C6D;
  font-size: 40px;
  font-weight: 600;
  padding-bottom: 8px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .speaker .speaker-box .speaker-ceo {
    font-size: 32px;
  }
}
@media (max-width: 900px) {
  .page-ldpShowcase .speaker .speaker-box .speaker-ceo {
    font-size: 24px;
  }
}
.page-ldpShowcase .speaker .speaker-box .speaker-text {
  font-size: 24px;
  color: #252C6D;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .speaker .speaker-box .speaker-text {
    font-size: 20px;
  }
}
@media (max-width: 900px) {
  .page-ldpShowcase .speaker .speaker-box .speaker-text {
    font-size: 16px;
  }
}
.page-ldpShowcase .speaker .speaker-box .speaker-ul {
  padding-top: 28px;
}
.page-ldpShowcase .speaker .speaker-box .speaker-ul li {
  padding: 12px 0;
  border-bottom: 1px solid #ECECEC;
  font-size: 18px;
  color: #4B4B4B;
  line-height: 30px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .speaker .speaker-box .speaker-ul li {
    font-size: 16px;
    padding: 8px 0;
  }
}
.page-ldpShowcase .speaker .speaker-box .speaker-ul li:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.page-ldpShowcase .agenda {
  padding-top: 100px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .agenda {
    padding-top: 20px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .agenda {
    padding-top: 0;
  }
}
.page-ldpShowcase .agenda .agenda-wrap {
  min-height: 848px;
  position: relative;
  background: url(../../images/data/bg-agenda.svg) no-repeat bottom center;
  padding: 0 0 100px;
  /*@media (min-width: 1921px) {
  	&::after {
  		position: absolute;
  		top: 0;
  		left: 0;
  		content: "";
  		width: 100%;
  		height: 100%;
  		background: url(../../images/data/bg-agenda.svg) no-repeat bottom center;
  		background-size: cover;
  	}
  }
  @media (max-width: 1920px) {
  	background: url(../../images/data/bg-agenda.svg) no-repeat bottom center;
  }*/
}
@media (max-width: 1280px) {
  .page-ldpShowcase .agenda .agenda-wrap {
    padding: 0 0 68px;
    min-height: 500px;
  }
}
.page-ldpShowcase .agenda .wrap {
  position: relative;
  z-index: 1;
}
.page-ldpShowcase .agenda .agenda-box {
  background: #fff;
  border-radius: 8px;
  padding: 12px;
  -webkit-box-shadow: 0 10px 14px rgba(18, 27, 58, 0.1);
          box-shadow: 0 10px 14px rgba(18, 27, 58, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  position: relative;
}
@media (max-width: 768px) {
  .page-ldpShowcase .agenda .agenda-box {
    padding: 6px;
  }
}
.page-ldpShowcase .agenda .agenda-box .agenda-thumb {
  width: 50%;
  /*height: 584px;*/
  position: relative;
}
@media (max-width: 768px) {
  .page-ldpShowcase .agenda .agenda-box .agenda-thumb {
    width: 100%;
    height: 180px;
  }
}
.page-ldpShowcase .agenda .agenda-box .agenda-thumb img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}
.page-ldpShowcase .agenda .agenda-box .agenda-thumb .agenda-title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 52px 34px;
  /*font-size: 100px;*/
  font-size: 5.2vw;
  font-weight: 700;
  color: #fff;
}
@media (min-width: 1921px) {
  .page-ldpShowcase .agenda .agenda-box .agenda-thumb .agenda-title {
    font-size: 100px;
  }
}
@media (max-width: 1280px) {
  .page-ldpShowcase .agenda .agenda-box .agenda-thumb .agenda-title {
    font-size: 6.3vw;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .agenda .agenda-box .agenda-thumb .agenda-title {
    padding: 26px 10px;
    font-size: 48px;
  }
}
.page-ldpShowcase .agenda .agenda-box .agenda-content {
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 768px) {
  .page-ldpShowcase .agenda .agenda-box .agenda-content {
    width: 100%;
    padding-top: 20px;
  }
}
.page-ldpShowcase .agenda .agenda-box .agenda-content ul {
  width: 100%;
}
.page-ldpShowcase .agenda .agenda-box .agenda-content ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 18px 30px;
}
@media (max-width: 900px) {
  .page-ldpShowcase .agenda .agenda-box .agenda-content ul li {
    padding: 10px 16px;
  }
}
.page-ldpShowcase .agenda .agenda-box .agenda-content ul li:nth-of-type(odd) {
  background: rgba(142, 194, 255, 0.2);
}
.page-ldpShowcase .agenda .agenda-box .agenda-content ul li:nth-child(1) {
  background: none;
}
.page-ldpShowcase .agenda .agenda-box .agenda-content .agenda-date {
  width: 150px;
  padding-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-right: 1px solid #B1BED8;
  color: #121B71;
  font-size: 16px;
  font-weight: 700;
}
@media (max-width: 900px) {
  .page-ldpShowcase .agenda .agenda-box .agenda-content .agenda-date {
    width: 120px;
    font-size: 14px;
    font-weight: 400;
  }
}
.page-ldpShowcase .agenda .agenda-box .agenda-content .agenda-info {
  width: calc(100% - 150px);
  padding-left: 30px;
}
@media (max-width: 900px) {
  .page-ldpShowcase .agenda .agenda-box .agenda-content .agenda-info {
    width: calc(100% - 120px);
    padding-left: 16px;
  }
}
.page-ldpShowcase .agenda .agenda-box .agenda-content .agenda-info .agenda-text {
  font-size: 16px;
  font-weight: 700;
  color: #252C6D;
}
@media (max-width: 900px) {
  .page-ldpShowcase .agenda .agenda-box .agenda-content .agenda-info .agenda-text {
    font-size: 14px;
    font-weight: 600;
  }
}
.page-ldpShowcase .agenda .agenda-box .agenda-content .agenda-info .agenda-textfl {
  font-size: 16px;
  color: #252C6D;
  padding-top: 4px;
}
@media (max-width: 900px) {
  .page-ldpShowcase .agenda .agenda-box .agenda-content .agenda-info .agenda-textfl {
    font-size: 12px;
  }
}
.page-ldpShowcase .totally {
  max-width: 884px;
  margin: 0 auto;
  padding-top: 96px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .totally {
    max-width: 904px;
    padding-top: 48px;
  }
}
.page-ldpShowcase .totally .totally-title {
  text-align: center;
  font-size: 48px;
  font-weight: 700;
  color: #fff;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .totally .totally-title {
    font-size: 40px;
  }
}
@media (max-width: 900px) {
  .page-ldpShowcase .totally .totally-title {
    font-size: 32px;
  }
}
@media (max-width: 768px) {
  .page-ldpShowcase .totally .totally-title {
    font-size: 24px;
  }
}
.page-ldpShowcase .totally .totally-title .totally-span {
  background: linear-gradient(45deg, rgb(12, 182, 255) 0%, rgb(153, 221, 255) 51%, rgb(190, 255, 243) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.page-ldpShowcase .totally .btn-totally {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-top: 48px;
}
@media (max-width: 1280px) {
  .page-ldpShowcase .totally .btn-totally {
    padding-top: 32px;
  }
}
.page-ldpShowcase .totally .btn-totally .btn {
  min-width: 316px;
}

.valuedPartners {
  padding: 64px 0 100px;
}
@media (max-width: 1280px) {
  .valuedPartners {
    padding: 32px 0 64px;
  }
}
@media (max-width: 768px) {
  .valuedPartners {
    padding: 24px 0 32px;
  }
}
.valuedPartners .title {
  padding: 0 0 32px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-bottom: 1px solid #C7C8CB;
}
@media (max-width: 768px) {
  .valuedPartners .title {
    text-align: center;
  }
}

.valuedCompany {
  padding-top: 6px;
}
@media (max-width: 1280px) {
  .valuedCompany {
    padding-top: 0;
  }
}
.valuedCompany .slick {
  padding-top: 58px;
}
@media (max-width: 1280px) {
  .valuedCompany .slick {
    padding-top: 32px;
  }
}
@media (max-width: 768px) {
  .valuedCompany .slick {
    padding-top: 24px;
  }
}
.valuedCompany .slick-slide {
  margin-right: 64px;
}
@media (max-width: 768px) {
  .valuedCompany .slick-slide {
    margin-right: 24px;
  }
}
.valuedCompany .valuedCompany-text {
  color: #999BA1;
  font-size: 26px;
}
@media (max-width: 768px) {
  .valuedCompany .valuedCompany-text {
    font-size: 14px;
  }
}

/* -- page-projects -- */
.page-projects .complete {
  overflow: hidden;
  background: #222;
  padding: 96px 0;
  background: url(../../images/data/bg-complete.jpg) no-repeat bottom right;
  background-size: cover;
}
@media (max-width: 1280px) {
  .page-projects .complete {
    padding: 56px 0 48px;
  }
}
@media (max-width: 768px) {
  .page-projects .complete {
    padding: 32px 0;
  }
}
.page-projects .complete .completeInfo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 1280px) {
  .page-projects .complete .completeInfo {
    display: block;
  }
}
.page-projects .complete .completeInfo .completeInfo__text {
  color: #fff;
  font-size: 29px;
  max-width: 680px;
}
@media (max-width: 1280px) {
  .page-projects .complete .completeInfo .completeInfo__text {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .page-projects .complete .completeInfo .completeInfo__text {
    font-size: 16px;
  }
}
.page-projects .complete .completeInfo .completeInfo__btn {
  padding-left: 64px;
}
@media (max-width: 1280px) {
  .page-projects .complete .completeInfo .completeInfo__btn {
    padding: 32px 0 0 0;
  }
}
@media (max-width: 768px) {
  .page-projects .complete .completeInfo .completeInfo__btn {
    padding-top: 16px;
  }
}
.page-projects .hospital {
  padding: 80px 0;
}
@media (max-width: 1280px) {
  .page-projects .hospital {
    padding: 64px 0;
  }
}
@media (max-width: 768px) {
  .page-projects .hospital {
    padding: 48px 0 64px;
  }
}
.page-projects .hospital .title .title-text {
  max-width: 800px;
}
.page-projects .hospital .hospital-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-top: 48px;
  margin-left: -96px;
}
@media (max-width: 1280px) {
  .page-projects .hospital .hospital-row {
    margin-left: -48px;
    padding-top: 32px;
  }
}
@media (max-width: 768px) {
  .page-projects .hospital .hospital-row {
    padding-top: 32px;
  }
}
.page-projects .hospital .hospital-row .hospital-col {
  width: 50%;
  padding-left: 96px;
}
@media (max-width: 1280px) {
  .page-projects .hospital .hospital-row .hospital-col {
    padding-left: 48px;
  }
}
@media (max-width: 768px) {
  .page-projects .hospital .hospital-row .hospital-col {
    width: 100%;
  }
  .page-projects .hospital .hospital-row .hospital-col:nth-child(2) .list-hospital ul li:first-of-type {
    border-top: none;
  }
}
.page-projects .hospital-thumb img {
  width: 100%;
}
.page-projects .list-hospital ul li {
  border-top: 1px solid #C3C4D2;
}
.page-projects .list-hospital ul li:last-child {
  border-bottom: 1px solid #C3C4D2;
}
.page-projects .list-hospital .accordion-title {
  padding: 16px 24px 16px 0;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  color: #383C46;
  position: relative;
}
@media (max-width: 1280px) {
  .page-projects .list-hospital .accordion-title {
    padding: 12px 24px 12px 0;
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .page-projects .list-hospital .accordion-title {
    font-size: 14px;
    padding: 16px 24px 16px 0;
  }
}
.page-projects .list-hospital .accordion-title.accordion-title--active::after {
  display: none;
}
.page-projects .list-hospital .accordion-title::after, .page-projects .list-hospital .accordion-title::before {
  position: absolute;
  content: "";
  background: #0246C6;
}
@media (max-width: 768px) {
  .page-projects .list-hospital .accordion-title::after, .page-projects .list-hospital .accordion-title::before {
    background: #6C6F77;
  }
}
.page-projects .list-hospital .accordion-title::before {
  width: 16px;
  height: 2px;
  right: 0;
  top: 50%;
}
@media (max-width: 768px) {
  .page-projects .list-hospital .accordion-title::before {
    width: 11px;
  }
}
.page-projects .list-hospital .accordion-title::after {
  width: 2px;
  height: 16px;
  top: calc(50% - 7px);
  right: 7px;
}
@media (max-width: 768px) {
  .page-projects .list-hospital .accordion-title::after {
    height: 11px;
    top: calc(50% - 5px);
    right: 5px;
  }
}
.page-projects .list-hospital .accordion-content {
  padding: 24px 0 32px;
  display: none;
  position: relative;
}
@media (max-width: 768px) {
  .page-projects .list-hospital .accordion-content {
    padding: 16px 0 24px 0;
  }
}
.page-projects .list-hospital .accordion-content .accordion-text {
  color: #3F424D;
  font-size: 18px;
}
@media (max-width: 1280px) {
  .page-projects .list-hospital .accordion-content .accordion-text {
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .page-projects .list-hospital .accordion-content .accordion-text {
    font-size: 14px;
  }
}
.page-projects .list-hospital .accordion-content .btn {
  margin-top: 32px;
}
@media (max-width: 768px) {
  .page-projects .list-hospital .accordion-content .btn {
    margin-top: 24px;
  }
}
.page-projects .highlight-wrap {
  padding-bottom: 80px;
}
@media (max-width: 1280px) {
  .page-projects .highlight-wrap {
    padding-bottom: 64px;
  }
}
@media (max-width: 768px) {
  .page-projects .highlight-wrap {
    padding-bottom: 40px;
  }
}
.page-projects .highlight-wrap .highlight-bg {
  background: url(../../images/data/thumb-highlight.jpg) no-repeat center;
  background-size: cover;
  height: 540px;
  padding-top: 136px;
}
@media (max-width: 1280px) {
  .page-projects .highlight-wrap .highlight-bg {
    background: url(../../images/data/thumb-highlight-pc.jpg) no-repeat center;
    background-size: cover;
    height: 328px;
    padding-top: 64px;
  }
}
@media (max-width: 768px) {
  .page-projects .highlight-wrap .highlight-bg {
    background: url(../../images/data/thumb-highlight-sp.jpg) no-repeat center;
    background-size: cover;
    height: 306px;
    padding-top: 48px;
  }
}
.page-projects .highlight-wrap .highlight-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 768px) {
  .page-projects .highlight-wrap .highlight-title {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
.page-projects .highlight-wrap .highlight-title .highlight-title__systems {
  padding-top: 24px;
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
  color: rgba(255, 255, 255, 0.6);
  font-size: 20px;
  text-align: right;
  max-width: 410px;
}
@media (max-width: 1280px) {
  .page-projects .highlight-wrap .highlight-title .highlight-title__systems {
    font-size: 18px;
    max-width: 228px;
  }
}
@media (max-width: 768px) {
  .page-projects .highlight-wrap .highlight-title .highlight-title__systems {
    padding-top: 0;
    max-width: 100%;
    width: 100%;
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    font-size: 14px;
    text-align: left;
  }
  .page-projects .highlight-wrap .highlight-title .highlight-title__systems br {
    display: none;
  }
}
.page-projects .highlight-wrap .highlight-title .highlight-title__numbers {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
  color: #fff;
  font-size: 64px;
  font-weight: 500;
}
.page-projects .highlight-wrap .highlight-title .highlight-title__numbers br {
  display: none;
}
@media (max-width: 1280px) {
  .page-projects .highlight-wrap .highlight-title .highlight-title__numbers br {
    display: block;
  }
}
@media (max-width: 1280px) {
  .page-projects .highlight-wrap .highlight-title .highlight-title__numbers {
    font-weight: 400;
  }
}
@media (max-width: 768px) {
  .page-projects .highlight-wrap .highlight-title .highlight-title__numbers {
    width: 100%;
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    padding-top: 24px;
    font-size: 40px;
  }
}
.page-projects .highlight-wrap .highlight-content {
  margin-top: -184px;
}
@media (max-width: 1280px) {
  .page-projects .highlight-wrap .highlight-content {
    margin-top: -70px;
  }
}
.page-projects .highlight-wrap .highlight-content ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin-left: -64px;
}
@media (max-width: 1280px) {
  .page-projects .highlight-wrap .highlight-content ul {
    margin-left: -24px;
  }
}
.page-projects .highlight-wrap .highlight-content ul li {
  background: url(../../images/data/highlight-ellipse.svg) no-repeat top right #0246C6;
  border-radius: 8px;
  padding: 48px;
  margin-left: 64px;
  width: calc(50% - 64px);
}
@media (max-width: 1280px) {
  .page-projects .highlight-wrap .highlight-content ul li {
    margin-left: 24px;
    width: calc(50% - 24px);
    padding: 24px 48px;
  }
}
@media (max-width: 900px) {
  .page-projects .highlight-wrap .highlight-content ul li {
    padding: 24px;
  }
}
@media (max-width: 768px) {
  .page-projects .highlight-wrap .highlight-content ul li {
    width: calc(100% - 24px);
    margin-bottom: 16px;
  }
  .page-projects .highlight-wrap .highlight-content ul li:last-child {
    margin-bottom: 0;
  }
}
.page-projects .highlight-wrap .highlight-content .highlight-number {
  font-size: 64px;
  color: #fff;
  display: block;
}
@media (max-width: 1280px) {
  .page-projects .highlight-wrap .highlight-content .highlight-number {
    font-size: 64px;
  }
}
@media (max-width: 768px) {
  .page-projects .highlight-wrap .highlight-content .highlight-number {
    font-size: 32px;
  }
}
.page-projects .highlight-wrap .highlight-content .highlight-progress {
  margin: 24px 12px 24px 0;
  position: relative;
  height: 12px;
}
@media (max-width: 1280px) {
  .page-projects .highlight-wrap .highlight-content .highlight-progress {
    margin: 16px 12px 16px 0;
  }
}
.page-projects .highlight-wrap .highlight-content .highlight-progress::after {
  position: absolute;
  top: 50%;
  left: 0;
  content: "";
  width: calc(100% + 12px);
  height: 1px;
  background: #367CFF;
}
.page-projects .highlight-wrap .highlight-content .highlight-progress .progress-bar {
  position: absolute;
  top: 0;
  width: 12px;
  height: 12px;
  background: #fff;
  border-radius: 50%;
  z-index: 1;
}
.page-projects .highlight-wrap .highlight-content .highlight-text {
  color: #F1F2F2;
  font-size: 26px;
  text-align: right;
  display: block;
}
@media (max-width: 1280px) {
  .page-projects .highlight-wrap .highlight-content .highlight-text {
    font-size: 20px;
    text-align: left;
  }
}
@media (max-width: 768px) {
  .page-projects .highlight-wrap .highlight-content .highlight-text {
    font-size: 16px;
  }
}
.page-projects .proud {
  padding: 0 0 96px;
}
@media (max-width: 1280px) {
  .page-projects .proud {
    padding: 0 0 96px;
  }
}
@media (max-width: 768px) {
  .page-projects .proud {
    padding: 0 0 80px;
  }
}
.page-projects .journey {
  background: url(../../images/data/bg-journey.jpg) no-repeat bottom right;
  padding: 48px 16px;
  border-radius: 16px;
}
@media (max-width: 1280px) {
  .page-projects .journey {
    padding: 40px 16px;
  }
}
.page-projects .journey .journey-info {
  width: 100%;
  text-align: center;
}
@media (max-width: 768px) {
  .page-projects .journey .journey-content {
    max-width: 460px;
    margin: 0 auto;
  }
}
.page-projects .journey .journey-start {
  font-size: 36px;
  font-weight: 500;
  color: #fff;
}
@media (max-width: 1280px) {
  .page-projects .journey .journey-start {
    font-size: 29px;
  }
}
@media (max-width: 768px) {
  .page-projects .journey .journey-start {
    font-size: 18px;
    font-weight: 400;
  }
}
.page-projects .journey .journey-become {
  display: block;
}
@media (max-width: 768px) {
  .page-projects .journey .journey-become {
    display: initial;
  }
}
.page-projects .journey .journey-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-top: 32px;
}
@media (max-width: 1280px) {
  .page-projects .journey .journey-btn {
    padding-top: 24px;
  }
}
@media (max-width: 768px) {
  .page-projects .journey .journey-btn {
    padding-top: 16px;
  }
}
.page-projects .stories {
  padding: 56px 0 88px;
}
@media (max-width: 1280px) {
  .page-projects .stories {
    padding: 64px 0;
  }
}
@media (max-width: 768px) {
  .page-projects .stories {
    padding: 64px 0;
  }
}
@media (max-width: 1280px) {
  .page-projects .systems-list {
    padding-top: 16px;
  }
}
@media (max-width: 768px) {
  .page-projects .systems-list {
    padding-top: 8px;
  }
}
.page-projects .systems-list > ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-left: -48px;
}
@media (max-width: 1280px) {
  .page-projects .systems-list > ul {
    margin-left: -24px;
  }
}
.page-projects .systems-list > ul > li {
  margin: 96px 0 0 48px;
  width: calc(33.333333% - 48px);
}
@media (max-width: 1280px) {
  .page-projects .systems-list > ul > li {
    margin: 48px 0 0 24px;
    width: calc(33.333333% - 24px);
  }
}
@media (max-width: 768px) {
  .page-projects .systems-list > ul > li {
    margin: 24px 0 0 24px;
    width: calc(50% - 24px);
  }
}
@media (max-width: 480px) {
  .page-projects .systems-list > ul > li {
    width: calc(100% - 24px);
  }
}
.page-projects .systems-list > ul > li a {
  display: block;
  padding: 16px;
}
@media (min-width: 991px) {
  .page-projects .systems-list > ul > li a:hover {
    background: #fff;
  }
}
@media (min-width: 991px) and (max-width: 1280px) {
  .page-projects .systems-list > ul > li a:hover {
    padding: 8px;
  }
}
@media (min-width: 991px) {
  .page-projects .systems-list > ul > li a:hover .systems-title {
    text-decoration: underline;
  }
  .page-projects .systems-list > ul > li a:hover .systems-item::after {
    left: 100%;
  }
  .page-projects .systems-list > ul > li a:hover .systems-item::before {
    left: 0;
  }
}
.page-projects .systems-list > ul > li a:hover .systems-thumb img {
  -webkit-transform: scale(1.1) rotateZ(0deg);
          transform: scale(1.1) rotateZ(0deg);
}
.page-projects .systems-list .systems-thumb {
  overflow: hidden;
}
.page-projects .systems-list .systems-thumb img {
  width: 100%;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.page-projects .systems-list .systems-info {
  padding-top: 40px;
}
@media (max-width: 1280px) {
  .page-projects .systems-list .systems-info {
    padding-top: 32px;
  }
}
@media (max-width: 768px) {
  .page-projects .systems-list .systems-info {
    padding-top: 24px;
  }
}
.page-projects .systems-list .systems-info .systems-title {
  color: #2F333E;
  font-size: 26px;
}
@media (max-width: 1280px) {
  .page-projects .systems-list .systems-info .systems-title {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .page-projects .systems-list .systems-info .systems-title {
    font-size: 16px;
  }
}
.page-projects .systems-list .systems-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-top: 29px;
}
@media (max-width: 1280px) {
  .page-projects .systems-list .systems-label {
    padding-top: 26px;
  }
}
@media (max-width: 768px) {
  .page-projects .systems-list .systems-label {
    padding-top: 18px;
  }
}
.page-projects .systems-list .systems-label ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.page-projects .systems-list .systems-label ul li {
  color: #383C46;
  font-size: 18px;
  padding-right: 32px;
  position: relative;
}
@media (max-width: 1280px) {
  .page-projects .systems-list .systems-label ul li {
    font-size: 16px;
    padding-right: 16px;
  }
}
@media (max-width: 768px) {
  .page-projects .systems-list .systems-label ul li {
    font-size: 14px;
  }
}
.page-projects .systems-list .systems-label ul li::after {
  position: absolute;
  top: 7px;
  right: 16px;
  content: "";
  background: #2F333E;
  height: 8px;
  width: 1px;
}
@media (max-width: 1280px) {
  .page-projects .systems-list .systems-label ul li::after {
    right: 8px;
    top: 6px;
  }
}
@media (max-width: 768px) {
  .page-projects .systems-list .systems-label ul li::after {
    top: 5px;
  }
}
.page-projects .systems-list .systems-label ul li:last-child {
  padding-right: 0;
}
.page-projects .systems-list .systems-label ul li:last-child::after {
  display: none;
}
.page-projects .systems-list .systems-label .systems-item {
  margin-left: 10px;
  min-width: 40px;
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}
@media (max-width: 1280px) {
  .page-projects .systems-list .systems-label .systems-item {
    display: none;
  }
}
.page-projects .systems-list .systems-label .systems-item::after, .page-projects .systems-list .systems-label .systems-item::before {
  position: absolute;
  content: "";
  background: url(../../images/common/icon-arrow-btn-gray.svg) no-repeat center;
  top: 0;
  -webkit-transition: 400ms all;
  transition: 400ms all;
  width: 40px;
  height: 40px;
}
.page-projects .systems-list .systems-label .systems-item::after {
  left: 0;
}
.page-projects .systems-list .systems-label .systems-item::before {
  left: -100%;
}
.page-projects .systems-list .systems-btn {
  display: none;
  padding-top: 42px;
  max-width: 320px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .page-projects .systems-list .systems-btn {
    display: block;
  }
}
.page-projects .systems-list .systems-btn .btn {
  width: 100%;
}
.page-projects .insights {
  padding: 56px 0 96px;
}
@media (max-width: 1280px) {
  .page-projects .insights {
    padding: 48px 0 80px;
  }
}
@media (max-width: 768px) {
  .page-projects .insights {
    padding: 40px 0 64px;
  }
}
@media (max-width: 480px) {
  .page-projects .insights .systems-list > ul > li {
    margin-top: 0;
    border-bottom: 1px solid #C7C8CB;
  }
  .page-projects .insights .systems-list > ul > li:first-of-type .systems-thumb {
    display: block;
  }
  .page-projects .insights .systems-list > ul > li a {
    padding: 24px 0;
  }
  .page-projects .insights .systems-list .systems-thumb {
    display: none;
    padding-bottom: 16px;
  }
  .page-projects .insights .systems-list .systems-info {
    padding-top: 0;
  }
}

.formSolutions {
  background: #2F333E;
  padding: 58px 0 64px;
}
@media (max-width: 768px) {
  .formSolutions {
    padding: 32px 0;
  }
}
.formSolutions .formHead .formHead-title {
  color: #fff;
  font-size: 32px;
  font-weight: 600;
  max-width: 800px;
}
@media (max-width: 1280px) {
  .formSolutions .formHead .formHead-title {
    font-size: 23px;
    max-width: 100%;
  }
}
@media (max-width: 768px) {
  .formSolutions .formHead .formHead-title {
    font-size: 20px;
  }
}
.formSolutions .formHead .formHead-note {
  padding-top: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 1280px) {
  .formSolutions .formHead .formHead-note {
    padding-top: 16px;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
.formSolutions .formHead .formHead-note .formHead-success,
.formSolutions .formHead .formHead-note .formHead-required {
  color: #999BA1;
  font-size: 20px;
}
@media (max-width: 1280px) {
  .formSolutions .formHead .formHead-note .formHead-success,
  .formSolutions .formHead .formHead-note .formHead-required {
    font-size: 16px;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .formSolutions .formHead .formHead-note .formHead-success,
  .formSolutions .formHead .formHead-note .formHead-required {
    font-size: 14px;
  }
}
.formSolutions .formHead .formHead-note .formHead-success {
  max-width: 800px;
}
@media (max-width: 1280px) {
  .formSolutions .formHead .formHead-note .formHead-success {
    max-width: 100%;
  }
}
.formSolutions .formHead .formHead-note .formHead-required {
  padding-left: 10px;
}
@media (max-width: 1280px) {
  .formSolutions .formHead .formHead-note .formHead-required {
    padding: 8px 0 0;
  }
}
.formSolutions .formHead-finding {
  text-align: center;
}
@media (max-width: 768px) {
  .formSolutions .formHead-finding {
    text-align: left;
  }
}
.formSolutions .formHead-finding .formHead-finding__title {
  color: #fff;
  font-size: 32px;
  font-weight: 500;
}
@media (max-width: 1280px) {
  .formSolutions .formHead-finding .formHead-finding__title {
    font-size: 23px;
  }
}
@media (max-width: 768px) {
  .formSolutions .formHead-finding .formHead-finding__title {
    font-size: 20px;
  }
}
.formSolutions .formHead-finding .formHead-finding__text {
  padding-top: 24px;
  font-size: 16px;
  color: #939393;
}
@media (max-width: 1280px) {
  .formSolutions .formHead-finding .formHead-finding__text {
    max-width: 800px;
    margin: 0 auto;
  }
}
@media (max-width: 768px) {
  .formSolutions .formHead-finding .formHead-finding__text {
    font-size: 14px;
    padding-top: 16px;
  }
}
.formSolutions .form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-top: 32px;
  margin-left: -64px;
}
@media (max-width: 1280px) {
  .formSolutions .form-row {
    padding-top: 16px;
    margin-left: -16px;
  }
}
.formSolutions .form-row .form-col {
  width: 50%;
  padding-left: 64px;
}
@media (max-width: 1280px) {
  .formSolutions .form-row .form-col {
    padding-left: 16px;
  }
}
@media (max-width: 768px) {
  .formSolutions .form-row .form-col {
    width: 100%;
  }
}
.formSolutions .form-row .groupForm {
  margin-top: 16px;
}
.formSolutions .formCheck {
  padding-top: 40px;
  max-width: 834px;
}
@media (max-width: 768px) {
  .formSolutions .formCheck {
    padding-top: 8px;
  }
}
.formSolutions .formCheck .formCheck__list {
  padding-top: 24px;
}
.formSolutions .formBtn {
  padding-top: 48px;
  max-width: 320px;
}
@media (max-width: 1280px) {
  .formSolutions .formBtn {
    max-width: 360px;
    padding-top: 32px;
  }
}
@media (max-width: 768px) {
  .formSolutions .formBtn {
    max-width: 260px;
    margin: 0 auto;
  }
}
.formSolutions .formBtn .btn {
  width: 100%;
}

/*****/
.slider-proud {
  padding: 48px 0;
}
@media (max-width: 1280px) {
  .slider-proud {
    padding: 32px 0;
  }
}
@media (max-width: 768px) {
  .slider-proud {
    padding: 40px 0;
  }
}
.slider-proud .slick-slide {
  width: 600px;
  margin-right: 32px;
  padding-bottom: 1px;
}
@media (max-width: 1280px) {
  .slider-proud .slick-slide {
    width: 586px;
  }
}
@media (max-width: 768px) {
  .slider-proud .slick-slide {
    width: 309px;
    margin-right: 16px;
  }
}
.slider-proud .proud-list {
  border-radius: 6px;
  border: 1px solid #C9D6EF;
  overflow: hidden;
  background: #F6F8FC;
}
@media (max-width: 768px) {
  .slider-proud .proud-list {
    background: #fff;
  }
}
.slider-proud .proud-list:hover .proud-thumb img {
  -webkit-transform: scale(1.1) rotateZ(0deg);
          transform: scale(1.1) rotateZ(0deg);
}
.slider-proud .proud-list .proud-thumb {
  overflow: hidden;
}
.slider-proud .proud-list .proud-thumb img {
  width: 100%;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.slider-proud .proud-list .proud-info {
  padding: 48px 40px;
  position: relative;
}
@media (max-width: 1280px) {
  .slider-proud .proud-list .proud-info {
    padding: 48px 24px 32px;
  }
}
@media (max-width: 768px) {
  .slider-proud .proud-list .proud-info {
    padding: 32px 24px;
  }
}
.slider-proud .proud-list .proud-info .proud-item {
  position: absolute;
  top: -40px;
  left: 40px;
  width: 80px;
  height: 80px;
  background: #F6F8FC;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 768px) {
  .slider-proud .proud-list .proud-info .proud-item {
    width: 40px;
    height: 40px;
    left: 24px;
    top: -20px;
    background: #fff;
  }
  .slider-proud .proud-list .proud-info .proud-item img,
  .slider-proud .proud-list .proud-info .proud-item svg {
    max-width: 18px;
  }
}
.slider-proud .proud-list .proud-info .proud-info__text {
  color: #383C46;
  font-size: 18px;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 3;
  -webkit-box-pack: end;
  height: 65px;
}
@media (max-width: 768px) {
  .slider-proud .proud-list .proud-info .proud-info__text {
    font-size: 14px;
    height: 50px;
  }
}
.slider-proud .proud-list .proud-info .proud-info__deliver {
  text-align: right;
  margin-top: 32px;
}
@media (max-width: 768px) {
  .slider-proud .proud-list .proud-info .proud-info__deliver {
    margin-top: 24px;
  }
}
.slider-proud .proud-list .proud-info .proud-info__deliver .deliver-title {
  font-weight: 500;
  font-size: 20px;
  color: #2F333E;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
@media (max-width: 1280px) {
  .slider-proud .proud-list .proud-info .proud-info__deliver .deliver-title {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .slider-proud .proud-list .proud-info .proud-info__deliver .deliver-title {
    font-size: 14px;
  }
}
.slider-proud .proud-list .proud-info .proud-info__deliver .deliver-text {
  margin-top: 8px;
  font-size: 16px;
  color: #383C46;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
}
@media (max-width: 768px) {
  .slider-proud .proud-list .proud-info .proud-info__deliver .deliver-text {
    font-size: 14px;
    margin-top: 4px;
  }
}

/* -- page-blog -- */
.page-blog {
  /************/
  /*******/
}
.page-blog .heade-blog {
  padding: 80px 0 56px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid #DCE3F5;
}
@media (max-width: 1280px) {
  .page-blog .heade-blog {
    padding: 64px 0 48px;
  }
}
@media (max-width: 768px) {
  .page-blog .heade-blog {
    padding: 32px 0;
  }
}
.page-blog .heade-blog .title {
  padding: 0;
  max-width: 520px;
}
@media (max-width: 1280px) {
  .page-blog .heade-blog .title {
    max-width: 420px;
  }
}
@media (max-width: 768px) {
  .page-blog .heade-blog .title {
    max-width: 100%;
    width: 100%;
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
}
.page-blog .heade-blog .heade-search {
  width: calc(100% - 520px);
  padding-left: 10px;
  margin-left: auto;
}
@media (max-width: 1280px) {
  .page-blog .heade-blog .heade-search {
    width: calc(100% - 420px);
  }
}
@media (max-width: 768px) {
  .page-blog .heade-blog .heade-search {
    width: 100%;
    padding-left: 0;
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
}
.page-blog .heade-blog .heade-search .search {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  max-width: 355px;
  margin-left: auto;
}
@media (max-width: 768px) {
  .page-blog .heade-blog .heade-search .search {
    margin-bottom: 32px;
    max-width: 100%;
  }
}
.page-blog .heade-blog .heade-search .search .form-control {
  height: 60px;
  width: 156px;
  border: none;
  border-radius: 6px;
  background: #2F333E;
  padding-right: 64px;
  font-size: 18px;
  -webkit-transition: 400ms all;
  transition: 400ms all;
}
@media (max-width: 1280px) {
  .page-blog .heade-blog .heade-search .search .form-control {
    height: 43px;
    width: 117px;
    font-size: 16px;
    padding-right: 32px;
    padding-left: 16px;
  }
}
@media (max-width: 768px) {
  .page-blog .heade-blog .heade-search .search .form-control {
    width: 100%;
    height: 40px;
    background: #fff;
    border: 1px solid #C3C4D2;
    color: #6C6F77;
  }
}
.page-blog .heade-blog .heade-search .search .form-control::-webkit-input-placeholder {
  color: #fff;
}
@media (max-width: 768px) {
  .page-blog .heade-blog .heade-search .search .form-control::-webkit-input-placeholder {
    color: #6C6F77;
  }
}
.page-blog .heade-blog .heade-search .search .form-control::-webkit-input-placeholder {
  color: #FFF;
}
@media (max-width: 768px) {
  .page-blog .heade-blog .heade-search .search .form-control::-webkit-input-placeholder {
    color: #6C6F77;
  }
}
.page-blog .heade-blog .heade-search .search .form-control::-moz-placeholder {
  color: #fff;
}
@media (max-width: 768px) {
  .page-blog .heade-blog .heade-search .search .form-control::-moz-placeholder {
    color: #6C6F77;
  }
}
.page-blog .heade-blog .heade-search .search .form-control:-ms-input-placeholder {
  color: #fff;
}
@media (max-width: 768px) {
  .page-blog .heade-blog .heade-search .search .form-control:-ms-input-placeholder {
    color: #6C6F77;
  }
}
.page-blog .heade-blog .heade-search .search .form-control::-ms-input-placeholder {
  color: #fff;
}
@media (max-width: 768px) {
  .page-blog .heade-blog .heade-search .search .form-control::-ms-input-placeholder {
    color: #6C6F77;
  }
}
.page-blog .heade-blog .heade-search .search .form-control::-webkit-input-placeholder {
  color: #fff;
}
.page-blog .heade-blog .heade-search .search .form-control::-moz-placeholder {
  color: #fff;
}
.page-blog .heade-blog .heade-search .search .form-control:-ms-input-placeholder {
  color: #fff;
}
.page-blog .heade-blog .heade-search .search .form-control::-ms-input-placeholder {
  color: #fff;
}
.page-blog .heade-blog .heade-search .search .form-control::placeholder {
  color: #fff;
}
@media (max-width: 768px) {
  .page-blog .heade-blog .heade-search .search .form-control::-webkit-input-placeholder {
    color: #6C6F77;
  }
  .page-blog .heade-blog .heade-search .search .form-control::-moz-placeholder {
    color: #6C6F77;
  }
  .page-blog .heade-blog .heade-search .search .form-control:-ms-input-placeholder {
    color: #6C6F77;
  }
  .page-blog .heade-blog .heade-search .search .form-control::-ms-input-placeholder {
    color: #6C6F77;
  }
  .page-blog .heade-blog .heade-search .search .form-control::placeholder {
    color: #6C6F77;
  }
}
.page-blog .heade-blog .heade-search .search .form-control:hover {
  background: #0246c6;
}
@media (max-width: 768px) {
  .page-blog .heade-blog .heade-search .search .form-control:hover {
    background: #fff;
  }
}
.page-blog .heade-blog .heade-search .search .form-control:focus {
  width: 100%;
  background: #fff;
  border: 1px solid #C3C4D2;
  color: #6C6F77;
}
.page-blog .heade-blog .heade-search .search .form-control:focus::-webkit-input-placeholder {
  color: #6C6F77;
}
.page-blog .heade-blog .heade-search .search .form-control:focus::-webkit-input-placeholder {
  color: #6C6F77;
}
.page-blog .heade-blog .heade-search .search .form-control:focus::-moz-placeholder {
  color: #6C6F77;
}
.page-blog .heade-blog .heade-search .search .form-control:focus:-ms-input-placeholder {
  color: #6C6F77;
}
.page-blog .heade-blog .heade-search .search .form-control:focus::-ms-input-placeholder {
  color: #6C6F77;
}
.page-blog .heade-blog .heade-search .search .form-control:focus::placeholder {
  color: #6C6F77;
}
.page-blog .heade-blog .heade-search .search .form-control:focus ~ .btn .svg path {
  fill: #0246C6;
}
.page-blog .heade-blog .heade-search .search .btn {
  position: absolute;
  top: 0;
  right: 0;
  height: 60px;
  width: 64px;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 1280px) {
  .page-blog .heade-blog .heade-search .search .btn {
    height: 43px;
    width: 40px;
  }
}
@media (max-width: 768px) {
  .page-blog .heade-blog .heade-search .search .btn {
    height: 40px;
  }
}
.page-blog .heade-blog .heade-search .search .btn:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
@media (max-width: 1280px) {
  .page-blog .heade-blog .heade-search .search .btn .svg {
    width: 16px;
    height: 16px;
  }
}
@media (max-width: 768px) {
  .page-blog .heade-blog .heade-search .search .btn .svg path {
    fill: #0246C6;
  }
}
.page-blog .topics {
  background: #fff;
  padding: 64px 0 80px;
}
@media (max-width: 1280px) {
  .page-blog .topics {
    padding: 56px 0 64px;
  }
}
@media (max-width: 768px) {
  .page-blog .topics {
    padding: 40px 0;
  }
}
.page-blog .topics .topics-title {
  text-align: center;
}
.page-blog .topics .topics-title .topics-title__tlt {
  font-size: 32px;
  font-weight: 600;
}
@media (max-width: 1280px) {
  .page-blog .topics .topics-title .topics-title__tlt {
    font-size: 23px;
  }
}
@media (max-width: 768px) {
  .page-blog .topics .topics-title .topics-title__tlt {
    font-size: 20px;
  }
}
.page-blog .topics .topics-title .topics-title__note {
  padding-top: 10px;
  font-size: 18px;
  color: #383C46;
}
@media (max-width: 768px) {
  .page-blog .topics .topics-title .topics-title__note {
    padding-top: 0;
    font-size: 16px;
  }
}
.page-blog .topics .topics-list {
  padding-top: 38px;
}
@media (max-width: 1280px) {
  .page-blog .topics .topics-list {
    padding-top: 22px;
  }
}
@media (max-width: 768px) {
  .page-blog .topics .topics-list {
    padding-top: 26px;
  }
}
.page-blog .topics .topics-list ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.page-blog .topics .topics-list ul li {
  padding: 10px 5px 0;
}
@media (max-width: 768px) {
  .page-blog .topics .topics-list ul li {
    padding: 6px 3px 0;
  }
}
@media (max-width: 768px) {
  .page-blog .topics .topics-list ul li .btn {
    padding: 0 8px;
  }
}
.page-blog .clinic {
  padding: 64px 0;
}
@media (max-width: 1280px) {
  .page-blog .clinic {
    padding: 56px 0 64px;
  }
}
@media (max-width: 768px) {
  .page-blog .clinic {
    padding: 40px 0;
  }
}
.page-blog .slider-clinic {
  padding: 48px 0 72px;
}
@media (max-width: 1280px) {
  .page-blog .slider-clinic {
    padding: 32px 0 50px;
  }
}
@media (max-width: 768px) {
  .page-blog .slider-clinic {
    padding: 32px 0 16px;
  }
}
.page-blog .slider-clinic .slick-slide {
  width: 452px;
  padding-right: 32px;
}
@media (max-width: 1280px) {
  .page-blog .slider-clinic .slick-slide {
    width: 364px;
    padding-right: 24px;
  }
}
@media (max-width: 768px) {
  .page-blog .slider-clinic .slick-slide {
    width: 281px;
    padding-right: 16px;
  }
}
.page-blog .slider-clinic .slick-dots {
  padding-right: 272px;
}
@media (max-width: 1280px) {
  .page-blog .slider-clinic .slick-dots {
    padding-right: 160px;
  }
}
@media (max-width: 768px) {
  .page-blog .slider-clinic .slick-dots {
    padding-right: 16px;
  }
}
.page-blog .slider-security {
  padding: 48px 0 72px;
}
@media (max-width: 1280px) {
  .page-blog .slider-security {
    padding: 32px 0 50px;
  }
}
@media (max-width: 768px) {
  .page-blog .slider-security {
    padding: 32px 0;
  }
}
.page-blog .slider-security .slick-slide {
  width: 452px;
  padding-right: 32px;
}
@media (max-width: 1280px) {
  .page-blog .slider-security .slick-slide {
    width: 364px;
    padding-right: 24px;
  }
}
@media (max-width: 768px) {
  .page-blog .slider-security .slick-slide {
    width: 281px;
    padding-right: 16px;
  }
}
.page-blog .slider-security .slick-dots {
  padding-right: 272px;
}
@media (max-width: 1280px) {
  .page-blog .slider-security .slick-dots {
    padding-right: 160px;
  }
}
@media (max-width: 768px) {
  .page-blog .slider-security .slick-dots {
    padding-right: 16px;
  }
}
.page-blog .clinicInfo:hover .clinicInfo-title a {
  text-decoration: underline;
}
.page-blog .clinicInfo:hover .clinicInfo-thumb img {
  -webkit-transform: scale(1.1) rotateZ(0deg);
          transform: scale(1.1) rotateZ(0deg);
}
.page-blog .clinicInfo .clinicInfo-thumb {
  overflow: hidden;
}
.page-blog .clinicInfo .clinicInfo-thumb img {
  width: 100%;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.page-blog .clinicInfo .clinicInfo-content {
  background: #fff;
  padding: 24px 24px 32px;
  border: 1px solid #C9D6EF;
  border-top: none;
  border-radius: 0px 0px 3px 3px;
}
@media (max-width: 1280px) {
  .page-blog .clinicInfo .clinicInfo-content {
    padding: 24px 16px;
  }
}
@media (max-width: 768px) {
  .page-blog .clinicInfo .clinicInfo-content {
    padding: 16px;
  }
}
.page-blog .clinicInfo .clinicInfo-content .clinicInfo-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.page-blog .clinicInfo .clinicInfo-content .clinicInfo-label .clinicInfo-label__name,
.page-blog .clinicInfo .clinicInfo-content .clinicInfo-label .clinicInfo-label__date {
  font-size: 16px;
  color: #6C6F77;
}
@media (max-width: 1280px) {
  .page-blog .clinicInfo .clinicInfo-content .clinicInfo-label .clinicInfo-label__name,
  .page-blog .clinicInfo .clinicInfo-content .clinicInfo-label .clinicInfo-label__date {
    font-size: 14px;
  }
}
@media (max-width: 768px) {
  .page-blog .clinicInfo .clinicInfo-content .clinicInfo-label .clinicInfo-label__name,
  .page-blog .clinicInfo .clinicInfo-content .clinicInfo-label .clinicInfo-label__date {
    font-size: 13px;
  }
}
.page-blog .clinicInfo .clinicInfo-content .clinicInfo-label .clinicInfo-label__name {
  padding-right: 24px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media (max-width: 1280px) {
  .page-blog .clinicInfo .clinicInfo-content .clinicInfo-label .clinicInfo-label__name {
    padding-right: 16px;
  }
}
@media (max-width: 768px) {
  .page-blog .clinicInfo .clinicInfo-content .clinicInfo-label .clinicInfo-label__name {
    padding-right: 8px;
  }
}
.page-blog .clinicInfo .clinicInfo-content .clinicInfo-label .clinicInfo-label__date {
  white-space: nowrap;
}
.page-blog .clinicInfo .clinicInfo-content .clinicInfo-title {
  margin-top: 16px;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 3;
  -webkit-box-pack: end;
  height: 65px;
}
@media (max-width: 768px) {
  .page-blog .clinicInfo .clinicInfo-content .clinicInfo-title {
    height: 57px;
  }
}
.page-blog .clinicInfo .clinicInfo-content .clinicInfo-title a {
  color: #2F333E;
  font-size: 18px;
  font-weight: 600;
}
@media (max-width: 768px) {
  .page-blog .clinicInfo .clinicInfo-content .clinicInfo-title a {
    font-size: 16px;
    font-weight: 500;
  }
}
.page-blog .clinicInfo .clinicInfo-content .clinicInfo-more {
  padding-top: 40px;
}
@media (max-width: 1280px) {
  .page-blog .clinicInfo .clinicInfo-content .clinicInfo-more {
    padding-top: 32px;
  }
}
@media (max-width: 768px) {
  .page-blog .clinicInfo .clinicInfo-content .clinicInfo-more {
    display: none;
  }
}
.page-blog .see-more {
  display: none;
  padding-top: 32px;
  max-width: 320px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .page-blog .see-more {
    display: block;
  }
}
.page-blog .see-more .btn {
  width: 100%;
}
.page-blog .should {
  background: url(../../images/data/bg-complete.jpg) no-repeat bottom right;
  background-size: cover;
  padding: 90px 0;
}
@media (max-width: 1280px) {
  .page-blog .should {
    padding: 56px 0;
  }
}
@media (max-width: 768px) {
  .page-blog .should {
    padding: 32px 0;
  }
}
.page-blog .should .should-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 1280px) {
  .page-blog .should .should-info {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: center;
  }
}
.page-blog .should .should-info .should-text {
  font-size: 36px;
  font-weight: 600;
  color: #fff;
}
@media (max-width: 1280px) {
  .page-blog .should .should-info .should-text {
    font-size: 41px;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .page-blog .should .should-info .should-text {
    font-size: 20px;
  }
}
.page-blog .should .should-info .should-btn {
  padding-left: 10px;
}
@media (max-width: 1280px) {
  .page-blog .should .should-info .should-btn {
    width: 100%;
    padding: 48px 0 0 0;
    max-width: 480px;
    margin: 0 auto;
  }
}
@media (max-width: 768px) {
  .page-blog .should .should-info .should-btn {
    max-width: 260px;
    padding-top: 16px;
  }
}
@media (max-width: 1280px) {
  .page-blog .should .should-info .should-btn .btn {
    width: 100%;
  }
}
.page-blog .testing {
  padding: 64px 0 96px;
}
@media (max-width: 1280px) {
  .page-blog .testing {
    padding: 56px 0 64px;
  }
}
@media (max-width: 768px) {
  .page-blog .testing {
    padding: 40px 0;
  }
}
@media (max-width: 1280px) {
  .page-blog .testing .testingInfo {
    padding-top: 8px;
  }
}
@media (max-width: 768px) {
  .page-blog .testing .testingInfo {
    padding-top: 16px;
  }
}
.page-blog .testing .testingInfo ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-left: -32px;
}
@media (max-width: 1280px) {
  .page-blog .testing .testingInfo ul {
    margin-left: -24px;
  }
}
.page-blog .testing .testingInfo ul li {
  padding-top: 48px;
  margin-left: 32px;
  width: calc(50% - 32px);
}
@media (max-width: 1280px) {
  .page-blog .testing .testingInfo ul li {
    padding-top: 24px;
    margin-left: 24px;
    width: calc(50% - 24px);
  }
}
@media (max-width: 768px) {
  .page-blog .testing .testingInfo ul li {
    width: calc(100% - 24px);
    padding-top: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
@media (max-width: 768px) {
  .page-blog .testing .testingInfo ul li:first-of-type {
    display: block;
    padding-bottom: 8px;
  }
  .page-blog .testing .testingInfo ul li:first-of-type .testingInfo-thumb {
    width: 100%;
  }
  .page-blog .testing .testingInfo ul li:first-of-type .testingInfo-content {
    padding: 16px 0 0;
    width: 100%;
  }
  .page-blog .testing .testingInfo ul li:first-of-type .testingInfo-content .testingInfo-title a {
    font-size: 16px;
  }
}
.page-blog .testing .testingInfo ul li:hover .testingInfo-title a {
  text-decoration: underline;
}
.page-blog .testing .testingInfo ul li:hover .testingInfo-thumb img {
  -webkit-transform: scale(1.1) rotateZ(0deg);
          transform: scale(1.1) rotateZ(0deg);
}
.page-blog .testing .testingInfo .testingInfo-thumb {
  overflow: hidden;
}
@media (max-width: 768px) {
  .page-blog .testing .testingInfo .testingInfo-thumb {
    width: 140px;
  }
}
.page-blog .testing .testingInfo .testingInfo-thumb img {
  width: 100%;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.page-blog .testing .testingInfo .testingInfo-content {
  padding: 24px 0 0;
}
@media (max-width: 768px) {
  .page-blog .testing .testingInfo .testingInfo-content {
    padding: 0 0 0 16px;
    width: calc(100% - 140px);
  }
}
.page-blog .testing .testingInfo .testingInfo-content .testingInfo-title {
  margin-top: 24px;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 2;
  -webkit-box-pack: end;
  min-height: 48px;
}
@media (max-width: 1280px) {
  .page-blog .testing .testingInfo .testingInfo-content .testingInfo-title {
    margin-top: 16px;
    min-height: initial;
  }
}
.page-blog .testing .testingInfo .testingInfo-content .testingInfo-title a {
  font-size: 20px;
  color: #2F333E;
  font-weight: 500;
}
@media (max-width: 1280px) {
  .page-blog .testing .testingInfo .testingInfo-content .testingInfo-title a {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .page-blog .testing .testingInfo .testingInfo-content .testingInfo-title a {
    font-size: 14px;
  }
}
.page-blog .testing .testingInfo .testingInfo-content .testingInfo-more {
  padding-top: 24px;
}
@media (max-width: 1280px) {
  .page-blog .testing .testingInfo .testingInfo-content .testingInfo-more {
    display: none;
  }
}
.page-blog .nursing {
  background: #fff;
}
.page-blog .podcast {
  padding: 64px 0;
  background: url(../../images/data/bg-podcast.jpg) no-repeat bottom right;
  background-size: cover;
}
@media (max-width: 1280px) {
  .page-blog .podcast {
    padding: 56px 0 64px;
  }
}
@media (max-width: 768px) {
  .page-blog .podcast {
    padding: 40px 0;
  }
}
.page-blog .podcast .title .title-text {
  color: #fff;
}
.page-blog .slider-podcast {
  padding: 48px 0;
}
@media (max-width: 1280px) {
  .page-blog .slider-podcast {
    padding: 32px 0 48px;
  }
}
@media (max-width: 768px) {
  .page-blog .slider-podcast {
    padding: 32px 0;
  }
}
.page-blog .slider-podcast .slick-slide {
  width: 452px;
  padding-right: 32px;
}
@media (max-width: 1280px) {
  .page-blog .slider-podcast .slick-slide {
    width: 364px;
    padding-right: 24px;
  }
}
@media (max-width: 768px) {
  .page-blog .slider-podcast .slick-slide {
    width: 265px;
    padding-right: 16px;
  }
}
.page-blog .slider-podcast .slick-dots {
  padding-right: 272px;
}
@media (max-width: 1280px) {
  .page-blog .slider-podcast .slick-dots {
    padding-right: 160px;
  }
}
@media (max-width: 768px) {
  .page-blog .slider-podcast .slick-dots {
    padding-right: 16px;
  }
}
.page-blog .slider-podcast .slick-dots li button {
  background: #D5E1EF;
  opacity: 0.4;
}
.page-blog .slider-podcast .slick-dots li.slick-active button {
  background: #fff;
  opacity: 1;
}
.page-blog .slider-podcast .podcastInfo {
  border-radius: 3px;
  overflow: hidden;
}
.page-blog .slider-podcast .podcastInfo-thumb {
  position: relative;
}
.page-blog .slider-podcast .podcastInfo-thumb .podcastInfo-item {
  position: absolute;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: url(../../images/common/icon-play.svg) no-repeat center rgba(0, 0, 0, 0.4);
}
@media (max-width: 1280px) {
  .page-blog .slider-podcast .podcastInfo-thumb .podcastInfo-item {
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    width: 60px;
    height: 60px;
    background-size: 16px auto;
  }
}
@media (max-width: 768px) {
  .page-blog .slider-podcast .podcastInfo-thumb .podcastInfo-item {
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    width: 40px;
    height: 40px;
    background-size: 14px auto;
  }
}
.page-blog .slider-podcast .podcastInfo-content {
  background: #fff;
  padding: 24px;
  border: 1px solid #C9D6EF;
  border-top: none;
  border-radius: 0 0 3px 3px;
}
@media (max-width: 768px) {
  .page-blog .slider-podcast .podcastInfo-content {
    padding: 16px;
  }
}
.page-blog .slider-podcast .podcastInfo-content .podcastInfo-trial {
  margin-top: 8px;
  color: #6C6F77;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 1280px) {
  .page-blog .slider-podcast .podcastInfo-content .podcastInfo-trial {
    font-size: 14px;
  }
}
@media (max-width: 768px) {
  .page-blog .slider-podcast .podcastInfo-content .podcastInfo-trial {
    font-size: 13px;
  }
}
.page-blog .slider-podcast .podcastInfo-content .podcastInfo-title {
  color: #2F333E;
  font-size: 20px;
  font-weight: 500;
  margin-top: 16px;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 4;
  -webkit-box-pack: end;
  height: 96px;
}
@media (max-width: 1280px) {
  .page-blog .slider-podcast .podcastInfo-content .podcastInfo-title {
    font-size: 18px;
    height: 86px;
  }
}
@media (max-width: 768px) {
  .page-blog .slider-podcast .podcastInfo-content .podcastInfo-title {
    font-size: 16px;
    height: 77px;
  }
}
.page-blog .slider-podcast .podcastInfo-author {
  border-top: 1px solid #D5E1EF;
  padding-top: 24px;
  margin-top: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 1280px) {
  .page-blog .slider-podcast .podcastInfo-author {
    padding-top: 16px;
  }
}
@media (max-width: 768px) {
  .page-blog .slider-podcast .podcastInfo-author {
    padding-top: 8px;
    margin-top: 24px;
  }
}
.page-blog .slider-podcast .podcastInfo-author .podcast-author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  max-width: 230px;
}
@media (max-width: 1280px) {
  .page-blog .slider-podcast .podcastInfo-author .podcast-author {
    max-width: 164px;
  }
}
.page-blog .slider-podcast .podcastInfo-author .podcast-author .podcast-author__avatar {
  width: 40px;
  min-width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-right: 9px;
}
.page-blog .slider-podcast .podcastInfo-author .podcast-author .podcast-author__name {
  color: #6C6F77;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 768px) {
  .page-blog .slider-podcast .podcastInfo-author .podcast-author .podcast-author__name {
    font-size: 14px;
  }
}
.page-blog .slider-podcast .podcastInfo-author .podcast-mins {
  margin-left: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.page-blog .slider-podcast .podcastInfo-author .podcast-mins .podcast-mins__number {
  color: #6C6F77;
  font-size: 16px;
}
@media (max-width: 768px) {
  .page-blog .slider-podcast .podcastInfo-author .podcast-mins .podcast-mins__number {
    display: none;
  }
}
.page-blog .slider-podcast .podcastInfo-author .podcast-mins .podcast-mins__item {
  background: url(../../images/common/icon-play.svg) no-repeat center rgba(0, 0, 0, 0.3);
  background-size: 12px auto;
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
  margin-left: 7px;
}
.page-blog .btn-slider,
.page-blog .btn-slider-podcast,
.page-blog .btn-slider-security,
.page-blog .btn-slider-library,
.page-blog .btn-slider-articles {
  width: 108px;
  margin: -26px 0 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  z-index: 2;
}
@media (max-width: 1280px) {
  .page-blog .btn-slider,
  .page-blog .btn-slider-podcast,
  .page-blog .btn-slider-security,
  .page-blog .btn-slider-library,
  .page-blog .btn-slider-articles {
    width: 98px;
    margin-top: -20px;
  }
}
@media (max-width: 768px) {
  .page-blog .btn-slider,
  .page-blog .btn-slider-podcast,
  .page-blog .btn-slider-security,
  .page-blog .btn-slider-library,
  .page-blog .btn-slider-articles {
    display: none;
  }
}
.page-blog .btn-slider .btn,
.page-blog .btn-slider-podcast .btn,
.page-blog .btn-slider-security .btn,
.page-blog .btn-slider-library .btn,
.page-blog .btn-slider-articles .btn {
  padding: 0;
  width: 50px;
  min-width: 50px;
  height: 50px;
  border: none;
  margin-left: 8px;
  border-radius: 50%;
}
@media (max-width: 1280px) {
  .page-blog .btn-slider .btn,
  .page-blog .btn-slider-podcast .btn,
  .page-blog .btn-slider-security .btn,
  .page-blog .btn-slider-library .btn,
  .page-blog .btn-slider-articles .btn {
    width: 40px;
    min-width: 40px;
    height: 40px;
  }
}
.page-blog .btn-slider .btn.btn-next,
.page-blog .btn-slider-podcast .btn.btn-next,
.page-blog .btn-slider-security .btn.btn-next,
.page-blog .btn-slider-library .btn.btn-next,
.page-blog .btn-slider-articles .btn.btn-next {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.page-blog .pharmacy {
  padding: 64px 0;
}
@media (max-width: 1280px) {
  .page-blog .pharmacy {
    padding: 46px 0 64px;
  }
}
@media (max-width: 768px) {
  .page-blog .pharmacy {
    padding: 40px 0;
  }
}
@media (max-width: 768px) {
  .page-blog .pharmacy .featured-main .featured-content .featured-text {
    display: block;
    font-size: 14px;
    padding-top: 8px;
  }
}
@media (max-width: 768px) {
  .page-blog .pharmacy .featured-info .featured-thumb {
    display: none;
  }
}
@media (max-width: 768px) {
  .page-blog .pharmacy .featured-info .featured-content {
    padding-left: 0;
    width: 100%;
  }
}
.page-blog .freeGguide {
  background: url(../../images/data/free-guide.jpg) no-repeat center right;
  background-size: cover;
  padding: 64px 0;
}
@media (max-width: 1280px) {
  .page-blog .freeGguide {
    padding: 56px 0;
  }
}
@media (max-width: 768px) {
  .page-blog .freeGguide {
    padding: 40px 0;
  }
}
.page-blog .freeGguide .free-dflex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.page-blog .freeGguide .free-dflex .free-content {
  max-width: 600px;
}
@media (max-width: 768px) {
  .page-blog .freeGguide .free-dflex .free-content {
    text-align: center;
  }
}
.page-blog .freeGguide .free-dflex .free-content .free-title {
  color: #fff;
  font-size: 32px;
  font-weight: 600;
}
@media (max-width: 1280px) {
  .page-blog .freeGguide .free-dflex .free-content .free-title {
    font-size: 23px;
  }
}
@media (max-width: 768px) {
  .page-blog .freeGguide .free-dflex .free-content .free-title {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .page-blog .freeGguide .free-dflex .free-content .free-title .free-span {
    display: block;
    font-size: 18px;
    font-weight: 400;
  }
}
.page-blog .freeGguide .free-dflex .free-content .free-text {
  padding: 24px 0;
  color: #C7C8CB;
  font-size: 18px;
}
@media (max-width: 1280px) {
  .page-blog .freeGguide .free-dflex .free-content .free-text {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .page-blog .freeGguide .free-dflex .free-content .free-text {
    font-size: 14px;
    padding: 16px 0 32px;
  }
}
@media (max-width: 768px) {
  .page-blog .freeGguide .free-dflex .free-content .free-btn .btn {
    margin: 0 auto;
  }
}
.page-blog .freeGguide .free-dflex .free-file {
  margin-left: auto;
  min-width: 220px;
}
@media (max-width: 768px) {
  .page-blog .freeGguide .free-dflex .free-file {
    display: none;
  }
}
.page-blog .patient {
  padding: 64px 0;
}
@media (max-width: 1280px) {
  .page-blog .patient {
    padding: 56px 0 64px;
  }
}
@media (max-width: 768px) {
  .page-blog .patient {
    padding: 40px 0;
  }
}
@media (max-width: 1280px) {
  .page-blog .patient-list {
    padding-top: 8px;
  }
}
@media (max-width: 768px) {
  .page-blog .patient-list {
    padding-top: 16px;
  }
}
.page-blog .patient-list ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-left: -32px;
}
@media (max-width: 1280px) {
  .page-blog .patient-list ul {
    margin-left: -24px;
  }
}
.page-blog .patient-list ul li {
  width: 50%;
  padding-left: 32px;
  margin-top: 48px;
}
@media (max-width: 1280px) {
  .page-blog .patient-list ul li {
    margin-top: 24px;
    padding-left: 24px;
  }
}
@media (max-width: 900px) {
  .page-blog .patient-list ul li {
    width: 100%;
    margin-top: 16px;
  }
}
.page-blog .patient-list .patient-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.page-blog .patient-list .patient-info .patient-thumb {
  width: 260px;
}
@media (max-width: 1280px) {
  .page-blog .patient-list .patient-info .patient-thumb {
    width: 206px;
  }
}
@media (max-width: 768px) {
  .page-blog .patient-list .patient-info .patient-thumb {
    width: 140px;
  }
}
.page-blog .patient-list .patient-info .patient-content {
  width: calc(100% - 260px);
  padding-left: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 1280px) {
  .page-blog .patient-list .patient-info .patient-content {
    width: calc(100% - 206px);
  }
}
@media (max-width: 768px) {
  .page-blog .patient-list .patient-info .patient-content {
    width: calc(100% - 140px);
    padding-left: 16px;
  }
}
.page-blog .patient-list .patient-info .patient-content .patient-title {
  padding-top: 16px;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 3;
  -webkit-box-pack: end;
}
@media (max-width: 768px) {
  .page-blog .patient-list .patient-info .patient-content .patient-title {
    padding-top: 8px;
  }
}
.page-blog .patient-list .patient-info .patient-content .patient-title a {
  font-size: 20px;
  color: #2F333E;
  font-weight: 600;
}
@media (max-width: 1280px) {
  .page-blog .patient-list .patient-info .patient-content .patient-title a {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .page-blog .patient-list .patient-info .patient-content .patient-title a {
    font-size: 14px;
  }
}
.page-blog .patient-list .patient-info .patient-content .patient-more {
  padding-top: 8px;
}
@media (max-width: 1280px) {
  .page-blog .patient-list .patient-info .patient-content .patient-more {
    display: none;
  }
}
.page-blog .library {
  padding: 64px 0;
  background: url(../../images/data/bg-library.jpg) no-repeat center right;
  background-size: cover;
}
@media (max-width: 1280px) {
  .page-blog .library {
    padding: 56px 0 64px;
  }
}
@media (max-width: 768px) {
  .page-blog .library {
    padding: 40px 0;
  }
}
.page-blog .library .title .title-text {
  color: #fff;
}
.page-blog .slider-library {
  padding: 48px 0 72px;
}
@media (max-width: 1280px) {
  .page-blog .slider-library {
    padding: 32px 0 50px;
  }
}
@media (max-width: 768px) {
  .page-blog .slider-library {
    padding: 32px 0;
  }
}
.page-blog .slider-library .slick-slide {
  width: 488px;
  padding-right: 28px;
}
@media (max-width: 1280px) {
  .page-blog .slider-library .slick-slide {
    width: 364px;
    padding-right: 24px;
  }
}
@media (max-width: 768px) {
  .page-blog .slider-library .slick-slide {
    width: 281px;
    padding-right: 16px;
  }
}
.page-blog .slider-library .slick-dots {
  padding-right: 272px;
}
@media (max-width: 1280px) {
  .page-blog .slider-library .slick-dots {
    padding-right: 160px;
  }
}
@media (max-width: 768px) {
  .page-blog .slider-library .slick-dots {
    padding-right: 16px;
  }
}
.page-blog .slider-library .slick-dots li button {
  background: #D5E1EF;
  opacity: 0.4;
}
.page-blog .slider-library .slick-dots li.slick-active button {
  background: #fff;
  opacity: 1;
}
.page-blog .libraryInfo a {
  border-radius: 3px;
  overflow: hidden;
}
.page-blog .libraryInfo .libraryInfo-thumb {
  position: relative;
}
.page-blog .libraryInfo .libraryInfo-thumb .libraryInfo-item {
  position: absolute;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: url(../../images/common/icon-play.svg) no-repeat center rgba(0, 0, 0, 0.4);
}
@media (max-width: 1280px) {
  .page-blog .libraryInfo .libraryInfo-thumb .libraryInfo-item {
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    width: 60px;
    height: 60px;
    background-size: 16px auto;
  }
}
@media (max-width: 768px) {
  .page-blog .libraryInfo .libraryInfo-thumb .libraryInfo-item {
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    width: 40px;
    height: 40px;
    background-size: 14px auto;
  }
}
.page-blog .libraryInfo .libraryInfo-content {
  background: #fff;
  border: 1px solid #C9D6EF;
  border-top: none;
  border-radius: 0px 0px 3px 3px;
  padding: 32px 24px;
}
@media (max-width: 1280px) {
  .page-blog .libraryInfo .libraryInfo-content {
    padding: 24px;
  }
}
@media (max-width: 768px) {
  .page-blog .libraryInfo .libraryInfo-content {
    padding: 24px 16px;
  }
}
.page-blog .libraryInfo .libraryInfo-content .libraryInfo-title {
  color: #2F333E;
  font-size: 20px;
  font-weight: 500;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 4;
  -webkit-box-pack: end;
  height: 72px;
}
@media (max-width: 1280px) {
  .page-blog .libraryInfo .libraryInfo-content .libraryInfo-title {
    font-size: 18px;
    height: 65px;
  }
}
@media (max-width: 768px) {
  .page-blog .libraryInfo .libraryInfo-content .libraryInfo-title {
    font-size: 16px;
    height: 58px;
  }
}
.page-blog .libraryInfo .libraryInfo-content .libraryInfo-feb {
  padding-top: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  color: #6C6F77;
  font-size: 16px;
}
@media (max-width: 1280px) {
  .page-blog .libraryInfo .libraryInfo-content .libraryInfo-feb {
    padding-top: 32px;
    font-size: 14px;
  }
}
@media (max-width: 768px) {
  .page-blog .libraryInfo .libraryInfo-content .libraryInfo-feb {
    padding-top: 24px;
    font-size: 13px;
  }
}
.page-blog .subscribe {
  background: url(../../images/data/bg-complete.jpg) no-repeat bottom right;
  background-size: cover;
  padding: 60px 0;
}
@media (max-width: 1280px) {
  .page-blog .subscribe {
    padding: 56px 0;
  }
}
@media (max-width: 768px) {
  .page-blog .subscribe {
    padding: 40px 0;
  }
}
.page-blog .subscribe .subscribe-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 768px) {
  .page-blog .subscribe .subscribe-info {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
.page-blog .subscribe .subscribe-info .subscribe-title {
  max-width: 654px;
  padding-right: 10px;
}
@media (max-width: 1280px) {
  .page-blog .subscribe .subscribe-info .subscribe-title {
    max-width: 400px;
  }
}
@media (max-width: 768px) {
  .page-blog .subscribe .subscribe-info .subscribe-title {
    max-width: 100%;
    padding-right: 0;
  }
}
.page-blog .subscribe .subscribe-info .subscribe-title .tlt {
  color: #fff;
  font-size: 46px;
  font-weight: 600;
}
@media (max-width: 1280px) {
  .page-blog .subscribe .subscribe-info .subscribe-title .tlt {
    font-size: 29px;
  }
}
@media (max-width: 768px) {
  .page-blog .subscribe .subscribe-info .subscribe-title .tlt {
    font-size: 23px;
  }
}
.page-blog .subscribe .subscribe-info .subscribe-form {
  width: 100%;
  max-width: 654px;
}
@media (max-width: 1280px) {
  .page-blog .subscribe .subscribe-info .subscribe-form {
    max-width: 500px;
  }
}
@media (max-width: 768px) {
  .page-blog .subscribe .subscribe-info .subscribe-form {
    max-width: 100%;
    padding-top: 24px;
  }
}
@media (max-width: 1280px) {
  .page-blog .subscribe .subscribe-info .subscribe-form .dropdown-menu .inner ul li a {
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .page-blog .subscribe .subscribe-info .subscribe-form .dropdown-menu .inner ul li a {
    font-size: 14px;
  }
}
.page-blog .subscribe .your-email {
  margin-top: 32px;
  position: relative;
}
.page-blog .subscribe .your-email .form-control {
  border-radius: 12px;
  border: 1px solid var(--grey-scale-100-white, #FFF);
  background: rgba(255, 255, 255, 0.9);
  -webkit-backdrop-filter: blur(19px);
          backdrop-filter: blur(19px);
  padding: 0 144px 0 24px;
  height: 72px;
  font-size: 24px;
  color: #383C46;
}
@media (max-width: 768px) {
  .page-blog .subscribe .your-email .form-control {
    height: 56px;
    font-size: 16px;
  }
}
.page-blog .subscribe .your-email .form-control::-webkit-input-placeholder {
  color: #383C46;
}
.page-blog .subscribe .your-email .form-control::-webkit-input-placeholder {
  color: #383C46;
}
.page-blog .subscribe .your-email .form-control::-moz-placeholder {
  color: #383C46;
}
.page-blog .subscribe .your-email .form-control:-ms-input-placeholder {
  color: #383C46;
}
.page-blog .subscribe .your-email .form-control::-ms-input-placeholder {
  color: #383C46;
}
.page-blog .subscribe .your-email .form-control::placeholder {
  color: #383C46;
}
.page-blog .subscribe .your-email .btn {
  height: 48px;
  position: absolute;
  top: 12px;
  right: 12px;
}
@media (max-width: 768px) {
  .page-blog .subscribe .your-email .btn {
    background: #0246c6;
    top: 10px;
    width: 37px;
    height: 37px;
    padding: 0;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
@media (max-width: 768px) {
  .page-blog .subscribe .your-email .btn .text {
    display: none;
  }
}
.page-blog .subscribe .your-email .btn .item {
  display: none;
}
@media (max-width: 768px) {
  .page-blog .subscribe .your-email .btn .item {
    display: initial;
  }
}
.page-blog .subscribe .your-email .btn .item img {
  max-width: 18px;
}
.page-blog .sub-topics {
  padding: 110px 0 96px;
}
@media (max-width: 1280px) {
  .page-blog .sub-topics {
    padding: 78px 0 64px;
  }
}
@media (max-width: 768px) {
  .page-blog .sub-topics {
    padding: 40px 0 64px;
  }
}
.page-blog .state {
  background: url(../../images/data/bg-state.jpg) no-repeat bottom left;
  padding: 40px 100px;
  border-radius: 8px;
  margin-top: 96px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 1280px) {
  .page-blog .state {
    padding: 40px;
    margin-top: 48px;
  }
}
@media (max-width: 768px) {
  .page-blog .state {
    padding: 24px;
    margin-top: 40px;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
.page-blog .state .state-text {
  font-size: 40px;
  font-weight: 600;
  color: #fff;
  padding-right: 12px;
  max-width: 800px;
}
@media (max-width: 1280px) {
  .page-blog .state .state-text {
    font-size: 23px;
  }
}
@media (max-width: 768px) {
  .page-blog .state .state-text {
    width: 100%;
    font-size: 20px;
    text-align: center;
    width: 100%;
    padding-right: 0;
  }
}
@media (max-width: 768px) {
  .page-blog .state .state-btn {
    width: 100%;
    padding-top: 16px;
  }
  .page-blog .state .state-btn .btn {
    margin: 0 auto;
  }
}
.page-blog .otherTopics {
  padding: 64px 0 96px;
}
@media (max-width: 1280px) {
  .page-blog .otherTopics {
    padding: 64px 0;
  }
}
@media (max-width: 768px) {
  .page-blog .otherTopics {
    padding: 40px 0;
  }
}
@media (max-width: 768px) {
  .page-blog .otherTopics .title {
    text-align: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .page-blog .otherTopics .title .title-text {
    width: 100%;
  }
  .page-blog .otherTopics .title .title-topic {
    width: 100%;
    color: #383C46;
    font-size: 16px;
  }
}
.page-blog .otherTopics .other-topics {
  padding-top: 38px;
}
@media (max-width: 1280px) {
  .page-blog .otherTopics .other-topics {
    padding-top: 22px;
  }
}
@media (max-width: 768px) {
  .page-blog .otherTopics .other-topics {
    padding-top: 26px;
  }
}
.page-blog .otherTopics .other-topics ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
@media (max-width: 768px) {
  .page-blog .otherTopics .other-topics ul {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.page-blog .otherTopics .other-topics ul li {
  padding: 10px 10px 0 0;
}
@media (max-width: 768px) {
  .page-blog .otherTopics .other-topics ul li {
    padding: 6px 6px 0;
  }
}
.page-blog .otherTopics .other-topics ul li:last-child {
  padding-right: 0;
}
.page-blog .same-author {
  background: url(../../images/data/bg-same-author.jpg) no-repeat bottom left;
  background-size: cover;
  padding: 48px 0;
}
.page-blog .same-author .same-user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.page-blog .same-author .same-user .same-avatar {
  width: 120px;
  height: 120px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  overflow: hidden;
}
@media (max-width: 1280px) {
  .page-blog .same-author .same-user .same-avatar {
    width: 64px;
    height: 64px;
  }
}
@media (max-width: 768px) {
  .page-blog .same-author .same-user .same-avatar {
    width: 48px;
    height: 48px;
  }
}
.page-blog .same-author .same-user .same-avatar img {
  width: 100%;
}
.page-blog .same-author .same-user .same-info {
  width: calc(100% - 120px);
  padding-left: 16px;
}
@media (max-width: 1280px) {
  .page-blog .same-author .same-user .same-info {
    width: calc(100% - 64px);
  }
}
@media (max-width: 768px) {
  .page-blog .same-author .same-user .same-info {
    width: calc(100% - 48px);
  }
}
.page-blog .same-author .same-user .same-info .same-info__name {
  font-size: 36px;
  color: #fff;
  font-weight: 600;
}
@media (max-width: 1280px) {
  .page-blog .same-author .same-user .same-info .same-info__name {
    font-size: 29px;
  }
}
@media (max-width: 768px) {
  .page-blog .same-author .same-user .same-info .same-info__name {
    font-size: 23px;
  }
}
.page-blog .same-author .same-user .same-info .same-info__title {
  font-size: 26px;
  color: #fff;
}
@media (max-width: 1280px) {
  .page-blog .same-author .same-user .same-info .same-info__title {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .page-blog .same-author .same-user .same-info .same-info__title {
    font-size: 13px;
  }
}
.page-blog .same-author .same-content {
  max-width: 600px;
  padding-top: 40px;
}
@media (max-width: 1280px) {
  .page-blog .same-author .same-content {
    padding-top: 32px;
  }
}
@media (max-width: 768px) {
  .page-blog .same-author .same-content {
    padding-top: 24px;
  }
}
.page-blog .same-author .same-content .same-text {
  color: #fff;
  font-size: 16px;
}
@media (max-width: 768px) {
  .page-blog .same-author .same-content .same-text {
    font-size: 13px;
  }
}
.page-blog .lasted-posts {
  padding: 96px 0;
}
@media (max-width: 1280px) {
  .page-blog .lasted-posts {
    padding: 40px 0 64px;
  }
}
@media (max-width: 768px) {
  .page-blog .lasted-posts {
    padding: 32px 0 40px;
  }
}
.page-blog .lasted-posts .navTabs {
  padding: 48px 0 24px;
  margin-left: -8px;
}
@media (max-width: 1280px) {
  .page-blog .lasted-posts .navTabs {
    padding: 32px 0 8px;
  }
}
@media (max-width: 768px) {
  .page-blog .lasted-posts .navTabs {
    padding: 24px 0 8px;
  }
}
.page-blog .lasted-posts .navTabs li {
  padding-left: 8px;
}
@media (max-width: 1280px) {
  .page-blog .lasted-posts .navTabs li {
    width: 50%;
  }
}
.page-blog .lasted-posts .navTabs li a {
  padding: 12px 16px;
  border-radius: 4px;
  background: #FFF;
  color: #383C46;
  font-size: 16px;
  display: block;
  text-align: center;
  cursor: pointer;
}
@media (max-width: 1280px) {
  .page-blog .lasted-posts .navTabs li a {
    font-size: 14px;
  }
}
@media (max-width: 768px) {
  .page-blog .lasted-posts .navTabs li a {
    font-size: 13px;
    padding: 8px;
  }
}
.page-blog .lasted-posts .navTabs li a.active, .page-blog .lasted-posts .navTabs li a:hover {
  background: #DDE8F5;
  color: #0246C6;
}
.page-blog .lasted-list > ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-left: -32px;
}
@media (max-width: 1280px) {
  .page-blog .lasted-list > ul {
    margin-left: -24px;
  }
}
.page-blog .lasted-list > ul > li {
  width: calc(50% - 32px);
  margin-left: 32px;
  padding: 24px 0;
  border-bottom: 1px solid #C3C4D2;
}
@media (max-width: 1280px) {
  .page-blog .lasted-list > ul > li {
    padding: 24px 0;
    width: calc(50% - 24px);
    margin-left: 24px;
  }
}
@media (max-width: 900px) {
  .page-blog .lasted-list > ul > li {
    padding: 16px 0;
    width: calc(100% - 24px);
  }
}
.page-blog .lasted-list .lasted-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.page-blog .lasted-list .lasted-info .lasted-thumb {
  width: 210px;
}
@media (max-width: 1280px) {
  .page-blog .lasted-list .lasted-info .lasted-thumb {
    width: 194px;
  }
}
@media (max-width: 768px) {
  .page-blog .lasted-list .lasted-info .lasted-thumb {
    width: 120px;
  }
}
.page-blog .lasted-list .lasted-info .lasted-content {
  width: calc(100% - 210px);
  padding-left: 24px;
}
@media (max-width: 1280px) {
  .page-blog .lasted-list .lasted-info .lasted-content {
    width: calc(100% - 194px);
  }
}
@media (max-width: 768px) {
  .page-blog .lasted-list .lasted-info .lasted-content {
    width: calc(100% - 120px);
    padding-left: 16px;
  }
}
.page-blog .lasted-list .lasted-info .lasted-content .lasted-title {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 3;
  -webkit-box-pack: end;
  min-height: 72px;
}
@media (max-width: 1280px) {
  .page-blog .lasted-list .lasted-info .lasted-content .lasted-title {
    min-height: 64px;
  }
}
@media (max-width: 768px) {
  .page-blog .lasted-list .lasted-info .lasted-content .lasted-title {
    min-height: 50px;
  }
}
.page-blog .lasted-list .lasted-info .lasted-content .lasted-title a {
  font-size: 20px;
  font-weight: 600;
  color: #2F333E;
}
@media (max-width: 1280px) {
  .page-blog .lasted-list .lasted-info .lasted-content .lasted-title a {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .page-blog .lasted-list .lasted-info .lasted-content .lasted-title a {
    font-size: 14px;
    font-weight: 500;
  }
}
.page-blog .lasted-list .lasted-info .lasted-content .lasted-feb {
  padding-top: 32px;
}
@media (max-width: 1280px) {
  .page-blog .lasted-list .lasted-info .lasted-content .lasted-feb {
    padding-top: 24px;
  }
}
@media (max-width: 768px) {
  .page-blog .lasted-list .lasted-info .lasted-content .lasted-feb {
    padding-top: 16px;
  }
}
.page-blog .lasted-list .lasted-info .lasted-content .lasted-feb .lasted-date {
  display: inline-block;
  padding-right: 32px;
  font-size: 16px;
  color: #6C6F77;
  vertical-align: baseline;
}
@media (max-width: 1280px) {
  .page-blog .lasted-list .lasted-info .lasted-content .lasted-feb .lasted-date {
    font-size: 14px;
    padding-right: 16px;
  }
}
@media (max-width: 768px) {
  .page-blog .lasted-list .lasted-info .lasted-content .lasted-feb .lasted-date {
    font-size: 13px;
  }
}
@media (max-width: 1280px) {
  .page-blog .lasted-list .lasted-info .lasted-content .lasted-feb .readMore {
    font-size: 14px;
  }
}
@media (max-width: 768px) {
  .page-blog .lasted-list .lasted-info .lasted-content .lasted-feb .readMore {
    display: none;
  }
}
.page-blog .lasted-more {
  padding-top: 64px;
  max-width: 600px;
  margin: 0 auto;
}
@media (max-width: 1280px) {
  .page-blog .lasted-more {
    max-width: 320px;
    padding-top: 32px;
  }
}
@media (max-width: 768px) {
  .page-blog .lasted-more {
    padding-top: 24px;
  }
}
.page-blog .lasted-more .btn {
  width: 100%;
}
.page-blog .detail-post {
  max-width: 700px;
  margin: 0 auto;
  padding: 16px 0 64px;
}
@media (max-width: 1280px) {
  .page-blog .detail-post {
    padding: 20px 0 64px;
  }
}
@media (max-width: 768px) {
  .page-blog .detail-post {
    padding: 48px 0 40px;
  }
}
.page-blog .detail-post .detail-content {
  overflow: hidden;
  padding-top: 40px;
}
@media (max-width: 768px) {
  .page-blog .detail-post .detail-content {
    padding-top: 32px;
  }
}
.page-blog .detail-post .detail-content .detail-title {
  font-size: 29px;
  font-weight: 600;
  color: #2F333E;
}
@media (max-width: 1280px) {
  .page-blog .detail-post .detail-content .detail-title {
    font-size: 23px;
  }
}
.page-blog .detail-post .detail-content .detail-text {
  font-size: 16px;
  color: #383C46;
}
.page-blog .detail-post .detail-content .detail-text-image {
  font-size: 16px;
  color: #6C6F77;
}
@media (max-width: 768px) {
  .page-blog .detail-post .detail-content .detail-text-image {
    font-size: 13px;
  }
}
.page-blog .list-tag {
  background: #E3EAF6;
  padding: 30px 0 40px;
}
@media (max-width: 768px) {
  .page-blog .list-tag {
    padding: 34px 0 40px;
  }
}
.page-blog .list-tag ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.page-blog .list-tag ul li {
  padding: 10px 10px 0 0;
}
@media (max-width: 768px) {
  .page-blog .list-tag ul li {
    padding: 6px 6px 0 0;
  }
}
.page-blog .list-tag ul li:last-child {
  padding-right: 0;
}
.page-blog .related-articles {
  padding-top: 64px;
}
@media (max-width: 1280px) {
  .page-blog .related-articles {
    padding-top: 40px;
  }
}
.page-blog .slider-articles {
  padding: 48px 0 72px;
}
@media (max-width: 1280px) {
  .page-blog .slider-articles {
    padding: 32px 0 50px;
  }
}
@media (max-width: 768px) {
  .page-blog .slider-articles {
    padding: 32px 0;
  }
}
.page-blog .slider-articles .slick-slide {
  width: 452px;
  padding-right: 32px;
}
@media (max-width: 1280px) {
  .page-blog .slider-articles .slick-slide {
    width: 364px;
    padding-right: 24px;
  }
}
@media (max-width: 768px) {
  .page-blog .slider-articles .slick-slide {
    width: 281px;
    padding-right: 16px;
  }
}
.page-blog .slider-articles .slick-dots {
  padding-right: 272px;
}
@media (max-width: 1280px) {
  .page-blog .slider-articles .slick-dots {
    padding-right: 160px;
  }
}
@media (max-width: 768px) {
  .page-blog .slider-articles .slick-dots {
    padding-right: 16px;
  }
}
.page-blog .articlesLst {
  background: #fff;
  border-radius: 3px;
  overflow: hidden;
}
.page-blog .articlesLst:hover .articlesLst-dflex .articlesLst-item::after {
  left: 100%;
}
.page-blog .articlesLst:hover .articlesLst-dflex .articlesLst-item::before {
  left: 0;
}
.page-blog .articlesLst .articlesLst-content {
  padding: 24px;
}
@media (max-width: 768px) {
  .page-blog .articlesLst .articlesLst-content {
    padding: 16px;
  }
}
.page-blog .articlesLst .articlesLst-content .articlesLst-title {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 4;
  -webkit-box-pack: end;
  height: 96px;
}
@media (max-width: 1280px) {
  .page-blog .articlesLst .articlesLst-content .articlesLst-title {
    height: 86px;
  }
}
@media (max-width: 768px) {
  .page-blog .articlesLst .articlesLst-content .articlesLst-title {
    height: 78px;
  }
}
.page-blog .articlesLst .articlesLst-content .articlesLst-title a {
  font-size: 20px;
  font-weight: 600;
  color: #2F333E;
}
@media (max-width: 1280px) {
  .page-blog .articlesLst .articlesLst-content .articlesLst-title a {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .page-blog .articlesLst .articlesLst-content .articlesLst-title a {
    font-size: 16px;
  }
}
.page-blog .articlesLst .articlesLst-content .articlesLst-dflex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 32px;
}
@media (max-width: 1280px) {
  .page-blog .articlesLst .articlesLst-content .articlesLst-dflex {
    padding-top: 16px;
  }
}
.page-blog .articlesLst .articlesLst-content .articlesLst-dflex .articlesLst-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: calc(100% - 30px);
  padding-right: 10px;
}
.page-blog .articlesLst .articlesLst-content .articlesLst-dflex .articlesLst-label .articlesLst-name,
.page-blog .articlesLst .articlesLst-content .articlesLst-dflex .articlesLst-label .articlesLst-feb {
  color: #6C6F77;
  font-size: 16px;
  display: inline-block;
}
@media (max-width: 768px) {
  .page-blog .articlesLst .articlesLst-content .articlesLst-dflex .articlesLst-label .articlesLst-name,
  .page-blog .articlesLst .articlesLst-content .articlesLst-dflex .articlesLst-label .articlesLst-feb {
    font-size: 13px;
  }
}
.page-blog .articlesLst .articlesLst-content .articlesLst-dflex .articlesLst-label .articlesLst-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.page-blog .articlesLst .articlesLst-content .articlesLst-dflex .articlesLst-label .articlesLst-feb {
  padding-left: 24px;
  white-space: nowrap;
}
@media (max-width: 768px) {
  .page-blog .articlesLst .articlesLst-content .articlesLst-dflex .articlesLst-label .articlesLst-feb {
    padding-left: 13px;
  }
}
.page-blog .articlesLst .articlesLst-content .articlesLst-dflex .articlesLst-item {
  width: 30px;
  height: 30px;
  min-width: 30px;
  border-radius: 50%;
  border: 1px solid #c7c8cb;
  position: relative;
  overflow: hidden;
}
.page-blog .articlesLst .articlesLst-content .articlesLst-dflex .articlesLst-item::after, .page-blog .articlesLst .articlesLst-content .articlesLst-dflex .articlesLst-item::before {
  position: absolute;
  content: "";
  background: url(../../images/common/icon-arrow-btn-gray.svg) no-repeat center;
  background-size: 13px auto;
  top: 0;
  -webkit-transition: 400ms all;
  transition: 400ms all;
  width: 30px;
  height: 30px;
}
.page-blog .articlesLst .articlesLst-content .articlesLst-dflex .articlesLst-item::after {
  left: 0;
}
.page-blog .articlesLst .articlesLst-content .articlesLst-dflex .articlesLst-item::before {
  left: -100%;
}
.page-blog .same {
  padding: 64px 0 96px;
}
@media (max-width: 1280px) {
  .page-blog .same {
    padding: 64px 0;
  }
}
@media (max-width: 768px) {
  .page-blog .same {
    padding: 40px 0;
  }
}
.page-blog .same .lasted-list {
  padding-top: 24px;
}
@media (max-width: 1280px) {
  .page-blog .same .lasted-list {
    padding-top: 8px;
  }
}
.page-blog .blog-search {
  padding: 64px 0 40px;
  border-bottom: 2px solid #C3C4D2;
}
@media (max-width: 1280px) {
  .page-blog .blog-search {
    padding: 40px 0 32px;
  }
}
@media (max-width: 768px) {
  .page-blog .blog-search {
    padding: 40px 0 24px;
  }
}
.page-blog .blog-search .title-search .tlt {
  font-size: 52px;
  font-weight: 600;
}
@media (max-width: 1280px) {
  .page-blog .blog-search .title-search .tlt {
    font-size: 41px;
  }
}
@media (max-width: 768px) {
  .page-blog .blog-search .title-search .tlt {
    font-size: 29px;
  }
}
.page-blog .blog-search .result-for {
  padding-top: 40px;
}
@media (max-width: 1280px) {
  .page-blog .blog-search .result-for {
    padding-top: 32px;
  }
}
@media (max-width: 768px) {
  .page-blog .blog-search .result-for {
    padding-top: 24px;
  }
}
.page-blog .blog-search .result-for .result-for__label {
  padding-bottom: 16px;
  font-size: 20px;
  font-weight: 600;
}
.page-blog .blog-search .result-for .result-for__control {
  position: relative;
  max-width: 816px;
}
.page-blog .blog-search .result-for .result-for__control .form-control {
  background: #fff;
  height: 60px;
  border-radius: 6px;
  border: 1px solid #C3C4D2;
  color: #2F333E;
  font-size: 23px;
  padding-right: 60px;
}
@media (max-width: 1280px) {
  .page-blog .blog-search .result-for .result-for__control .form-control {
    font-size: 20px;
  }
}
.page-blog .blog-search .result-for .result-for__control .form-control::-webkit-input-placeholder {
  color: #2F333E;
}
.page-blog .blog-search .result-for .result-for__control .form-control::-webkit-input-placeholder {
  color: #2F333E;
}
.page-blog .blog-search .result-for .result-for__control .form-control::-moz-placeholder {
  color: #2F333E;
}
.page-blog .blog-search .result-for .result-for__control .form-control:-ms-input-placeholder {
  color: #2F333E;
}
.page-blog .blog-search .result-for .result-for__control .form-control::-ms-input-placeholder {
  color: #2F333E;
}
.page-blog .blog-search .result-for .result-for__control .form-control::placeholder {
  color: #2F333E;
}
.page-blog .blog-search .result-for .result-for__control .btn {
  position: absolute;
  top: 0;
  right: 0;
  height: 60px;
  width: 60px;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.page-blog .blog-search .result-for .result-for__control .btn:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.page-blog .blog-search .result-for .result-for__control .btn .svg path {
  fill: #0246C6;
}
.page-blog .content-search {
  padding: 40px 0 96px;
}
@media (max-width: 1280px) {
  .page-blog .content-search {
    padding: 32px 0 40px;
  }
}
.page-blog .title-mask .tlt {
  font-size: 32px;
  font-weight: 600;
}
@media (max-width: 1280px) {
  .page-blog .title-mask .tlt {
    font-size: 23px;
  }
}
@media (max-width: 768px) {
  .page-blog .title-mask .tlt {
    font-size: 20px;
  }
}
.page-blog .title-mask .text {
  color: #383C46;
  font-size: 22px;
  padding-top: 10px;
}
@media (max-width: 1280px) {
  .page-blog .title-mask .text {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .page-blog .title-mask .text {
    font-size: 16px;
  }
}
.page-blog .found-note {
  max-width: 600px;
  background: #FCE7E7;
  border: 1px solid #E43838;
  border-radius: 3px;
  padding: 16px 24px;
  margin-top: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.page-blog .found-note .found-item {
  width: 24px;
  min-width: 24px;
  margin-right: 16px;
}
.page-blog .found-note ul li {
  color: #880909;
  font-size: 16px;
  padding: 2px 0 2px 15px;
  position: relative;
}
.page-blog .found-note ul li::after {
  position: absolute;
  top: 9px;
  left: 0;
  content: "";
  width: 4px;
  height: 4px;
  background: #880909;
}
.page-blog .most {
  padding: 64px 0;
}
@media (max-width: 1280px) {
  .page-blog .most {
    padding: 24px 0 40px;
  }
}
.page-blog .most-list {
  padding-top: 40px;
}
@media (max-width: 1280px) {
  .page-blog .most-list {
    padding-top: 26px;
  }
}
.page-blog .most-list ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.page-blog .most-list ul li {
  padding: 8px 8px 0 0;
}
.page-blog .most-list ul li:last-child {
  padding-right: 0;
}
.page-blog .recommen {
  border-top: 1px solid #C3C4D2;
}
.page-blog .recommen .title-mask {
  padding-top: 64px;
}
@media (max-width: 1280px) {
  .page-blog .recommen .title-mask {
    padding-top: 32px;
  }
}
.page-blog .recommen ~ .most {
  padding-bottom: 0;
}
.page-blog .recommen-list {
  padding-top: 48px;
  max-width: 816px;
}
@media (max-width: 768px) {
  .page-blog .recommen-list {
    padding-top: 12px;
  }
}
.page-blog .recommen-list ul li {
  padding: 16px 0;
}
@media (max-width: 768px) {
  .page-blog .recommen-list ul li {
    padding: 12px 0;
  }
}
.page-blog .recommen-list .thumb-hover {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.page-blog .recommen-list .recommen-thumb {
  width: 238px;
}
@media (max-width: 768px) {
  .page-blog .recommen-list .recommen-thumb {
    width: 140px;
  }
}
.page-blog .recommen-list .recommen-content {
  width: calc(100% - 238px);
  padding-left: 24px;
}
@media (max-width: 768px) {
  .page-blog .recommen-list .recommen-content {
    width: calc(100% - 140px);
    padding-left: 16px;
  }
}
.page-blog .recommen-list .recommen-content .recommen-title {
  margin-top: 24px;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 2;
  -webkit-box-pack: end;
}
@media (max-width: 768px) {
  .page-blog .recommen-list .recommen-content .recommen-title {
    margin-top: 8px;
  }
}
.page-blog .recommen-list .recommen-content .recommen-title a {
  color: #2F333E;
  font-size: 20px;
  font-weight: 600;
}
@media (max-width: 1280px) {
  .page-blog .recommen-list .recommen-content .recommen-title a {
    font-size: 18px;
    font-weight: 500;
  }
}
@media (max-width: 768px) {
  .page-blog .recommen-list .recommen-content .recommen-title a {
    font-size: 14px;
  }
}
.page-blog .main-topics {
  background: #fff;
  padding: 64px 0;
}
@media (max-width: 1280px) {
  .page-blog .main-topics {
    padding: 48px 0;
  }
}
@media (max-width: 768px) {
  .page-blog .main-topics {
    padding: 32px 0;
  }
}
.page-blog .sort-by {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 32px 0;
}
@media (max-width: 1280px) {
  .page-blog .sort-by {
    padding: 24px 0;
  }
}
.page-blog .sort-by .sort-by__Results ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.page-blog .sort-by .sort-by__Results ul li {
  padding: 0 16px;
  font-size: 20px;
  border-right: 2px solid #C3C4D2;
}
@media (max-width: 768px) {
  .page-blog .sort-by .sort-by__Results ul li {
    font-size: 14px;
  }
}
.page-blog .sort-by .sort-by__Results ul li:first-of-type {
  padding-left: 0;
}
@media (max-width: 768px) {
  .page-blog .sort-by .sort-by__Results ul li:first-of-type {
    display: none;
  }
}
.page-blog .sort-by .sort-by__Results ul li:last-child {
  font-weight: 600;
  padding-right: 0;
  border: none;
}
@media (max-width: 768px) {
  .page-blog .sort-by .sort-by__Results ul li:last-child {
    font-weight: 400;
    padding-left: 0;
    padding-right: 8px;
    border-right: 2px solid #C3C4D2;
  }
}
.page-blog .sort-by .sort-by__tabs {
  padding-left: 24px;
}
@media (max-width: 768px) {
  .page-blog .sort-by .sort-by__tabs {
    padding-left: 8px;
  }
}
.page-blog .sort-by .sort-by__tabs ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.page-blog .sort-by .sort-by__tabs ul li {
  padding-right: 16px;
}
@media (max-width: 768px) {
  .page-blog .sort-by .sort-by__tabs ul li {
    padding-right: 8px;
  }
}
.page-blog .sort-by .sort-by__tabs ul li:last-child {
  padding-right: 0;
}
.page-blog .sort-by .sort-by__tabs ul li a {
  padding: 12px 16px;
  display: block;
  text-align: center;
  background: #fff;
  color: #383C46;
  font-size: 16px;
  border-radius: 4px;
}
@media (max-width: 768px) {
  .page-blog .sort-by .sort-by__tabs ul li a {
    font-size: 13px;
    padding: 8px;
  }
}
.page-blog .sort-by .sort-by__tabs ul li a.active, .page-blog .sort-by .sort-by__tabs ul li a:hover {
  background: #D5E1EF;
  color: #0246C6;
}

/******/
.author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.author .author__date,
.author .author__name {
  color: #6C6F77;
  font-size: 16px;
}
@media (max-width: 1280px) {
  .author .author__date,
  .author .author__name {
    font-size: 14px;
  }
}
@media (max-width: 768px) {
  .author .author__date,
  .author .author__name {
    font-size: 13px;
  }
}
.author .author__name {
  padding-right: 24px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media (max-width: 1280px) {
  .author .author__name {
    padding-right: 16px;
  }
}
@media (max-width: 768px) {
  .author .author__name {
    padding-right: 8px;
  }
}
.author .author__date {
  white-space: nowrap;
}

.heading-post {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid #C3C4D2;
}
@media (max-width: 1280px) {
  .heading-post {
    padding-bottom: 8px;
  }
}
.heading-post .heading-avatar {
  width: 58px;
  height: 58px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  border: 2px solid #C3C4D2;
}
@media (max-width: 768px) {
  .heading-post .heading-avatar {
    width: 40px;
    height: 40px;
  }
}
.heading-post .heading-avatar img {
  width: 100%;
}
.heading-post .heading-info {
  width: calc(100% - 58px);
  padding-left: 16px;
}
@media (max-width: 768px) {
  .heading-post .heading-info {
    width: calc(100% - 40px);
    padding-left: 8px;
  }
}
.heading-post .heading-info .heading-name {
  color: #2F333E;
}
@media (max-width: 768px) {
  .heading-post .heading-info .heading-name {
    font-size: 14px;
    font-weight: 400;
  }
}
.heading-post .heading-info .heading-feb {
  color: #373737;
  padding-left: 24px;
}
@media (max-width: 768px) {
  .heading-post .heading-info .heading-feb {
    padding-left: 16px;
  }
}

/***********/
.featured-posts {
  padding: 64px 0 96px;
}
@media (max-width: 1280px) {
  .featured-posts {
    padding: 48px 0 48px;
  }
}
@media (max-width: 768px) {
  .featured-posts {
    padding: 40px 0;
  }
}
.featured-posts .posts-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-left: -32px;
  padding-top: 64px;
}
@media (max-width: 1280px) {
  .featured-posts .posts-row {
    margin-left: -24px;
  }
}
@media (max-width: 768px) {
  .featured-posts .posts-row {
    padding-top: 16px;
  }
}
.featured-posts .posts-row .posts-col {
  width: 33.3333333%;
  padding-left: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
@media (max-width: 1280px) {
  .featured-posts .posts-row .posts-col {
    padding-left: 24px;
  }
}
@media (max-width: 900px) {
  .featured-posts .posts-row .posts-col {
    width: 50%;
  }
}
@media (max-width: 480px) {
  .featured-posts .posts-row .posts-col {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .featured-posts .posts-row .posts-col:nth-child(2) .posts .posts-thumb {
    display: none;
  }
}
@media (max-width: 900px) {
  .featured-posts .posts-row .posts-col:nth-child(3) {
    width: 100%;
    padding-top: 24px;
  }
}
@media (max-width: 900px) and (max-width: 768px) {
  .featured-posts .posts-row .posts-col:nth-child(3) {
    padding-top: 0;
  }
}
.featured-posts .posts-row .posts-col:nth-child(3) .posts {
  margin-bottom: 24px;
  height: calc(50% - 12px);
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 900px) {
  .featured-posts .posts-row .posts-col:nth-child(3) .posts {
    margin-bottom: 16px;
    height: inherit;
  }
}
.featured-posts .posts-row .posts-col:nth-child(3) .posts:last-child {
  margin-bottom: 0;
}
.featured-posts .posts-row .posts-col:nth-child(3) .posts .thumb-hover {
  width: 100%;
}
@media (max-width: 1280px) {
  .featured-posts .posts-row .posts-col:nth-child(3) .posts .posts-content {
    padding-top: 16px;
  }
}
.featured-posts .posts-row .posts-col:nth-child(3) .posts .posts-content .posts-title {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 3;
  -webkit-box-pack: end;
}
.featured-posts .posts-row .posts-col:nth-child(3) .posts .posts-content .posts-title a {
  font-size: 18px;
}
@media (max-width: 1280px) {
  .featured-posts .posts-row .posts-col:nth-child(3) .posts .posts-content .posts-title a {
    font-size: 16px;
  }
}
.featured-posts .posts {
  background: #fff;
  width: 100%;
}
@media (max-width: 768px) {
  .featured-posts .posts {
    margin-bottom: 16px;
  }
}
.featured-posts .posts .posts-content {
  padding: 24px 32px;
}
@media (max-width: 768px) {
  .featured-posts .posts .posts-content {
    padding: 24px 16px;
  }
}
.featured-posts .posts .posts-content .posts-title {
  margin-top: 24px;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 4;
  -webkit-box-pack: end;
}
@media (max-width: 768px) {
  .featured-posts .posts .posts-content .posts-title {
    margin-top: 16px;
  }
}
.featured-posts .posts .posts-content .posts-title a {
  color: #2F333E;
  font-size: 23px;
  font-weight: 600;
}
@media (max-width: 1280px) {
  .featured-posts .posts .posts-content .posts-title a {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .featured-posts .posts .posts-content .posts-title a {
    font-size: 16px;
  }
}

/*****/
.featured {
  padding: 64px 0 96px;
}
@media (max-width: 1280px) {
  .featured {
    padding: 56px 0 64px;
  }
}
@media (max-width: 768px) {
  .featured {
    padding: 40px 0 40px;
  }
}

.featured-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-top: 48px;
  margin-left: -4.4%;
}
@media (max-width: 1280px) {
  .featured-row {
    padding-top: 32px;
    margin-left: -24px;
  }
}
.featured-row .featured-col {
  width: 50%;
  padding-left: 4.4%;
}
@media (max-width: 1280px) {
  .featured-row .featured-col {
    padding-left: 24px;
  }
}
@media (max-width: 900px) {
  .featured-row .featured-col {
    width: 100%;
  }
}

@media (max-width: 900px) {
  .featured-main {
    background: #fff;
  }
}
.featured-main:hover .featured-title {
  text-decoration: underline;
}
.featured-main:hover .featured-thumb img {
  -webkit-transform: scale(1.1) rotateZ(0deg);
          transform: scale(1.1) rotateZ(0deg);
}
.featured-main .featured-thumb {
  overflow: hidden;
}
.featured-main .featured-thumb img {
  width: 100%;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.featured-main .featured-content {
  padding-top: 24px;
}
@media (max-width: 900px) {
  .featured-main .featured-content {
    padding: 24px 16px;
  }
}
.featured-main .featured-content .featured-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.featured-main .featured-content .featured-label .featured-label__topics,
.featured-main .featured-content .featured-label .featured-label__date {
  color: #6C6F77;
  font-size: 16px;
}
@media (max-width: 1280px) {
  .featured-main .featured-content .featured-label .featured-label__topics,
  .featured-main .featured-content .featured-label .featured-label__date {
    font-size: 14px;
  }
}
@media (max-width: 768px) {
  .featured-main .featured-content .featured-label .featured-label__topics,
  .featured-main .featured-content .featured-label .featured-label__date {
    font-size: 13px;
  }
}
.featured-main .featured-content .featured-label .featured-label__topics {
  padding-right: 24px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media (max-width: 768px) {
  .featured-main .featured-content .featured-label .featured-label__topics {
    padding-right: 8px;
  }
}
.featured-main .featured-content .featured-label .featured-label__date {
  white-space: nowrap;
}
.featured-main .featured-content .featured-title {
  font-size: 20px;
  font-weight: 500;
  margin-top: 24px;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 3;
  -webkit-box-pack: end;
}
.featured-main .featured-content .featured-title a {
  color: #2F333E;
}
@media (max-width: 1280px) {
  .featured-main .featured-content .featured-title {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .featured-main .featured-content .featured-title {
    margin-top: 16px;
    font-size: 16px;
  }
}
.featured-main .featured-content .featured-text {
  font-size: 16px;
  color: #383C46;
  margin-top: 16px;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 3;
  -webkit-box-pack: end;
}
@media (max-width: 768px) {
  .featured-main .featured-content .featured-text {
    margin-top: 8px;
    font-size: 14px;
  }
}

@media (max-width: 900px) {
  .featured-info {
    padding-top: 16px;
  }
}
.featured-info ul li {
  margin-bottom: 24px;
}
@media (max-width: 1280px) {
  .featured-info ul li {
    margin-bottom: 24px;
  }
}
@media (max-width: 900px) {
  .featured-info ul li {
    margin-bottom: 16px;
    background: #fff;
    padding: 16px;
  }
}
.featured-info ul li:last-child {
  padding-bottom: 0;
}
@media (max-width: 900px) {
  .featured-info ul li:last-child {
    padding-bottom: 16px;
  }
}
.featured-info ul li:hover .featured-title {
  text-decoration: underline;
}
.featured-info ul li:hover .featured-thumb img {
  -webkit-transform: scale(1.1) rotateZ(0deg);
          transform: scale(1.1) rotateZ(0deg);
}
.featured-info .featured-dflex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.featured-info .featured-thumb {
  width: 260px;
  overflow: hidden;
}
@media (max-width: 1280px) {
  .featured-info .featured-thumb {
    width: 180px;
  }
}
@media (max-width: 768px) {
  .featured-info .featured-thumb {
    width: 140px;
  }
}
.featured-info .featured-thumb img {
  width: 100%;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.featured-info .featured-label {
  display: none;
}
@media (max-width: 768px) {
  .featured-info .featured-label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 16px;
  }
}
.featured-info .featured-label .featured-label__topics,
.featured-info .featured-label .featured-label__date {
  color: #6C6F77;
  font-size: 16px;
}
@media (max-width: 1280px) {
  .featured-info .featured-label .featured-label__topics,
  .featured-info .featured-label .featured-label__date {
    font-size: 14px;
  }
}
@media (max-width: 768px) {
  .featured-info .featured-label .featured-label__topics,
  .featured-info .featured-label .featured-label__date {
    font-size: 13px;
  }
}
.featured-info .featured-label .featured-label__topics {
  padding-right: 24px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media (max-width: 768px) {
  .featured-info .featured-label .featured-label__topics {
    padding-right: 8px;
  }
}
.featured-info .featured-label .featured-label__date {
  white-space: nowrap;
}
.featured-info .featured-content {
  width: calc(100% - 260px);
  padding-left: 24px;
}
@media (max-width: 1280px) {
  .featured-info .featured-content {
    width: calc(100% - 180px);
  }
}
@media (max-width: 768px) {
  .featured-info .featured-content {
    width: calc(100% - 140px);
    padding-left: 16px;
  }
}
.featured-info .featured-content .featured-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 768px) {
  .featured-info .featured-content .featured-label {
    display: none;
  }
}
.featured-info .featured-content .featured-title {
  margin-top: 24px;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 3;
  -webkit-box-pack: end;
}
@media (max-width: 1280px) {
  .featured-info .featured-content .featured-title {
    margin-top: 16px;
  }
}
@media (max-width: 768px) {
  .featured-info .featured-content .featured-title {
    margin-top: 0;
  }
}
.featured-info .featured-content .featured-title a {
  font-size: 18px;
  font-weight: 600;
  color: #2F333E;
}
@media (max-width: 1280px) {
  .featured-info .featured-content .featured-title a {
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .featured-info .featured-content .featured-title a {
    font-size: 14px;
    font-weight: 500;
  }
}

/* -- page-health -- */
.page-health {
  /******/
}
.page-health .provides {
  padding: 96px 0;
  background: url(../../images/data/bg-complete.jpg) no-repeat bottom right;
  background-size: cover;
}
@media (max-width: 1280px) {
  .page-health .provides {
    padding: 56px 0 48px;
  }
}
@media (max-width: 768px) {
  .page-health .provides {
    padding: 32px 0;
  }
}
.page-health .provides .provides-info {
  max-width: 800px;
}
@media (max-width: 1280px) {
  .page-health .provides .provides-info {
    max-width: 680px;
  }
}
.page-health .provides .provides-info .provides-text {
  color: #fff;
  font-size: 29px;
}
@media (max-width: 1280px) {
  .page-health .provides .provides-info .provides-text {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .page-health .provides .provides-info .provides-text {
    font-size: 16px;
  }
}
.page-health .health-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 96px 0;
}
@media (max-width: 1280px) {
  .page-health .health-row {
    padding: 56px 0;
  }
}
@media (max-width: 768px) {
  .page-health .health-row {
    padding: 16px 0 40px;
  }
}
.page-health .health-row .health-col:nth-child(1) {
  width: calc(100% - 563px);
  padding-right: 32px;
}
@media (max-width: 1280px) {
  .page-health .health-row .health-col:nth-child(1) {
    width: calc(100% - 404px);
    padding-right: 24px;
  }
}
@media (max-width: 900px) {
  .page-health .health-row .health-col:nth-child(1) {
    width: 100%;
    padding-right: 0;
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
}
.page-health .health-row .health-col:nth-child(2) {
  width: 563px;
}
@media (max-width: 1280px) {
  .page-health .health-row .health-col:nth-child(2) {
    width: 404px;
  }
}
@media (max-width: 900px) {
  .page-health .health-row .health-col:nth-child(2) {
    width: 100%;
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
}
.page-health .filterBy {
  background: #fff;
  padding: 24px;
  border: 1px solid #C3C4D2;
  border-radius: 6px;
}
@media (max-width: 900px) {
  .page-health .filterBy {
    border: none;
    padding: 8px;
    margin-top: 8px;
  }
}
.page-health .filterBy .filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 16px 0;
  border-bottom: 1px solid #C3C4D2;
}
@media (max-width: 900px) {
  .page-health .filterBy .filter {
    display: none;
  }
}
.page-health .filterBy .filter .filter-title {
  font-size: 20px;
  font-weight: 600;
  padding-right: 24px;
  white-space: nowrap;
  padding-top: 5px;
}
@media (max-width: 1280px) {
  .page-health .filterBy .filter .filter-title {
    font-size: 18px;
  }
}
.page-health .filterBy .filter .filter-list {
  margin-top: -8px;
}
.page-health .filterBy .filter .filter-list ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.page-health .filterBy .filter .filter-list ul li {
  padding: 8px 8px 0 0;
}
.page-health .filterBy .filter .filter-list ul li:last-child {
  padding-right: 0;
}
.page-health .filterBy .tag-arrange {
  padding-top: 16px;
}
@media (max-width: 900px) {
  .page-health .filterBy .tag-arrange {
    padding-top: 0;
  }
}
.page-health .filterBy .tag-arrange ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.page-health .filterBy .tag-arrange ul li {
  padding-right: 16px;
}
.page-health .filterBy .tag-arrange ul li:last-child {
  padding-right: 0;
}
@media (max-width: 900px) {
  .page-health .filterBy .tag-arrange ul li {
    width: 50%;
    text-align: center;
    padding-right: 0;
  }
}
.page-health .filterBy .tag-arrange ul li a {
  padding: 12px 16px;
  display: block;
  background: #fff;
  color: #383C46;
  border-radius: 4px;
  font-size: 16px;
}
@media (max-width: 1280px) {
  .page-health .filterBy .tag-arrange ul li a {
    font-size: 14px;
  }
}
.page-health .filterBy .tag-arrange ul li a.active {
  background: #D5E1EF;
  color: #0246C6;
}
.page-health .filterBox {
  padding: 40px 24px;
  background: #fff;
  border: 1px solid #C3C4D2;
  border-radius: 6px;
}
@media (max-width: 1280px) {
  .page-health .filterBox {
    padding: 40px 16px;
  }
}
@media (max-width: 900px) {
  .page-health .filterBox {
    padding: 16px;
    border: none;
  }
}
.page-health .filterBox .filterBox-title {
  font-size: 23px;
  font-weight: 600;
  padding-bottom: 20px;
  margin-bottom: 40px;
  border-bottom: 1px solid #A6BBE4;
}
@media (max-width: 1280px) {
  .page-health .filterBox .filterBox-title {
    font-size: 20px;
    margin-bottom: 32px;
  }
}
@media (max-width: 900px) {
  .page-health .filterBox .filterBox-title {
    font-size: 16px;
    padding-bottom: 16px;
    margin-bottom: 8px;
  }
}
.page-health .filterBox .filter-accordion .filter-accordion__title.filter-accordion__title--active .tlt::after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.page-health .filterBox .filter-accordion .filter-accordion__title .tlt {
  padding: 8px 0;
  font-size: 20px;
  font-weight: 600;
}
@media (max-width: 900px) {
  .page-health .filterBox .filter-accordion .filter-accordion__title .tlt {
    font-size: 14px;
    cursor: pointer;
    border-bottom: 1px solid #F5F6F8;
    position: relative;
  }
  .page-health .filterBox .filter-accordion .filter-accordion__title .tlt::after {
    position: absolute;
    top: 4px;
    right: 0;
    content: "";
    width: 24px;
    height: 24px;
    background: url(../../images/common/icon-down-topics.svg) no-repeat center;
    -webkit-transition: 400ms all;
    transition: 400ms all;
  }
}
.page-health .filterBox .filter-accordion .filter-accordion__content {
  padding-top: 6px;
}
@media (min-width: 901px) {
  .page-health .filterBox .filter-accordion .filter-accordion__content {
    display: block !important;
    height: initial !important;
    padding: initial !important;
  }
}
@media (max-width: 900px) {
  .page-health .filterBox .filter-accordion .filter-accordion__content {
    display: none;
  }
}
.page-health .filterBox .filter-accordion .filter-accordion__content ul li {
  padding: 10px 0;
  border-bottom: 1px solid #D5E1EF;
}
.page-health .filterBox .filter-accordion .filter-accordion__content ul li:last-child {
  border: none;
  padding-bottom: 0;
}
@media (max-width: 900px) {
  .page-health .filterBox .filter-accordion .filter-accordion__content ul .custom-control-description {
    font-size: 14px;
  }
}
.page-health .filterBox .filter-result {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-top: 40px;
}
@media (max-width: 1280px) {
  .page-health .filterBox .filter-result {
    padding-top: 32px;
  }
}
@media (max-width: 900px) {
  .page-health .filterBox .filter-result {
    padding-top: 16px;
  }
}
.page-health .filterBox .filter-result .filter-clear .readMore {
  font-size: 26px;
}
@media (max-width: 1280px) {
  .page-health .filterBox .filter-result .filter-clear .readMore {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .page-health .filterBox .filter-result .filter-clear .readMore {
    font-size: 14px;
  }
}
.page-health .health-list {
  padding-top: 32px;
}
@media (max-width: 1280px) {
  .page-health .health-list {
    padding-top: 8px;
  }
}
@media (max-width: 768px) {
  .page-health .health-list {
    width: calc(100% + 32px);
    margin-left: -16px;
    margin-top: 16px;
    padding: 8px 16px 0;
    border-top: 1px solid #C3C4D2;
  }
}
.page-health .health-list ul li {
  padding: 32px 0;
  border-bottom: 1px solid #C3C4D2;
}
@media (max-width: 1280px) {
  .page-health .health-list ul li {
    padding: 24px 0;
  }
}
@media (max-width: 900px) {
  .page-health .health-list ul li {
    padding: 16px 0;
  }
}
.page-health .healthInfo:hover .healthInfo-title a {
  text-decoration: underline;
}
.page-health .healthInfo .healthInfo-date {
  font-size: 16px;
  color: #3F424D;
}
@media (max-width: 900px) {
  .page-health .healthInfo .healthInfo-date {
    font-size: 13px;
    color: #6C6F77;
  }
}
.page-health .healthInfo .healthInfo-testing {
  font-size: 18px;
  padding-top: 40px;
  color: #0246C6;
}
@media (max-width: 1280px) {
  .page-health .healthInfo .healthInfo-testing {
    font-size: 14px;
    padding-top: 24px;
  }
}
@media (max-width: 900px) {
  .page-health .healthInfo .healthInfo-testing {
    font-size: 13px;
    padding-top: 16px;
  }
}
.page-health .healthInfo .healthInfo-title {
  padding-top: 10px;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 1;
  -webkit-box-pack: end;
}
@media (max-width: 1280px) {
  .page-health .healthInfo .healthInfo-title {
    padding-top: 8px;
  }
}
@media (max-width: 900px) {
  .page-health .healthInfo .healthInfo-title {
    padding-top: 16px;
  }
}
@media (max-width: 768px) {
  .page-health .healthInfo .healthInfo-title {
    -webkit-line-clamp: 2;
    line-clamp: 2;
  }
}
.page-health .healthInfo .healthInfo-title a {
  color: #383C46;
  font-size: 23px;
  font-weight: 600;
}
@media (max-width: 1280px) {
  .page-health .healthInfo .healthInfo-title a {
    font-size: 20px;
  }
}
@media (max-width: 900px) {
  .page-health .healthInfo .healthInfo-title a {
    font-size: 16px;
    font-weight: 500;
  }
}
.page-health .healthInfo .healthInfo-text {
  color: #383C46;
  padding-top: 16px;
  font-size: 16px;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 3;
  -webkit-box-pack: end;
}
@media (max-width: 1280px) {
  .page-health .healthInfo .healthInfo-text {
    font-size: 14px;
  }
}
@media (max-width: 900px) {
  .page-health .healthInfo .healthInfo-text {
    display: none;
  }
}
.page-health .header-single {
  background: url(../../images/data/bg-header-single-post.jpg) no-repeat right #9aa0a5;
  background-size: cover;
  padding: 96px 0;
}
@media (max-width: 1280px) {
  .page-health .header-single {
    padding: 64px 0 56px;
  }
}
@media (max-width: 768px) {
  .page-health .header-single {
    background: url(../../images/data/bg-header-single-post-sp.jpg) no-repeat bottom right #9aa0a5;
  }
}
.page-health .header-single .single-info {
  max-width: 800px;
}
@media (max-width: 1280px) {
  .page-health .header-single .single-info {
    max-width: 680px;
  }
}
.page-health .header-single .single-info .single-testing {
  font-size: 16px;
  color: #fff;
}
@media (max-width: 768px) {
  .page-health .header-single .single-info .single-testing {
    font-size: 14px;
  }
}
.page-health .header-single .single-info .single-title {
  font-size: 41px;
  font-weight: 600;
  padding-top: 32px;
  color: #fff;
}
@media (max-width: 1280px) {
  .page-health .header-single .single-info .single-title {
    padding-top: 24px;
    font-size: 29px;
  }
}
@media (max-width: 768px) {
  .page-health .header-single .single-info .single-title {
    font-size: 20px;
  }
}
.page-health .header-single .single-info .single-text {
  padding-top: 32px;
  color: #F1F2F2;
  font-size: 20px;
}
@media (max-width: 1280px) {
  .page-health .header-single .single-info .single-text {
    padding-top: 24px;
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .page-health .header-single .single-info .single-text {
    font-size: 14px;
  }
}
.page-health .single-content {
  padding: 96px 0;
}
@media (max-width: 1280px) {
  .page-health .single-content {
    padding: 56px 0;
  }
}
@media (max-width: 768px) {
  .page-health .single-content {
    padding: 40px 0;
  }
}

/*****/
.single-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.single-row .single-col:nth-child(1) {
  width: calc(100% - 563px);
  padding-right: 64px;
}
@media (max-width: 1280px) {
  .single-row .single-col:nth-child(1) {
    width: calc(100% - 376px);
    padding-right: 48px;
  }
}
@media (max-width: 900px) {
  .single-row .single-col:nth-child(1) {
    width: 100%;
    padding-right: 0;
  }
}
.single-row .single-col:nth-child(2) {
  width: 563px;
}
@media (max-width: 1280px) {
  .single-row .single-col:nth-child(2) {
    width: 376px;
  }
}
@media (max-width: 900px) {
  .single-row .single-col:nth-child(2) {
    width: 100%;
  }
}

.detail-content {
  overflow: hidden;
}
.detail-content .detail-title {
  font-size: 29px;
  font-weight: 600;
}
@media (max-width: 1280px) {
  .detail-content .detail-title {
    font-size: 23px;
  }
}
@media (max-width: 768px) {
  .detail-content .detail-title {
    font-size: 18px;
  }
}
.detail-content .detail-text {
  font-size: 16px;
  color: #383C46;
}
.detail-content .detail-text-image {
  font-size: 16px;
  color: #6C6F77;
}
@media (max-width: 768px) {
  .detail-content .detail-text-image {
    font-size: 13px;
  }
}

.single-box {
  background: #2F333E;
  padding: 32px 16px;
}
@media (max-width: 900px) {
  .single-box {
    margin-top: 56px;
  }
}
.single-box .single-tlt .tlt {
  color: #F1F2F2;
  font-size: 23px;
  font-weight: 600;
}
@media (max-width: 1280px) {
  .single-box .single-tlt .tlt {
    font-size: 20px;
  }
}
.single-box .single-tlt .required {
  padding-top: 46px;
  color: #999BA1;
  font-size: 18px;
}
@media (max-width: 1280px) {
  .single-box .single-tlt .required {
    font-size: 14px;
    padding-top: 16px;
  }
}
.single-box .single-form {
  padding-top: 16px;
}
.single-box .single-form .groupForm {
  padding-top: 16px;
}
.single-box .single-form .groupForm .iti .iti__flag-container {
  left: 10px;
}
.single-box .single-form .groupForm .iti .form-control {
  padding-left: 94px !important;
}
.single-box .single-form .groupForm-check {
  padding-top: 8px;
}
.single-box .single-form .groupForm-check ul li {
  padding-top: 24px;
}
.single-box .single-form .groupForm-check .custom-control-description {
  font-size: 16px;
}
@media (max-width: 1280px) {
  .single-box .single-form .groupForm-check .custom-control-description {
    font-size: 14px;
  }
}
.single-box .single-form .groupForm-btn {
  padding-top: 32px;
}
@media (max-width: 1280px) {
  .single-box .single-form .groupForm-btn {
    max-width: 260px;
    margin: 0 auto;
  }
}
.single-box .single-form .groupForm-btn .btn {
  width: 100%;
}

/* -- page-caseStudies -- */
.page-caseStudies .banner-case {
  padding: 96px 0;
  background: url(../../images/data/bg-complete.jpg) no-repeat bottom right;
  background-size: cover;
}
@media (max-width: 1280px) {
  .page-caseStudies .banner-case {
    padding: 56px 0 48px;
  }
}
@media (max-width: 768px) {
  .page-caseStudies .banner-case {
    padding: 32px 0;
  }
}
.page-caseStudies .case-dflex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 1280px) {
  .page-caseStudies .case-dflex {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
.page-caseStudies .case-dflex .case-info {
  max-width: 700px;
  padding-right: 20px;
}
@media (max-width: 1280px) {
  .page-caseStudies .case-dflex .case-info {
    padding-right: 0;
    max-width: 680px;
  }
}
.page-caseStudies .case-dflex .case-info .case-text {
  font-size: 29px;
  color: #fff;
}
@media (max-width: 1280px) {
  .page-caseStudies .case-dflex .case-info .case-text {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .page-caseStudies .case-dflex .case-info .case-text {
    font-size: 16px;
  }
}
.page-caseStudies .case-dflex .case-btn {
  margin-left: auto;
}
@media (max-width: 1280px) {
  .page-caseStudies .case-dflex .case-btn {
    width: 100%;
    padding-top: 32px;
  }
}
@media (max-width: 768px) {
  .page-caseStudies .case-dflex .case-btn {
    padding-top: 16px;
  }
}
.page-caseStudies .featured {
  padding-top: 64px;
}
@media (max-width: 1280px) {
  .page-caseStudies .featured {
    padding-top: 56px;
  }
}
@media (max-width: 768px) {
  .page-caseStudies .featured {
    padding-top: 40px;
  }
}
@media (max-width: 768px) {
  .page-caseStudies .featured-list {
    padding-top: 16px;
  }
}
.page-caseStudies .featured-list ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-left: -32px;
}
@media (max-width: 1280px) {
  .page-caseStudies .featured-list ul {
    margin-left: -24px;
  }
}
.page-caseStudies .featured-list ul li {
  width: 33.333333%;
  padding-left: 32px;
  margin-top: 48px;
}
.page-caseStudies .featured-list ul li:nth-child(1), .page-caseStudies .featured-list ul li:nth-child(2) {
  width: 50%;
}
@media (max-width: 768px) {
  .page-caseStudies .featured-list ul li:nth-child(1), .page-caseStudies .featured-list ul li:nth-child(2) {
    width: 100%;
  }
}
.page-caseStudies .featured-list ul li:nth-child(1) .featured-content .featured-title, .page-caseStudies .featured-list ul li:nth-child(2) .featured-content .featured-title {
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-pack: end;
}
@media (max-width: 768px) {
  .page-caseStudies .featured-list ul li:nth-child(1) .featured-content .featured-title, .page-caseStudies .featured-list ul li:nth-child(2) .featured-content .featured-title {
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }
}
@media (max-width: 768px) {
  .page-caseStudies .featured-list ul li:nth-child(1) .featured-thumb {
    display: block;
  }
}
@media (max-width: 768px) {
  .page-caseStudies .featured-list ul li:nth-child(1) .featured-content .featured-title {
    font-size: 16px;
  }
}
@media (max-width: 1280px) {
  .page-caseStudies .featured-list ul li {
    margin-top: 32px;
    padding-left: 24px;
  }
}
@media (max-width: 768px) {
  .page-caseStudies .featured-list ul li {
    margin-top: 16px;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .page-caseStudies .featured-list .featured-thumb {
    display: none;
  }
}
.page-caseStudies .featured-list .featured-content {
  padding-top: 24px;
}
@media (max-width: 768px) {
  .page-caseStudies .featured-list .featured-content {
    background: #fff;
    padding: 16px;
  }
}
.page-caseStudies .featured-list .featured-content .author {
  display: block;
}
.page-caseStudies .featured-list .featured-content .author span {
  display: block;
}
.page-caseStudies .featured-list .featured-content .author span.author__date {
  padding-top: 4px;
}
.page-caseStudies .featured-list .featured-content .featured-title {
  margin-top: 16px;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 3;
  -webkit-box-pack: end;
}
.page-caseStudies .featured-list .featured-content .featured-title a {
  font-size: 20px;
  font-weight: 600;
  color: #2F333E;
}
@media (max-width: 1280px) {
  .page-caseStudies .featured-list .featured-content .featured-title a {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .page-caseStudies .featured-list .featured-content .featured-title a {
    font-size: 14px;
    margin-top: 16px;
  }
}
.page-caseStudies .read {
  padding: 0 0 96px;
}
@media (max-width: 1280px) {
  .page-caseStudies .read {
    padding: 0 0 64px;
  }
}
@media (max-width: 768px) {
  .page-caseStudies .read {
    padding: 0 0 40px;
  }
}
.page-caseStudies .read .read-choose {
  padding: 40px 0 32px;
}
@media (max-width: 1280px) {
  .page-caseStudies .read .read-choose {
    padding: 24px 0;
  }
}
.page-caseStudies .read .read-choose ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.page-caseStudies .read .read-choose ul li {
  padding: 8px 16px 0 0;
}
@media (max-width: 768px) {
  .page-caseStudies .read .read-choose ul li {
    padding: 4px 4px 0 0;
  }
}
.page-caseStudies .read .read-choose ul li:last-child {
  padding-right: 0;
}
@media (max-width: 768px) {
  .page-caseStudies .read .read-choose ul li .btn {
    padding: 0 16px;
  }
}
.page-caseStudies .read .sort-tabs {
  padding-top: 24px;
  border-top: 2px solid #C3C4D2;
}
.page-caseStudies .read .sort-tabs ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.page-caseStudies .read .sort-tabs ul li {
  padding-right: 24px;
}
@media (max-width: 1280px) {
  .page-caseStudies .read .sort-tabs ul li {
    padding-right: 16px;
  }
}
@media (max-width: 768px) {
  .page-caseStudies .read .sort-tabs ul li {
    padding-right: 8px;
    width: 50%;
  }
}
.page-caseStudies .read .sort-tabs ul li:last-child {
  padding-right: 0;
}
.page-caseStudies .read .sort-tabs ul li a {
  padding: 12px 16px;
  display: block;
  color: #383C46;
  font-size: 16px;
  background: #fff;
  border-radius: 4px;
}
@media (max-width: 1280px) {
  .page-caseStudies .read .sort-tabs ul li a {
    font-size: 14px;
  }
}
@media (max-width: 768px) {
  .page-caseStudies .read .sort-tabs ul li a {
    padding: 8px;
    font-size: 13px;
    text-align: center;
  }
}
.page-caseStudies .read .sort-tabs ul li a.active, .page-caseStudies .read .sort-tabs ul li a:hover {
  background: #D5E1EF;
  color: #0246C6;
}
.page-caseStudies .read-list--col2 ul {
  margin-left: -4%;
}
@media (max-width: 1280px) {
  .page-caseStudies .read-list--col2 ul {
    margin-left: -3%;
  }
}
@media (max-width: 768px) {
  .page-caseStudies .read-list--col2 ul {
    margin-left: -16px;
  }
}
.page-caseStudies .read-list--col2 ul li {
  width: 50%;
  padding-left: 4%;
  margin-top: 56px;
}
@media (max-width: 1280px) {
  .page-caseStudies .read-list--col2 ul li {
    margin-top: 40px;
    padding-left: 3%;
  }
}
@media (max-width: 768px) {
  .page-caseStudies .read-list--col2 ul li {
    padding-left: 16px;
  }
}
@media (max-width: 480px) {
  .page-caseStudies .read-list--col2 ul li {
    width: 100%;
    margin-top: 32px;
  }
}
.page-caseStudies .read-list--col2 .read-info .read-content .read-title {
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-pack: end;
  min-height: 49px;
}
@media (max-width: 1280px) {
  .page-caseStudies .read-list--col2 .read-info .read-content .read-title {
    min-height: inherit;
  }
}
.page-caseStudies .read-list--col3 ul {
  margin-left: -32px;
}
@media (max-width: 768px) {
  .page-caseStudies .read-list--col3 ul {
    margin-left: -16px;
  }
}
.page-caseStudies .read-list--col3 ul li {
  width: 33.333333%;
  margin-top: 40px;
  padding-left: 32px;
}
@media (max-width: 1280px) {
  .page-caseStudies .read-list--col3 ul li {
    width: 50%;
  }
}
@media (max-width: 768px) {
  .page-caseStudies .read-list--col3 ul li {
    padding-left: 16px;
  }
}
@media (max-width: 480px) {
  .page-caseStudies .read-list--col3 ul li {
    margin-top: 32px;
    width: 100%;
  }
}
.page-caseStudies .read-list--col3 .read-info .read-content .read-title {
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-pack: end;
  min-height: 72px;
}
@media (max-width: 1280px) {
  .page-caseStudies .read-list--col3 .read-info .read-content .read-title {
    min-height: inherit;
  }
}
.page-caseStudies .read-list ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.page-caseStudies .read-info .read-content {
  padding-top: 24px;
}
@media (max-width: 768px) {
  .page-caseStudies .read-info .read-content {
    padding-top: 16px;
  }
}
.page-caseStudies .read-info .read-content .author {
  display: block;
}
.page-caseStudies .read-info .read-content .author span {
  display: block;
}
.page-caseStudies .read-info .read-content .author span.author__date {
  padding-top: 4px;
}
.page-caseStudies .read-info .read-content .read-title {
  margin: 16px 0;
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 1280px) {
  .page-caseStudies .read-info .read-content .read-title {
    margin-bottom: 0;
  }
}
.page-caseStudies .read-info .read-content .read-title a {
  font-size: 20px;
  font-weight: 600;
  color: #2F333E;
}
@media (max-width: 1280px) {
  .page-caseStudies .read-info .read-content .read-title a {
    font-size: 18px;
    margin: 16px 0 0;
  }
}
@media (max-width: 768px) {
  .page-caseStudies .read-info .read-content .read-title a {
    font-size: 16px;
  }
}
@media (max-width: 1280px) {
  .page-caseStudies .read-info .readMore {
    display: none;
  }
}
.page-caseStudies .statistical ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.page-caseStudies .statistical ul li {
  width: 33.333333%;
  text-align: center;
  padding: 64px 16px;
}
@media (max-width: 1280px) {
  .page-caseStudies .statistical ul li {
    padding: 48px 8px;
  }
}
@media (max-width: 768px) {
  .page-caseStudies .statistical ul li {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 32px 16px;
    text-align: left;
  }
}
.page-caseStudies .statistical ul li:nth-child(1) {
  background: #0246C6;
}
.page-caseStudies .statistical ul li:nth-child(2) {
  background: #1C57C5;
}
.page-caseStudies .statistical ul li:nth-child(3) {
  background: #3062C0;
}
.page-caseStudies .statistical .statistical-number {
  font-size: 41px;
  font-weight: 600;
  color: #fff;
}
@media (max-width: 768px) {
  .page-caseStudies .statistical .statistical-number {
    font-size: 26px;
  }
}
.page-caseStudies .statistical .statistical-info {
  font-size: 23px;
  color: #fff;
  padding-top: 16px;
}
@media (max-width: 768px) {
  .page-caseStudies .statistical .statistical-info {
    padding-top: 0;
    padding-left: 32px;
    font-size: 16px;
  }
}
.page-caseStudies .banner-casefl {
  padding: 96px 0;
  background: url(../../images/data/bg-complete.jpg) no-repeat bottom right;
  background-size: cover;
}
@media (max-width: 1280px) {
  .page-caseStudies .banner-casefl {
    padding: 64px 0 56px;
  }
}
@media (max-width: 768px) {
  .page-caseStudies .banner-casefl {
    padding: 32px 0;
  }
}
.page-caseStudies .banner-casefl .casefl {
  max-width: 800px;
}
@media (max-width: 1280px) {
  .page-caseStudies .banner-casefl .casefl {
    max-width: 680px;
  }
}
.page-caseStudies .banner-casefl .casefl .casefl-title {
  color: #fff;
  font-size: 41px;
  font-weight: 600;
}
@media (max-width: 1280px) {
  .page-caseStudies .banner-casefl .casefl .casefl-title {
    font-size: 29px;
  }
}
@media (max-width: 768px) {
  .page-caseStudies .banner-casefl .casefl .casefl-title {
    font-size: 20px;
  }
}
.page-caseStudies .banner-casefl .casefl .casefl-text {
  color: #F1F2F2;
  font-size: 20px;
  padding-top: 32px;
}
@media (max-width: 1280px) {
  .page-caseStudies .banner-casefl .casefl .casefl-text {
    font-size: 18px;
    padding-top: 24px;
  }
}
@media (max-width: 768px) {
  .page-caseStudies .banner-casefl .casefl .casefl-text {
    font-size: 14px;
  }
}
.page-caseStudies .studies-content {
  padding: 64px 0 96px;
}
@media (max-width: 1280px) {
  .page-caseStudies .studies-content {
    padding: 64px 0;
  }
}
@media (max-width: 768px) {
  .page-caseStudies .studies-content {
    padding: 40px 0;
  }
}
.page-caseStudies .studies-content .detail-content {
  padding-top: 40px;
}
@media (max-width: 768px) {
  .page-caseStudies .studies-content .detail-content {
    padding-top: 32px;
  }
}
.page-caseStudies .related-case {
  background: #fff;
  padding: 64px 0 96px;
}
@media (max-width: 1280px) {
  .page-caseStudies .related-case {
    padding: 64px 0;
  }
}
@media (max-width: 768px) {
  .page-caseStudies .related-case {
    padding: 40px 0;
  }
}
.page-caseStudies .back {
  padding-top: 48px;
  text-align: center;
}
@media (max-width: 768px) {
  .page-caseStudies .back .btn {
    min-width: 290px;
  }
}

.banner-leverage {
  padding: 90px 0;
  background: url(../../images/data/bg-complete.jpg) no-repeat bottom right;
  background-size: cover;
}
@media (max-width: 1280px) {
  .banner-leverage {
    padding: 56px 0;
  }
}
@media (max-width: 768px) {
  .banner-leverage {
    padding: 32px 0;
  }
}
.banner-leverage .leverage-dflex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 1280px) {
  .banner-leverage .leverage-dflex {
    display: block;
    text-align: center;
  }
}
.banner-leverage .leverage-dflex .leverage-info .leverage-text {
  font-size: 36px;
  font-weight: 600;
  color: #fff;
}
@media (max-width: 1280px) {
  .banner-leverage .leverage-dflex .leverage-info .leverage-text {
    font-size: 29px;
  }
}
@media (max-width: 768px) {
  .banner-leverage .leverage-dflex .leverage-info .leverage-text {
    font-size: 20px;
  }
}
.banner-leverage .leverage-dflex .leverage-btn {
  margin-left: auto;
}
@media (max-width: 1280px) {
  .banner-leverage .leverage-dflex .leverage-btn {
    margin: 48px auto 0;
    max-width: 480px;
  }
  .banner-leverage .leverage-dflex .leverage-btn .btn {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .banner-leverage .leverage-dflex .leverage-btn {
    margin-top: 16px;
    max-width: 260px;
  }
}

/* -- page-events -- */
.page-events .banner-event {
  padding: 96px 0;
  background: url(../../images/data/bg-event.jpg) no-repeat center right;
  background-size: cover;
}
@media (max-width: 1280px) {
  .page-events .banner-event {
    padding: 56px 0 48px;
  }
}
@media (max-width: 768px) {
  .page-events .banner-event {
    padding: 32px 0;
    background-position: 30% 0;
  }
}
.page-events .event-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 1280px) {
  .page-events .event-info {
    display: block;
  }
}
.page-events .event-info .event-content {
  max-width: 700px;
  padding-right: 20px;
}
@media (max-width: 1280px) {
  .page-events .event-info .event-content {
    max-width: 680px;
    padding-right: 0;
  }
}
.page-events .event-info .event-content .event-text {
  color: #fff;
  font-size: 29px;
}
@media (max-width: 1280px) {
  .page-events .event-info .event-content .event-text {
    font-size: 23px;
  }
}
@media (max-width: 768px) {
  .page-events .event-info .event-content .event-text {
    font-size: 18px;
  }
}
.page-events .event-info .event-btn {
  margin-left: auto;
}
@media (max-width: 1280px) {
  .page-events .event-info .event-btn {
    padding-top: 32px;
  }
}
@media (max-width: 768px) {
  .page-events .event-info .event-btn {
    padding-top: 16px;
  }
}
.page-events .banner-eventOn {
  padding: 96px 0;
  background: url(../../images/data/bg-event01.jpg) no-repeat center right;
  background-size: cover;
}
@media (max-width: 1280px) {
  .page-events .banner-eventOn {
    padding: 64px 0 56px;
  }
}
@media (max-width: 768px) {
  .page-events .banner-eventOn {
    padding: 32px 0;
    background-position: 52% 0;
  }
}
.page-events .banner-eventSingle {
  padding: 96px 0;
  background: url(../../images/data/bg-event02.jpg) no-repeat center right;
  background-size: cover;
}
@media (max-width: 1280px) {
  .page-events .banner-eventSingle {
    padding: 64px 0 56px;
  }
}
@media (max-width: 768px) {
  .page-events .banner-eventSingle {
    padding: 32px 0;
    background-position: 40% 0;
  }
}
.page-events .eventOn-info {
  max-width: 800px;
}
@media (max-width: 1280px) {
  .page-events .eventOn-info {
    max-width: 680px;
  }
}
.page-events .eventOn-info .eventOn-title {
  font-size: 41px;
  color: #fff;
  font-weight: 600;
}
@media (max-width: 1280px) {
  .page-events .eventOn-info .eventOn-title {
    font-size: 29px;
  }
}
@media (max-width: 768px) {
  .page-events .eventOn-info .eventOn-title {
    font-size: 20px;
  }
}
.page-events .eventOn-info .eventOn-text {
  font-size: 20px;
  color: #F1F2F2;
  padding-top: 32px;
}
@media (max-width: 1280px) {
  .page-events .eventOn-info .eventOn-text {
    font-size: 18px;
    padding-top: 24px;
  }
}
@media (max-width: 768px) {
  .page-events .eventOn-info .eventOn-text {
    font-size: 14px;
  }
}
.page-events .category {
  background: #fff;
  z-index: 1;
}
.page-events .category .category-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 768px) {
  .page-events .category .category-nav {
    width: calc(100% + 32px);
    margin-left: -16px;
  }
}
.page-events .category .category-nav li:nth-child(1) {
  padding: 0 22px 0 0;
  color: #383C46;
  font-size: 23px;
}
@media (max-width: 1280px) {
  .page-events .category .category-nav li:nth-child(1) {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .page-events .category .category-nav li:nth-child(1) {
    display: none;
  }
}
@media (max-width: 768px) {
  .page-events .category .category-nav li:nth-child(2) {
    width: 40%;
  }
}
@media (max-width: 768px) {
  .page-events .category .category-nav li:nth-child(3) {
    width: 60%;
  }
}
.page-events .category .category-nav li a {
  padding: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  color: #292E60;
  font-size: 20px;
  border-bottom: 3px solid #E3EAF6;
}
@media (max-width: 1280px) {
  .page-events .category .category-nav li a {
    padding: 14px 24px;
    font-size: 18px;
    min-height: 54px;
  }
}
@media (max-width: 768px) {
  .page-events .category .category-nav li a {
    font-size: 16px;
    padding: 14px 10px;
  }
}
.page-events .category .category-nav li a:hover, .page-events .category .category-nav li a.active {
  background: #EEF2FA;
  border-bottom: 3px solid #0246C6;
  color: #0246C6;
}
.page-events .category .category-nav li a:hover .category-item .svg path, .page-events .category .category-nav li a.active .category-item .svg path {
  fill: #0246C6;
}
.page-events .category .category-nav li a .category-item {
  width: 24px;
  min-width: 24px;
  height: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: -2px 8px 0 0;
}
@media (max-width: 768px) {
  .page-events .category .category-nav li a .category-item {
    width: 16px;
    min-width: 16px;
    height: 16px;
    margin-right: 6px;
  }
}
.page-events .category .category-nav li a .category-item .svg path {
  fill: #292E60;
}
.page-events .events-content {
  padding: 64px 0 96px;
}
@media (max-width: 1280px) {
  .page-events .events-content {
    padding: 40px 0 96px;
  }
}
@media (max-width: 768px) {
  .page-events .events-content {
    padding: 40px 0;
  }
}
.page-events .events-content .past-events {
  padding-top: 160px;
}
@media (max-width: 1280px) {
  .page-events .events-content .past-events {
    padding-top: 48px;
  }
}
@media (max-width: 768px) {
  .page-events .events-content .past-events {
    padding-top: 40px;
  }
}
.page-events .events-content .detail-content {
  padding-top: 40px;
}
@media (max-width: 768px) {
  .page-events .events-content .detail-content {
    padding-top: 32px;
  }
}
.page-events .events-content .events-wrap {
  max-width: 800px;
}
@media (max-width: 1280px) {
  .page-events .events-content .events-wrap {
    max-width: 680px;
  }
}
.page-events .events-list {
  margin-top: 32px;
  border-top: 1px solid #E0E0E8;
}
@media (max-width: 1280px) {
  .page-events .events-list {
    margin-top: 24px;
  }
}
@media (max-width: 480px) {
  .page-events .events-list {
    border-top: none;
    margin-top: 8px;
  }
}
.page-events .events-list ul li {
  padding-top: 32px;
}
@media (max-width: 480px) {
  .page-events .events-list ul li {
    padding-top: 24px;
  }
}
.page-events .events-list .eventsInfo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.page-events .events-list .eventsInfo .eventsInfo-date {
  width: 180px;
}
@media (max-width: 1280px) {
  .page-events .events-list .eventsInfo .eventsInfo-date {
    width: 122px;
  }
}
@media (max-width: 900px) {
  .page-events .events-list .eventsInfo .eventsInfo-date {
    width: 90px;
  }
}
@media (max-width: 768px) {
  .page-events .events-list .eventsInfo .eventsInfo-date {
    display: none;
  }
}
.page-events .events-list .eventsInfo .eventsInfo-date span {
  display: block;
  color: #252C6D;
}
.page-events .events-list .eventsInfo .eventsInfo-date span.eventsInfo-number {
  font-size: 50px;
  font-weight: 700;
}
@media (max-width: 1280px) {
  .page-events .events-list .eventsInfo .eventsInfo-date span.eventsInfo-number {
    font-size: 41px;
  }
}
.page-events .events-list .eventsInfo .eventsInfo-date span.eventsInfo-aug {
  font-size: 26px;
}
@media (max-width: 1280px) {
  .page-events .events-list .eventsInfo .eventsInfo-date span.eventsInfo-aug {
    font-size: 23px;
  }
}
.page-events .events-list .eventsInfo .eventsInfo-main {
  width: calc(100% - 180px);
  padding-bottom: 32px;
  border-bottom: 1px solid #E0E0E8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
@media (max-width: 1280px) {
  .page-events .events-list .eventsInfo .eventsInfo-main {
    width: calc(100% - 122px);
  }
}
@media (max-width: 900px) {
  .page-events .events-list .eventsInfo .eventsInfo-main {
    width: calc(100% - 90px);
  }
}
@media (max-width: 768px) {
  .page-events .events-list .eventsInfo .eventsInfo-main {
    width: 100%;
    padding-bottom: 24px;
  }
}
.page-events .events-list .eventsInfo .eventsInfo-main .eventsInfo-thumb {
  width: 360px;
}
@media (max-width: 1280px) {
  .page-events .events-list .eventsInfo .eventsInfo-main .eventsInfo-thumb {
    width: 320px;
  }
}
@media (max-width: 900px) {
  .page-events .events-list .eventsInfo .eventsInfo-main .eventsInfo-thumb {
    width: 240px;
  }
}
@media (max-width: 768px) {
  .page-events .events-list .eventsInfo .eventsInfo-main .eventsInfo-thumb {
    width: 160px;
  }
}
@media (max-width: 480px) {
  .page-events .events-list .eventsInfo .eventsInfo-main .eventsInfo-thumb {
    width: 100%;
  }
}
.page-events .events-list .eventsInfo .eventsInfo-main .eventsInfo-content {
  width: calc(100% - 360px);
  padding-left: 4%;
}
@media (max-width: 1280px) {
  .page-events .events-list .eventsInfo .eventsInfo-main .eventsInfo-content {
    width: calc(100% - 320px);
  }
}
@media (max-width: 900px) {
  .page-events .events-list .eventsInfo .eventsInfo-main .eventsInfo-content {
    width: calc(100% - 240px);
    padding-left: 3%;
  }
}
@media (max-width: 768px) {
  .page-events .events-list .eventsInfo .eventsInfo-main .eventsInfo-content {
    width: calc(100% - 160px);
  }
}
@media (max-width: 480px) {
  .page-events .events-list .eventsInfo .eventsInfo-main .eventsInfo-content {
    width: 100%;
    padding-left: 0;
  }
}
.page-events .events-list .eventsInfo .eventsInfo-main .eventsInfo-content .eventsInfo-time {
  color: #0246C6;
  font-size: 22px;
}
@media (max-width: 1280px) {
  .page-events .events-list .eventsInfo .eventsInfo-main .eventsInfo-content .eventsInfo-time {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .page-events .events-list .eventsInfo .eventsInfo-main .eventsInfo-content .eventsInfo-time {
    font-size: 14px;
  }
}
@media (max-width: 480px) {
  .page-events .events-list .eventsInfo .eventsInfo-main .eventsInfo-content .eventsInfo-time {
    padding-top: 16px;
  }
}
.page-events .events-list .eventsInfo .eventsInfo-main .eventsInfo-content .eventsInfo-title {
  color: #2F333E;
  font-size: 23px;
  font-weight: 600;
  margin-top: 24px;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 2;
  -webkit-box-pack: end;
}
@media (max-width: 1280px) {
  .page-events .events-list .eventsInfo .eventsInfo-main .eventsInfo-content .eventsInfo-title {
    font-size: 20px;
  }
}
@media (max-width: 900px) {
  .page-events .events-list .eventsInfo .eventsInfo-main .eventsInfo-content .eventsInfo-title {
    margin-top: 16px;
  }
}
@media (max-width: 768px) {
  .page-events .events-list .eventsInfo .eventsInfo-main .eventsInfo-content .eventsInfo-title {
    font-size: 16px;
  }
}
.page-events .events-list .eventsInfo .eventsInfo-main .eventsInfo-content .eventsInfo-title a {
  color: #2F333E;
}
.page-events .events-list .eventsInfo .eventsInfo-main .eventsInfo-content .eventsInfo-text {
  color: #383C46;
  font-size: 18px;
  padding-top: 24px;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 3;
  -webkit-box-pack: end;
}
@media (max-width: 1280px) {
  .page-events .events-list .eventsInfo .eventsInfo-main .eventsInfo-content .eventsInfo-text {
    font-size: 16px;
  }
}
@media (max-width: 900px) {
  .page-events .events-list .eventsInfo .eventsInfo-main .eventsInfo-content .eventsInfo-text {
    padding-top: 16px;
  }
}
@media (max-width: 768px) {
  .page-events .events-list .eventsInfo .eventsInfo-main .eventsInfo-content .eventsInfo-text {
    font-size: 14px;
  }
}
.page-events .btn-loadmore {
  padding-top: 48px;
  text-align: center;
}
@media (max-width: 900px) {
  .page-events .btn-loadmore {
    padding-top: 40px;
  }
}
@media (max-width: 768px) {
  .page-events .btn-loadmore {
    padding-top: 32px;
  }
}
.page-events .btn-loadmore .btn {
  min-width: 420px;
}
@media (max-width: 900px) {
  .page-events .btn-loadmore .btn {
    min-width: 280px;
  }
}
@media (max-width: 768px) {
  .page-events .btn-loadmore .btn {
    min-width: 320px;
  }
}

.head-time {
  padding: 16px 24px;
  margin-top: 48px;
  border: 1px solid #0246C6;
  border-radius: 3px;
  background: #fff;
}
@media (max-width: 1280px) {
  .head-time {
    margin-top: 40px;
  }
}
@media (max-width: 768px) {
  .head-time {
    padding: 16px;
    margin-top: 32px;
  }
}
.head-time ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 768px) {
  .head-time ul {
    display: block;
  }
}
.head-time ul li {
  padding: 0 24px;
}
@media (max-width: 768px) {
  .head-time ul li {
    padding: 16px 0;
  }
}
.head-time ul li:first-of-type {
  padding-left: 0;
  font-size: 20px;
  font-weight: 500;
  color: #2F333E;
  border-right: 1px solid #2F333E;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 1280px) {
  .head-time ul li:first-of-type {
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .head-time ul li:first-of-type {
    padding-top: 0;
    border-right: none;
    border-bottom: 1px solid #0246C6;
    font-size: 14px;
  }
}
.head-time ul li:first-of-type .time-item {
  min-width: 20px;
  width: 20px;
  height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-right: 8px;
}
@media (max-width: 768px) {
  .head-time ul li:first-of-type .time-item {
    min-width: 16px;
    width: 16px;
    height: 16px;
  }
}
.head-time ul li:first-of-type .time-item .svg path {
  fill: #2F333E;
}
.head-time ul li:last-child {
  padding-right: 0;
  color: #0246C6;
  font-size: 18px;
}
@media (max-width: 1280px) {
  .head-time ul li:last-child {
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .head-time ul li:last-child {
    padding-bottom: 0;
    font-size: 13px;
  }
}
.head-time ul li:last-child .time-text {
  display: inline-block;
  padding-right: 24px;
}
@media (max-width: 768px) {
  .head-time ul li:last-child .time-text {
    padding-right: 16px;
  }
}
.head-time ul li:last-child .time-text:last-child {
  padding-right: 0;
}

/*****/
.thankYou {
  min-height: 100vh;
  background: -webkit-gradient(linear, left top, left bottom, from(#FFF), to(#BBCCEB));
  background: linear-gradient(180deg, #FFF 0%, #BBCCEB 100%);
  padding: 100px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 768px) {
  .thankYou {
    padding: 48px 0;
  }
}
.thankYou .wrap {
  width: 100%;
}
@media (max-width: 1280px) {
  .thankYou .wrap {
    max-width: 690px;
  }
}
.thankYou .thankYou-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
}
@media (max-width: 1280px) {
  .thankYou .thankYou-info {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
.thankYou .thankYou-info .thankYou-title {
  font-size: 64px;
  font-weight: 600;
  color: #252C6D;
  padding-right: 12px;
  white-space: nowrap;
}
@media (max-width: 1280px) {
  .thankYou .thankYou-info .thankYou-title {
    width: 100%;
    padding-right: 0;
    font-size: 80px;
  }
}
@media (max-width: 768px) {
  .thankYou .thankYou-info .thankYou-title {
    font-size: 48px;
  }
}
.thankYou .thankYou-info .thankYou-content {
  max-width: 800px;
}
@media (max-width: 1280px) {
  .thankYou .thankYou-info .thankYou-content {
    width: 100%;
    max-width: 100%;
  }
}
.thankYou .thankYou-info .thankYou-request {
  font-size: 26px;
  font-weight: 600;
}
@media (max-width: 1280px) {
  .thankYou .thankYou-info .thankYou-request {
    padding-top: 48px;
    font-size: 23px;
  }
}
@media (max-width: 768px) {
  .thankYou .thankYou-info .thankYou-request {
    font-size: 18px;
    padding-top: 64px;
  }
}
.thankYou .thankYou-info .thankYou-for {
  padding-top: 40px;
}
.thankYou .thankYou-info .thankYou-for li {
  margin-bottom: 16px;
  padding: 16px;
  background: #F5F6F8;
  border-radius: 4px;
  color: #000;
  font-size: 20px;
  text-align: center;
}
@media (max-width: 768px) {
  .thankYou .thankYou-info .thankYou-for li {
    margin-bottom: 8px;
    font-size: 14px;
    padding: 8px;
  }
}
.thankYou .thankYou-info .thankYou-for li a {
  color: #0246C6;
}
.thankYou .thankYou-info .thankYou-for li a:hover {
  text-decoration: underline;
}
.thankYou .thankYou-info .thankYou-for li:last-child {
  margin-bottom: 0;
}
.thankYou .thankYou-info .thankYou-text {
  padding-top: 40px;
  font-size: 29px;
}
@media (max-width: 1280px) {
  .thankYou .thankYou-info .thankYou-text {
    font-size: 23px;
  }
}
@media (max-width: 768px) {
  .thankYou .thankYou-info .thankYou-text {
    padding-top: 40px;
    font-size: 18px;
  }
}
.thankYou .thankYou-info .thankYou-text.thankYou-text--fs40 {
  font-size: 40px;
}
@media (max-width: 1280px) {
  .thankYou .thankYou-info .thankYou-text.thankYou-text--fs40 {
    font-size: 23px;
  }
}
@media (max-width: 768px) {
  .thankYou .thankYou-info .thankYou-text.thankYou-text--fs40 {
    font-size: 16px;
  }
}

/* -- page-contact -- */
.page-contact .banner-contact {
  padding: 96px 0;
  background: url(../../images/data/bg-complete.jpg) no-repeat center right;
  background-size: cover;
}
@media (max-width: 1280px) {
  .page-contact .banner-contact {
    padding: 56px 0 48px;
  }
}
@media (max-width: 768px) {
  .page-contact .banner-contact {
    padding: 32px 0;
  }
}
.page-contact .banner-contact .contact-info {
  max-width: 800px;
}
@media (max-width: 1280px) {
  .page-contact .banner-contact .contact-info {
    max-width: 680px;
  }
}
.page-contact .banner-contact .contact-info .contact-text {
  font-size: 29px;
  color: #fff;
}
@media (max-width: 1280px) {
  .page-contact .banner-contact .contact-info .contact-text {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .page-contact .banner-contact .contact-info .contact-text {
    font-size: 16px;
  }
}
.page-contact .form-contact .accordion-is ul li {
  border-bottom: 1px solid #6C6F77;
}
.page-contact .form-contact .accordion-is ul li:last-child {
  border-bottom: none;
}
.page-contact .form-contact .accordion-title {
  background: #fff;
}
.page-contact .form-contact .accordion-title--active .tlt::after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.page-contact .form-contact .accordion-title:hover .tlt {
  color: #0246C6;
}
.page-contact .form-contact .accordion-title:hover .tlt::after {
  width: 48px;
  height: 48px;
  background: url(../../images/common/icon-arrow-contact-blue.svg) no-repeat center;
}
@media (max-width: 768px) {
  .page-contact .form-contact .accordion-title:hover .tlt::after {
    background-size: 12px;
    width: 24px;
    height: 24px;
    top: calc(50% - 12px);
  }
}
.page-contact .form-contact .accordion-title .tlt {
  padding: 40px 48px 40px 0;
  color: #383C46;
  font-size: 32px;
  font-weight: 600;
  cursor: pointer;
  position: relative;
}
@media (max-width: 1280px) {
  .page-contact .form-contact .accordion-title .tlt {
    padding: 32px 48px 32px 0;
    font-size: 29px;
  }
}
@media (max-width: 768px) {
  .page-contact .form-contact .accordion-title .tlt {
    padding: 16px 24px 16px 0;
    font-size: 16px;
  }
}
.page-contact .form-contact .accordion-title .tlt::after {
  position: absolute;
  top: calc(50% - 24px);
  right: 0;
  content: "";
  width: 48px;
  height: 48px;
  background: url(../../images/common/icon-arrow-contact.svg) no-repeat center;
  -webkit-transition: 400ms all;
  transition: 400ms all;
}
@media (max-width: 768px) {
  .page-contact .form-contact .accordion-title .tlt::after {
    background-size: 12px;
    width: 24px;
    height: 24px;
    top: calc(50% - 12px);
  }
}
.page-contact .form-contact .accordion-content {
  display: none;
}
.page-contact .contact-content {
  padding: 64px 0 96px;
}
@media (max-width: 1280px) {
  .page-contact .contact-content {
    padding: 56px 0 64px;
  }
}
@media (max-width: 768px) {
  .page-contact .contact-content {
    padding: 40px 0 64px;
  }
}
.page-contact .contact-content .title .title-text {
  font-size: 52px;
}
@media (max-width: 1280px) {
  .page-contact .contact-content .title .title-text {
    font-size: 29px;
  }
}
@media (max-width: 768px) {
  .page-contact .contact-content .title .title-text {
    font-size: 23px;
  }
}
.page-contact .contact-content .accordion-is {
  padding-top: 50px;
}
@media (max-width: 768px) {
  .page-contact .contact-content .accordion-is {
    padding-top: 32px;
  }
}
.page-contact .contact-content .accordion-is > ul > li {
  border-top: 1px solid #C3C4D2;
}
.page-contact .contact-content .accordion-is .accordion-title:hover .tlt {
  color: #0246C6;
}
.page-contact .contact-content .accordion-is .accordion-title:hover .tlt .accordion-item-custom .svg path {
  fill: #0246C6;
}
.page-contact .contact-content .accordion-is .accordion-title .tlt {
  padding: 30px 0;
  color: #2F333E;
  font-size: 28px;
  cursor: pointer;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 1280px) {
  .page-contact .contact-content .accordion-is .accordion-title .tlt {
    padding: 24px 0;
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .page-contact .contact-content .accordion-is .accordion-title .tlt {
    padding: 8px 0;
    font-size: 14px;
  }
}
.page-contact .contact-content .accordion-is .accordion-title .tlt .accordion-item-custom {
  min-width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 768px) {
  .page-contact .contact-content .accordion-is .accordion-title .tlt .accordion-item-custom {
    min-width: 24px;
    height: 24px;
  }
}
.page-contact .contact-content .accordion-is .accordion-title .tlt .accordion-item-custom .svg {
  -webkit-transition: 400ms all;
  transition: 400ms all;
}
@media (max-width: 768px) {
  .page-contact .contact-content .accordion-is .accordion-title .tlt .accordion-item-custom .svg {
    width: 12px;
  }
}
.page-contact .contact-content .accordion-is .accordion-title--active .tlt {
  color: #0246C6;
  text-decoration: underline;
}
.page-contact .contact-content .accordion-is .accordion-title--active .tlt .accordion-item-custom .svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.page-contact .contact-content .accordion-is .accordion-title--active .tlt .accordion-item-custom .svg path {
  fill: #0246C6;
}
.page-contact .contact-content .accordion-is .accordion-content {
  display: none;
}
.page-contact .contact-content .contact-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-left: -3%;
  padding-bottom: 40px;
}
@media (max-width: 1280px) {
  .page-contact .contact-content .contact-row {
    margin-left: -2%;
    padding-bottom: 32px;
  }
}
@media (max-width: 768px) {
  .page-contact .contact-content .contact-row {
    padding-bottom: 24px;
  }
}
.page-contact .contact-content .contact-row .contact-col {
  width: 50%;
  padding-left: 3%;
}
@media (max-width: 1280px) {
  .page-contact .contact-content .contact-row .contact-col {
    padding-left: 2%;
  }
}
@media (max-width: 768px) {
  .page-contact .contact-content .contact-row .contact-col {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .page-contact .contact-content .contact-row .thumb-contact {
    padding-top: 32px;
  }
}
.page-contact .contact-content .contact-row .thumb-contact img {
  width: 100%;
}
.page-contact .contact-content .contact-row .add-contact ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-bottom: 40px;
}
@media (max-width: 1280px) {
  .page-contact .contact-content .contact-row .add-contact ul li {
    padding-bottom: 32px;
  }
}
.page-contact .contact-content .contact-row .add-contact ul li:last-child {
  padding-bottom: 0;
}
.page-contact .contact-content .contact-row .add-contact ul li .add-label {
  width: 140px;
  padding-right: 10px;
  color: #2F333E;
  font-size: 23px;
  font-weight: 600;
}
@media (max-width: 1280px) {
  .page-contact .contact-content .contact-row .add-contact ul li .add-label {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .page-contact .contact-content .contact-row .add-contact ul li .add-label {
    font-size: 13px;
    width: 94px;
  }
}
.page-contact .contact-content .contact-row .add-contact ul li .add-content {
  width: calc(100% - 140px);
}
@media (max-width: 768px) {
  .page-contact .contact-content .contact-row .add-contact ul li .add-content {
    width: calc(100% - 94px);
  }
}
.page-contact .contact-content .contact-row .add-contact ul li .add-content .add-title {
  font-size: 23px;
  font-weight: 600;
  color: #2F333E;
  text-decoration: none;
}
@media (max-width: 1280px) {
  .page-contact .contact-content .contact-row .add-contact ul li .add-content .add-title {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .page-contact .contact-content .contact-row .add-contact ul li .add-content .add-title {
    font-size: 13px;
  }
}
.page-contact .contact-content .contact-row .add-contact ul li .add-content .add-text {
  padding-top: 16px;
  font-size: 23px;
  color: #383C46;
}
@media (max-width: 1280px) {
  .page-contact .contact-content .contact-row .add-contact ul li .add-content .add-text {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .page-contact .contact-content .contact-row .add-contact ul li .add-content .add-text {
    padding-top: 8px;
    font-size: 13px;
  }
}
.page-contact .contact-content .contact-row .add-contact ul li .add-content .maps {
  padding-top: 32px;
}
@media (max-width: 1280px) {
  .page-contact .contact-content .contact-row .add-contact ul li .add-content .maps {
    padding-top: 24px;
  }
}
@media (max-width: 768px) {
  .page-contact .contact-content .contact-row .add-contact ul li .add-content .maps {
    padding-top: 16px;
  }
}
.page-contact .contact-content .contact-row .add-contact ul li .add-content .maps a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 16px 24px;
  color: #0246C6;
  font-size: 18px;
  background: #D5E1EF;
  border-radius: 100px;
}
@media (max-width: 1280px) {
  .page-contact .contact-content .contact-row .add-contact ul li .add-content .maps a {
    padding: 9px 16px;
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .page-contact .contact-content .contact-row .add-contact ul li .add-content .maps a {
    font-size: 13px;
  }
}
.page-contact .contact-content .contact-row .add-contact ul li .add-content .maps a .maps-item {
  margin: 0 7px 0 0;
  width: 18px;
  min-width: 18px;
  height: 21px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

/* -- page-about -- */
.page-about {
  /*****/
}
.page-about .titleAbout {
  padding: 64px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 768px) {
  .page-about .titleAbout {
    padding: 64px 0 32px;
  }
}
.page-about .titleAbout .tlt {
  max-width: 460px;
  font-size: 46px;
  font-weight: 500;
  color: #2F333E;
}
@media (max-width: 1280px) {
  .page-about .titleAbout .tlt {
    font-size: 41px;
  }
}
@media (max-width: 768px) {
  .page-about .titleAbout .tlt {
    font-size: 26px;
  }
}
.page-about .titleAbout .create {
  font-size: 23px;
  color: #383C46;
}
@media (max-width: 1280px) {
  .page-about .titleAbout .create {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .page-about .titleAbout .create {
    display: none;
  }
}
.page-about .thumb-about {
  background: url(../../images/data/thumb-about.jpg) no-repeat top;
  background-size: cover;
  height: 600px;
}
@media (min-width: 1025px) {
  .page-about .thumb-about {
    background-attachment: fixed;
  }
}
@media (max-width: 1280px) {
  .page-about .thumb-about {
    height: 400px;
  }
}
@media (max-width: 1023px) {
  .page-about .thumb-about {
    background: none;
    height: initial;
  }
}
.page-about .thumb-about img {
  width: 100%;
  display: none;
}
@media (max-width: 1023px) {
  .page-about .thumb-about img {
    display: block;
  }
}
.page-about .about-omi {
  padding: 128px 0;
}
@media (max-width: 768px) {
  .page-about .about-omi {
    padding: 40px 0;
  }
}
.page-about .about-omi .about-info {
  max-width: 50%;
  padding-right: 6px;
}
@media (max-width: 768px) {
  .page-about .about-omi .about-info {
    max-width: 100%;
    padding-right: 0;
  }
}
.page-about .about-omi .about-info .about-text {
  font-size: 36px;
  font-weight: 500;
  color: #383C46;
}
@media (max-width: 1280px) {
  .page-about .about-omi .about-info .about-text {
    font-size: 23px;
  }
}
@media (max-width: 768px) {
  .page-about .about-omi .about-info .about-text {
    font-size: 20px;
  }
}
.page-about .about-omi .about-same {
  padding: 46px 0 0 6px;
  margin-left: auto;
  max-width: 50%;
}
@media (max-width: 768px) {
  .page-about .about-omi .about-same {
    padding: 40px 0 0;
    max-width: 100%;
  }
}
.page-about .about-omi .about-same .about-text {
  color: #383C46;
  font-size: 23px;
}
@media (max-width: 1280px) {
  .page-about .about-omi .about-same .about-text {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .page-about .about-omi .about-same .about-text {
    font-size: 16px;
  }
}
.page-about .slogan {
  padding: 64px 0;
  overflow: hidden;
  width: 100%;
}
@media (max-width: 1280px) {
  .page-about .slogan {
    padding: 48px 0;
  }
}
@media (max-width: 768px) {
  .page-about .slogan {
    display: none;
  }
}
.page-about .slogan .slogan-text {
  font-size: 56px;
  color: #0246C6;
  font-weight: 100;
  padding: 0 64px;
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  white-space: nowrap;
}
.page-about .slogan .slogan-text::after {
  position: absolute;
  top: 25px;
  right: -4px;
  content: "";
  width: 16px;
  height: 16px;
  background: #0246C6;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.page-about .about-our img {
  width: 100%;
}
.page-about .about-our .our-list {
  margin-top: -180px;
  position: relative;
}
@media (max-width: 1280px) {
  .page-about .about-our .our-list {
    margin-top: -90px;
  }
}
@media (max-width: 768px) {
  .page-about .about-our .our-list {
    margin-top: -20px;
  }
}
.page-about .about-our .our-list ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.page-about .about-our .our-list ul li {
  width: 50%;
  padding: 96px 64px;
  border-right: 1px solid #F1F2F2;
  background: #fff;
}
@media (max-width: 1280px) {
  .page-about .about-our .our-list ul li {
    padding: 64px 32px;
  }
}
@media (max-width: 768px) {
  .page-about .about-our .our-list ul li {
    width: 100%;
    padding: 24px 16px;
    margin-bottom: 8px;
    border-right: none;
  }
}
.page-about .about-our .our-list ul li:last-child {
  border-right: none;
}
@media (max-width: 768px) {
  .page-about .about-our .our-list ul li:last-child {
    margin-bottom: 0;
  }
}
.page-about .about-our .our-list .our-title {
  color: #2F333E;
  font-size: 29px;
  font-weight: 600;
}
@media (max-width: 1280px) {
  .page-about .about-our .our-list .our-title {
    font-size: 23px;
  }
}
@media (max-width: 768px) {
  .page-about .about-our .our-list .our-title {
    font-size: 18px;
  }
}
.page-about .about-our .our-list .our-text {
  font-size: 23px;
  color: #383C46;
  padding-top: 64px;
}
@media (max-width: 1280px) {
  .page-about .about-our .our-list .our-text {
    font-size: 20px;
    padding-top: 32px;
  }
}
@media (max-width: 768px) {
  .page-about .about-our .our-list .our-text {
    font-size: 14px;
    padding-top: 24px;
  }
}
.page-about .our-core {
  padding: 104px 0 152px;
}
@media (max-width: 1280px) {
  .page-about .our-core {
    padding: 64px 0;
  }
}
@media (max-width: 768px) {
  .page-about .our-core {
    padding: 48px 0 56px;
  }
}
.page-about .core-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-left: -3%;
}
.page-about .core-row .core-col {
  width: 50%;
  padding-left: 3%;
}
@media (max-width: 768px) {
  .page-about .core-row .core-col {
    width: 100%;
  }
  .page-about .core-row .core-col:nth-child(1) {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .page-about .core-row .core-col:nth-child(2) {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
}
.page-about .core-row .title-core .tlt {
  color: #2F333E;
  font-size: 46px;
  font-weight: 600;
}
@media (max-width: 1280px) {
  .page-about .core-row .title-core .tlt {
    font-size: 41px;
  }
}
@media (max-width: 768px) {
  .page-about .core-row .title-core .tlt {
    font-size: 23px;
  }
}
@media (max-width: 768px) {
  .page-about .core-row .core-thumb {
    padding-bottom: 56px;
  }
}
.page-about .core-row .core-thumb img {
  width: 100%;
}
.page-about .core-row .accordion-is {
  padding-top: 64px;
}
@media (max-width: 768px) {
  .page-about .core-row .accordion-is {
    padding-top: 40px;
    width: calc(100% + 32px);
    margin-left: -16px;
  }
}
.page-about .core-row .accordion-is ul li {
  border-top: 1px solid #C3C4D2;
}
.page-about .core-row .accordion-is ul li:last-child {
  border-bottom: 1px solid #C3C4D2;
}
.page-about .core-row .accordion-is .accordion-title .tlt {
  padding: 24px 30px 24px 0;
  font-size: 23px;
  color: #383C46;
  font-weight: 600;
  cursor: pointer;
  position: relative;
}
@media (max-width: 1280px) {
  .page-about .core-row .accordion-is .accordion-title .tlt {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .page-about .core-row .accordion-is .accordion-title .tlt {
    padding: 16px 30px 16px 16px;
    font-size: 16px;
  }
}
.page-about .core-row .accordion-is .accordion-title .tlt::after, .page-about .core-row .accordion-is .accordion-title .tlt::before {
  position: absolute;
  content: "";
  background: #6C6F77;
}
.page-about .core-row .accordion-is .accordion-title .tlt::after {
  top: calc(50% - 8px);
  right: 8px;
  width: 2px;
  height: 18px;
}
@media (max-width: 768px) {
  .page-about .core-row .accordion-is .accordion-title .tlt::after {
    height: 12px;
    top: calc(50% - 6px);
    right: 15px;
  }
}
.page-about .core-row .accordion-is .accordion-title .tlt::before {
  top: 50%;
  right: 0;
  width: 18px;
  height: 2px;
}
@media (max-width: 768px) {
  .page-about .core-row .accordion-is .accordion-title .tlt::before {
    width: 12px;
    right: 10px;
    top: calc(50% - 1px);
  }
}
.page-about .core-row .accordion-is .accordion-title--active .tlt::after {
  display: none;
}
.page-about .core-row .accordion-is .accordion-content {
  padding: 32px 0 64px;
  display: none;
}
@media (max-width: 768px) {
  .page-about .core-row .accordion-is .accordion-content {
    padding: 16px 16px 32px;
  }
}
.page-about .core-row .accordion-is .accordion-content .core-info .core-text {
  color: #383C46;
  font-size: 20px;
}
@media (max-width: 1280px) {
  .page-about .core-row .accordion-is .accordion-content .core-info .core-text {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .page-about .core-row .accordion-is .accordion-content .core-info .core-text {
    font-size: 14px;
  }
}
.page-about .awards {
  background: #fff;
  padding: 96px 0;
}
@media (max-width: 1280px) {
  .page-about .awards {
    padding: 64px 0;
  }
}
@media (max-width: 768px) {
  .page-about .awards {
    padding: 40px 0;
  }
}
.page-about .awards .title-awards .tlt {
  font-size: 41px;
  font-weight: 600;
  color: #2F333E;
}
@media (max-width: 1280px) {
  .page-about .awards .title-awards .tlt {
    font-size: 29px;
  }
}
@media (max-width: 768px) {
  .page-about .awards .title-awards .tlt {
    font-size: 23px;
  }
}
.page-about .awards .awards-logo {
  padding-top: 40px;
}
@media (max-width: 768px) {
  .page-about .awards .awards-logo {
    padding-top: 8px;
  }
}
.page-about .awards .awards-logo ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-left: -16px;
}
.page-about .awards .awards-logo ul li {
  padding: 24px 0 0 16px;
  width: 190px;
}
@media (max-width: 768px) {
  .page-about .awards .awards-logo ul li {
    width: 33.333333%;
  }
}
@media (max-width: 480px) {
  .page-about .awards .awards-logo ul li {
    width: 50%;
  }
}
.page-about .awards .awards-logo ul li:first-of-type {
  width: 100%;
}
.page-about .featured {
  padding: 0;
}
.page-about .featured .featured-line {
  border-top: 1px solid #DCE3F5;
  border-bottom: 1px solid #DCE3F5;
  padding-bottom: 56px;
}
@media (max-width: 1280px) {
  .page-about .featured .featured-line {
    padding-bottom: 42px;
  }
}
@media (max-width: 768px) {
  .page-about .featured .featured-line {
    padding-bottom: 16px;
  }
}
.page-about .our-news {
  padding-bottom: 96px;
}
@media (max-width: 1280px) {
  .page-about .our-news {
    padding-bottom: 48px;
  }
}
.page-about .news {
  padding-bottom: 24px;
}
@media (max-width: 1280px) {
  .page-about .news {
    padding-bottom: 8px;
  }
}
.page-about .news ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-left: -48px;
}
@media (max-width: 1280px) {
  .page-about .news ul {
    margin-left: -24px;
  }
}
.page-about .news ul li {
  width: 33.333333%;
  padding-left: 48px;
  margin-top: 64px;
}
@media (max-width: 1280px) {
  .page-about .news ul li {
    padding-left: 24px;
    margin-top: 48px;
  }
}
@media (max-width: 900px) {
  .page-about .news ul li {
    width: 50%;
  }
}
@media (max-width: 480px) {
  .page-about .news ul li {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .page-about .news .news-info {
    background: #fff;
  }
}
.page-about .news .news-info .news-content {
  padding-top: 24px;
}
@media (max-width: 480px) {
  .page-about .news .news-info .news-content {
    padding: 24px 16px;
  }
}
.page-about .news .news-info .news-title {
  margin-top: 24px;
  font-size: 20px;
  font-weight: 500;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 1280px) {
  .page-about .news .news-info .news-title {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .page-about .news .news-info .news-title {
    margin-top: 16px;
    font-size: 16px;
  }
}
.page-about .news .news-info .news-title a {
  color: #2F333E;
}
.page-about .our-team {
  padding-bottom: 96px;
}
@media (max-width: 768px) {
  .page-about .our-team {
    padding-bottom: 32px;
  }
}
.page-about .our-team .our-team-title {
  font-size: 36px;
  font-weight: 600;
}
@media (max-width: 1280px) {
  .page-about .our-team .our-team-title {
    font-size: 29px;
  }
}
@media (max-width: 768px) {
  .page-about .our-team .our-team-title {
    font-size: 20px;
  }
}
.page-about .our-team .our-team-list .our-tlt {
  margin-top: 48px;
  padding-top: 24px;
  border-top: 1px solid #C7C8CB;
  font-size: 36px;
}
@media (max-width: 1280px) {
  .page-about .our-team .our-team-list .our-tlt {
    font-size: 29px;
  }
}
@media (max-width: 768px) {
  .page-about .our-team .our-team-list .our-tlt {
    font-size: 18px;
    margin-top: 24px;
    padding-top: 8px;
  }
}
.page-about .our-team .our-team-list ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-left: -16px;
  padding-top: 32px;
}
@media (max-width: 1280px) {
  .page-about .our-team .our-team-list ul {
    padding-top: 16px;
  }
}
@media (max-width: 768px) {
  .page-about .our-team .our-team-list ul {
    padding-top: 0;
  }
}
.page-about .our-team .our-team-list ul li {
  width: calc(33.333333% - 16px);
  margin: 16px 0 0 16px;
  background: #fff;
}
@media (max-width: 768px) {
  .page-about .our-team .our-team-list ul li {
    width: calc(50% - 16px);
  }
}
@media (max-width: 480px) {
  .page-about .our-team .our-team-list ul li {
    width: calc(100% - 16px);
  }
}
.page-about .our-team .our-team-list ul li .our-team__content {
  padding: 24px 16px;
}
@media (max-width: 768px) {
  .page-about .our-team .our-team-list ul li .our-team__content {
    padding: 16px;
  }
}
.page-about .our-team .our-team-list ul li .our-team__content .our-name {
  font-size: 20px;
  font-weight: 500;
}
@media (max-width: 1280px) {
  .page-about .our-team .our-team-list ul li .our-team__content .our-name {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .page-about .our-team .our-team-list ul li .our-team__content .our-name {
    font-size: 16px;
  }
}
.page-about .our-team .our-team-list ul li .our-team__content .our-position {
  padding-top: 4px;
  color: #6C6F77;
  font-size: 16px;
}
@media (max-width: 1280px) {
  .page-about .our-team .our-team-list ul li .our-team__content .our-position {
    font-size: 14px;
  }
}
@media (max-width: 768px) {
  .page-about .our-team .our-team-list ul li .our-team__content .our-position {
    font-size: 13px;
  }
}

/* -- page-testing -- */
.page-testing .unleash {
  padding: 64px 0;
  background: url(../../images/data/bg-unleash.jpg) no-repeat center;
  background-size: cover;
}
@media (max-width: 1280px) {
  .page-testing .unleash {
    padding: 56px 0 48px;
  }
}
@media (max-width: 768px) {
  .page-testing .unleash {
    padding: 32px 0;
    background-position: 40% 0;
  }
}
.page-testing .unleash .unleash-info {
  max-width: 800px;
}
.page-testing .unleash .unleash-info .unleash-text {
  font-size: 36px;
  font-weight: 600;
  color: #fff;
  padding-bottom: 64px;
}
@media (max-width: 1280px) {
  .page-testing .unleash .unleash-info .unleash-text {
    font-size: 23px;
    padding-bottom: 32px;
  }
}
@media (max-width: 768px) {
  .page-testing .unleash .unleash-info .unleash-text {
    font-size: 18px;
    padding-bottom: 16px;
  }
}
.page-testing .ourServices {
  padding: 128px 0;
}
@media (max-width: 1280px) {
  .page-testing .ourServices {
    padding: 64px 0;
  }
}
.page-testing .ourServices .ourServices-wrap .ourServices-label {
  color: #6C6F77;
  font-size: 20px;
  padding-bottom: 40px;
  border-bottom: 1px solid #C3C4D2;
}
@media (max-width: 768px) {
  .page-testing .ourServices .ourServices-wrap .ourServices-label {
    font-size: 14px;
  }
}
.page-testing .ourServices .ourServices-wrap .ourServices-info {
  padding-top: 64px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
@media (max-width: 1280px) {
  .page-testing .ourServices .ourServices-wrap .ourServices-info {
    padding-top: 32px;
  }
}
.page-testing .ourServices .ourServices-wrap .ourServices-info .ourServices-title {
  width: calc(100% - 636px);
  padding-right: 10px;
  font-size: 36px;
  font-weight: 600;
}
@media (max-width: 1280px) {
  .page-testing .ourServices .ourServices-wrap .ourServices-info .ourServices-title {
    font-size: 29px;
    width: calc(100% - 496px);
  }
}
@media (max-width: 900px) {
  .page-testing .ourServices .ourServices-wrap .ourServices-info .ourServices-title {
    width: 50%;
  }
}
@media (max-width: 768px) {
  .page-testing .ourServices .ourServices-wrap .ourServices-info .ourServices-title {
    width: 100%;
    font-size: 20px;
  }
}
.page-testing .ourServices .ourServices-wrap .ourServices-info .ourServices-content {
  width: 636px;
}
@media (max-width: 1280px) {
  .page-testing .ourServices .ourServices-wrap .ourServices-info .ourServices-content {
    width: 496px;
  }
}
@media (max-width: 900px) {
  .page-testing .ourServices .ourServices-wrap .ourServices-info .ourServices-content {
    width: 50%;
  }
}
@media (max-width: 768px) {
  .page-testing .ourServices .ourServices-wrap .ourServices-info .ourServices-content {
    width: 100%;
    padding-top: 32px;
  }
}
.page-testing .ourServices .ourServices-wrap .ourServices-info .ourServices-content .ourServices-text {
  color: #6C6F77;
  font-size: 20px;
  line-height: 170%;
}
@media (max-width: 1280px) {
  .page-testing .ourServices .ourServices-wrap .ourServices-info .ourServices-content .ourServices-text {
    font-size: 18px;
    line-height: 120%;
  }
}
@media (max-width: 768px) {
  .page-testing .ourServices .ourServices-wrap .ourServices-info .ourServices-content .ourServices-text {
    font-size: 16px;
  }
}
.page-testing .our {
  background: #2F333E;
  padding-bottom: 96px;
}
@media (max-width: 1280px) {
  .page-testing .our {
    padding-bottom: 64px;
  }
}
.page-testing .our-box {
  min-height: 465px;
  padding-top: 64px;
  position: relative;
}
@media (max-width: 1280px) {
  .page-testing .our-box {
    min-height: 332px;
    padding-top: 48px;
  }
}
@media (max-width: 768px) {
  .page-testing .our-box {
    min-height: 360px;
    padding-top: 32px;
  }
}
@media (max-width: 480px) {
  .page-testing .our-box {
    min-height: 638px;
  }
}
.page-testing .our-box::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 465px;
  background: url(../../images/data/bg-our-capacities-pc.jpg) no-repeat center;
  background-size: cover;
}
@media (max-width: 1280px) {
  .page-testing .our-box::after {
    height: 332px;
  }
}
@media (max-width: 768px) {
  .page-testing .our-box::after {
    height: 360px;
  }
}
@media (max-width: 480px) {
  .page-testing .our-box::after {
    background: url(../../images/data/bg-our-capacities-sp.jpg) no-repeat center;
    background-size: cover;
    height: 638px;
  }
}
.page-testing .our-box .wrap {
  position: relative;
  z-index: 1;
}
.page-testing .title-testing {
  position: relative;
}
.page-testing .title-testing .tlt-text {
  font-size: 20px;
  color: #C7C8CB;
  padding-bottom: 48px;
}
@media (max-width: 1280px) {
  .page-testing .title-testing .tlt-text {
    padding-bottom: 32px;
  }
}
@media (max-width: 768px) {
  .page-testing .title-testing .tlt-text {
    font-size: 14px;
  }
}
.page-testing .title-testing .tlt-testing {
  font-size: 36px;
  font-weight: 600;
  color: #fff;
}
@media (max-width: 1280px) {
  .page-testing .title-testing .tlt-testing {
    font-size: 29px;
  }
}
@media (max-width: 768px) {
  .page-testing .title-testing .tlt-testing {
    font-size: 26px;
  }
}
.page-testing .our-tabs {
  padding-top: 80px;
}
@media (max-width: 1280px) {
  .page-testing .our-tabs {
    padding-top: 48px;
  }
}
@media (max-width: 768px) {
  .page-testing .our-tabs {
    padding-top: 24px;
  }
}
.page-testing .our-tabs .navTabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.page-testing .our-tabs .navTabs li {
  padding-right: 24px;
}
@media (max-width: 768px) {
  .page-testing .our-tabs .navTabs li {
    padding-right: 8px;
    padding-top: 8px;
  }
}
.page-testing .our-tabs .navTabs li:last-child {
  padding-right: 0;
}
.page-testing .our-tabs .navTabs li a {
  border-radius: 100px;
  border: 1px solid #C3C4D2;
  background: rgba(255, 255, 255, 0.1);
  padding: 12px 24px;
  display: block;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
}
@media (max-width: 1280px) {
  .page-testing .our-tabs .navTabs li a {
    padding: 8px 24px;
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .page-testing .our-tabs .navTabs li a {
    padding: 8px 16px;
  }
}
.page-testing .our-tabs .navTabs li a.active {
  background: #FFF;
  color: #0E144C;
}
@media (max-width: 768px) {
  .page-testing .capacities-list {
    padding-top: 16px;
  }
}
.page-testing .capacities-list--col2 ul li {
  width: 50%;
}
@media (max-width: 480px) {
  .page-testing .capacities-list--col2 ul li {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .page-testing .capacities-list--col3 ul:last-child {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.page-testing .capacities-list--col3 ul li {
  width: 33.333333%;
}
@media (max-width: 768px) {
  .page-testing .capacities-list--col3 ul li {
    width: 50%;
  }
}
@media (max-width: 480px) {
  .page-testing .capacities-list--col3 ul li {
    width: 100%;
  }
}
.page-testing .capacities-list ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-left: -16px;
}
@media (max-width: 1280px) {
  .page-testing .capacities-list ul {
    margin-left: -8px;
  }
}
.page-testing .capacities-list ul li {
  padding-left: 16px;
  padding-top: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 1280px) {
  .page-testing .capacities-list ul li {
    padding-left: 8px;
    padding-top: 32px;
  }
}
@media (max-width: 768px) {
  .page-testing .capacities-list ul li {
    padding-top: 8px;
  }
}
.page-testing .capacities-list .thumb-hover {
  background: #fff;
  padding: 16px 16px 32px;
  -webkit-box-shadow: 0px 4px 32px 0px rgba(65, 65, 65, 0.08);
          box-shadow: 0px 4px 32px 0px rgba(65, 65, 65, 0.08);
  border-radius: 6px;
  display: block;
  width: 100%;
}
@media (max-width: 1280px) {
  .page-testing .capacities-list .thumb-hover {
    padding: 16px 8px;
  }
}
@media (max-width: 768px) {
  .page-testing .capacities-list .thumb-hover {
    padding: 8px 8px 16px;
  }
}
.page-testing .capacities-list .capacities-thumb {
  border-radius: 6px;
}
.page-testing .capacities-list .capacities-content {
  padding-top: 32px;
}
@media (max-width: 1280px) {
  .page-testing .capacities-list .capacities-content {
    padding-top: 16px;
  }
}
.page-testing .capacities-list .capacities-content .capacities-title {
  font-size: 23px;
  font-weight: 600;
  color: #2F333E;
}
@media (max-width: 1280px) {
  .page-testing .capacities-list .capacities-content .capacities-title {
    font-size: 18px;
    font-weight: 500;
  }
}
@media (max-width: 768px) {
  .page-testing .capacities-list .capacities-content .capacities-title {
    font-size: 16px;
  }
}
.page-testing .capacities-list .capacities-content .capacities-text {
  padding-top: 8px;
  font-size: 20px;
  color: #2F333E;
}
@media (max-width: 1280px) {
  .page-testing .capacities-list .capacities-content .capacities-text {
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .page-testing .capacities-list .capacities-content .capacities-text {
    font-size: 14px;
  }
}
.page-testing .procedure {
  padding-top: 88px;
}
@media (max-width: 1280px) {
  .page-testing .procedure {
    padding-top: 64px;
  }
}
.page-testing .procedure .procedure-list {
  padding-top: 16px;
}
.page-testing .procedure .procedure-list ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-left: -32px;
}
@media (max-width: 1280px) {
  .page-testing .procedure .procedure-list ul {
    margin-left: -16px;
  }
}
.page-testing .procedure .procedure-list ul li {
  width: 33.333333%;
  padding: 32px 0 0 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
@media (max-width: 1280px) {
  .page-testing .procedure .procedure-list ul li {
    padding: 16px 0 0 16px;
  }
}
@media (max-width: 768px) {
  .page-testing .procedure .procedure-list ul li {
    width: 50%;
  }
}
@media (max-width: 480px) {
  .page-testing .procedure .procedure-list ul li {
    width: 100%;
  }
}
.page-testing .procedure .procedure-list .procedure-info {
  width: 100%;
  background: #fff;
  padding: 32px;
  border-radius: 4px;
}
@media (max-width: 1280px) {
  .page-testing .procedure .procedure-list .procedure-info {
    padding: 24px;
  }
}
@media (max-width: 480px) {
  .page-testing .procedure .procedure-list .procedure-info {
    padding: 16px;
    border-radius: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
.page-testing .procedure .procedure-list .procedure-info .item {
  border-radius: 8px;
  background: rgba(2, 70, 198, 0.06);
  width: 48px;
  height: 48px;
  display: block;
  padding: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 1280px) {
  .page-testing .procedure .procedure-list .procedure-info .item {
    width: 40px;
    height: 40px;
  }
}
@media (max-width: 480px) {
  .page-testing .procedure .procedure-list .procedure-info .item {
    width: 48px;
    height: 48px;
  }
}
.page-testing .procedure .procedure-list .procedure-info .procedure-title {
  padding-top: 64px;
  font-size: 26px;
  font-weight: 600;
  color: #2F333E;
}
@media (max-width: 1280px) {
  .page-testing .procedure .procedure-list .procedure-info .procedure-title {
    padding-top: 32px;
    font-size: 18px;
    font-weight: 500;
  }
}
@media (max-width: 480px) {
  .page-testing .procedure .procedure-list .procedure-info .procedure-title {
    width: calc(100% - 48px);
    padding-left: 32px;
    padding-top: 0;
    font-size: 20px;
    font-weight: 600;
  }
}
.page-testing .stuides {
  padding-top: 64px;
}
@media (max-width: 1280px) {
  .page-testing .stuides {
    padding-top: 48px;
  }
}
@media (max-width: 768px) {
  .page-testing .stuides {
    padding: 32px 0 64px;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(#FFF));
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFF 100%);
  }
}
.page-testing .proud {
  padding: 80px 0 96px;
}
@media (max-width: 1280px) {
  .page-testing .proud {
    padding: 80px 0 64px;
  }
}
@media (max-width: 768px) {
  .page-testing .proud {
    padding: 80px 0 40px;
  }
}
.page-testing .proud.proud--changePad {
  padding-top: 0;
}
.page-testing .ensure {
  background: url(../../images/data/bg-complete.jpg) no-repeat bottom right;
  background-size: cover;
  padding: 64px 0;
}
@media (max-width: 768px) {
  .page-testing .ensure {
    padding: 32px 0;
  }
}
.page-testing .ensure .ensure-info {
  text-align: center;
  max-width: 860px;
  margin: 0 auto;
}
.page-testing .ensure .ensure-info .ensure-text {
  font-size: 41px;
  font-weight: 600;
  color: #fff;
  padding-bottom: 48px;
}
@media (max-width: 1280px) {
  .page-testing .ensure .ensure-info .ensure-text {
    font-size: 29px;
  }
}
@media (max-width: 768px) {
  .page-testing .ensure .ensure-info .ensure-text {
    font-size: 20px;
    padding-bottom: 16px;
  }
}
.page-testing .ensure .ensure-info .btn {
  margin: 0 auto;
}
.page-testing .whyUs {
  background: #fff;
  /*.wrap-mw1920 {
  	max-width: 1920px;
  	margin: 0 auto;
  }*/
}
@media (max-width: 768px) {
  .page-testing .whyUs .wrap-content {
    padding-right: 0;
  }
}
.page-testing .choose-us {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.page-testing .choose-us .choose-thumb {
  width: 58.2%;
  height: 768px;
  background-size: cover;
  background-position: top right;
  background-repeat: no-repeat;
}
@media (max-width: 768px) {
  .page-testing .choose-us .choose-thumb {
    display: none;
  }
}
.page-testing .choose-us .choose-thumb img {
  width: 100%;
}
.page-testing .choose-us .choose-content {
  width: 41.8%;
  padding: 82px 0 16px 6%;
}
@media (max-width: 1280px) {
  .page-testing .choose-us .choose-content {
    padding: 48px 0 16px 4%;
  }
}
@media (max-width: 768px) {
  .page-testing .choose-us .choose-content {
    padding: 48px 16px;
  }
}
@media (max-width: 768px) {
  .page-testing .choose-us .choose-content {
    width: 100%;
  }
}
.page-testing .choose-us .choose-content .choose-title .tlt {
  font-size: 36px;
  font-weight: 600;
}
@media (max-width: 1280px) {
  .page-testing .choose-us .choose-content .choose-title .tlt {
    font-size: 29px;
  }
}
@media (max-width: 768px) {
  .page-testing .choose-us .choose-content .choose-title .tlt {
    font-size: 20px;
  }
}
.page-testing .choose-us .choose-content .choose-thumb {
  width: 100%;
  height: initial;
  padding-top: 32px;
  display: none;
}
@media (max-width: 768px) {
  .page-testing .choose-us .choose-content .choose-thumb {
    display: block;
  }
}
.page-testing .choose-wrap {
  padding-top: 40px;
}
@media (max-width: 768px) {
  .page-testing .choose-wrap {
    padding-top: 32px;
  }
}
.page-testing .choose-info {
  border-bottom: 1px solid #C3C4D2;
}
.page-testing .choose-info:last-child {
  border-bottom: none;
}
.page-testing .choose-info .choose-header {
  padding: 24px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}
@media (max-width: 1280px) {
  .page-testing .choose-info .choose-header {
    padding: 16px 0;
  }
}
.page-testing .choose-info .choose-header[aria-expanded=true] .choose-number {
  color: #0246C6;
  font-size: 42px;
}
@media (max-width: 900px) {
  .page-testing .choose-info .choose-header[aria-expanded=true] .choose-number {
    font-size: 32px;
  }
}
.page-testing .choose-info .choose-header[aria-expanded=true] .choose-label {
  font-size: 23px;
  font-weight: 600;
  color: #2F333E;
  line-height: 120%;
}
@media (max-width: 1280px) {
  .page-testing .choose-info .choose-header[aria-expanded=true] .choose-label {
    font-size: 20px;
  }
}
@media (max-width: 900px) {
  .page-testing .choose-info .choose-header[aria-expanded=true] .choose-label {
    font-size: 18px;
  }
}
.page-testing .choose-info .choose-header .choose-number {
  width: 94px;
  padding-right: 10px;
  font-size: 24px;
  color: #383C46;
  -webkit-transition: 400ms all;
  transition: 400ms all;
}
@media (max-width: 900px) {
  .page-testing .choose-info .choose-header .choose-number {
    width: 60px;
  }
}
.page-testing .choose-info .choose-header .choose-label {
  width: calc(100% - 94px);
  font-size: 20px;
  font-weight: 500;
  color: #383C46;
  -webkit-transition: 400ms all;
  transition: 400ms all;
}
@media (max-width: 1280px) {
  .page-testing .choose-info .choose-header .choose-label {
    font-size: 18px;
  }
}
@media (max-width: 900px) {
  .page-testing .choose-info .choose-header .choose-label {
    width: calc(100% - 60px);
    font-weight: 600;
  }
}
.page-testing .choose-info .choose-main .choose-team {
  padding: 0 0 24px 94px;
}
@media (max-width: 1280px) {
  .page-testing .choose-info .choose-main .choose-team {
    padding: 0 0 16px 94px;
  }
}
@media (max-width: 900px) {
  .page-testing .choose-info .choose-main .choose-team {
    padding: 16px 0 16px 0;
  }
}
.page-testing .choose-info .choose-main .choose-team .choose-team__text {
  color: #383C46;
  font-size: 16px;
}
@media (max-width: 900px) {
  .page-testing .choose-info .choose-main .choose-team .choose-team__text {
    font-size: 14px;
  }
}
.page-testing .choose-info .choose-main .choose-team .choose-team__ul {
  padding-top: 32px;
}
@media (max-width: 900px) {
  .page-testing .choose-info .choose-main .choose-team .choose-team__ul {
    padding-top: 24px;
  }
}
.page-testing .choose-info .choose-main .choose-team .choose-team__ul li {
  padding: 12px 16px;
  margin-bottom: 8px;
  border-radius: 8px;
  background: rgba(2, 70, 198, 0.06);
  color: #0246C6;
  font-size: 18px;
}
@media (max-width: 1280px) {
  .page-testing .choose-info .choose-main .choose-team .choose-team__ul li {
    font-size: 16px;
  }
}
@media (max-width: 900px) {
  .page-testing .choose-info .choose-main .choose-team .choose-team__ul li {
    padding: 8px 16px;
  }
}
.page-testing .choose-info .choose-main .choose-team .choose-team__ul li:last-child {
  margin-bottom: 0;
}
.page-testing .banner-service {
  background: url(../../images/data/header-service.jpg) no-repeat center;
  background-size: cover;
  padding: 136px 0 104px;
}
@media (max-width: 1280px) {
  .page-testing .banner-service {
    padding: 96px 0 64px;
  }
}
@media (max-width: 768px) {
  .page-testing .banner-service {
    padding: 40px 0;
  }
}
.page-testing .banner-service .banner-title {
  font-size: 48px;
  font-weight: 600;
  color: #fff;
}
@media (max-width: 1280px) {
  .page-testing .banner-service .banner-title {
    font-size: 41px;
  }
}
@media (max-width: 768px) {
  .page-testing .banner-service .banner-title {
    font-size: 23px;
    max-width: 300px;
  }
}
.page-testing .banner-service .btn {
  margin-top: 64px;
}
@media (max-width: 1280px) {
  .page-testing .banner-service .btn {
    margin-top: 32px;
  }
}
.page-testing .navLink {
  padding-top: 16px;
  border-bottom: 1px solid #6C6F77;
  background: #FFF;
  position: sticky;
  top: -1px;
  z-index: 8;
  -webkit-transition: 600ms all;
  transition: 600ms all;
}
@media (max-width: 1280px) {
  .page-testing .navLink {
    padding-top: 8px;
  }
}
@media (max-width: 768px) {
  .page-testing .navLink {
    display: none;
  }
}
.page-testing .navLink.navLink-fixed {
  top: 92px;
}
@media (max-width: 1280px) {
  .page-testing .navLink.navLink-fixed {
    top: 55px;
  }
}
@media (max-width: 1180px) {
  .page-testing .navLink {
    top: 55px;
  }
}
.page-testing .navLink ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.page-testing .navLink ul li {
  width: 50%;
}
.page-testing .navLink ul li a {
  padding: 16px 8px;
  display: block;
  text-align: center;
  font-size: 23px;
  font-weight: 600;
  color: #2F333E;
  border: 1px solid #fff;
  border-bottom: none;
  border-radius: 4px 4px 0px 0px;
  position: relative;
}
@media (max-width: 1280px) {
  .page-testing .navLink ul li a {
    padding: 12px 8px;
    font-size: 18px;
  }
}
@media (max-width: 900px) {
  .page-testing .navLink ul li a {
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .page-testing .navLink ul li a {
    font-size: 12px;
    padding: 4px 16px;
  }
}
.page-testing .navLink ul li a.active {
  color: #0246C6;
  background: #F5F6F8;
  border: 1px solid #0246C6;
  border-bottom: none;
}
.page-testing .navLink ul li a.active::after {
  background: #F5F6F8;
}
@media (min-width: 1180px) {
  .page-testing .navLink ul li a:hover {
    color: #0246C6;
    background: #F5F6F8;
    border: 1px solid #0246C6;
    border-bottom: none;
  }
}
.page-testing .navLink ul li a::after {
  position: absolute;
  bottom: -1px;
  left: 0;
  content: "";
  width: 100%;
  height: 1px;
}
.page-testing .based {
  padding-top: 96px;
}
@media (max-width: 1180px) {
  .page-testing .based {
    padding-top: 32px;
  }
}
.page-testing .based .based-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-bottom: 32px;
  border-bottom: 1px solid #C3C4D2;
}
.page-testing .based .based-info .based-title {
  width: 50%;
  padding-right: 8px;
  /*@media (max-width: $viewport_tablet) {
  	width: 40%;
  }*/
}
@media (max-width: 768px) {
  .page-testing .based .based-info .based-title {
    width: 100%;
    padding: 0;
  }
}
.page-testing .based .based-info .based-title .based-tlt {
  font-size: 32px;
  font-weight: 600;
}
@media (max-width: 1280px) {
  .page-testing .based .based-info .based-title .based-tlt {
    font-size: 23px;
  }
}
@media (max-width: 768px) {
  .page-testing .based .based-info .based-title .based-tlt {
    font-size: 20px;
  }
}
.page-testing .based .based-info .based-content {
  width: 50%;
  padding-left: 8px;
  /*@media (max-width: $viewport_tablet) {
  	width: 60%;
  }*/
}
@media (max-width: 768px) {
  .page-testing .based .based-info .based-content {
    width: 100%;
    padding: 24px 0 0;
  }
}
.page-testing .based .based-info .based-content .based-tlt {
  color: #383C46;
  font-size: 23px;
  font-weight: 600;
}
@media (max-width: 1280px) {
  .page-testing .based .based-info .based-content .based-tlt {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .page-testing .based .based-info .based-content .based-tlt {
    font-size: 16px;
  }
}
.page-testing .based .based-info .based-content .based-text {
  padding-top: 16px;
  font-size: 16px;
  color: #5A5D66;
}
@media (max-width: 1280px) {
  .page-testing .based .based-info .based-content .based-text {
    padding-top: 8px;
  }
}
.page-testing .based-one {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-top: 32px;
}
.page-testing .based-one .based-one__thumb {
  width: 50%;
  padding-right: 8px;
  /*@media (max-width: $viewport_tablet) {
  	width: 40%;
  }*/
}
@media (max-width: 768px) {
  .page-testing .based-one .based-one__thumb {
    width: 100%;
    padding-right: 0;
    text-align: center;
  }
}
.page-testing .based-one .based-one__content {
  width: 50%;
  padding-left: 8px;
  /*@media (max-width: $viewport_tablet) {
  	width: 60%;
  }*/
}
@media (max-width: 768px) {
  .page-testing .based-one .based-one__content {
    width: 100%;
    padding: 24px 0 0 0;
  }
}
.page-testing .based-one .based-one__content ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 16px;
  padding: 24px 16px;
  border-radius: 4px;
  background: #FFF;
}
.page-testing .based-one .based-one__content ul li:last-child {
  margin-bottom: 0;
}
.page-testing .based-one .based-one__content ul li .based-item {
  width: 56px;
}
.page-testing .based-one .based-one__content ul li .based-mask {
  width: calc(100% - 56px);
  padding-left: 32px;
}
@media (max-width: 1280px) {
  .page-testing .based-one .based-one__content ul li .based-mask {
    padding-left: 24px;
  }
}
.page-testing .based-one .based-one__content ul li .based-mask .based-mask__tlt {
  font-size: 23px;
  font-weight: 600;
}
@media (max-width: 1280px) {
  .page-testing .based-one .based-one__content ul li .based-mask .based-mask__tlt {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .page-testing .based-one .based-one__content ul li .based-mask .based-mask__tlt {
    font-size: 18px;
  }
}
.page-testing .based-one .based-one__content ul li .based-mask .based-mask__text {
  margin-top: 16px;
  color: #5A5D66;
  font-size: 16px;
}
.page-testing .labor {
  padding: 78px 0 0;
}
@media (max-width: 768px) {
  .page-testing .labor {
    padding-top: 32px;
  }
}
.page-testing .labor .certification {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(#FFF));
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFF 100%);
  padding: 64px 0 64px;
}
@media (max-width: 768px) {
  .page-testing .labor .certification {
    padding: 32px 0 64px;
  }
}
.page-testing .labor .certification .title {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.page-testing .labor .offer {
  background: #fff;
  padding: 64px 0;
}
@media (max-width: 768px) {
  .page-testing .labor .offer {
    padding: 32px 0;
  }
}
.page-testing .labor .offer .title {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.page-testing .labor .offer .offer-box {
  margin-top: 32px;
  background: #F5F6F8;
  border-radius: 8px;
  padding: 24px;
}
@media (max-width: 1280px) {
  .page-testing .labor .offer .offer-box {
    margin-top: 24px;
  }
}
@media (max-width: 768px) {
  .page-testing .labor .offer .offer-box {
    padding: 16px 8px;
  }
}
.page-testing .labor .offer .offer-tabs {
  margin-top: -8px;
}
.page-testing .labor .offer .offer-tabs ul {
  margin-left: -8px;
}
.page-testing .labor .offer .offer-tabs ul li {
  width: 25%;
  padding: 8px 0 0 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 1280px) {
  .page-testing .labor .offer .offer-tabs ul li {
    width: 50%;
  }
}
@media (max-width: 768px) {
  .page-testing .labor .offer .offer-tabs ul li {
    width: 100%;
  }
}
.page-testing .labor .offer .offer-tabs ul li a {
  background: #fff;
  border-radius: 8px;
  padding: 6px 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  width: 100%;
  min-height: 52px;
  font-size: 16px;
  font-weight: 500;
  color: #2F333E;
  cursor: pointer;
  border: none;
}
.page-testing .labor .offer .offer-tabs ul li a:hover {
  background: #E3EAF6;
}
.page-testing .labor .offer .offer-tabs ul li a.active {
  background: #0246C6;
  color: #fff;
}
.page-testing .labor .offer .offer-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-left: -8px;
}
.page-testing .labor .offer .offer-content .offer-col {
  width: calc(50% - 8px);
  margin: 24px 0 0 8px;
}
.page-testing .labor .offer .offer-content .offer-col:nth-child(1) {
  background: #fff;
  border-radius: 4px;
  padding: 32px 3.5%;
}
@media (max-width: 1280px) {
  .page-testing .labor .offer .offer-content .offer-col:nth-child(1) {
    padding: 16px 24px;
  }
}
@media (max-width: 768px) {
  .page-testing .labor .offer .offer-content .offer-col:nth-child(1) {
    margin-top: 16px;
    padding: 12px 16px;
  }
}
@media (max-width: 768px) {
  .page-testing .labor .offer .offer-content .offer-col {
    width: calc(100% - 8px);
    margin-top: 8px;
  }
}
.page-testing .labor .offer .offer-content .offer-list ul li {
  padding: 16px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #F1F2F2;
}
@media (max-width: 768px) {
  .page-testing .labor .offer .offer-content .offer-list ul li {
    padding: 12px 0;
  }
}
.page-testing .labor .offer .offer-content .offer-list ul li:last-child {
  border-bottom: none;
}
.page-testing .labor .offer .offer-content .offer-list ul li .offer-item {
  width: 20px;
  height: 20px;
  min-width: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-right: 24px;
}
@media (max-width: 1280px) {
  .page-testing .labor .offer .offer-content .offer-list ul li .offer-item {
    width: 16px;
    height: 16px;
    min-width: 16px;
    margin-right: 16px;
  }
}
.page-testing .labor .offer .offer-content .offer-list ul li .offer-text {
  color: #2F333E;
  font-size: 18px;
}
@media (max-width: 1280px) {
  .page-testing .labor .offer .offer-content .offer-list ul li .offer-text {
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .page-testing .labor .offer .offer-content .offer-list ul li .offer-text {
    font-size: 14px;
  }
}
.page-testing .labor .offer .offer-content .slider-offer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -8px;
  overflow: hidden;
}
.page-testing .labor .offer .offer-content .slider-offer .slider-thumb {
  margin-left: 8px;
  width: 64px;
  min-width: 64px;
  border-radius: 8px;
  opacity: 35%;
  height: 386px;
  overflow: hidden;
  -webkit-transition: all 600ms;
  transition: all 600ms;
  cursor: pointer;
}
@media (max-width: 768px) {
  .page-testing .labor .offer .offer-content .slider-offer .slider-thumb {
    width: 39px;
    min-width: 39px;
    height: 240px;
  }
}
.page-testing .labor .offer .offer-content .slider-offer .slider-thumb.active {
  width: 100%;
  opacity: 1;
}
.page-testing .labor .offer .offer-content .slider-offer .slider-thumb:hover {
  width: 100%;
  opacity: 1;
}
.page-testing .labor .offer .offer-content .slider-offer .slider-thumb img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.page-testing .certification-dflex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.page-testing .certification-dflex .title {
  width: calc(100% - 441px);
  padding-right: 10px;
}
@media (max-width: 1280px) {
  .page-testing .certification-dflex .title {
    width: calc(100% - 314px);
  }
}
@media (max-width: 768px) {
  .page-testing .certification-dflex .title {
    width: 220px;
  }
}
@media (max-width: 480px) {
  .page-testing .certification-dflex .title {
    width: 100%;
    padding-right: 0;
  }
}
.page-testing .certification-dflex .certification-thumb {
  width: 441px;
}
@media (max-width: 1280px) {
  .page-testing .certification-dflex .certification-thumb {
    width: 314px;
  }
}
@media (max-width: 768px) {
  .page-testing .certification-dflex .certification-thumb {
    width: calc(100% - 220px);
    max-width: 314px;
  }
}
@media (max-width: 480px) {
  .page-testing .certification-dflex .certification-thumb {
    width: 100%;
    margin: 0 auto;
    padding-top: 32px;
  }
}
.page-testing .certification-dflex .certification-thumb img {
  width: 100%;
}
.page-testing .labor-service {
  background: url(../../images/data/bg-service-list.jpg) no-repeat top center;
  background-size: cover;
  padding: 112px 0 160px;
}
@media (max-width: 1280px) {
  .page-testing .labor-service {
    padding: 64px 0 100px;
  }
}
@media (max-width: 768px) {
  .page-testing .labor-service {
    padding: 32px 0 82px;
  }
}
.page-testing .labor-service .service-content .service-content__title {
  color: #FFF;
  font-size: 32px;
  font-weight: 600;
  line-height: 120%;
}
@media (max-width: 1280px) {
  .page-testing .labor-service .service-content .service-content__title {
    font-size: 23px;
  }
}
@media (max-width: 768px) {
  .page-testing .labor-service .service-content .service-content__title {
    font-size: 20px;
  }
}
.page-testing .labor-service .service-content .service-content__text {
  color: #F1F2F2;
  font-size: 20px;
  font-weight: 500;
  line-height: 140%;
  padding-top: 40px;
  max-width: 680px;
}
@media (max-width: 1280px) {
  .page-testing .labor-service .service-content .service-content__text {
    font-size: 18px;
    max-width: 460px;
  }
}
@media (max-width: 768px) {
  .page-testing .labor-service .service-content .service-content__text {
    font-size: 14px;
    max-width: 230px;
    padding-top: 24px;
  }
}
.page-testing .benefits {
  margin-top: -99px;
  position: relative;
  z-index: 1;
}
@media (max-width: 1280px) {
  .page-testing .benefits {
    margin-top: -70px;
  }
}
@media (max-width: 768px) {
  .page-testing .benefits {
    margin-top: -56px;
  }
}
.page-testing .benefits .benefits-info {
  border-radius: 6px;
  background: #FFF;
  -webkit-box-shadow: 0px 4px 35.3px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 4px 35.3px 0px rgba(0, 0, 0, 0.06);
  padding: 32px 48px 48px;
}
@media (max-width: 1280px) {
  .page-testing .benefits .benefits-info {
    padding: 16px 32px 24px;
  }
}
@media (max-width: 900px) {
  .page-testing .benefits .benefits-info {
    padding: 16px 16px 24px;
  }
}
.page-testing .benefits .benefits-info .benefits-title {
  padding-bottom: 32px;
  border-bottom: 4px solid #0246C6;
  color: #0246C6;
  font-size: 26px;
  font-weight: 600;
  line-height: 120%;
}
@media (max-width: 1280px) {
  .page-testing .benefits .benefits-info .benefits-title {
    padding-bottom: 24px;
    font-size: 23px;
    border-width: 2px;
  }
}
@media (max-width: 768px) {
  .page-testing .benefits .benefits-info .benefits-title {
    padding-bottom: 16px;
    font-size: 18px;
  }
}
.page-testing .benefits .benefits-info .benefits-ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  border-bottom: 1px solid #C7C8CB;
  padding: 24px 0;
}
@media (max-width: 1280px) {
  .page-testing .benefits .benefits-info .benefits-ul {
    padding: 16px 0;
  }
}
@media (max-width: 768px) {
  .page-testing .benefits .benefits-info .benefits-ul {
    border: none;
    padding: 16px 0 0;
  }
}
.page-testing .benefits .benefits-info .benefits-ul:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
@media (max-width: 768px) {
  .page-testing .benefits .benefits-info .benefits-ul:last-child {
    padding: 0;
  }
}
@media (max-width: 768px) {
  .page-testing .benefits .benefits-info .benefits-ul:last-child li:last-child {
    border-bottom: none;
  }
}
.page-testing .benefits .benefits-info .benefits-ul li {
  width: 33.333333%;
  border-right: 1px solid #C7C8CB;
  padding: 24px 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
@media (max-width: 1280px) {
  .page-testing .benefits .benefits-info .benefits-ul li {
    padding: 16px 24px;
  }
}
@media (max-width: 900px) {
  .page-testing .benefits .benefits-info .benefits-ul li {
    padding: 16px 8px;
  }
}
@media (max-width: 768px) {
  .page-testing .benefits .benefits-info .benefits-ul li {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #C7C8CB;
    padding: 10px 0;
  }
}
.page-testing .benefits .benefits-info .benefits-ul li:last-child {
  border-right: none;
}
.page-testing .benefits .benefits-info .benefits-ul li .benefits-item {
  width: 32px;
  height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 900px) {
  .page-testing .benefits .benefits-info .benefits-ul li .benefits-item {
    width: 24px;
    height: 24px;
    padding: 4px;
  }
}
.page-testing .benefits .benefits-info .benefits-ul li .benefits-text {
  width: calc(100% - 32px);
  padding-left: 16px;
  color: #383C46;
  font-size: 18px;
  font-weight: 600;
  line-height: 120%;
}
@media (max-width: 1280px) {
  .page-testing .benefits .benefits-info .benefits-ul li .benefits-text {
    font-size: 16px;
  }
}
@media (max-width: 900px) {
  .page-testing .benefits .benefits-info .benefits-ul li .benefits-text {
    width: calc(100% - 24px);
    font-size: 14px;
  }
}
.page-testing .labor-forms {
  padding: 64px 0 96px;
}
@media (max-width: 1280px) {
  .page-testing .labor-forms {
    padding: 56px 0 32px;
  }
}
@media (max-width: 768px) {
  .page-testing .labor-forms {
    padding: 48px 0 32px;
  }
}
.page-testing .labor-forms .title {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.page-testing .labor-forms .forms-wrap {
  padding-top: 48px;
}
@media (max-width: 1280px) {
  .page-testing .labor-forms .forms-wrap {
    padding-top: 32px;
  }
}
@media (max-width: 900px) {
  .page-testing .labor-forms .forms-wrap {
    padding-top: 0;
  }
}
.page-testing .labor-forms .forms-wrap > ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-left: -32px;
}
@media (max-width: 1280px) {
  .page-testing .labor-forms .forms-wrap > ul {
    margin-left: -24px;
  }
}
.page-testing .labor-forms .forms-wrap > ul > li {
  margin-left: 32px;
  width: calc(33.333333% - 32px);
  padding: 24px 16px 16px;
  border-radius: 6px;
  background: -webkit-gradient(linear, left top, left bottom, from(#FFF), to(rgba(255, 255, 255, 0)));
  background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0) 100%);
  -webkit-box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.08);
          box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.08);
}
@media (max-width: 1280px) {
  .page-testing .labor-forms .forms-wrap > ul > li {
    margin-left: 24px;
    width: calc(33.333333% - 24px);
  }
}
@media (max-width: 900px) {
  .page-testing .labor-forms .forms-wrap > ul > li {
    width: calc(50% - 24px);
    margin-top: 24px;
  }
}
@media (max-width: 768px) {
  .page-testing .labor-forms .forms-wrap > ul > li {
    width: calc(100% - 24px);
  }
}
@media (max-width: 900px) {
  .page-testing .labor-forms .forms-wrap > ul > li:last-child {
    width: 100%;
  }
}
.page-testing .labor-forms .forms-wrap .forms-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.page-testing .labor-forms .forms-wrap .forms-head .forms-head__number {
  opacity: 0.2;
  font-size: 64px;
  font-weight: 500;
  line-height: 120%;
  width: 100px;
}
@media (max-width: 1280px) {
  .page-testing .labor-forms .forms-wrap .forms-head .forms-head__number {
    font-size: 48px;
    width: 70px;
  }
}
@media (max-width: 900px) {
  .page-testing .labor-forms .forms-wrap .forms-head .forms-head__number {
    font-size: 40px;
    width: 60px;
  }
}
.page-testing .labor-forms .forms-wrap .forms-head .forms-head__text {
  padding-left: 16px;
  width: calc(100% - 100px);
  max-width: 282px;
  margin-left: auto;
  font-size: 20px;
  font-weight: 500;
  line-height: 120%;
}
@media (max-width: 1280px) {
  .page-testing .labor-forms .forms-wrap .forms-head .forms-head__text {
    width: calc(100% - 70px);
    font-size: 16px;
  }
}
@media (max-width: 900px) {
  .page-testing .labor-forms .forms-wrap .forms-head .forms-head__text {
    width: calc(100% - 60px);
    max-width: 100%;
  }
}
.page-testing .labor-forms .forms-wrap .forms-content {
  margin-top: 32px;
  border-radius: 4px;
  background: #2F78CD;
  overflow: hidden;
}
@media (max-width: 1280px) {
  .page-testing .labor-forms .forms-wrap .forms-content {
    margin-top: 16px;
  }
}
.page-testing .labor-forms .forms-wrap .forms-content .forms-partner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(36, 233, 255, 0.34)), to(rgba(75, 166, 221, 0.22)));
  background: linear-gradient(0deg, rgba(36, 233, 255, 0.34) 0%, rgba(75, 166, 221, 0.22) 100%);
  padding: 16px;
}
@media (max-width: 1280px) {
  .page-testing .labor-forms .forms-wrap .forms-content .forms-partner {
    padding: 8px 16px;
  }
}
.page-testing .labor-forms .forms-wrap .forms-content .forms-partner .forms-partner__title {
  width: calc(100% - 72px);
  padding-right: 10px;
  color: #FFF;
  font-size: 23px;
  font-weight: 600;
  line-height: 120%;
}
@media (max-width: 1280px) {
  .page-testing .labor-forms .forms-wrap .forms-content .forms-partner .forms-partner__title {
    font-size: 18px;
    width: calc(100% - 56px);
  }
}
@media (max-width: 900px) {
  .page-testing .labor-forms .forms-wrap .forms-content .forms-partner .forms-partner__title {
    font-size: 16px;
  }
}
.page-testing .labor-forms .forms-wrap .forms-content .forms-partner .forms-partner__flag {
  width: 72px;
  height: 40px;
  padding: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 4px;
  overflow: hidden;
}
@media (max-width: 1280px) {
  .page-testing .labor-forms .forms-wrap .forms-content .forms-partner .forms-partner__flag {
    width: 56px;
    height: 32px;
  }
}
.page-testing .labor-forms .forms-wrap .forms-content .forms-partner .forms-partner__flag.forms-partner__flag--white {
  background: #fff;
}
.page-testing .labor-forms .forms-wrap .forms-content .forms-partner .forms-partner__flag.forms-partner__flag--red {
  background: #F33;
}
.page-testing .labor-forms .forms-wrap .forms-content .forms-partner .forms-partner__flag img {
  max-height: 22px;
}
@media (max-width: 1280px) {
  .page-testing .labor-forms .forms-wrap .forms-content .forms-partner .forms-partner__flag img {
    max-height: 20px;
  }
}
.page-testing .labor-forms .forms-wrap .forms-content .forms-ul {
  padding: 0 16px 24px;
}
.page-testing .labor-forms .forms-wrap .forms-content .forms-ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 16px 0;
  border-bottom: 1px solid #fff;
}
@media (max-width: 900px) {
  .page-testing .labor-forms .forms-wrap .forms-content .forms-ul li {
    padding: 10px 0;
  }
}
.page-testing .labor-forms .forms-wrap .forms-content .forms-ul li:last-child {
  padding-bottom: 0;
  border-bottom: none;
}
.page-testing .labor-forms .forms-wrap .forms-content .forms-ul li .forms-thumb {
  width: 56px;
  height: 56px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
}
.page-testing .labor-forms .forms-wrap .forms-content .forms-ul li .forms-text {
  width: calc(100% - 56px);
  padding-left: 5%;
  color: #FFF;
  font-size: 20px;
  font-weight: 500;
  line-height: 120%;
}
@media (max-width: 1280px) {
  .page-testing .labor-forms .forms-wrap .forms-content .forms-ul li .forms-text {
    font-size: 16px;
  }
}
.page-testing .certification {
  padding: 64px 0;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(#FFF));
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFF 100%);
}
@media (max-width: 768px) {
  .page-testing .certification {
    padding: 32px 0 64px;
  }
}
@media (max-width: 768px) {
  .page-testing .certification .certification-info {
    padding-top: 16px;
  }
}
.page-testing .certification .certification-info ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-left: -64px;
}
@media (max-width: 1280px) {
  .page-testing .certification .certification-info ul {
    margin-left: -48px;
  }
}
.page-testing .certification .certification-info ul li {
  margin-top: 48px;
  padding-left: 64px;
  width: 33%;
}
@media (max-width: 1280px) {
  .page-testing .certification .certification-info ul li {
    margin-top: 32px;
    padding-left: 48px;
  }
}
@media (max-width: 768px) {
  .page-testing .certification .certification-info ul li {
    width: 100%;
    margin-top: 16px;
  }
}
.page-testing .certification .certification-info ul li img {
  width: 100%;
}

/******/
.robust {
  padding: 64px 0 96px;
}
@media (max-width: 1280px) {
  .robust {
    padding: 64px 0 32px;
  }
}
@media (max-width: 768px) {
  .robust {
    padding: 32px 0;
  }
}
.robust .robust-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-top: 1px solid #C7C8CB;
  margin-top: 42px;
  padding-top: 48px;
}
@media (max-width: 1280px) {
  .robust .robust-row {
    margin-top: 32px;
    padding-top: 32px;
  }
}
@media (max-width: 768px) {
  .robust .robust-row {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
.robust .robust-row .robust-col {
  width: 50%;
}
@media (max-width: 768px) {
  .robust .robust-row .robust-col {
    width: 100%;
  }
}
.robust .robust-row .robust-col:nth-child(1) {
  max-width: 632px;
  padding-right: 16px;
}
@media (max-width: 1280px) {
  .robust .robust-row .robust-col:nth-child(1) {
    max-width: 486px;
  }
}
@media (max-width: 768px) {
  .robust .robust-row .robust-col:nth-child(1) {
    max-width: 100%;
    padding-right: 0;
  }
}
.robust .robust-row .robust-col:nth-child(2) {
  max-width: 632px;
  padding-left: 16px;
  margin-left: auto;
}
@media (max-width: 1280px) {
  .robust .robust-row .robust-col:nth-child(2) {
    max-width: 610px;
  }
}
@media (max-width: 768px) {
  .robust .robust-row .robust-col:nth-child(2) {
    max-width: 100%;
    padding-left: 0;
    padding-top: 10px;
  }
}
.robust .robust-row .robust-thumb {
  border-radius: 4px;
  overflow: hidden;
}
.robust .robust-row .robust-info {
  border-bottom: 1px solid #C7C8CB;
  /*&:first-of-type {
  	.robust-header {
  		padding-top: 0;
  	}
  }*/
}
.robust .robust-row .robust-info .robust-header {
  padding: 40px 40px 40px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  cursor: pointer;
  position: relative;
}
@media (max-width: 1280px) {
  .robust .robust-row .robust-info .robust-header {
    padding: 24px 32px 24px 0;
  }
}
.robust .robust-row .robust-info .robust-header::after {
  position: absolute;
  top: calc(50% - 20px);
  right: 0;
  content: "";
  background: url(../../images/common/icon-arrow-robust.svg) no-repeat center;
  width: 40px;
  height: 40px;
  -webkit-transition: 400ms all;
  transition: 400ms all;
  opacity: 0.5;
}
@media (max-width: 1280px) {
  .robust .robust-row .robust-info .robust-header::after {
    background-size: 16px auto;
    width: 32px;
  }
}
.robust .robust-row .robust-info .robust-header[aria-expanded=true] .robust-number {
  color: #0246C6;
  font-size: 48px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -2.88px;
}
@media (max-width: 1280px) {
  .robust .robust-row .robust-info .robust-header[aria-expanded=true] .robust-number {
    font-size: 32px;
  }
}
.robust .robust-row .robust-info .robust-header[aria-expanded=true]::after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.robust .robust-row .robust-info .robust-header .robust-number {
  width: 64px;
  color: #999BA1;
  font-size: 20px;
  font-weight: 600;
  line-height: 120%;
  -webkit-transition: 400ms all;
  transition: 400ms all;
}
@media (max-width: 768px) {
  .robust .robust-row .robust-info .robust-header .robust-number {
    width: 46px;
  }
}
.robust .robust-row .robust-info .robust-header .robust-label {
  color: #2F333E;
  font-size: 23px;
  font-weight: 600;
  line-height: 120%;
  width: calc(100% - 64px);
  padding-left: 32px;
}
@media (max-width: 1280px) {
  .robust .robust-row .robust-info .robust-header .robust-label {
    font-size: 18px;
    padding-left: 16px;
  }
}
@media (max-width: 768px) {
  .robust .robust-row .robust-info .robust-header .robust-label {
    width: calc(100% - 46px);
    font-size: 16px;
  }
}
.robust .robust-row .robust-info .robust-main .robust-team {
  padding-bottom: 40px;
}
@media (max-width: 1280px) {
  .robust .robust-row .robust-info .robust-main .robust-team {
    padding-bottom: 24px;
  }
}
.robust .robust-row .robust-info .robust-main .robust-team .robust-team__text {
  color: #5A5D66;
  font-size: 16px;
  line-height: 140%;
}
@media (max-width: 768px) {
  .robust .robust-row .robust-info .robust-main .robust-team .robust-team__text {
    font-size: 14px;
  }
}

.valued {
  background: -webkit-gradient(linear, left bottom, left top, from(#F5F6F8), color-stop(100.02%, #FFF));
  background: linear-gradient(0deg, #F5F6F8 0%, #FFF 100.02%);
  padding: 128px 0 120px;
}
@media (max-width: 1280px) {
  .valued {
    padding: 64px 0 96px;
  }
}
@media (max-width: 768px) {
  .valued {
    padding: 48px 0;
  }
}
.valued .valued-company {
  padding-top: 16px;
}
@media (max-width: 768px) {
  .valued .valued-company {
    padding-top: 8px;
  }
}
.valued .valued-company ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.valued .valued-company ul li {
  color: #999BA1;
  padding: 48px 48px 0 0;
  font-size: 26px;
  font-weight: 600;
  color: #999BA1;
}
@media (max-width: 1280px) {
  .valued .valued-company ul li {
    padding: 32px 32px 0 0;
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .valued .valued-company ul li {
    padding: 24px 24px 0 0;
    font-size: 14px;
  }
}
.valued .valued-company ul li:last-child {
  padding-right: 0;
}

/* -- page-future -- */
.page-future .wrap-mw1548 {
  max-width: 1580px;
  margin: 0 auto;
  padding: 0 16px;
}
.page-future .banner-future {
  background: url(../../images/data/banner-future.jpg) no-repeat center;
  background-size: cover;
  border-radius: 0px 0px 24px 24px;
  height: 100vh;
}
@media (max-width: 1280px) {
  .page-future .banner-future {
    height: 675px;
  }
}
@media (max-width: 768px) {
  .page-future .banner-future {
    height: 460px;
    border-radius: 0;
  }
}
.page-future .banner-future .wrap {
  height: 100%;
}
.page-future .banner-future .future-name {
  height: 100%;
  padding: 20px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 1280px) {
  .page-future .banner-future .future-name img {
    max-width: 464px;
  }
}
@media (max-width: 768px) {
  .page-future .banner-future .future-name img {
    max-width: 282px;
  }
}
.page-future .banner-future .future-name .future-title {
  color: #fff;
  font-size: 96px;
  font-weight: 400;
  text-align: center;
}
@media (max-width: 1280px) {
  .page-future .banner-future .future-name .future-title {
    font-size: 66px;
  }
}
@media (max-width: 768px) {
  .page-future .banner-future .future-name .future-title {
    font-size: 36px;
  }
}
.page-future .banner-future .future-name .future-title .future-of {
  font-size: 64px;
  font-style: italic;
  vertical-align: baseline;
  font-family: "Spectral", serif;
}
@media (max-width: 1280px) {
  .page-future .banner-future .future-name .future-title .future-of {
    font-size: 44px;
  }
}
@media (max-width: 768px) {
  .page-future .banner-future .future-name .future-title .future-of {
    font-size: 24px;
  }
}
.page-future .insightful {
  margin-top: -88px;
  position: relative;
}
@media (max-width: 1280px) {
  .page-future .insightful {
    margin-top: -58px;
  }
}
@media (max-width: 768px) {
  .page-future .insightful {
    margin-top: -20px;
  }
}
.page-future .insightful .insightful-box {
  background: #fff;
  border-radius: 15px;
  padding: 78px 74px 44px;
}
@media (max-width: 1280px) {
  .page-future .insightful .insightful-box {
    padding: 52px 48px 34px;
  }
}
@media (max-width: 900px) {
  .page-future .insightful .insightful-box {
    padding: 28px 16px 34px;
  }
}
@media (max-width: 768px) {
  .page-future .insightful .insightful-box {
    width: calc(100% + 32px);
    margin-left: -16px;
  }
}
.page-future .insightful .insightful-info {
  max-width: 1161px;
  margin: 0 auto;
  text-align: center;
}
@media (max-width: 1280px) {
  .page-future .insightful .insightful-info {
    max-width: 775px;
  }
}
@media (max-width: 768px) {
  .page-future .insightful .insightful-info {
    max-width: 327px;
  }
}
.page-future .insightful .insightful-info .insightful-text {
  font-size: 36px;
  font-weight: 600;
}
@media (max-width: 1280px) {
  .page-future .insightful .insightful-info .insightful-text {
    font-size: 24px;
  }
}
@media (max-width: 768px) {
  .page-future .insightful .insightful-info .insightful-text {
    font-size: 18px;
    line-height: 140%;
  }
}
.page-future .insightful-list ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-left: -80px;
}
@media (max-width: 1280px) {
  .page-future .insightful-list ul {
    margin-left: -48px;
  }
}
@media (max-width: 900px) {
  .page-future .insightful-list ul {
    margin-left: -16px;
  }
}
.page-future .insightful-list ul li {
  width: 50%;
  padding: 64px 0 0 80px;
}
@media (max-width: 1280px) {
  .page-future .insightful-list ul li {
    padding: 34px 0 0 48px;
  }
}
@media (max-width: 900px) {
  .page-future .insightful-list ul li {
    padding: 34px 0 0 16px;
  }
}
@media (max-width: 768px) {
  .page-future .insightful-list ul li {
    width: 100%;
  }
}
.page-future .insightful-list ul li:first-of-type .insightful-head::after {
  width: calc(100% + 80px);
}
@media (max-width: 1280px) {
  .page-future .insightful-list ul li:first-of-type .insightful-head::after {
    width: calc(100% + 48px);
  }
}
@media (max-width: 900px) {
  .page-future .insightful-list ul li:first-of-type .insightful-head::after {
    width: calc(100% + 16px);
  }
}
@media (max-width: 768px) {
  .page-future .insightful-list ul li:first-of-type .insightful-head::after {
    width: 100%;
  }
}
.page-future .insightful-list .insightful-head {
  font-size: 36px;
  font-weight: 500;
  padding-bottom: 18px;
  position: relative;
}
@media (max-width: 1280px) {
  .page-future .insightful-list .insightful-head {
    font-size: 24px;
    padding-bottom: 12px;
  }
}
@media (max-width: 768px) {
  .page-future .insightful-list .insightful-head {
    font-size: 16px;
  }
}
.page-future .insightful-list .insightful-head::after, .page-future .insightful-list .insightful-head::before {
  position: absolute;
  content: "";
  left: 0;
  background: #0246C6;
}
.page-future .insightful-list .insightful-head::after {
  bottom: 0;
  width: 100%;
  height: 1px;
}
.page-future .insightful-list .insightful-head::before {
  bottom: -5px;
  width: 7px;
  height: 11px;
  border-radius: 100px;
}
@media (max-width: 1280px) {
  .page-future .insightful-list .insightful-head::before {
    bottom: -4px;
    width: 5px;
    height: 8px;
  }
}
@media (max-width: 768px) {
  .page-future .insightful-list .insightful-head::before {
    display: none;
  }
}
.page-future .insightful-list .insightful-content {
  margin-top: 44px;
}
@media (max-width: 1280px) {
  .page-future .insightful-list .insightful-content {
    margin-top: 32px;
  }
}
@media (max-width: 768px) {
  .page-future .insightful-list .insightful-content {
    margin-top: 16px;
  }
}
.page-future .insightful-list .insightful-content:hover .insightful-thumb .insightful-scale {
  top: 0;
}
.page-future .insightful-list .insightful-content .insightful-thumb {
  position: relative;
  overflow: hidden;
  display: block;
  border-radius: 4px;
}
.page-future .insightful-list .insightful-content .insightful-thumb img {
  width: 100%;
}
.page-future .insightful-list .insightful-content .insightful-thumb .insightful-scale {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #0246C6;
  padding: 64px;
  color: #fff;
  -webkit-transition: 400ms all;
  transition: 400ms all;
}
@media (max-width: 1280px) {
  .page-future .insightful-list .insightful-content .insightful-thumb .insightful-scale {
    padding: 22px;
  }
}
.page-future .insightful-list .insightful-content .insightful-thumb .insightful-scale .insightful-title {
  font-size: 29px;
  font-weight: 500;
}
@media (max-width: 1280px) {
  .page-future .insightful-list .insightful-content .insightful-thumb .insightful-scale .insightful-title {
    font-size: 20px;
  }
}
.page-future .insightful-list .insightful-content .insightful-thumb .insightful-scale .insightful-text {
  font-size: 20px;
  padding-top: 34px;
}
@media (max-width: 1280px) {
  .page-future .insightful-list .insightful-content .insightful-thumb .insightful-scale .insightful-text {
    font-size: 16px;
    padding-top: 16px;
  }
}
.page-future .insightful-list .insightful-content .insightful-mask {
  padding-top: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 900px) {
  .page-future .insightful-list .insightful-content .insightful-mask {
    display: block;
  }
}
@media (max-width: 768px) {
  .page-future .insightful-list .insightful-content .insightful-mask {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-top: 16px;
  }
}
.page-future .insightful-list .insightful-content .insightful-mask .insightful-title {
  padding-right: 10px;
  font-size: 23px;
  font-weight: 600;
}
@media (max-width: 1280px) {
  .page-future .insightful-list .insightful-content .insightful-mask .insightful-title {
    font-size: 16px;
  }
}
@media (max-width: 900px) {
  .page-future .insightful-list .insightful-content .insightful-mask .insightful-title {
    padding-bottom: 10px;
  }
}
@media (max-width: 768px) {
  .page-future .insightful-list .insightful-content .insightful-mask .insightful-title {
    padding-bottom: 0;
    font-size: 15px;
  }
}
.page-future .insightful-list .insightful-content .insightful-mask .btn {
  padding-left: 16px;
  padding-right: 16px;
}
.page-future .medtech {
  margin-top: 77px;
}
.page-future .medtech .medtech-info {
  background: url(../../images/data/bg-medtech.jpg) no-repeat center;
  height: 711px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  padding: 16px;
  border-radius: 31px;
}
@media (max-width: 1280px) {
  .page-future .medtech .medtech-info {
    height: 475px;
    border-radius: 20px;
  }
}
@media (max-width: 768px) {
  .page-future .medtech .medtech-info {
    height: 294px;
    background-position: center right;
    background-size: 200%;
  }
}
@media (max-width: 1280px) {
  .page-future .medtech {
    margin-top: 42px;
  }
}
@media (max-width: 900px) {
  .page-future .medtech {
    margin-top: 0;
    padding-top: 55px;
  }
}
.page-future .medtech .medtech-info .medtech-text {
  color: #fff;
  font-size: 65px;
  text-transform: uppercase;
}
@media (max-width: 1280px) {
  .page-future .medtech .medtech-info .medtech-text {
    font-size: 42px;
  }
}
@media (max-width: 768px) {
  .page-future .medtech .medtech-info .medtech-text {
    font-size: 24px;
  }
}
.page-future .challenges {
  padding: 143px 0 103px;
}
@media (max-width: 1280px) {
  .page-future .challenges {
    padding: 89px 0 68px;
  }
}
@media (max-width: 768px) {
  .page-future .challenges {
    padding: 60px 0 26px;
  }
}
.page-future .challenges .challenges-head {
  position: relative;
  min-height: 478px;
}
@media (max-width: 1280px) {
  .page-future .challenges .challenges-head {
    min-height: 320px;
  }
}
@media (max-width: 768px) {
  .page-future .challenges .challenges-head {
    min-height: 296px;
  }
}
.page-future .challenges .challenges-head .challenges-tlt {
  max-width: 460px;
  font-size: 40px;
  font-weight: 600;
}
@media (max-width: 1280px) {
  .page-future .challenges .challenges-head .challenges-tlt {
    font-size: 26px;
    max-width: 307px;
  }
}
@media (max-width: 768px) {
  .page-future .challenges .challenges-head .challenges-tlt {
    font-size: 18px;
    max-width: 100%;
  }
}
.page-future .challenges .challenges-head::after {
  position: absolute;
  bottom: 0;
  right: 0;
  content: "";
  background: url(../../images/data/bg-challenges.jpg) no-repeat;
  background-size: cover;
  width: calc(100% - 550px);
  height: 478px;
  border-radius: 24px 0px 0px 24px;
}
@media (max-width: 1280px) {
  .page-future .challenges .challenges-head::after {
    height: 320px;
    width: calc(100% - 330px);
  }
}
@media (max-width: 768px) {
  .page-future .challenges .challenges-head::after {
    height: 253px;
    width: 100%;
  }
}
.page-future .slider-challenges {
  margin-top: -160px;
  min-height: 100px;
}
@media (max-width: 1280px) {
  .page-future .slider-challenges {
    margin-top: -108px;
  }
}
@media (max-width: 900px) {
  .page-future .slider-challenges {
    margin-top: -85px;
    width: calc(100% + 40px);
  }
}
@media (max-width: 768px) {
  .page-future .slider-challenges {
    width: calc(100% + 16px);
  }
}
.page-future .slider-challenges .slick-list {
  margin-left: -24px;
}
@media (max-width: 1280px) {
  .page-future .slider-challenges .slick-list {
    margin-left: -16px;
  }
}
@media (max-width: 900px) {
  .page-future .slider-challenges .slick-list {
    margin-left: 0;
  }
}
.page-future .slider-challenges .slick-slide {
  padding: 8px 0 8px 24px;
}
@media (max-width: 1280px) {
  .page-future .slider-challenges .slick-slide {
    padding-left: 16px;
  }
}
@media (max-width: 900px) {
  .page-future .slider-challenges .slick-slide {
    width: 236px;
    padding-left: 0;
    margin-right: 16px;
  }
}
@media (max-width: 768px) {
  .page-future .slider-challenges .slick-slide {
    width: 290px;
  }
}
.page-future .slider-challenges .challenges-info {
  background: #fff;
  -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
  /*padding: 48px 48px 64px;*/
  padding: 8% 10%;
  min-height: 280px;
}
@media (max-width: 1280px) {
  .page-future .slider-challenges .challenges-info {
    padding: 32px;
    min-height: 225px;
  }
}
@media (max-width: 1000px) {
  .page-future .slider-challenges .challenges-info {
    min-height: 245px;
    padding: 24px 16px;
  }
}
@media (max-width: 900px) {
  .page-future .slider-challenges .challenges-info {
    min-height: 230px;
  }
}
@media (max-width: 768px) {
  .page-future .slider-challenges .challenges-info {
    min-height: 202px;
  }
}
.page-future .slider-challenges .challenges-info .challenges-info__title {
  font-size: 23px;
  font-weight: 600;
  color: #0246C6;
}
@media (max-width: 1280px) {
  .page-future .slider-challenges .challenges-info .challenges-info__title {
    font-size: 15px;
  }
}
@media (max-width: 768px) {
  .page-future .slider-challenges .challenges-info .challenges-info__title {
    font-size: 16px;
  }
}
.page-future .slider-challenges .challenges-info .challenges-info__text {
  margin-top: 40px;
  font-size: 18px;
}
@media (max-width: 1280px) {
  .page-future .slider-challenges .challenges-info .challenges-info__text {
    margin-top: 26px;
    font-size: 14px;
    line-height: 140%;
  }
}
@media (max-width: 768px) {
  .page-future .slider-challenges .challenges-info .challenges-info__text {
    margin-top: 16px;
  }
}
.page-future .benefits {
  background: #fff;
  padding: 54px 0 116px;
}
@media (max-width: 1280px) {
  .page-future .benefits {
    padding: 36px 0 77px;
  }
}
@media (max-width: 768px) {
  .page-future .benefits {
    padding: 26px 0 42px;
    background: none;
  }
}
.page-future .benefits .benefits-wrap {
  position: relative;
  min-height: 586px;
}
@media (max-width: 1280px) {
  .page-future .benefits .benefits-wrap {
    min-height: 390px;
  }
}
@media (max-width: 768px) {
  .page-future .benefits .benefits-wrap {
    padding-left: 16px;
    min-height: 242px;
  }
}
.page-future .benefits .benefits-info .benefits-title {
  font-size: 40px;
  font-weight: 600;
  padding-left: 50%;
  padding: 0 0 74px 50%;
}
@media (max-width: 1280px) {
  .page-future .benefits .benefits-info .benefits-title {
    font-size: 26px;
    padding: 0 0 50px 45%;
  }
}
@media (max-width: 768px) {
  .page-future .benefits .benefits-info .benefits-title {
    font-size: 18px;
    padding: 0 0 16px 0;
  }
}
.page-future .benefits-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-left: 50%;
  position: relative;
}
@media (max-width: 1280px) {
  .page-future .benefits-row {
    padding-left: 45%;
  }
}
@media (max-width: 768px) {
  .page-future .benefits-row {
    padding-left: 0;
    width: calc(100% + 16px);
    margin-left: -16px;
  }
}
.page-future .benefits-row::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 45%;
  height: 460px;
  background: url(../../images/data/bg-team-doctors.jpg) no-repeat right center;
  background-size: cover;
  border-radius: 0px 24px 24px 0px;
}
@media (max-width: 1280px) {
  .page-future .benefits-row::after {
    height: 307px;
    width: 40%;
  }
}
@media (max-width: 900px) {
  .page-future .benefits-row::after {
    bottom: 28px;
  }
}
@media (max-width: 768px) {
  .page-future .benefits-row::after {
    width: 100%;
    height: 202px;
    bottom: 0;
  }
}
.page-future .benefits-row .benefits-col {
  width: 50%;
  padding-right: 2%;
}
.page-future .benefits-row .benefits-col:last-child {
  padding-left: 2%;
  padding-right: 0;
}
@media (max-width: 768px) {
  .page-future .benefits-row .benefits-col {
    display: none;
  }
}
.page-future .benefits-row .navTabs {
  display: block;
}
.page-future .benefits-row .navTabs li a {
  padding-bottom: 16px;
  display: block;
  color: rgba(47, 51, 62, 0.4);
  font-size: 23px;
  -webkit-transition: 400ms all;
  transition: 400ms all;
  position: relative;
  cursor: pointer;
}
@media (max-width: 1280px) {
  .page-future .benefits-row .navTabs li a {
    font-size: 16px;
    padding-bottom: 10px;
  }
}
.page-future .benefits-row .navTabs li a::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  background: #6C6F77;
  -webkit-transition: 400ms all;
  transition: 400ms all;
  width: 0;
  height: 0;
}
.page-future .benefits-row .navTabs li a.active {
  color: #0246C6;
  font-weight: 600;
  margin-bottom: 16px;
}
@media (max-width: 1280px) {
  .page-future .benefits-row .navTabs li a.active {
    margin-bottom: 10px;
  }
}
.page-future .benefits-row .navTabs li a.active::after {
  width: 100%;
  height: 1px;
}
.page-future .benefits-row .benefits-pane .benefits-pane_text {
  color: #383C46;
  font-size: 18px;
  line-height: 140%;
}
@media (max-width: 1280px) {
  .page-future .benefits-row .benefits-pane .benefits-pane_text {
    font-size: 14px;
  }
}
.page-future .benefits-sp {
  display: none;
  padding: 26px 16px 0;
}
@media (max-width: 768px) {
  .page-future .benefits-sp {
    display: block;
  }
}
.page-future .benefits-sp .benefits-list .benefits-header {
  color: rgba(47, 51, 62, 0.4);
  font-size: 16px;
  padding: 0 0 10px;
  cursor: pointer;
  position: relative;
  -webkit-transition: 400ms all;
  transition: 400ms all;
}
.page-future .benefits-sp .benefits-list .benefits-header[aria-expanded=true] {
  color: #0246C6;
  font-weight: 600;
}
.page-future .benefits-sp .benefits-list .benefits-header[aria-expanded=true]::after {
  width: 100%;
  height: 1px;
}
.page-future .benefits-sp .benefits-list .benefits-header::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  background: #6C6F77;
  -webkit-transition: 400ms all;
  transition: 400ms all;
  width: 0;
  height: 0;
}
.page-future .benefits-sp .benefits-list .benefits-body .benefits-pane {
  padding: 10px 0 26px;
}
.page-future .benefits-sp .benefits-list .benefits-body .benefits-pane .benefits-pane_text {
  color: #383C46;
  line-height: 140%;
}
.page-future .adverse {
  border-radius: 26px;
  background: #2F333E;
  padding: 74px 0 126px;
  margin-top: -20px;
}
@media (max-width: 1280px) {
  .page-future .adverse {
    border-radius: 17px;
    padding: 50px 0 85px;
  }
}
@media (max-width: 900px) {
  .page-future .adverse {
    padding-bottom: 25px;
  }
}
@media (max-width: 768px) {
  .page-future .adverse {
    margin-top: 0;
    padding: 46px 0 0;
  }
}
.page-future .adverse .adverse-wrap {
  max-width: 1086px;
  margin: 0 auto;
}
@media (max-width: 1280px) {
  .page-future .adverse .adverse-wrap {
    max-width: 652px;
  }
}
.page-future .adverse .adverse-wrap .adverse-title {
  text-align: center;
  color: #fff;
  max-width: 822px;
  margin: 0 auto;
}
@media (max-width: 1280px) {
  .page-future .adverse .adverse-wrap .adverse-title {
    max-width: 548px;
  }
}
.page-future .adverse .adverse-wrap .adverse-title .adverse-label {
  font-size: 36px;
}
@media (max-width: 1280px) {
  .page-future .adverse .adverse-wrap .adverse-title .adverse-label {
    font-size: 24px;
  }
}
@media (max-width: 768px) {
  .page-future .adverse .adverse-wrap .adverse-title .adverse-label {
    font-size: 18px;
  }
}
.page-future .adverse .adverse-wrap .adverse-title .adverse-text {
  margin-top: 59px;
  font-size: 40px;
  font-weight: 600;
}
@media (max-width: 1280px) {
  .page-future .adverse .adverse-wrap .adverse-title .adverse-text {
    margin-top: 40px;
    font-size: 26px;
  }
}
@media (max-width: 768px) {
  .page-future .adverse .adverse-wrap .adverse-title .adverse-text {
    margin-top: 29px;
    font-size: 18px;
  }
}
.page-future .adverse .adverse-wrap .adverse-list ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-left: -42px;
}
@media (max-width: 1280px) {
  .page-future .adverse .adverse-wrap .adverse-list ul {
    margin-left: -28px;
  }
}
@media (max-width: 768px) {
  .page-future .adverse .adverse-wrap .adverse-list ul {
    margin-left: -13px;
  }
}
.page-future .adverse .adverse-wrap .adverse-list ul li {
  width: 50%;
  padding: 26px 0 0 42px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 1280px) {
  .page-future .adverse .adverse-wrap .adverse-list ul li {
    padding: 18px 0 0 28px;
  }
}
@media (max-width: 768px) {
  .page-future .adverse .adverse-wrap .adverse-list ul li {
    padding-left: 13px;
  }
}
.page-future .adverse .adverse-wrap .adverse-list ul li .adverse-info {
  width: 100%;
  display: block;
  background: rgba(255, 255, 255, 0.06);
  padding: 30px 38px;
}
@media (max-width: 1280px) {
  .page-future .adverse .adverse-wrap .adverse-list ul li .adverse-info {
    padding: 20px 22px;
  }
}
@media (max-width: 768px) {
  .page-future .adverse .adverse-wrap .adverse-list ul li .adverse-info {
    padding: 12px;
  }
}
.page-future .adverse .adverse-wrap .adverse-list ul li .adverse-content {
  text-align: center;
  padding-top: 40px;
}
@media (max-width: 1280px) {
  .page-future .adverse .adverse-wrap .adverse-list ul li .adverse-content {
    padding-top: 26px;
  }
}
@media (max-width: 768px) {
  .page-future .adverse .adverse-wrap .adverse-list ul li .adverse-content {
    padding-top: 15px;
  }
}
.page-future .adverse .adverse-wrap .adverse-list ul li .adverse-content .adverse-tlt {
  color: #fff;
  font-size: 26px;
  font-weight: 600;
}
@media (max-width: 1280px) {
  .page-future .adverse .adverse-wrap .adverse-list ul li .adverse-content .adverse-tlt {
    font-size: 17px;
  }
}
@media (max-width: 768px) {
  .page-future .adverse .adverse-wrap .adverse-list ul li .adverse-content .adverse-tlt {
    font-size: 12px;
  }
}
.page-future .based {
  background: #2F333E;
  padding-bottom: 120px;
  margin-top: -26px;
  min-height: 890px;
  position: relative;
}
@media (max-width: 1280px) {
  .page-future .based {
    min-height: 600px;
    padding-bottom: 80px;
  }
}
@media (max-width: 900px) {
  .page-future .based {
    padding-top: 55px;
  }
}
@media (max-width: 768px) {
  .page-future .based {
    min-height: 320px;
    padding-bottom: 0;
    border-radius: 10px 10px 20px 20px;
  }
}
.page-future .based::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 871px;
  background: url(../../images/data/bg-based.png) no-repeat top center;
  background-size: cover;
  border-radius: 24px;
  overflow: hidden;
}
@media (max-width: 1280px) {
  .page-future .based::after {
    height: 582px;
  }
}
@media (max-width: 900px) {
  .page-future .based::after {
    top: 55px;
  }
}
@media (max-width: 768px) {
  .page-future .based::after {
    height: 311px;
  }
}
.page-future .based .based-wrap {
  position: relative;
  z-index: 1;
}
.page-future .based .based-title {
  text-align: center;
  padding: 226px 0 152px;
  color: #fff;
}
@media (max-width: 1280px) {
  .page-future .based .based-title {
    padding: 150px 0 90px;
  }
}
@media (max-width: 768px) {
  .page-future .based .based-title {
    padding: 98px 0 48px;
  }
}
.page-future .based .based-title .based-tlt {
  font-size: 64px;
  font-weight: 500;
}
@media (max-width: 1280px) {
  .page-future .based .based-title .based-tlt {
    font-size: 42px;
  }
}
@media (max-width: 768px) {
  .page-future .based .based-title .based-tlt {
    font-size: 24px;
  }
}
.page-future .based .based-title .based-text {
  font-size: 48px;
  font-weight: 300;
}
@media (max-width: 1280px) {
  .page-future .based .based-title .based-text {
    font-size: 32px;
  }
}
@media (max-width: 768px) {
  .page-future .based .based-title .based-text {
    font-size: 24px;
    padding-top: 10px;
  }
}
.page-future .based .based-value {
  background: rgba(1, 8, 75, 0.6);
  border-radius: 16px;
  padding: 40px 62px 48px;
}
@media (max-width: 1280px) {
  .page-future .based .based-value {
    padding: 38px 40px 32px;
  }
}
@media (max-width: 768px) {
  .page-future .based .based-value {
    padding: 26px 32px 42px;
    margin-left: -16px;
    width: calc(100% + 32px);
  }
}
.page-future .based .based-value .based-value__text {
  font-size: 36px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  max-width: 975px;
  margin: 0 auto;
}
@media (max-width: 1280px) {
  .page-future .based .based-value .based-value__text {
    max-width: 652px;
    font-size: 24px;
  }
}
@media (max-width: 768px) {
  .page-future .based .based-value .based-value__text {
    font-size: 18px;
    max-width: 295px;
  }
}
@media (max-width: 768px) {
  .page-future .based .based-value .based-value__list {
    padding-top: 9px;
  }
}
.page-future .based .based-value .based-value__list ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-left: -32px;
}
@media (max-width: 1280px) {
  .page-future .based .based-value .based-value__list ul {
    margin-left: -21px;
  }
}
.page-future .based .based-value .based-value__list ul li {
  margin: 55px 0 0 32px;
  width: calc(50% - 32px);
  background: #fff;
  border-radius: 16px;
  padding: 56px 3%;
}
@media (max-width: 1280px) {
  .page-future .based .based-value .based-value__list ul li {
    margin: 36px 0 0 21px;
    width: calc(50% - 21px);
    padding: 37px 4%;
  }
}
@media (max-width: 768px) {
  .page-future .based .based-value .based-value__list ul li {
    width: calc(100% - 21px);
    margin-top: 16px;
    padding: 24px;
  }
}
.page-future .based .based-value .based-value__list .based-value-tlt {
  font-size: 30px;
  color: #0246C6;
}
@media (max-width: 1280px) {
  .page-future .based .based-value .based-value__list .based-value-tlt {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .page-future .based .based-value .based-value__list .based-value-tlt {
    font-size: 18px;
  }
}
.page-future .based .based-value .based-value__list .based-value-tlt br {
  display: none;
}
@media (max-width: 900px) {
  .page-future .based .based-value .based-value__list .based-value-tlt br {
    display: block;
  }
}
@media (max-width: 768px) {
  .page-future .based .based-value .based-value__list .based-value-tlt br {
    display: none;
  }
}
.page-future .based .based-value .based-value__list .based-value-text {
  margin-top: 32px;
  font-size: 20px;
}
@media (max-width: 1280px) {
  .page-future .based .based-value .based-value__list .based-value-text {
    margin-top: 20px;
    font-size: 13px;
  }
}
@media (max-width: 768px) {
  .page-future .based .based-value .based-value__list .based-value-text {
    margin-top: 16px;
  }
}
.page-future .based-serviced {
  margin-top: 24px;
  background: #fff;
  padding: 48px 4.5% 80px;
  border-radius: 16px;
}
@media (max-width: 1280px) {
  .page-future .based-serviced {
    margin-top: 16px;
    padding: 32px 3.5%;
    border-radius: 10px;
  }
}
@media (max-width: 768px) {
  .page-future .based-serviced {
    padding: 24px 16px;
    width: calc(100% + 32px);
    margin: -15px 0 0 -16px;
  }
}
.page-future .based-serviced .based-serviced__text {
  font-size: 26px;
  font-weight: 600;
  text-align: center;
}
@media (max-width: 1280px) {
  .page-future .based-serviced .based-serviced__text {
    font-size: 17px;
  }
}
@media (max-width: 768px) {
  .page-future .based-serviced .based-serviced__text {
    font-size: 16px;
  }
}
.page-future .based-serviced .based-serviced__table {
  padding-top: 16px;
}
@media (max-width: 1280px) {
  .page-future .based-serviced .based-serviced__table {
    padding-top: 12px;
  }
}
@media (max-width: 768px) {
  .page-future .based-serviced .based-serviced__table {
    padding-top: 16px;
  }
}
.page-future .based-serviced .based-serviced__table tbody tr:last-child td {
  border-bottom: none;
}
.page-future .based-serviced .based-serviced__table tr th,
.page-future .based-serviced .based-serviced__table tr td {
  vertical-align: top;
  border: none;
  width: 33.333333%;
}
.page-future .based-serviced .based-serviced__table tr th:nth-child(2),
.page-future .based-serviced .based-serviced__table tr td:nth-child(2) {
  padding-left: 4%;
}
@media (max-width: 768px) {
  .page-future .based-serviced .based-serviced__table tr th:nth-child(2),
  .page-future .based-serviced .based-serviced__table tr td:nth-child(2) {
    padding-left: 8px;
  }
}
.page-future .based-serviced .based-serviced__table tr th:nth-child(3),
.page-future .based-serviced .based-serviced__table tr td:nth-child(3) {
  padding-right: 0;
  padding-left: 4%;
}
@media (max-width: 768px) {
  .page-future .based-serviced .based-serviced__table tr th:nth-child(3),
  .page-future .based-serviced .based-serviced__table tr td:nth-child(3) {
    padding-left: 8px;
  }
}
.page-future .based-serviced .based-serviced__table tr th {
  padding: 32px 0;
  font-size: 23px;
  font-weight: 600;
  color: #0246C6;
  border-bottom: 2px solid #0246C6;
}
@media (max-width: 1280px) {
  .page-future .based-serviced .based-serviced__table tr th {
    font-size: 15px;
    padding: 20px 0;
  }
}
@media (max-width: 768px) {
  .page-future .based-serviced .based-serviced__table tr th {
    font-size: 12px;
    padding: 8px 0;
  }
}
.page-future .based-serviced .based-serviced__table tr td {
  padding: 16px 4% 16px 0;
  font-size: 20px;
  border-bottom: 1px solid #C3C4D2;
  position: relative;
  line-height: 120%;
}
@media (max-width: 1280px) {
  .page-future .based-serviced .based-serviced__table tr td {
    padding: 10px 4% 10px 0;
    font-size: 13px;
  }
}
@media (max-width: 768px) {
  .page-future .based-serviced .based-serviced__table tr td {
    padding: 8px 8px 8px 0;
    font-size: 12px;
  }
}
.page-future .based-serviced .based-serviced__table tr td:nth-child(1) {
  font-weight: 500;
}
.page-future .based-serviced .based-serviced__table tr td::after {
  position: absolute;
  top: 16px;
  right: 0;
  content: "";
  width: 1px;
  height: calc(100% - 32px);
  background: #C3C4D2;
}
@media (max-width: 1280px) {
  .page-future .based-serviced .based-serviced__table tr td::after {
    top: 10px;
    height: calc(100% - 20px);
  }
}
@media (max-width: 768px) {
  .page-future .based-serviced .based-serviced__table tr td::after {
    top: 8px;
    height: calc(100% - 16px);
  }
}
.page-future .based-serviced .based-serviced__table tr td:last-child::after {
  display: none;
}
.page-future .pursue {
  padding: 100px 0 120px;
}
@media (max-width: 1280px) {
  .page-future .pursue {
    padding: 68px 0;
  }
}
@media (max-width: 768px) {
  .page-future .pursue {
    padding: 48px 0 30px;
  }
}
.page-future .pursue .pursue-title .tlt {
  font-size: 40px;
  font-weight: 600;
}
@media (max-width: 1280px) {
  .page-future .pursue .pursue-title .tlt {
    font-size: 26px;
  }
}
@media (max-width: 768px) {
  .page-future .pursue .pursue-title .tlt {
    font-size: 18px;
  }
}
.page-future .btn-slider {
  width: 108px;
  margin: -26px 0 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  z-index: 2;
}
@media (max-width: 1280px) {
  .page-future .btn-slider {
    width: 82px;
    margin-top: -18px;
  }
}
@media (max-width: 768px) {
  .page-future .btn-slider {
    display: none;
  }
}
.page-future .btn-slider .btn {
  padding: 0;
  width: 50px;
  min-width: 50px;
  height: 50px;
  border: none;
  margin-left: 8px;
  border-radius: 50%;
}
@media (max-width: 1280px) {
  .page-future .btn-slider .btn {
    width: 33px;
    min-width: 33px;
    height: 33px;
  }
}
.page-future .btn-slider .btn.btn-next {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.page-future .slider-pursue {
  padding: 102px 0 90px;
  display: none;
}
@media (max-width: 1280px) {
  .page-future .slider-pursue {
    padding: 58px 0 44px;
  }
}
@media (max-width: 768px) {
  .page-future .slider-pursue {
    padding: 28px 0 44px;
    display: block;
  }
}
.page-future .slider-pursue .slick-slide {
  width: 383px;
  margin-right: 24px;
}
@media (max-width: 1280px) {
  .page-future .slider-pursue .slick-slide {
    width: 297px;
    margin-right: 18px;
  }
}
@media (max-width: 768px) {
  .page-future .slider-pursue .slick-slide {
    width: 306px;
    margin-right: 15px;
  }
}
.page-future .slider-pursue .slick-dots {
  padding-right: 272px;
}
@media (max-width: 1280px) {
  .page-future .slider-pursue .slick-dots {
    padding-right: 160px;
  }
}
@media (max-width: 768px) {
  .page-future .slider-pursue .slick-dots {
    padding-right: 16px;
  }
}
.page-future .pursue-info {
  position: relative;
  border-radius: 16px;
}
@media (max-width: 768px) {
  .page-future .pursue-info {
    border-radius: 8px;
  }
}
.page-future .pursue-info:hover .pursue-content {
  top: 0;
}
.page-future .pursue-info:hover::after {
  display: none;
}
.page-future .pursue-info .pursue-thumb {
  border-radius: 16px;
  overflow: hidden;
  position: relative;
}
@media (max-width: 768px) {
  .page-future .pursue-info .pursue-thumb {
    border-radius: 8px 8px 0 0;
  }
}
.page-future .pursue-info .pursue-thumb::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  height: 100%;
  width: 100%;
  border-radius: 16px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(rgb(0, 0, 0)));
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(0, 0, 0) 100%);
}
@media (max-width: 768px) {
  .page-future .pursue-info .pursue-thumb::after {
    border-radius: 8px 8px 0 0;
  }
}
.page-future .pursue-info .pursue-thumb img {
  width: 100%;
}
.page-future .pursue-info .pursue-info-title {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  padding: 24px;
  font-size: 23px;
  font-weight: 600;
  color: #fff;
}
@media (max-width: 1280px) {
  .page-future .pursue-info .pursue-info-title {
    padding: 18px;
    font-size: 17px;
  }
}
@media (max-width: 768px) {
  .page-future .pursue-info .pursue-info-title {
    font-size: 15px;
    display: none;
  }
}
.page-future .pursue-info .pursue-content {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 24px;
  border-radius: 10px;
  -webkit-transition: 400ms all;
  transition: 400ms all;
}
@media (max-width: 1280px) {
  .page-future .pursue-info .pursue-content {
    padding: 18px;
  }
}
@media (max-width: 768px) {
  .page-future .pursue-info .pursue-content {
    position: static;
    min-height: 188px;
    border-radius: 8px 8px 0 0;
  }
}
.page-future .pursue-info .pursue-content .pursue-tlt {
  font-size: 20px;
  font-weight: 600;
}
@media (max-width: 1280px) {
  .page-future .pursue-info .pursue-content .pursue-tlt {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .page-future .pursue-info .pursue-content .pursue-tlt {
    font-size: 16px;
  }
}
.page-future .pursue-info .pursue-content .pursue-text {
  font-size: 16px;
  color: #383C46;
  padding-top: 24px;
}
@media (max-width: 1280px) {
  .page-future .pursue-info .pursue-content .pursue-text {
    font-size: 14px;
    padding-top: 16px;
  }
}
.page-future .pursue-list {
  padding-top: 40px;
}
@media (max-width: 1280px) {
  .page-future .pursue-list {
    padding-top: 24px;
  }
}
@media (max-width: 768px) {
  .page-future .pursue-list {
    display: none;
  }
}
.page-future .pursue-list ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-left: -24px;
}
.page-future .pursue-list ul li {
  margin: 24px 0 0 24px;
  width: calc(33.333333% - 24px);
  overflow: hidden;
}
@media (max-width: 900px) {
  .page-future .pursue-list ul li {
    width: calc(50% - 24px);
  }
}
.page-future .meets {
  position: relative;
  min-height: 603px;
}
@media (max-width: 1280px) {
  .page-future .meets {
    min-height: 402px;
  }
}
@media (max-width: 768px) {
  .page-future .meets {
    min-height: 390px;
    padding-bottom: 48px;
  }
}
.page-future .meets::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 603px;
  background: url(../../images/data/bg-meets.jpg) no-repeat center;
  background-size: cover;
}
@media (max-width: 1280px) {
  .page-future .meets::after {
    height: 402px;
  }
}
@media (max-width: 768px) {
  .page-future .meets::after {
    height: 217px;
  }
}
.page-future .meets .meets-wrap {
  position: relative;
  z-index: 1;
}
.page-future .meets .meets-wrap .meets-title {
  padding-top: 77px;
  text-align: center;
  max-width: 607px;
  margin: 0 auto;
}
@media (max-width: 1280px) {
  .page-future .meets .meets-wrap .meets-title {
    padding-top: 50px;
    max-width: 405px;
  }
}
@media (max-width: 768px) {
  .page-future .meets .meets-wrap .meets-title {
    padding-top: 44px;
    max-width: 280px;
  }
}
.page-future .meets .meets-wrap .meets-title .tlt {
  font-size: 40px;
  font-weight: 600;
  color: #fff;
}
@media (max-width: 1280px) {
  .page-future .meets .meets-wrap .meets-title .tlt {
    font-size: 26px;
  }
}
@media (max-width: 768px) {
  .page-future .meets .meets-wrap .meets-title .tlt {
    font-size: 18px;
  }
}
.page-future .meets .meets-list {
  padding-top: 48px;
}
@media (max-width: 1280px) {
  .page-future .meets .meets-list {
    padding-top: 20px;
  }
}
@media (max-width: 768px) {
  .page-future .meets .meets-list {
    padding-top: 30px;
  }
}
.page-future .meets .meets-list ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-left: -16px;
}
@media (max-width: 1280px) {
  .page-future .meets .meets-list ul {
    margin-left: -10px;
  }
}
@media (max-width: 900px) {
  .page-future .meets .meets-list ul {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.page-future .meets .meets-list ul li {
  width: calc(20% - 16px);
  margin-left: 16px;
  padding: 24px;
  min-height: 236px;
  border-radius: 16px;
  background: #FFF;
  -webkit-transition: 400ms all;
  transition: 400ms all;
}
@media (max-width: 1280px) {
  .page-future .meets .meets-list ul li {
    padding: 16px;
    border-radius: 10px;
    min-height: 157px;
    margin: 10px 0 0 10px;
    width: calc(20% - 10px);
  }
}
@media (max-width: 900px) {
  .page-future .meets .meets-list ul li {
    width: calc(33.333333% - 10px);
    min-height: initial;
  }
  .page-future .meets .meets-list ul li:nth-child(4), .page-future .meets .meets-list ul li:nth-child(5) {
    width: calc(50% - 10px);
  }
}
@media (max-width: 900px) and (max-width: 768px) {
  .page-future .meets .meets-list ul li:nth-child(4), .page-future .meets .meets-list ul li:nth-child(5) {
    width: calc(100% - 10px);
  }
}
@media (max-width: 768px) {
  .page-future .meets .meets-list ul li {
    width: calc(100% - 10px);
    padding: 16px;
    border-radius: 8px;
    min-height: 108px;
  }
}
.page-future .meets .meets-list ul li .meets-tlt {
  font-size: 23px;
  font-weight: 600;
}
@media (max-width: 1280px) {
  .page-future .meets .meets-list ul li .meets-tlt {
    font-size: 15px;
  }
}
@media (max-width: 768px) {
  .page-future .meets .meets-list ul li .meets-tlt {
    font-size: 16px;
  }
}
.page-future .meets .meets-list ul li .meets-text {
  margin-top: 32px;
  font-size: 18px;
  color: #383C46;
}
@media (max-width: 1280px) {
  .page-future .meets .meets-list ul li .meets-text {
    margin-top: 20px;
    font-size: 12px;
  }
}
@media (max-width: 900px) {
  .page-future .meets .meets-list ul li .meets-text {
    margin-top: 16px;
  }
}
@media (max-width: 768px) {
  .page-future .meets .meets-list ul li .meets-text {
    font-size: 14px;
  }
}
.page-future .meets .meets-list ul li:hover {
  background: #252C6D;
}
.page-future .meets .meets-list ul li:hover .meets-tlt,
.page-future .meets .meets-list ul li:hover .meets-text {
  color: #fff;
}
.page-future .factor {
  background: url(../../images/data/bg-factor.jpg) no-repeat center;
  background-size: cover;
  height: 811px;
  border-radius: 24px;
  margin-top: -24px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
@media (max-width: 1280px) {
  .page-future .factor {
    height: 541px;
  }
}
@media (max-width: 768px) {
  .page-future .factor {
    height: 357px;
    margin-top: 0;
    border-radius: 24px 24px 0 0;
  }
}
.page-future .factor .factor-info {
  text-align: center;
  padding-top: 337px;
  color: #fff;
}
@media (max-width: 1280px) {
  .page-future .factor .factor-info {
    padding-top: 225px;
  }
}
@media (max-width: 768px) {
  .page-future .factor .factor-info {
    padding-top: 42px;
  }
}
.page-future .factor .factor-info .factor-tlt {
  font-size: 48px;
  font-weight: 600;
  text-transform: uppercase;
}
@media (max-width: 1280px) {
  .page-future .factor .factor-info .factor-tlt {
    font-size: 32px;
  }
}
@media (max-width: 768px) {
  .page-future .factor .factor-info .factor-tlt {
    font-size: 24px;
  }
}
.page-future .factor .factor-info .factor-text {
  margin-top: 10px;
  font-size: 36px;
  font-weight: 500;
  text-transform: uppercase;
}
@media (max-width: 1280px) {
  .page-future .factor .factor-info .factor-text {
    margin-top: 6px;
    font-size: 24px;
  }
}
@media (max-width: 768px) {
  .page-future .factor .factor-info .factor-text {
    margin-top: 20px;
    font-size: 18px;
  }
  .page-future .factor .factor-info .factor-text br {
    display: none;
  }
}
.page-future .policy {
  position: relative;
  z-index: 1;
  margin-top: -24px;
  background: -webkit-gradient(linear, left top, right top, color-stop(70%, rgb(63, 66, 77)), color-stop(70%, rgb(2, 70, 198)));
  background: linear-gradient(to right, rgb(63, 66, 77) 70%, rgb(2, 70, 198) 70%);
}
@media (min-width: 1921px) {
  .page-future .policy {
    background: -webkit-gradient(linear, left top, right top, color-stop(65%, rgb(63, 66, 77)), color-stop(65%, rgb(2, 70, 198)));
    background: linear-gradient(to right, rgb(63, 66, 77) 65%, rgb(2, 70, 198) 65%);
  }
}
@media (max-width: 1280px) {
  .page-future .policy {
    background: -webkit-gradient(linear, left top, right top, color-stop(75%, rgb(63, 66, 77)), color-stop(75%, rgb(2, 70, 198)));
    background: linear-gradient(to right, rgb(63, 66, 77) 75%, rgb(2, 70, 198) 75%);
  }
}
@media (max-width: 768px) {
  .page-future .policy {
    background: #3F424D;
    margin-top: 0;
  }
}
.page-future .policy .policy-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 768px) {
  .page-future .policy .policy-row {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
.page-future .policy .policy-row .policy-content {
  width: 45%;
  padding: 20px 0 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 1280px) {
  .page-future .policy .policy-row .policy-content {
    padding: 20px 0 44px;
  }
}
@media (max-width: 768px) {
  .page-future .policy .policy-row .policy-content {
    width: 100%;
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    padding: 35px 0 60px;
  }
}
.page-future .policy .policy-row .policy-thumb {
  width: 55%;
  margin-top: -58px;
  position: relative;
  z-index: 1;
}
@media (max-width: 768px) {
  .page-future .policy .policy-row .policy-thumb {
    width: 100%;
    margin: -196px 0 0;
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    text-align: center;
  }
  .page-future .policy .policy-row .policy-thumb::after {
    position: absolute;
    bottom: 0;
    left: -16px;
    content: "";
    width: calc(100% + 32px);
    height: 88px;
    background: #0246C6;
    border-radius: 0 0 16px 16px;
  }
}
.page-future .policy .policy-row .policy-thumb img {
  position: relative;
  z-index: 1;
}
@media (max-width: 768px) {
  .page-future .policy .policy-row .policy-thumb img {
    max-width: 265px;
  }
}
.page-future .policy .policy-info {
  width: 100%;
  max-width: 570px;
  padding-right: 10px;
}
@media (max-width: 1280px) {
  .page-future .policy .policy-info {
    max-width: 380px;
    padding-right: 0;
  }
}
@media (max-width: 768px) {
  .page-future .policy .policy-info {
    max-width: 100%;
  }
}
.page-future .policy .policy-info .policy-mask {
  padding-bottom: 6%;
  position: relative;
}
@media (max-width: 768px) {
  .page-future .policy .policy-info .policy-mask {
    padding-bottom: 28px;
  }
}
.page-future .policy .policy-info .policy-mask::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  background: rgba(255, 255, 255, 0.1);
  width: calc(100% + 100px);
  height: 3px;
}
@media (max-width: 1280px) {
  .page-future .policy .policy-info .policy-mask::after {
    width: calc(100% + 200px);
  }
}
@media (max-width: 768px) {
  .page-future .policy .policy-info .policy-mask::after {
    width: 100%;
    height: 2px;
  }
}
.page-future .policy .policy-info .policy-mask .policy-text {
  font-size: 23px;
  color: #fff;
  line-height: 160%;
}
@media (max-width: 1280px) {
  .page-future .policy .policy-info .policy-mask .policy-text {
    font-size: 15px;
  }
}
@media (max-width: 900px) {
  .page-future .policy .policy-info .policy-mask .policy-text {
    line-height: normal;
  }
}
@media (max-width: 768px) {
  .page-future .policy .policy-info .policy-mask .policy-text {
    font-size: 16px;
    line-height: 160%;
  }
}
.page-future .policy .policy-info .policy-key {
  padding-top: 6%;
}
@media (max-width: 768px) {
  .page-future .policy .policy-info .policy-key {
    padding-top: 20px;
  }
}
.page-future .policy .policy-info .policy-key .policy-text {
  color: rgba(255, 255, 255, 0.2);
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 170%;
}
@media (max-width: 1280px) {
  .page-future .policy .policy-info .policy-key .policy-text {
    font-size: 13px;
  }
}
@media (max-width: 900px) {
  .page-future .policy .policy-info .policy-key .policy-text {
    line-height: normal;
  }
}
@media (max-width: 768px) {
  .page-future .policy .policy-info .policy-key .policy-text {
    line-height: 170%;
  }
}
.page-future .policy .policy-info .policy-key .policy-text span {
  color: #fff;
}
.page-future .possibility {
  position: relative;
  z-index: 1;
  margin-top: -48px;
  padding-bottom: 108px;
  min-height: 1009px;
}
@media (max-width: 1280px) {
  .page-future .possibility {
    min-height: 673px;
    margin-top: -32px;
    padding-bottom: 72px;
  }
}
@media (max-width: 768px) {
  .page-future .possibility {
    min-height: 809px;
    margin-top: -16px;
    padding-bottom: 45px;
  }
}
.page-future .possibility::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 1009px;
  border-radius: 48px;
  background: url(../../images/data/bg-possibility.jpg) no-repeat top center;
  background-size: cover;
  overflow: hidden;
}
@media (max-width: 1280px) {
  .page-future .possibility::after {
    border-radius: 32px;
    height: 673px;
  }
}
@media (max-width: 768px) {
  .page-future .possibility::after {
    border-radius: 16px;
    height: 809px;
  }
}
.page-future .possibility-wrap {
  position: relative;
  z-index: 1;
}
.page-future .possibility-wrap .possibility-title {
  padding-top: 90px;
  max-width: 662px;
}
@media (max-width: 1280px) {
  .page-future .possibility-wrap .possibility-title {
    padding-top: 60px;
    max-width: 442px;
  }
}
@media (max-width: 768px) {
  .page-future .possibility-wrap .possibility-title {
    padding-top: 42px;
    max-width: 100%;
  }
}
.page-future .possibility-wrap .possibility-title .tlt {
  color: #0246C6;
  font-size: 40px;
  font-weight: 600;
}
@media (max-width: 1280px) {
  .page-future .possibility-wrap .possibility-title .tlt {
    font-size: 26px;
  }
}
@media (max-width: 768px) {
  .page-future .possibility-wrap .possibility-title .tlt {
    font-size: 18px;
  }
}
.page-future .possibility-wrap .possibility-title .tlt span {
  color: #2F333E;
}
.page-future .possibility-list {
  padding-top: 70px;
}
@media (max-width: 1280px) {
  .page-future .possibility-list {
    padding-top: 56px;
  }
}
@media (max-width: 900px) {
  .page-future .possibility-list {
    padding-top: 40px;
  }
}
@media (max-width: 480px) {
  .page-future .possibility-list {
    padding-top: 18px;
  }
}
.page-future .possibility-list ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-left: -16px;
}
@media (max-width: 1280px) {
  .page-future .possibility-list ul {
    margin-left: -10px;
  }
}
.page-future .possibility-list ul li {
  margin: 16px 0 0 16px;
  background: #fff;
  padding: 24px 2.2%;
  -webkit-box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.06);
  width: calc(25% - 16px);
}
@media (max-width: 1280px) {
  .page-future .possibility-list ul li {
    margin: 10px 0 0 10px;
    width: calc(25% - 10px);
    padding: 16px 1.8%;
  }
}
@media (max-width: 900px) {
  .page-future .possibility-list ul li {
    width: calc(33.333333% - 10px);
  }
}
@media (max-width: 480px) {
  .page-future .possibility-list ul li {
    width: calc(50% - 10px);
    padding: 16px;
  }
}
.page-future .possibility-list ul li:nth-child(5), .page-future .possibility-list ul li:nth-child(6), .page-future .possibility-list ul li:nth-child(7) {
  width: calc(33.333333% - 16px);
}
@media (max-width: 1280px) {
  .page-future .possibility-list ul li:nth-child(5), .page-future .possibility-list ul li:nth-child(6), .page-future .possibility-list ul li:nth-child(7) {
    width: calc(33.333333% - 10px);
  }
}
@media (max-width: 480px) {
  .page-future .possibility-list ul li:nth-child(5), .page-future .possibility-list ul li:nth-child(6), .page-future .possibility-list ul li:nth-child(7) {
    width: calc(50% - 10px);
  }
}
.page-future .possibility-list ul li .possibility-tlt {
  font-size: 23px;
  font-weight: 600;
}
@media (max-width: 1280px) {
  .page-future .possibility-list ul li .possibility-tlt {
    font-size: 15px;
  }
}
@media (max-width: 480px) {
  .page-future .possibility-list ul li .possibility-tlt {
    font-size: 16px;
  }
}
.page-future .possibility-list ul li .possibility-text {
  margin-top: 32px;
  font-size: 18px;
  color: #383C46;
}
@media (max-width: 1280px) {
  .page-future .possibility-list ul li .possibility-text {
    margin-top: 21px;
    font-size: 12px;
  }
}
@media (max-width: 480px) {
  .page-future .possibility-list ul li .possibility-text {
    margin-top: 16px;
    font-size: 14px;
  }
}
.page-future .conclusion {
  margin-top: 108px;
  position: relative;
}
@media (max-width: 1280px) {
  .page-future .conclusion {
    margin-top: 72px;
  }
}
@media (max-width: 768px) {
  .page-future .conclusion {
    margin-top: 38px;
  }
}
.page-future .conclusion .conclusion-thumb {
  background-size: cover;
  background-position: top right;
  border-radius: 16px;
  width: 50%;
  height: 489px;
  overflow: hidden;
}
@media (max-width: 1280px) {
  .page-future .conclusion .conclusion-thumb {
    height: 326px;
  }
}
@media (max-width: 768px) {
  .page-future .conclusion .conclusion-thumb {
    height: initial;
    width: 100%;
    border-radius: 10px;
    background: none;
  }
}
.page-future .conclusion .conclusion-thumb img {
  display: none;
  width: 100%;
}
@media (max-width: 768px) {
  .page-future .conclusion .conclusion-thumb img {
    display: block;
  }
}
.page-future .conclusion .conclusion-info {
  position: absolute;
  top: 0;
  right: 0;
  width: calc(50% + 24px);
  height: 100%;
  background: #252C6D;
  border-radius: 16px;
  padding: 10px;
  color: #fff;
}
@media (max-width: 768px) {
  .page-future .conclusion .conclusion-info {
    position: relative;
    width: 100%;
    margin-top: -10px;
    border-radius: 0px 0px 10px 10px;
  }
}
.page-future .conclusion .conclusion-info .conclusion-content {
  height: 100%;
  padding: 10%;
  overflow: auto;
  scrollbar-width: thin;
}
@media (max-width: 768px) {
  .page-future .conclusion .conclusion-info .conclusion-content {
    padding: 20px 6px;
  }
}
.page-future .conclusion .conclusion-info .conclusion-content::-webkit-scrollbar {
  width: 8px;
}
.page-future .conclusion .conclusion-info .conclusion-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.2);
}
.page-future .conclusion .conclusion-info .conclusion-content .conclusion-text {
  font-size: 20px;
  font-weight: 500;
  line-height: 120%;
}
@media (max-width: 1280px) {
  .page-future .conclusion .conclusion-info .conclusion-content .conclusion-text {
    font-size: 13px;
  }
}
@media (max-width: 768px) {
  .page-future .conclusion .conclusion-info .conclusion-content .conclusion-text {
    font-size: 16px;
  }
}
.page-future .conclusion .conclusion-info .conclusion-content .conclusion-textFl {
  margin-top: 36px;
  font-size: 18px;
  line-height: 140%;
}
@media (max-width: 1280px) {
  .page-future .conclusion .conclusion-info .conclusion-content .conclusion-textFl {
    margin-top: 24px;
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  .page-future .conclusion .conclusion-info .conclusion-content .conclusion-textFl {
    margin-top: 16px;
    font-size: 16px;
  }
}
.page-future .searching {
  background: url(../../images/data/bg-searching.jpg) no-repeat center;
  background-size: cover;
  padding: 96px 0;
}
@media (max-width: 1280px) {
  .page-future .searching {
    padding: 64px 0;
  }
}
@media (max-width: 768px) {
  .page-future .searching {
    background: url(../../images/data/bg-searching-sp.jpg) no-repeat center;
    background-size: cover;
    padding: 32px 0;
  }
}
.page-future .searching .searching-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 768px) {
  .page-future .searching .searching-info {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
.page-future .searching .searching-info .searching-content {
  padding-right: 10px;
  color: #fff;
  font-size: 36px;
}
@media (max-width: 1280px) {
  .page-future .searching .searching-info .searching-content {
    font-size: 24px;
  }
}
@media (max-width: 768px) {
  .page-future .searching .searching-info .searching-content {
    width: 100%;
    font-size: 20px;
    line-height: 160%;
    padding: 0 0 16px;
    text-align: center;
  }
}
.page-future .searching .searching-info .searching-content .searching-tlt {
  font-weight: 700;
}
@media (max-width: 768px) {
  .page-future .searching .searching-info .searching-btn {
    width: 100%;
  }
}
.page-future .searching .searching-info .searching-btn .btn {
  -webkit-box-shadow: 0px 13px 25px 0px rgba(10, 9, 45, 0.25);
          box-shadow: 0px 13px 25px 0px rgba(10, 9, 45, 0.25);
}
@media (max-width: 768px) {
  .page-future .searching .searching-info .searching-btn .btn {
    margin: 0 auto;
  }
}

/* -- page-home -- */
.page-home .digital {
  background: url(../../images/data/banner-home.jpg) no-repeat center;
  background-size: cover;
  height: 838px;
  padding: 104px 0 40px;
  overflow: hidden;
  position: relative;
}
@media (min-width: 1025px) {
  .page-home .digital {
    background-attachment: fixed;
  }
}
@media (max-width: 1280px) {
  .page-home .digital {
    padding: 96px 0 32px;
    height: 648px;
  }
}
@media (max-width: 768px) {
  .page-home .digital {
    height: 562px;
  }
}
.page-home .digital .digital-title .tlt {
  color: #fff;
  font-size: 56px;
}
@media (max-width: 1280px) {
  .page-home .digital .digital-title .tlt {
    font-size: 41px;
    font-weight: 500;
  }
}
@media (max-width: 768px) {
  .page-home .digital .digital-title .tlt {
    font-size: 26px;
  }
}
.page-home .digital .digital-btn {
  padding-top: 72px;
}
@media (max-width: 1280px) {
  .page-home .digital .digital-btn {
    padding-top: 48px;
  }
}
.page-home .digital .digital-wrap {
  margin-top: 176px;
  padding-left: 44px;
  position: relative;
  min-height: 100px;
}
@media (max-width: 1280px) {
  .page-home .digital .digital-wrap {
    margin-top: 96px;
  }
}
@media (max-width: 768px) {
  .page-home .digital .digital-wrap {
    width: calc(100% + 32px);
    margin-left: -16px;
    padding-left: 0;
  }
}
.page-home .digital .digital-wrap .digital-arrow {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 34px;
}
@media (max-width: 768px) {
  .page-home .digital .digital-wrap .digital-arrow {
    display: none;
  }
}
.page-home .digital .digital-wrap .digital-arrow a {
  border-radius: 24px;
  border: 0.75px solid var(--grey-scale-100-white, #FFF);
  background: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(4.5px);
          backdrop-filter: blur(4.5px);
  height: 55px;
  display: block;
  position: relative;
}
.page-home .digital .digital-wrap .digital-arrow a::after {
  position: absolute;
  top: 12px;
  left: calc(50% - 1.5px);
  content: "";
  width: 3px;
  height: 12px;
  border-radius: 12px;
  background: #fff;
  -webkit-animation-name: circle;
          animation-name: circle;
  -webkit-animation-duration: 2000ms;
          animation-duration: 2000ms;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: normal;
          animation-direction: normal;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
@-webkit-keyframes circle {
  0% {
    top: 10px;
  }
  25% {
    top: 35px;
  }
  50% {
    top: 10px;
  }
  75% {
    top: 35px;
  }
  100% {
    top: 10px;
  }
}
@keyframes circle {
  0% {
    top: 10px;
  }
  25% {
    top: 35px;
  }
  50% {
    top: 10px;
  }
  75% {
    top: 35px;
  }
  100% {
    top: 10px;
  }
}
.page-home .digital .digital-wrap .digitalSlide {
  max-width: 940px;
  margin-left: auto;
}
@media (max-width: 768px) {
  .page-home .slider-digital .slick-list {
    padding-left: 16px;
  }
}
.page-home .slider-digital .slick-slide {
  padding-right: 16px;
}
@media (max-width: 768px) {
  .page-home .slider-digital .slick-slide {
    width: 276px;
    padding-right: 16px;
  }
}
.page-home .digital-info {
  overflow: hidden;
  display: block;
}
.page-home .digital-info a {
  display: block;
  background: rgba(0, 0, 0, 0.4);
  padding: 16px 24px 24px 24px;
  -webkit-transition: 400ms all;
  transition: 400ms all;
  display: block;
}
@media (max-width: 1280px) {
  .page-home .digital-info a {
    padding: 16px;
  }
}
@media (max-width: 768px) {
  .page-home .digital-info a {
    background: #fff;
  }
}
.page-home .digital-info a:hover {
  background: #fff;
}
.page-home .digital-info a:hover .digital-info__title {
  border-bottom: 1px solid #F1F2F2;
}
.page-home .digital-info a:hover .digital-info__title .tlt {
  color: #6C6F77;
}
.page-home .digital-info a:hover .digital-info__title .item {
  background: #1C57C5;
  border: 1px solid #3D6CC4;
}
.page-home .digital-info a:hover .digital-info__title .item::after {
  left: 100%;
}
.page-home .digital-info a:hover .digital-info__title .item::before {
  left: 0;
}
.page-home .digital-info a:hover .digital-info__content {
  overflow: hidden;
  display: block;
}
.page-home .digital-info a:hover .digital-info__content .digital-text {
  color: #2F333E;
}
.page-home .digital-info a .digital-info__title {
  border-bottom: 1px solid rgba(108, 111, 119, 0.2);
  padding-bottom: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 768px) {
  .page-home .digital-info a .digital-info__title {
    border-bottom: 1px solid #F1F2F2;
  }
}
.page-home .digital-info a .digital-info__title .tlt {
  font-size: 16px;
  color: #fff;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media (max-width: 1280px) {
  .page-home .digital-info a .digital-info__title .tlt {
    font-size: 14px;
  }
}
@media (max-width: 768px) {
  .page-home .digital-info a .digital-info__title .tlt {
    color: #6C6F77;
  }
}
.page-home .digital-info a .digital-info__title .item {
  min-width: 24px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  margin-left: 10px;
  background: #fff;
  border: 1px solid #fff;
  position: relative;
}
@media (max-width: 768px) {
  .page-home .digital-info a .digital-info__title .item {
    background: #1C57C5;
    border: 1px solid #3D6CC4;
  }
}
.page-home .digital-info a .digital-info__title .item::after, .page-home .digital-info a .digital-info__title .item::before {
  position: absolute;
  top: 0;
  content: "";
  width: 24px;
  height: 24px;
  -webkit-transition: 400ms all;
  transition: 400ms all;
}
.page-home .digital-info a .digital-info__title .item::after {
  left: 0;
  background: url(../../images/common/icon-arrow-digital-gray.svg) no-repeat center;
}
@media (max-width: 768px) {
  .page-home .digital-info a .digital-info__title .item::after {
    left: 100%;
  }
}
.page-home .digital-info a .digital-info__title .item::before {
  left: -100%;
  background: url(../../images/common/icon-arrow-digital-white.svg) no-repeat center;
}
@media (max-width: 768px) {
  .page-home .digital-info a .digital-info__title .item::before {
    left: 0;
  }
}
.page-home .digital-info a .digital-info__content {
  padding-top: 24px;
  display: block;
  overflow: hidden;
}
@media (max-width: 1280px) {
  .page-home .digital-info a .digital-info__content {
    padding-top: 16px;
  }
}
.page-home .digital-info a .digital-info__content .digital-text {
  font-size: 16px;
  font-weight: 500;
  color: #F1F2F2;
  line-height: 140%;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 3;
  -webkit-box-pack: end;
  height: 67px;
}
@media (max-width: 768px) {
  .page-home .digital-info a .digital-info__content .digital-text {
    color: #2F333E;
    font-size: 14px;
    height: 58px;
  }
}
.page-home .navLink {
  overflow: hidden;
  position: sticky;
  top: -1px;
  z-index: 8;
  background: #f5f6f8;
  -webkit-transition: 600ms all;
  transition: 600ms all;
}
.page-home .navLink.navLink-fixed {
  top: 92px;
}
@media (max-width: 1280px) {
  .page-home .navLink.navLink-fixed {
    top: 55px;
  }
}
@media (max-width: 1180px) {
  .page-home .navLink {
    top: 55px;
  }
}
@media (max-width: 768px) {
  .page-home .navLink {
    display: none;
  }
}
.page-home .navLink ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.page-home .navLink ul li {
  width: 100%;
}
.page-home .navLink ul li a {
  display: block;
  text-align: center;
  padding: 22px 10px;
  font-size: 16px;
  color: #2F333E;
}
.page-home .navLink ul li a.active {
  background: #fff;
  color: #0246C6;
}
@media (min-width: 1180px) {
  .page-home .navLink ul li a:hover {
    background: #fff;
    color: #0246C6;
  }
}
.page-home .who-we {
  padding: 104px 0 0;
  background: #fff;
  overflow: hidden;
}
@media (max-width: 1280px) {
  .page-home .who-we {
    padding: 48px 0 0;
  }
}
@media (max-width: 768px) {
  .page-home .who-we {
    padding: 48px 0 0;
  }
}
.page-home .who-we .title-who {
  padding-bottom: 48px;
  border-bottom: 1px solid #C3C4D2;
}
@media (max-width: 1280px) {
  .page-home .who-we .title-who {
    padding-bottom: 32px;
  }
}
@media (max-width: 768px) {
  .page-home .who-we .title-who {
    border-bottom: none;
  }
}
.page-home .who-we .title-who .tlt {
  font-size: 20px;
  text-align: center;
}
@media (max-width: 1280px) {
  .page-home .who-we .title-who .tlt {
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .page-home .who-we .title-who .tlt {
    font-size: 12px;
  }
}
.page-home .who-we .video-full {
  margin-top: 136px;
}
@media (max-width: 1280px) {
  .page-home .who-we .video-full {
    margin-top: 64px;
  }
}
@media (max-width: 768px) {
  .page-home .who-we .video-full {
    margin-top: 48px;
  }
}
.page-home .who-we .who-info {
  padding-top: 64px;
  text-align: center;
  max-width: 1024px;
  margin: 0 auto;
}
@media (max-width: 1280px) {
  .page-home .who-we .who-info {
    max-width: 600px;
  }
}
@media (max-width: 768px) {
  .page-home .who-we .who-info {
    padding-top: 0;
  }
}
.page-home .who-we .who-info .who-title {
  color: #0246C6;
  font-size: 58px;
  font-weight: 600;
}
@media (max-width: 1280px) {
  .page-home .who-we .who-info .who-title {
    font-size: 41px;
  }
}
@media (max-width: 768px) {
  .page-home .who-we .who-info .who-title {
    font-size: 23px;
  }
}
.page-home .who-we .who-info .who-title span {
  display: block;
  color: #2F333E;
}
@media (max-width: 768px) {
  .page-home .who-we .who-info .who-title span {
    display: initial;
  }
}
.page-home .who-we .who-info .who-text {
  padding-top: 64px;
  color: #383C46;
  font-size: 29px;
}
@media (max-width: 1280px) {
  .page-home .who-we .who-info .who-text {
    padding-top: 48px;
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .page-home .who-we .who-info .who-text {
    padding-top: 40px;
    font-size: 16px;
  }
}
.page-home .who-we .who-btn {
  padding-top: 64px;
}
@media (max-width: 1280px) {
  .page-home .who-we .who-btn {
    padding-top: 48px;
  }
}
@media (max-width: 768px) {
  .page-home .who-we .who-btn {
    padding-top: 40px;
  }
}
.page-home .who-we .who-btn .btn {
  margin: 0 auto;
}
.page-home .video-full {
  position: relative;
}
@media (max-width: 768px) {
  .page-home .video-full {
    background: #f5f6f8;
  }
}
.page-home .video-full img {
  width: 100%;
}
.page-home .video-full .embed-responsive::before {
  padding-top: 56.857143%;
}
.page-home .video-full .video-btn {
  position: absolute;
  bottom: 80px;
  left: 0;
  width: 100%;
  text-align: center;
}
@media (max-width: 1280px) {
  .page-home .video-full .video-btn {
    bottom: 42px;
  }
}
@media (max-width: 768px) {
  .page-home .video-full .video-btn {
    position: static;
    padding-top: 32px;
  }
}
.page-home .video-full .video-btn .btn {
  height: 54px;
  -webkit-box-shadow: 0px 15px 17px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 15px 17px 0px rgba(0, 0, 0, 0.2);
}
@media (max-width: 768px) {
  .page-home .video-full .video-btn .btn {
    -webkit-box-shadow: none;
            box-shadow: none;
    height: 48px;
    min-width: 170px;
  }
}
.page-home .ourProjects {
  padding: 64px 0 0;
  overflow: hidden;
}
@media (max-width: 768px) {
  .page-home .ourProjects {
    padding: 48px 0 0;
  }
}
@media (max-width: 768px) {
  .page-home .ourProjects .title-our {
    text-align: center;
  }
}
.page-home .ourProjects .title-our .tlt {
  font-size: 64px;
  font-weight: 600;
}
@media (max-width: 1280px) {
  .page-home .ourProjects .title-our .tlt {
    font-size: 41px;
  }
}
@media (max-width: 768px) {
  .page-home .ourProjects .title-our .tlt {
    font-size: 29px;
  }
}
.page-home .ourProjects .title-our .our-text {
  padding-top: 24px;
  font-size: 26px;
}
@media (max-width: 1280px) {
  .page-home .ourProjects .title-our .our-text {
    padding-top: 16px;
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .page-home .ourProjects .title-our .our-text {
    padding-top: 8px;
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .page-home .ourProjects-pc {
    display: none;
  }
}
.page-home .ourProjects-pc .slider-projects {
  padding: 64px 0 0;
}
.page-home .ourProjects-pc .slider-projects .slick-track {
  -webkit-transition: 400ms all;
  transition: 400ms all;
}
.page-home .ourProjects-pc .slider-projects .slick-dots {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 64px 100px 0;
  position: static;
}
@media (max-width: 1280px) {
  .page-home .ourProjects-pc .slider-projects .slick-dots {
    padding: 64px 40px 0;
  }
}
@media (max-width: 768px) {
  .page-home .ourProjects-pc .slider-projects .slick-dots {
    padding: 0 16px;
  }
}
.page-home .ourProjects-pc .slider-projects .slick-slide {
  min-width: 260px;
  max-width: 260px;
  overflow: hidden;
  margin-right: 24px;
  cursor: pointer;
  -webkit-transition: 400ms all;
  transition: 400ms all;
}
@media (max-width: 1280px) {
  .page-home .ourProjects-pc .slider-projects .slick-slide {
    margin-right: 16px;
  }
}
.page-home .ourProjects-pc .slider-projects .slick-slide.active, .page-home .ourProjects-pc .slider-projects .slick-slide.slick-active {
  min-width: 800px;
  max-width: 800px;
}
@media (max-width: 1280px) {
  .page-home .ourProjects-pc .slider-projects .slick-slide.active, .page-home .ourProjects-pc .slider-projects .slick-slide.slick-active {
    min-width: 600px;
    max-width: 600px;
  }
}
.page-home .ourProjects-pc .slider-projects .slick-slide.active .projects-info .projects-content .projects-main .tlt, .page-home .ourProjects-pc .slider-projects .slick-slide.slick-active .projects-info .projects-content .projects-main .tlt {
  font-size: 29px;
  font-weight: 600;
  max-width: 516px;
  padding-right: 10px;
}
@media (max-width: 1280px) {
  .page-home .ourProjects-pc .slider-projects .slick-slide.active .projects-info .projects-content .projects-main .tlt, .page-home .ourProjects-pc .slider-projects .slick-slide.slick-active .projects-info .projects-content .projects-main .tlt {
    font-size: 20px;
  }
}
.page-home .ourProjects-pc .slider-projects .slick-slide.active .projects-info .projects-content .projects-main .projects-btn, .page-home .ourProjects-pc .slider-projects .slick-slide.slick-active .projects-info .projects-content .projects-main .projects-btn {
  display: initial;
}
.page-home .projects-info {
  background: #fff;
}
.page-home .projects-info .projects-thumb {
  height: 309px;
  width: 800px;
  overflow: hidden;
  position: relative;
}
.page-home .projects-info .projects-thumb img {
  max-height: 309px;
  position: absolute;
  top: 0;
  right: 0;
}
.page-home .projects-info .projects-content {
  padding: 32px;
  min-height: 134px;
}
.page-home .projects-info .projects-content .projects-main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.page-home .projects-info .projects-content .projects-main .tlt {
  font-size: 20px;
  font-weight: 500;
  height: 72px;
  overflow: hidden;
  /*transition: 1500ms all;*/
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 1280px) {
  .page-home .projects-info .projects-content .projects-main .tlt {
    font-size: 18px;
  }
}
.page-home .projects-info .projects-content .projects-main .projects-btn {
  display: none;
}
.page-home .ourProjects-sp {
  display: none;
  padding-top: 40px;
}
@media (max-width: 768px) {
  .page-home .ourProjects-sp {
    display: block;
  }
}
.page-home .ourProjects-sp .projects-industries {
  padding-bottom: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.page-home .ourProjects-sp .projects-industries:last-child {
  padding-bottom: 0;
}
.page-home .ourProjects-sp .projects-industries .projects-thumb {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}
.page-home .ourProjects-sp .projects-industries .projects-content {
  background: #fff;
  padding: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: 400ms all;
  transition: 400ms all;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  min-height: 78px;
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}
.page-home .ourProjects-sp .projects-industries .projects-content[aria-expanded=true] .projects-btn {
  display: initial;
}
.page-home .ourProjects-sp .projects-industries .projects-content .tlt {
  font-size: 16px;
  font-weight: 500;
  color: #2F333E;
}
.page-home .ourProjects-sp .projects-industries .projects-content .projects-btn {
  min-width: 30px;
  margin-left: 10px;
  display: none;
}
.page-home .ourProjects-sp .projects-industries .projects-content .projects-btn .btn {
  background: #1C57C5;
  border: 1px solid #3D6CC4;
  border-radius: 50%;
  padding: 0;
  width: 30px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.page-home .ourProjects-sp .projects-industries .projects-content--active .projects-btn {
  display: initial;
}
.page-home .chooseUs-our {
  padding-top: 112px;
  overflow: hidden;
}
@media (max-width: 768px) {
  .page-home .chooseUs-our {
    padding-top: 40px;
  }
}
.page-home .chooseUs-our .chooseUs-title {
  position: relative;
  z-index: 1;
}
@media (max-width: 1280px) {
  .page-home .chooseUs-our .chooseUs-title {
    max-width: 366px;
  }
}
@media (max-width: 768px) {
  .page-home .chooseUs-our .chooseUs-title {
    max-width: 100%;
  }
}
.page-home .chooseUs-our .chooseUs-title .chooseUs-title__label {
  font-size: 18px;
}
@media (max-width: 1280px) {
  .page-home .chooseUs-our .chooseUs-title .chooseUs-title__label {
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .page-home .chooseUs-our .chooseUs-title .chooseUs-title__label {
    font-size: 12px;
  }
}
.page-home .chooseUs-our .chooseUs-title .chooseUs-title__label span {
  font-weight: 700;
}
.page-home .chooseUs-our .chooseUs-title .chooseUs-title__title {
  padding-top: 48px;
  font-size: 36px;
  font-weight: 600;
}
@media (max-width: 1280px) {
  .page-home .chooseUs-our .chooseUs-title .chooseUs-title__title {
    font-size: 29px;
  }
}
@media (max-width: 900px) {
  .page-home .chooseUs-our .chooseUs-title .chooseUs-title__title {
    font-size: 25px;
  }
}
@media (max-width: 768px) {
  .page-home .chooseUs-our .chooseUs-title .chooseUs-title__title {
    padding-top: 24px;
    font-size: 23px;
  }
}
.page-home .chooseUs-our .chooseUs-title .chooseUs-title__title span {
  color: #0246C6;
}
.page-home .chooseUs-our .chooseUs-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: -36px;
}
@media (max-width: 768px) {
  .page-home .chooseUs-our .chooseUs-row {
    margin-top: 0;
  }
}
.page-home .chooseUs-our .chooseUs-row .chooseUs-col {
  width: 50%;
}
@media (max-width: 768px) {
  .page-home .chooseUs-our .chooseUs-row .chooseUs-col {
    width: 100%;
  }
}
.page-home .chooseUs-our .chooseUs-row .chooseUs-col:nth-child(2) {
  padding-left: 5%;
}
@media (max-width: 1280px) {
  .page-home .chooseUs-our .chooseUs-row .chooseUs-col:nth-child(2) {
    padding-left: 3%;
  }
}
@media (max-width: 768px) {
  .page-home .chooseUs-our .chooseUs-row .chooseUs-col:nth-child(2) {
    padding-left: 0;
  }
}
.page-home .chooseUs-our .chooseUs-row .chooseUs-thumb {
  margin-left: -146px;
}
@media (max-width: 1280px) {
  .page-home .chooseUs-our .chooseUs-row .chooseUs-thumb {
    margin-left: -40px;
  }
}
@media (max-width: 768px) {
  .page-home .chooseUs-our .chooseUs-row .chooseUs-thumb {
    margin-left: 0;
  }
}
.page-home .chooseUs-our .chooseUs-row .chooseUs-thumb img {
  width: 100%;
}
.page-home .chooseUs-our .chooseUs-row .chooseUs-list ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.page-home .chooseUs-our .chooseUs-row .chooseUs-list ul li {
  width: 50%;
  background: rgba(255, 255, 255, 0.4);
  -webkit-transition: 400ms all;
  transition: 400ms all;
  padding: 6%;
}
@media (max-width: 768px) {
  .page-home .chooseUs-our .chooseUs-row .chooseUs-list ul li {
    padding: 16px;
  }
}
.page-home .chooseUs-our .chooseUs-row .chooseUs-list ul li.active, .page-home .chooseUs-our .chooseUs-row .chooseUs-list ul li:hover {
  color: #fff;
  background: #252C6D;
}
@media (max-width: 768px) {
  .page-home .chooseUs-our .chooseUs-row .chooseUs-list ul li.active .chooseUs-text, .page-home .chooseUs-our .chooseUs-row .chooseUs-list ul li:hover .chooseUs-text {
    color: #fff;
  }
}
.page-home .chooseUs-our .chooseUs-row .chooseUs-list ul li.active:hover {
  color: #2F333E;
  background: #fff;
}
@media (max-width: 768px) {
  .page-home .chooseUs-our .chooseUs-row .chooseUs-list ul li.active:hover .chooseUs-text {
    color: #2F333E;
  }
}
.page-home .chooseUs-our .chooseUs-row .chooseUs-list ul li .chooseUs-number {
  font-size: 64px;
}
@media (max-width: 1280px) {
  .page-home .chooseUs-our .chooseUs-row .chooseUs-list ul li .chooseUs-number {
    font-size: 48px;
  }
}
@media (max-width: 768px) {
  .page-home .chooseUs-our .chooseUs-row .chooseUs-list ul li .chooseUs-number {
    font-size: 23px;
    font-weight: 500;
  }
}
.page-home .chooseUs-our .chooseUs-row .chooseUs-list ul li .chooseUs-text {
  font-size: 23px;
  padding-top: 48px;
}
@media (max-width: 1280px) {
  .page-home .chooseUs-our .chooseUs-row .chooseUs-list ul li .chooseUs-text {
    font-size: 20px;
    padding-top: 32px;
  }
}
@media (max-width: 768px) {
  .page-home .chooseUs-our .chooseUs-row .chooseUs-list ul li .chooseUs-text {
    padding-top: 24px;
    font-size: 14px;
    color: #383C46;
  }
}
.page-home .chooseUs-global {
  padding: 96px 0 104px;
  overflow: hidden;
}
@media (max-width: 768px) {
  .page-home .chooseUs-global {
    padding: 80px 0 62px;
  }
}
.page-home .chooseUs-global .chooseUs-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 768px) {
  .page-home .chooseUs-global .chooseUs-title {
    display: block;
  }
}
.page-home .chooseUs-global .chooseUs-title .chooseUs-title__label {
  font-size: 18px;
}
@media (max-width: 1280px) {
  .page-home .chooseUs-global .chooseUs-title .chooseUs-title__label {
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .page-home .chooseUs-global .chooseUs-title .chooseUs-title__label {
    font-size: 12px;
  }
}
.page-home .chooseUs-global .chooseUs-title .chooseUs-title__label span {
  font-weight: 700;
}
.page-home .chooseUs-global .chooseUs-title .chooseUs-title__title {
  max-width: 684px;
  font-size: 36px;
  font-weight: 600;
}
@media (max-width: 1280px) {
  .page-home .chooseUs-global .chooseUs-title .chooseUs-title__title {
    max-width: 46%;
    font-size: 29px;
  }
}
@media (max-width: 768px) {
  .page-home .chooseUs-global .chooseUs-title .chooseUs-title__title {
    font-size: 23px;
    padding-top: 32px;
    max-width: 100%;
  }
}
.page-home .chooseUs-global .chooseUs-title .chooseUs-title__title span {
  color: #0246C6;
}
.page-home .choose-maps {
  padding-top: 64px;
}
@media (max-width: 768px) {
  .page-home .choose-maps {
    padding-top: 40px;
  }
}
.page-home .choose-maps .maps-wrap {
  position: relative;
}
.page-home .choose-maps .maps-add {
  max-width: 482px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
@media (max-width: 1280px) {
  .page-home .choose-maps .maps-add {
    max-width: 100%;
    position: static;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
@media (max-width: 1280px) {
  .page-home .choose-maps .maps-add .add-list {
    width: 52%;
    padding-right: 2%;
  }
}
@media (max-width: 768px) {
  .page-home .choose-maps .maps-add .add-list {
    width: 100%;
    padding-right: 0;
  }
}
.page-home .choose-maps .maps-add .add-list ul li {
  padding-bottom: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 1280px) {
  .page-home .choose-maps .maps-add .add-list ul li {
    padding-bottom: 30px;
  }
}
@media (max-width: 768px) {
  .page-home .choose-maps .maps-add .add-list ul li {
    padding-bottom: 16px;
  }
}
.page-home .choose-maps .maps-add .add-list ul li:last-child {
  padding-bottom: 0;
}
.page-home .choose-maps .maps-add .add-list ul li .add-title {
  min-width: 200px;
  font-size: 23px;
  font-weight: 600;
  cursor: pointer;
}
@media (max-width: 1280px) {
  .page-home .choose-maps .maps-add .add-list ul li .add-title {
    min-width: 120px;
  }
}
@media (max-width: 768px) {
  .page-home .choose-maps .maps-add .add-list ul li .add-title {
    font-size: 16px;
    min-width: 100px;
  }
}
.page-home .choose-maps .maps-add .add-list ul li .add-title:hover {
  color: #0246C6;
}
.page-home .choose-maps .maps-add .add-list ul li .add-title.active {
  color: #0246C6;
}
.page-home .choose-maps .maps-add .add-list ul li .add-text {
  width: 100%;
  font-size: 20px;
  color: #383C46;
  padding-left: 24px;
  padding-top: 2px;
}
@media (max-width: 1280px) {
  .page-home .choose-maps .maps-add .add-list ul li .add-text {
    font-size: 18px;
    padding-top: 4px;
  }
}
@media (max-width: 768px) {
  .page-home .choose-maps .maps-add .add-list ul li .add-text {
    font-size: 14px;
    padding-left: 8px;
    padding-top: 2px;
  }
}
.page-home .choose-maps .maps-add .address {
  margin-top: 104px;
  position: relative;
}
@media (max-width: 1280px) {
  .page-home .choose-maps .maps-add .address {
    margin-top: 0;
    width: 48%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
@media (max-width: 768px) {
  .page-home .choose-maps .maps-add .address {
    display: none;
  }
}
.page-home .choose-maps .maps-add .address .address-text {
  position: relative;
  font-size: 18px;
  font-weight: 600;
  display: none;
  padding-left: 224px;
}
@media (max-width: 1280px) {
  .page-home .choose-maps .maps-add .address .address-text {
    margin-top: auto;
    color: #2F333E;
    font-weight: 400;
    padding-left: 0;
    padding-top: 20px;
    height: 100%;
  }
}
.page-home .choose-maps .maps-add .address .address-text::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 4px;
  width: 130px;
  background: #0246C6;
}
.page-home .choose-maps .maps-add .address .address-text.active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.page-home .choose-maps .maps-info {
  margin-left: 308px;
  position: relative;
}
@media (max-width: 1280px) {
  .page-home .choose-maps .maps-info {
    max-width: 1055px;
    margin: 64px auto 0;
  }
}
@media (max-width: 768px) {
  .page-home .choose-maps .maps-info {
    margin-top: 40px;
  }
}
.page-home .choose-maps .maps-info img {
  width: 100%;
}
.page-home .choose-maps .maps-info .maps-locate {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.page-home .choose-maps .maps-info .maps-locate .locate {
  position: absolute;
  z-index: 1;
}
.page-home .choose-maps .maps-info .maps-locate .locate.locate--vietnam {
  right: 19%;
  top: 56%;
}
.page-home .choose-maps .maps-info .maps-locate .locate.locate--vietnam .locate-building {
  top: 35px;
  right: 35px;
}
@media (max-width: 900px) {
  .page-home .choose-maps .maps-info .maps-locate .locate.locate--vietnam .locate-building {
    top: 20px;
    right: 20px;
  }
}
@media (max-width: 768px) {
  .page-home .choose-maps .maps-info .maps-locate .locate.locate--vietnam .locate-building {
    top: 12px;
    right: 12px;
  }
}
.page-home .choose-maps .maps-info .maps-locate .locate.locate--japan {
  right: 9%;
  top: 36%;
}
@media (max-width: 900px) {
  .page-home .choose-maps .maps-info .maps-locate .locate.locate--japan {
    right: 11%;
  }
}
.page-home .choose-maps .maps-info .maps-locate .locate.locate--japan .locate-circle {
  width: 44px;
  height: 44px;
}
@media (max-width: 900px) {
  .page-home .choose-maps .maps-info .maps-locate .locate.locate--japan .locate-circle {
    width: 16px;
    height: 16px;
  }
}
@media (max-width: 768px) {
  .page-home .choose-maps .maps-info .maps-locate .locate.locate--japan .locate-circle {
    width: 12px;
    height: 12px;
  }
}
.page-home .choose-maps .maps-info .maps-locate .locate.locate--japan .locate-building {
  bottom: 35px;
  right: 35px;
}
@media (max-width: 900px) {
  .page-home .choose-maps .maps-info .maps-locate .locate.locate--japan .locate-building {
    bottom: 20px;
    right: 20px;
  }
}
@media (max-width: 768px) {
  .page-home .choose-maps .maps-info .maps-locate .locate.locate--japan .locate-building {
    bottom: 12px;
    right: 12px;
  }
}
@media (max-width: 480px) {
  .page-home .choose-maps .maps-info .maps-locate .locate.locate--japan .locate-building {
    right: -12px;
  }
}
.page-home .choose-maps .maps-info .maps-locate .locate.locate-finland {
  right: 49%;
  top: 21%;
}
.page-home .choose-maps .maps-info .maps-locate .locate.locate-finland .locate-building {
  left: 35px;
  top: 35px;
}
@media (max-width: 1280px) {
  .page-home .choose-maps .maps-info .maps-locate .locate.locate-finland .locate-building {
    left: initial;
    bottom: 32px;
    right: 32px;
  }
}
@media (max-width: 900px) {
  .page-home .choose-maps .maps-info .maps-locate .locate.locate-finland .locate-building {
    top: 20px;
    right: 20px;
  }
}
@media (max-width: 768px) {
  .page-home .choose-maps .maps-info .maps-locate .locate.locate-finland .locate-building {
    top: 12px;
    right: 12px;
  }
}
.page-home .choose-maps .maps-info .maps-locate .locate.locate-korean {
  right: 14%;
  top: 46%;
}
@media (max-width: 900px) {
  .page-home .choose-maps .maps-info .maps-locate .locate.locate-korean {
    right: 14.5%;
    top: 44%;
  }
}
.page-home .choose-maps .maps-info .maps-locate .locate.locate-korean .locate-building {
  bottom: 35px;
  right: 35px;
}
@media (max-width: 900px) {
  .page-home .choose-maps .maps-info .maps-locate .locate.locate-korean .locate-building {
    bottom: 20px;
    right: 20px;
  }
}
@media (max-width: 768px) {
  .page-home .choose-maps .maps-info .maps-locate .locate.locate-korean .locate-building {
    bottom: 12px;
    right: 12px;
  }
}
@media (max-width: 480px) {
  .page-home .choose-maps .maps-info .maps-locate .locate.locate-korean .locate-building {
    right: -40px;
  }
}
.page-home .choose-maps .maps-info .maps-locate .locate.active {
  z-index: 2;
}
.page-home .choose-maps .maps-info .maps-locate .locate.active.locate--vietnam {
  right: 15%;
  top: 52%;
}
.page-home .choose-maps .maps-info .maps-locate .locate.active.locate--japan {
  right: 6%;
  top: 36%;
}
.page-home .choose-maps .maps-info .maps-locate .locate.active.locate-finland {
  right: 45%;
  top: 17%;
}
.page-home .choose-maps .maps-info .maps-locate .locate.active .locate-circle {
  width: 70px;
  height: 70px;
}
@media (max-width: 900px) {
  .page-home .choose-maps .maps-info .maps-locate .locate.active .locate-circle {
    width: 40px;
    height: 40px;
  }
}
@media (max-width: 768px) {
  .page-home .choose-maps .maps-info .maps-locate .locate.active .locate-circle {
    width: 24px;
    height: 24px;
  }
}
.page-home .choose-maps .maps-info .maps-locate .locate.active .locate-building {
  visibility: initial;
  opacity: 1;
  height: initial;
  width: 290px;
  max-width: 290px;
}
@media (max-width: 900px) {
  .page-home .choose-maps .maps-info .maps-locate .locate.active .locate-building {
    width: 200px;
    max-width: 200px;
  }
}
@media (max-width: 768px) {
  .page-home .choose-maps .maps-info .maps-locate .locate.active .locate-building {
    width: 200px;
    max-width: 200px;
  }
}
@media (max-width: 480px) {
  .page-home .choose-maps .maps-info .maps-locate .locate.active .locate-building {
    width: 150px;
    max-width: 150px;
  }
}
.page-home .choose-maps .maps-info .maps-locate .locate .locate-circle {
  background: #0246C6;
  opacity: 0.6;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: block;
  cursor: pointer;
  position: relative;
  z-index: 2;
  -webkit-transition: 1000ms all;
  transition: 1000ms all;
}
@media (max-width: 900px) {
  .page-home .choose-maps .maps-info .maps-locate .locate .locate-circle {
    width: 16px;
    height: 16px;
  }
}
@media (max-width: 768px) {
  .page-home .choose-maps .maps-info .maps-locate .locate .locate-circle {
    width: 10px;
    height: 10px;
  }
}
.page-home .choose-maps .maps-info .maps-locate .locate .locate-building {
  visibility: hidden;
  opacity: 0;
  height: 0;
  width: 0px;
  max-width: 0px;
  position: absolute;
  -webkit-transition: 1000ms all;
  transition: 1000ms all;
}
.page-home .apart {
  background: url(../../images/data/bg-apart.jpg) no-repeat center;
  background-size: cover;
  padding: 72px 0 64px;
  overflow: hidden;
}
@media (min-width: 1025px) {
  .page-home .apart {
    background-attachment: fixed;
  }
}
@media (max-width: 1280px) {
  .page-home .apart {
    padding: 64px 0 40px;
  }
}
@media (max-width: 768px) {
  .page-home .apart {
    padding: 40px 0;
  }
}
.page-home .apart .chooseUs-title .chooseUs-title__label {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.6);
}
@media (max-width: 1280px) {
  .page-home .apart .chooseUs-title .chooseUs-title__label {
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .page-home .apart .chooseUs-title .chooseUs-title__label {
    font-size: 12px;
  }
}
.page-home .apart .chooseUs-title .chooseUs-title__label span {
  font-weight: 700;
}
.page-home .apart .chooseUs-title .chooseUs-title__title {
  padding-top: 40px;
  font-size: 36px;
  font-weight: 600;
  color: #fff;
}
@media (max-width: 1280px) {
  .page-home .apart .chooseUs-title .chooseUs-title__title {
    font-size: 29px;
  }
}
@media (max-width: 768px) {
  .page-home .apart .chooseUs-title .chooseUs-title__title {
    padding-top: 24px;
    font-size: 23px;
  }
}
.page-home .apart .chooseUs-title .chooseUs-btn {
  padding-top: 40px;
}
@media (max-width: 768px) {
  .page-home .apart .chooseUs-title .chooseUs-btn {
    padding-top: 24px;
  }
}
.page-home .slider-apart {
  padding: 88px 0 48px;
}
@media (max-width: 1280px) {
  .page-home .slider-apart {
    padding: 84px 0 32px;
  }
}
@media (max-width: 768px) {
  .page-home .slider-apart {
    padding: 48px 0 32px;
  }
}
.page-home .slider-apart .slick-slide {
  width: 460px;
  margin-right: 24px;
}
@media (max-width: 1280px) {
  .page-home .slider-apart .slick-slide {
    width: 400px;
  }
}
@media (max-width: 768px) {
  .page-home .slider-apart .slick-slide {
    width: 320px;
    margin-right: 16px;
  }
}
.page-home .slider-apart .slick-dots li button {
  background: #D5E1EF;
  opacity: 0.4;
}
.page-home .slider-apart .slick-dots li.slick-active button {
  background: #fff;
  opacity: 1;
}
.page-home .slider-apart .apart-info {
  background: #fff;
  border-radius: 4px;
  display: block;
  padding: 40px 32px;
  min-height: 404px;
}
@media (max-width: 1280px) {
  .page-home .slider-apart .apart-info {
    padding: 40px 24px;
  }
}
@media (max-width: 768px) {
  .page-home .slider-apart .apart-info {
    padding: 32px 16px;
    min-height: 380px;
  }
}
.page-home .slider-apart .apart-info .apart-item {
  height: 48px;
  overflow: hidden;
}
.page-home .slider-apart .apart-info .apart-title {
  margin: 32px 0;
  font-size: 23px;
  font-weight: 600;
  color: #2F333E;
  line-height: 120%;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 2;
  -webkit-box-pack: end;
}
@media (max-width: 1280px) {
  .page-home .slider-apart .apart-info .apart-title {
    margin: 24px 0;
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .page-home .slider-apart .apart-info .apart-title {
    font-size: 18px;
  }
}
.page-home .slider-apart .apart-info .apart-text {
  font-size: 16px;
  color: #5A5D66;
  line-height: 140%;
  /*
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 7;
  -webkit-box-pack: end;
  */
}
@media (max-width: 768px) {
  .page-home .slider-apart .apart-info .apart-text {
    font-size: 14px;
  }
}
.page-home .testimonials {
  padding-top: 88px;
  overflow: hidden;
}
@media (max-width: 768px) {
  .page-home .testimonials {
    padding-top: 40px;
  }
}
.page-home .testimonials-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.page-home .testimonials-row .testimonials-col:nth-child(1) {
  width: 438px;
  padding-right: 48px;
}
@media (max-width: 1280px) {
  .page-home .testimonials-row .testimonials-col:nth-child(1) {
    width: 368px;
  }
}
@media (max-width: 900px) {
  .page-home .testimonials-row .testimonials-col:nth-child(1) {
    width: 100%;
    padding-right: 0;
  }
}
.page-home .testimonials-row .testimonials-col:nth-child(2) {
  width: calc(100% - 438px);
}
@media (max-width: 1280px) {
  .page-home .testimonials-row .testimonials-col:nth-child(2) {
    width: calc(100% - 368px);
  }
}
@media (max-width: 900px) {
  .page-home .testimonials-row .testimonials-col:nth-child(2) {
    width: calc(100% + 16px);
    margin-left: 0;
  }
}
.page-home .testimonials-direction {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 100%;
}
.page-home .testimonials-direction .title-testimonials .title-text {
  font-size: 18px;
  color: #6C6F77;
}
@media (max-width: 1280px) {
  .page-home .testimonials-direction .title-testimonials .title-text {
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .page-home .testimonials-direction .title-testimonials .title-text {
    font-size: 12px;
  }
}
.page-home .testimonials-direction .title-testimonials .title-tlt {
  font-size: 36px;
  font-weight: 600;
  color: #0246C6;
  padding-top: 63px;
}
@media (max-width: 1280px) {
  .page-home .testimonials-direction .title-testimonials .title-tlt {
    font-size: 29px;
    padding-top: 32px;
  }
}
@media (max-width: 768px) {
  .page-home .testimonials-direction .title-testimonials .title-tlt {
    font-size: 26px;
  }
}
.page-home .testimonials-direction .title-testimonials .title-tlt span {
  color: #2F333E;
}
.page-home .slider-testimonialsName {
  padding: 20px 0 96px;
}
@media (max-width: 900px) {
  .page-home .slider-testimonialsName {
    padding: 32px 0 64px;
    display: none;
  }
}
.page-home .slider-testimonialsName .slick-arrow {
  background: url(../../images/common/icon-arrow-slide.svg) no-repeat center #fff;
  border: 1px solid #C7C8CB;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  top: initial;
  bottom: 4px;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 1280px) {
  .page-home .slider-testimonialsName .slick-arrow {
    width: 40px;
    height: 40px;
  }
}
.page-home .slider-testimonialsName .slick-arrow:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.page-home .slider-testimonialsName .slick-arrow.slick-prev {
  left: 0;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.page-home .slider-testimonialsName .slick-arrow.slick-next {
  left: 60px;
}
@media (max-width: 1280px) {
  .page-home .slider-testimonialsName .slick-arrow.slick-next {
    left: 50px;
  }
}
.page-home .slider-testimonialsName .slick-dots {
  max-width: calc(100% - 130px);
  right: 0;
  bottom: 28px;
  margin-left: auto;
  padding-right: 0;
}
@media (max-width: 1280px) {
  .page-home .slider-testimonialsName .slick-dots {
    bottom: 18px;
  }
}
.page-home .slider-testimonialsName .slick-dots li {
  margin: 0 3px;
  -webkit-transition: 400ms all;
  transition: 400ms all;
  width: calc(100% - 40px);
}
.page-home .slider-testimonialsName .slick-dots li.slick-active {
  width: calc(100% + 40px);
}
.page-home .slider-testimonialsName .slick-dots li button {
  background: #FFF;
  border-radius: 100px;
  height: 4px;
}
.page-home .slider-testimonialsName .slick-dots li.slick-active button {
  background: #0246C6;
}
.page-home .slider-testimonialsName .testimonial-ceo .name {
  font-size: 26px;
  font-weight: 600;
}
@media (max-width: 1280px) {
  .page-home .slider-testimonialsName .testimonial-ceo .name {
    font-size: 16px;
  }
}
.page-home .slider-testimonialsName .testimonial-ceo .position {
  padding-top: 16px;
  font-size: 18px;
}
@media (max-width: 1280px) {
  .page-home .slider-testimonialsName .testimonial-ceo .position {
    padding-top: 8px;
    font-size: 14px;
  }
}
@media (max-width: 900px) {
  .page-home .slider-testimonialsContent {
    padding: 40px 0;
  }
}
@media (max-width: 900px) {
  .page-home .slider-testimonialsContent .slick-slide {
    /*width: 358px;*/
    width: 330px;
    margin-right: 16px;
  }
}
.page-home .testimonials-info .testimonials-wrap {
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  overflow: hidden;
}
@media (max-width: 900px) {
  .page-home .testimonials-info .testimonials-wrap {
    border-radius: 6px;
    overflow: hidden;
    display: block;
  }
}
.page-home .testimonials-info .testimonials-thumb,
.page-home .testimonials-info .testimonials-mask {
  width: 50%;
}
@media (max-width: 900px) {
  .page-home .testimonials-info .testimonials-thumb,
  .page-home .testimonials-info .testimonials-mask {
    width: 100%;
  }
}
.page-home .testimonials-info .testimonials-thumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 900px) {
  .page-home .testimonials-info .testimonials-thumb {
    height: 200px;
    overflow: hidden;
    display: block;
  }
}
.page-home .testimonials-info .testimonials-thumb img {
  width: 100%;
}
.page-home .testimonials-info .testimonials-mask {
  padding: 4%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}
@media (max-width: 900px) {
  .page-home .testimonials-info .testimonials-mask {
    padding: 48px 24px;
  }
}
.page-home .testimonials-info .testimonials-mask .testimonials-content {
  width: 100%;
}
.page-home .testimonials-info .testimonials-mask .testimonials-content .item {
  max-width: 40px;
}
@media (max-width: 900px) {
  .page-home .testimonials-info .testimonials-mask .testimonials-content .item {
    width: 60px;
    max-width: 60px;
    height: 60px;
    background: #fff;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin: -76px 0 0;
    position: relative;
  }
  .page-home .testimonials-info .testimonials-mask .testimonials-content .item .svg,
  .page-home .testimonials-info .testimonials-mask .testimonials-content .item img {
    width: 24px;
  }
}
.page-home .testimonials-info .testimonials-mask .testimonials-content .dummy-tlt {
  margin: 48px 0;
  font-size: 23px;
  font-weight: 600;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 3;
  -webkit-box-pack: end;
}
@media (max-width: 1280px) {
  .page-home .testimonials-info .testimonials-mask .testimonials-content .dummy-tlt {
    margin: 32px 0 24px;
    font-size: 18px;
  }
}
@media (max-width: 900px) {
  .page-home .testimonials-info .testimonials-mask .testimonials-content .dummy-tlt {
    font-size: 16px;
    height: 58px;
    margin: 10px 0 32px;
  }
}
.page-home .testimonials-info .testimonials-mask .testimonials-content .lorem-text {
  font-size: 18px;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 6;
  -webkit-box-pack: end;
}
@media (max-width: 1280px) {
  .page-home .testimonials-info .testimonials-mask .testimonials-content .lorem-text {
    font-size: 16px;
  }
}
@media (max-width: 900px) {
  .page-home .testimonials-info .testimonials-mask .testimonials-content .lorem-text {
    font-size: 14px;
    height: 100px;
  }
}
.page-home .testimonials-info .testimonials-mask .testimonials-content .testimonials-position {
  display: none;
  padding-top: 32px;
  text-align: right;
}
@media (max-width: 900px) {
  .page-home .testimonials-info .testimonials-mask .testimonials-content .testimonials-position {
    display: block;
  }
}
.page-home .testimonials-info .testimonials-mask .testimonials-content .testimonials-position .name {
  font-size: 14px;
  font-weight: 500;
  color: #3A3A3A;
}
.page-home .testimonials-info .testimonials-mask .testimonials-content .testimonials-position .position {
  padding-top: 6px;
  color: #909090;
  font-size: 13px;
}
.page-home .deep {
  padding: 88px 0;
  overflow: hidden;
}
@media (max-width: 1280px) {
  .page-home .deep {
    padding: 80px 0;
  }
}
@media (max-width: 768px) {
  .page-home .deep {
    padding: 80px 0 64px;
  }
}
.page-home .deep .deep-title .deep-text {
  color: #6C6F77;
  font-size: 18px;
}
@media (max-width: 1280px) {
  .page-home .deep .deep-title .deep-text {
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .page-home .deep .deep-title .deep-text {
    font-size: 12px;
  }
}
.page-home .deep .deep-title .deep-dflex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-top: 32px;
}
@media (max-width: 1280px) {
  .page-home .deep .deep-title .deep-dflex {
    padding-top: 24px;
  }
}
@media (max-width: 768px) {
  .page-home .deep .deep-title .deep-dflex {
    padding-top: 32px;
  }
}
.page-home .deep .deep-title .deep-dflex .title-text {
  color: #0246C6;
  font-size: 36px;
  font-weight: 600;
  padding-right: 10px;
}
@media (max-width: 1280px) {
  .page-home .deep .deep-title .deep-dflex .title-text {
    font-size: 29px;
  }
}
@media (max-width: 768px) {
  .page-home .deep .deep-title .deep-dflex .title-text {
    font-size: 26px;
    padding-right: 0;
  }
}
.page-home .deep .deep-title .deep-dflex .title-text span {
  color: #2F333E;
}
@media (max-width: 768px) {
  .page-home .deep .deep-title .deep-dflex .btn-more {
    display: none;
  }
}
.page-home .valued {
  padding-top: 96px;
  padding-bottom: 0;
}
@media (max-width: 1280px) {
  .page-home .valued {
    padding-top: 64px;
  }
}
@media (max-width: 768px) {
  .page-home .valued {
    padding-top: 32px;
  }
}
.page-home .valued .valued-company {
  padding-bottom: 96px;
  border-bottom: 1px solid #6C6F77;
}
@media (max-width: 1280px) {
  .page-home .valued .valued-company {
    padding-bottom: 64px;
  }
}
@media (max-width: 768px) {
  .page-home .valued .valued-company {
    padding-bottom: 32px;
  }
}

/*****/
.should {
  padding: 96px 0;
  background: url(../../images/data/bg-should.jpg) no-repeat bottom center;
  background-size: cover;
}
@media (max-width: 1280px) {
  .should {
    padding: 64px 0;
  }
}
@media (max-width: 768px) {
  .should {
    padding: 32px 0;
  }
}
.should .should-dflex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 768px) {
  .should .should-dflex {
    display: block;
    text-align: center;
  }
}
.should .should-dflex .should-info {
  padding-right: 10px;
}
@media (max-width: 1280px) {
  .should .should-dflex .should-info {
    max-width: 638px;
  }
}
@media (max-width: 768px) {
  .should .should-dflex .should-info {
    padding: 0 0 24px;
    max-width: 400px;
    margin: 0 auto;
  }
}
.should .should-dflex .should-info .should-text {
  font-size: 64px;
  color: #fff;
}
@media (max-width: 1280px) {
  .should .should-dflex .should-info .should-text {
    font-size: 41px;
  }
}
@media (max-width: 768px) {
  .should .should-dflex .should-info .should-text {
    font-size: 26px;
  }
}
.should .should-dflex .should-info .should-text span {
  display: block;
  font-weight: 600;
}
@media (max-width: 768px) {
  .should .should-dflex .should-info .should-text span {
    font-weight: 400;
  }
}
@media (max-width: 768px) {
  .should .should-dflex .should-btn .btn {
    margin: 0 auto;
  }
}

.deep-list ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.deep-list .deep-info .deep-thumb img {
  width: 100%;
}
.deep-list .deep-info .deep-content {
  padding-top: 40px;
}
@media (max-width: 768px) {
  .deep-list .deep-info .deep-content {
    padding-top: 24px;
  }
}
.deep-list .deep-info .deep-content .deep-title {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 2;
  -webkit-box-pack: end;
  min-height: 48px;
}
@media (max-width: 768px) {
  .deep-list .deep-info .deep-content .deep-title {
    min-height: 40px;
  }
}
.deep-list .deep-info .deep-content .deep-title a {
  font-size: 20px;
  font-weight: 500;
  color: #2F333E;
}
@media (max-width: 768px) {
  .deep-list .deep-info .deep-content .deep-title a {
    font-size: 16px;
  }
}
.deep-list .deep-info .deep-content .deep-tag {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-top: 24px;
}
.deep-list .deep-info .deep-content .deep-tag .deep-case {
  color: #383C46;
  font-size: 16px;
  padding-right: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media (max-width: 768px) {
  .deep-list .deep-info .deep-content .deep-tag .deep-case {
    font-size: 13px;
  }
}
.deep-list .deep-info .deep-content .deep-tag .readMore {
  white-space: nowrap;
  font-size: 16px;
}
@media (max-width: 768px) {
  .deep-list .deep-info .deep-content .deep-tag .readMore {
    font-size: 14px;
  }
}
@media (max-width: 480px) {
  .deep-list--col2 {
    padding-top: 24px;
  }
}
.deep-list--col2 ul {
  margin-left: -64px;
}
@media (max-width: 1280px) {
  .deep-list--col2 ul {
    margin-left: -48px;
  }
}
@media (max-width: 768px) {
  .deep-list--col2 ul {
    margin-left: -16px;
  }
}
.deep-list--col2 ul li {
  padding: 48px 0 0 64px;
  width: 50%;
}
@media (max-width: 1280px) {
  .deep-list--col2 ul li {
    padding-left: 48px;
  }
}
@media (max-width: 768px) {
  .deep-list--col2 ul li {
    padding-left: 16px;
  }
}
@media (max-width: 480px) {
  .deep-list--col2 ul li {
    width: 100%;
    padding-top: 24px;
  }
}
.deep-list--col3 ul {
  margin-left: -48px;
}
@media (max-width: 768px) {
  .deep-list--col3 ul {
    margin-left: -16px;
  }
}
.deep-list--col3 ul li {
  width: 33.333333%;
  padding: 48px 0 0 48px;
}
@media (max-width: 1280px) {
  .deep-list--col3 ul li {
    width: 50%;
  }
}
@media (max-width: 768px) {
  .deep-list--col3 ul li {
    padding-left: 16px;
  }
}
@media (max-width: 480px) {
  .deep-list--col3 ul li {
    width: 100%;
    padding-top: 24px;
  }
}
.deep-list--col3-clinic ul {
  margin-left: -48px;
}
@media (max-width: 1280px) {
  .deep-list--col3-clinic ul {
    margin-left: -32px;
  }
}
@media (max-width: 900px) {
  .deep-list--col3-clinic ul {
    margin-left: -16px;
  }
}
.deep-list--col3-clinic ul li {
  width: 33.333333%;
  padding: 48px 0 0 48px;
}
@media (max-width: 1280px) {
  .deep-list--col3-clinic ul li {
    padding: 32px 0 0 32px;
  }
}
@media (max-width: 900px) {
  .deep-list--col3-clinic ul li {
    padding-left: 16px;
  }
}
@media (max-width: 768px) {
  .deep-list--col3-clinic ul li {
    width: 50%;
  }
}
@media (max-width: 480px) {
  .deep-list--col3-clinic ul li {
    width: 100%;
    padding-top: 24px;
  }
}
@media (max-width: 1280px) {
  .deep-list--col3-clinic .deep-info .deep-content {
    padding-top: 32px;
  }
}
@media (max-width: 1280px) {
  .deep-list--col3-clinic .deep-info .deep-content .deep-title {
    min-height: 43px;
  }
}
@media (max-width: 768px) {
  .deep-list--col3-clinic .deep-info .deep-content .deep-title {
    min-height: 40px;
  }
}
@media (max-width: 1280px) {
  .deep-list--col3-clinic .deep-info .deep-content .deep-title a {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .deep-list--col3-clinic .deep-info .deep-content .deep-title a {
    font-size: 16px;
  }
}

.deep-btn {
  display: none;
  max-width: 320px;
  margin: 0 auto;
  padding-top: 48px;
}
@media (max-width: 768px) {
  .deep-btn {
    display: block;
  }
}
.deep-btn .btn {
  width: 100%;
}

/*****/
.our-industries {
  padding: 64px 0 0;
}
.our-industries .slick-slide {
  overflow: hidden;
  margin: 0 0.6vw;
  cursor: pointer;
  -webkit-transition: 400ms all;
  transition: 400ms all;
}
.our-industries .slick-slide.desktop-active-slide .desktop-image-slider__img .projects-content .projects-main .tlt {
  font-size: 29px;
  font-weight: 600;
  max-width: 516px;
  padding-right: 10px;
}
@media (max-width: 1280px) {
  .our-industries .slick-slide.desktop-active-slide .desktop-image-slider__img .projects-content .projects-main .tlt {
    font-size: 20px;
  }
}
.our-industries .slick-slide.desktop-active-slide .desktop-image-slider__img .projects-content .projects-main .projects-btn {
  display: initial;
}
.our-industries .desktop-image-slider__img {
  background: #fff;
}
.our-industries .desktop-image-slider__img .projects-thumb {
  height: 309px;
  overflow: hidden;
  position: relative;
}
.our-industries .desktop-image-slider__img .projects-thumb img {
  max-height: 309px;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  position: absolute;
  top: 0;
  right: 0;
}
.our-industries .desktop-image-slider__img .projects-content {
  padding: 32px 20px;
  min-height: 134px;
}
@media (max-width: 1280px) {
  .our-industries .desktop-image-slider__img .projects-content {
    padding: 16px 20px;
    min-height: 106px;
  }
}
@media (max-width: 900px) {
  .our-industries .desktop-image-slider__img .projects-content {
    padding: 16px 10px;
  }
}
.our-industries .desktop-image-slider__img .projects-content .projects-main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  min-height: 70px;
}
.our-industries .desktop-image-slider__img .projects-content .projects-main .tlt {
  font-size: 20px;
  font-weight: 500;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 2;
  -webkit-box-pack: end;
}
@media (max-width: 1280px) {
  .our-industries .desktop-image-slider__img .projects-content .projects-main .tlt {
    font-size: 18px;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-pack: end;
  }
}
.our-industries .desktop-image-slider__img .projects-content .projects-main .projects-btn {
  display: none;
}
.our-industries .progress-input {
  padding-top: 64px;
}

.our-industries .desktop-slider .desktop-image-slider__item {
  cursor: pointer;
  width: 13.5vw;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
}
@media (max-width: 1280px) {
  .our-industries .desktop-slider .desktop-image-slider__item {
    width: 13vw;
  }
}
@media (max-width: 900px) {
  .our-industries .desktop-slider .desktop-image-slider__item {
    width: 9.4vw;
  }
}

.our-industries .desktop-slider .desktop-image-slider .desktop-active-slide {
  width: 40vw;
  cursor: default;
}
@media (max-width: 1280px) {
  .our-industries .desktop-slider .desktop-image-slider .desktop-active-slide {
    width: 42.5vw;
  }
}
@media (max-width: 900px) {
  .our-industries .desktop-slider .desktop-image-slider .desktop-active-slide {
    width: 56vw;
  }
}

.our-industries input[type=range],
.our-industries input[type=range]::-webkit-slider-runnable-track,
.our-industries input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  font: inherit;
}

.our-industries .progress-bar {
  cursor: pointer;
  width: 100%;
  height: 3px;
  background: #fff;
  overflow: hidden;
}

.our-industries .progress-bar::-webkit-slider-thumb {
  width: 0.5em;
  height: auto;
  background-color: #0246C6;
  border-radius: 0;
  -webkit-box-shadow: -100vw 0 0 100vw #0246C6;
          box-shadow: -100vw 0 0 100vw #0246C6;
}

.our-industries .progress-bar::-moz-range-thumb {
  width: 0.5em;
  height: auto;
  background-color: #0246C6;
  border-radius: 0;
  box-shadow: -100vw 0 0 100vw #0246C6;
  border: none;
}

.gNav {
  width: calc(100% - 140px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
@media (max-width: 1280px) {
  .gNav {
    display: none;
  }
}
.gNav > ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.gNav > ul > li {
  position: relative;
}
.gNav > ul > li:hover .gNav-sub {
  display: block;
}
.gNav > ul > li:hover > a {
  color: #252C6D;
  background: #EEF2FA;
}
.gNav > ul > li:hover > a::after {
  background: #252C6D;
}
.gNav > ul > li:hover > a .gNav-item {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.gNav > ul > li:hover > a .gNav-item .svg path {
  stroke: #252C6D;
}
.gNav > ul > li.gNav-drop:hover > a::before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  background: url(../../images/common/icon-arrow-menu-hover.svg) no-repeat center;
}
.gNav > ul > li.gNav-drop > a {
  padding-right: 38px;
}
.gNav > ul > li.gNav-drop > a:hover::before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  background: url(../../images/common/icon-arrow-menu-hover.svg) no-repeat center;
}
.gNav > ul > li.gNav-drop > a::before {
  position: absolute;
  top: calc(50% - 8px);
  right: 16px;
  content: "";
  width: 16px;
  height: 16px;
  -webkit-transition: 400ms all;
  transition: 400ms all;
  background: url(../../images/common/icon-arrow-menu.svg) no-repeat center;
}
.gNav > ul > li > a {
  padding: 16px;
  display: block;
  color: #383C46;
  font-size: 16px;
  line-height: 140%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.gNav > ul > li > a::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  width: 100%;
  height: 3px;
}
.gNav > ul > li > a:hover {
  color: #252C6D;
  background: #EEF2FA;
}
.gNav > ul > li > a:hover::after {
  background: #252C6D;
}
.gNav > ul > li > a:hover .gNav-item {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.gNav > ul > li > a:hover .gNav-item .svg path {
  stroke: #252C6D;
}
.gNav > ul > li > a .gNav-item {
  width: 16px;
  min-width: 16px;
  height: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-left: 6px;
  -webkit-transition: 400ms all;
  transition: 400ms all;
}
.gNav > ul > li > a .gNav-item .svg path {
  stroke: #383C46;
}
.gNav .gNav-sub {
  position: absolute;
  top: 100%;
  right: 0;
  padding: 30px 24px 40px;
  border-radius: 0px 0px 4px 4px;
  background: #FFF;
  -webkit-box-shadow: 0px 4px 4px 0px rgba(120, 120, 120, 0.1);
          box-shadow: 0px 4px 4px 0px rgba(120, 120, 120, 0.1);
  display: none;
}
.gNav .gNav-sub .gNav-title {
  font-size: 20px;
  font-weight: 600;
  color: #252C6D;
  padding: 16px 0;
  border-bottom: 1px solid #C7C8CB;
}
.gNav .gNav-sub ul {
  margin-top: -4px;
}
.gNav .gNav-sub ul li {
  padding-top: 24px;
}
.gNav .gNav-sub ul li a {
  font-size: 14px;
  color: #2F333E;
}
.gNav .gNav-sub ul li a:hover {
  color: #252C6D;
}
.gNav .gNav-sub.gNav-sub--mw364 {
  width: 364px;
}
.gNav .gNav-sub.gNav-sub--mw450 {
  width: 450px;
}
.gNav .gNav-sub.gNav-sub--mw728 {
  width: 636px;
}
.gNav .gNav-sub.gNav-sub--mw728 ul, .gNav .gNav-sub.gNav-sub--mw450 ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-left: -1%;
}
.gNav .gNav-sub.gNav-sub--mw728 ul li, .gNav .gNav-sub.gNav-sub--mw450 ul li {
  width: 50%;
  padding-left: 1%;
}

/****/
.btn--toggle {
  background: #F1F2F2;
  width: 40px;
  height: 40px;
  padding: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  display: none;
  position: relative;
  z-index: 11;
}
@media (max-width: 1280px) {
  .btn--toggle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.btn--toggle .item-close {
  display: none;
}
.btn--toggle.btn--close .item-menu {
  display: none;
}
.btn--toggle.btn--close .item-close {
  display: initial;
}
.btn--toggle:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

/****/
.navInfo {
  position: fixed;
  top: 55px;
  left: 0;
  width: 100%;
  height: calc(100% - 55px);
  padding: 24px 0 32px;
  display: none;
  z-index: 10;
  background: #F5F6F8;
}
@media (max-width: 1280px) {
  .navInfo.navInfo--show {
    display: block;
  }
}
@media (max-width: 768px) {
  .navInfo {
    padding: 27px 0;
  }
}
.navInfo .wrap {
  height: 100%;
}

@media (max-width: 768px) {
  .navTablet {
    display: none;
  }
}
.navTablet .navTop {
  border-bottom: 1px solid #E0E0E8;
  padding-bottom: 16px;
}
.navTablet .navTop ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.navTablet .navTop ul li {
  padding-left: 32px;
}
.navTablet .navTop ul li a {
  color: #3F424D;
}
.navTablet .navTop ul li a:hover {
  color: #252C6D;
}
.navTablet .create-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-top: 24px;
}
.navTablet .create-row .create-col:nth-child(1) {
  width: 237px;
  padding-right: 32px;
}
@media (max-width: 900px) {
  .navTablet .create-row .create-col:nth-child(1) {
    width: 100%;
    padding-right: 0;
  }
}
.navTablet .create-row .create-col:nth-child(2) {
  width: calc(100% - 237px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
@media (max-width: 900px) {
  .navTablet .create-row .create-col:nth-child(2) {
    width: 100%;
  }
}
.navTablet .create-row .create-col:nth-child(2) .create-info:nth-child(1) {
  width: calc(60% - 62px);
  padding-right: 10px;
}
.navTablet .create-row .create-col:nth-child(2) .create-info:nth-child(2) {
  width: calc(40% - 62px);
  padding-right: 10px;
}
.navTablet .create-row .create-col:nth-child(2) .create-info:nth-child(3) {
  width: 124px;
}
.navTablet .create-row .create-col:nth-child(2) .create-info .connect:last-child .connect-title {
  font-size: 14px;
  font-weight: 400;
}
.navTablet .create-row .create-col .create-slogan {
  font-size: 32px;
}
@media (max-width: 900px) {
  .navTablet .create-row .create-col .create-slogan {
    padding-bottom: 32px;
  }
}
.navTablet .create-row .create-col .create-slogan .create-span {
  color: #999BA1;
  display: block;
  opacity: 0.3;
}
@media (max-width: 900px) {
  .navTablet .create-row .create-col .create-slogan .create-span {
    display: inline-block;
  }
}
.navTablet .create-row .create-col .create-slogan .create-span:last-child {
  color: #6C6F77;
}
.navTablet .navigation ul li {
  padding-bottom: 40px;
}
.navTablet .navigation ul li:last-child {
  padding-bottom: 0;
}
.navTablet .navigation ul li a {
  color: #2F333E;
  font-size: 16px;
  font-weight: 600;
}
.navTablet .navigation ul li a:hover {
  color: #252C6D;
}
.navTablet .navigation .navigation-sub {
  padding-top: 16px;
}
.navTablet .navigation .navigation-sub ul li {
  padding: 8px 0 0;
}
.navTablet .navigation .navigation-sub ul li a {
  font-size: 14px;
  font-weight: 400;
  color: #3F424D;
}
.navTablet .navigation .navigation-sub ul li a:hover {
  color: #252C6D;
}

.connect {
  padding-top: 32px;
}
@media (max-width: 768px) {
  .connect {
    padding-top: 0;
  }
}
.connect .connect-title {
  color: #2F333E;
  font-size: 16px;
  font-weight: 600;
}
@media (max-width: 768px) {
  .connect .connect-title {
    font-size: 14px;
    font-weight: 400;
  }
}
.connect .connect-language {
  padding: 8px 0 32px;
}
.connect .connect-language li {
  padding-top: 8px;
}
.connect .connect-language li a {
  color: #3F424D;
  display: block;
}
.connect .connect-language li a:hover {
  color: #252C6D;
}
.connect .connect-share {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 16px;
}
@media (max-width: 768px) {
  .connect .connect-share {
    padding-top: 24px;
  }
}
.connect .connect-share li {
  padding-right: 6px;
}
.connect .connect-share li:last-child {
  padding-right: 0;
}
.connect .connect-share li a {
  display: block;
  height: 22px;
}
.connect .connect-share li a:hover {
  opacity: 0.6;
}
.connect .connect-share li svg path {
  fill: #545454;
}

.navMobile {
  margin-left: -16px;
  width: calc(100% + 32px);
  height: 100%;
  overflow: auto;
  display: none;
}
@media (max-width: 768px) {
  .navMobile {
    display: block;
  }
}
.navMobile .list-language {
  padding: 0 16px;
}
.navMobile .list-language ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid #c7c8cb;
}
.navMobile .list-language ul li {
  border-right: 1px solid #c7c8cb;
  width: 100%;
}
.navMobile .list-language ul li:last-child {
  border-right: none;
}
.navMobile .list-language ul li a {
  background: rgba(255, 255, 255, 0.2);
  padding: 12px 8px;
  display: block;
  color: #2F333E;
  text-align: center;
}
.navMobile .list-language ul li a.active {
  background: #fff;
}
.navMobile .connect {
  padding: 32px 16px 0;
}
.navMobile .connect .connect-share {
  padding-top: 16px;
}

.gNav-mobi {
  padding-top: 16px;
}
.gNav-mobi ul li {
  border-bottom: 1px solid #C7C8CB;
}
.gNav-mobi ul li.gNav-mobi-bodNone {
  border-bottom: none;
}
.gNav-mobi ul li.gNav-mobi-child > a {
  position: relative;
}
.gNav-mobi ul li.gNav-mobi-child > a::after {
  position: absolute;
  top: calc(50% - 4px);
  right: 16px;
  content: "";
  width: 13px;
  height: 8px;
  background: url(../../images/common/icon-chevron-down-sub01.svg) no-repeat;
  -webkit-transition: 400ms all;
  transition: 400ms all;
}
.gNav-mobi ul li.gNav-mobi-child > a.active-nav {
  background: #fff;
}
.gNav-mobi ul li.gNav-mobi-child > a.active-nav::after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.gNav-mobi ul li a {
  padding: 16px;
  color: #2F333E;
  display: block;
}
.gNav-mobi .gNav-mobi-sub {
  display: none;
  background: #fff;
  border-top: 1px solid #C7C8CB;
}
.gNav-mobi .gNav-mobi-sub ul li:last-child {
  border: none;
}
@media (max-width: 1280px) {
  .overflow {
    overflow: hidden;
  }
}

.header {
  background: #FFF;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(195, 195, 195, 0.25);
          box-shadow: 0px 3px 6px 0px rgba(195, 195, 195, 0.25);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  -webkit-transition: 600ms all;
  transition: 600ms all;
  /****/
}
@media (max-width: 1180px) {
  .header {
    top: 0 !important;
  }
}
@media (max-width: 1280px) {
  .header.header--show {
    top: 0 !important;
  }
}
.header .headerTop {
  background: #2F333E;
  height: 38px;
}
@media (max-width: 1280px) {
  .header .headerTop {
    display: none;
  }
}
.header .headerInfo {
  height: 55px;
}
.header .header-nav > ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.header .header-nav > ul > li {
  position: relative;
}
.header .header-nav > ul > li > a {
  font-size: 16px;
  line-height: 38px;
  padding: 0 16px;
  display: block;
  color: #fff;
}
.header .header-nav > ul > li > a:hover {
  text-decoration: underline;
  color: rgba(255, 255, 255, 0.6);
}
.header .header-nav .language > a {
  position: relative;
  padding: 0 36px;
}
.header .header-nav .language > a:hover::before {
  opacity: 0.6;
}
.header .header-nav .language > a::after, .header .header-nav .language > a::before {
  position: absolute;
  content: "";
  width: 16px;
  height: 16px;
}
.header .header-nav .language > a::before {
  background: url(../../images/common/icon-globe.svg) no-repeat;
  left: 16px;
  top: calc(50% - 9px);
}
.header .header-nav .language > a::after {
  background: url(../../images/common/icon-chevron-down.svg) no-repeat;
  right: 16px;
  top: calc(50% - 8px);
  -webkit-transition: 400ms all;
  transition: 400ms all;
}
.header .header-nav .language > a[aria-expanded=true]::after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.header .header-nav .language .dropdown-menu {
  margin: 0;
  padding: 16px 0;
  border: none;
  border-radius: 0 0 4px 4px;
  -webkit-box-shadow: 0px 4px 4px 0px rgba(120, 120, 120, 0.1);
          box-shadow: 0px 4px 4px 0px rgba(120, 120, 120, 0.1);
}
.header .header-nav .language .dropdown-menu .language-title {
  color: #252C6D;
  font-size: 20px;
  font-weight: 600;
  padding: 8px 0 16px;
  margin: 0 24px;
  border-bottom: 1px solid #C7C8CB;
  width: 220px;
}
.header .header-nav .language .dropdown-menu ul {
  padding-top: 16px;
}
.header .header-nav .language .dropdown-menu ul li a {
  display: block;
  padding: 8px 24px;
  font-size: 16px;
  color: #2F333E;
}
.header .header-nav .language .dropdown-menu ul li a:hover {
  background: #EEF2FA;
  color: #252C6D;
}
.header .headerWrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: relative;
}
.header .headerWrap .logo {
  padding: 8px 0;
  position: relative;
  z-index: 11;
  width: 140px;
}
@media (max-width: 768px) {
  .header .headerWrap .logo {
    width: 40px;
    overflow: hidden;
  }
}
.header .headerWrap .logo a {
  display: block;
  height: 39px;
  overflow: hidden;
}

.footer {
  background: #2F333E;
}
.footer .footer-lastFirst {
  padding: 120px 0 28px;
}
@media (max-width: 1280px) {
  .footer .footer-lastFirst {
    padding-top: 32px;
  }
}
@media (max-width: 768px) {
  .footer .footer-lastFirst {
    padding-bottom: 16px;
  }
}
.footer .footer-lastChild {
  background: rgba(0, 0, 0, 0.3);
  padding: 10px 0;
  margin-top: 28px;
}
@media (max-width: 768px) {
  .footer .footer-lastChild {
    padding: 8px 0 16px;
    margin-top: 16px;
  }
}
.footer .footer-lastChild .nav-term {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 768px) {
  .footer .footer-lastChild .nav-term {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: initial;
        -ms-flex-pack: initial;
            justify-content: initial;
  }
}
@media (max-width: 768px) {
  .footer .footer-lastChild .nav-term li {
    padding: 8px 8px 0 0;
  }
  .footer .footer-lastChild .nav-term li:last-child {
    padding-right: 0;
  }
}
.footer .footer-lastChild .nav-term li a,
.footer .footer-lastChild .nav-term li span {
  color: #fff;
}
@media (max-width: 768px) {
  .footer .footer-lastChild .nav-term li a,
  .footer .footer-lastChild .nav-term li span {
    color: #6C6F77;
    font-size: 12px;
  }
}
.footer .nav-desktop {
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid rgba(220, 227, 245, 0.2);
  display: none;
}
@media (max-width: 1280px) {
  .footer .nav-desktop {
    display: block;
  }
}
@media (max-width: 768px) {
  .footer .nav-desktop {
    display: none;
  }
}
.footer .nav-desktop ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.footer .nav-desktop ul li {
  padding-right: 16px;
}
.footer .nav-desktop ul li a {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}
.footer .footer-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.footer .footer-row .footer-col {
  padding-right: 24px;
  /*&:nth-child(4) {
  	width: 190px;
  	@media (max-width: $viewport_mobile) {
  		width: 100%;
  	}
  }*/
}
@media (max-width: 1280px) {
  .footer .footer-row .footer-col {
    padding-right: 16px;
  }
}
@media (max-width: 768px) {
  .footer .footer-row .footer-col {
    padding-right: 0;
  }
}
.footer .footer-row .footer-col:nth-child(1) {
  width: 266px;
}
@media (max-width: 1280px) {
  .footer .footer-row .footer-col:nth-child(1) {
    width: 22.2%;
  }
}
@media (max-width: 768px) {
  .footer .footer-row .footer-col:nth-child(1) {
    width: 100%;
  }
}
.footer .footer-row .footer-col:nth-child(2) {
  width: 146px;
}
@media (max-width: 1280px) {
  .footer .footer-row .footer-col:nth-child(2) {
    display: none;
  }
}
@media (max-width: 768px) {
  .footer .footer-row .footer-col:nth-child(2) {
    width: 100%;
    display: block;
  }
}
.footer .footer-row .footer-col:nth-child(3) {
  width: calc(100% - 667px);
}
@media (max-width: 1280px) {
  .footer .footer-row .footer-col:nth-child(3) {
    /*width: 36.5%;*/
    width: 56.5%;
  }
}
@media (max-width: 768px) {
  .footer .footer-row .footer-col:nth-child(3) {
    width: 100%;
  }
}
.footer .footer-row .footer-col:nth-child(4) {
  width: 255px;
  padding-right: 0;
}
@media (max-width: 1280px) {
  .footer .footer-row .footer-col:nth-child(4) {
    width: 21.3%;
  }
}
@media (max-width: 768px) {
  .footer .footer-row .footer-col:nth-child(4) {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .footer .footer-row .logo {
    padding-bottom: 32px;
  }
}
.footer .footer-row .footer-about {
  padding-top: 8px;
}
@media (max-width: 768px) {
  .footer .footer-row .footer-about {
    display: none;
  }
}
.footer .footer-row .footer-about .title-about,
.footer .footer-row .footer-about .text-about,
.footer .footer-row .footer-about .titleIs-about {
  padding-top: 24px;
  font-size: 16px;
}
.footer .footer-row .footer-about .title-about {
  font-weight: 700;
  color: #fff;
}
.footer .footer-row .footer-about .titleIs-about {
  font-weight: 600;
  color: #F1F2F2;
}
.footer .footer-row .footer-about .text-about {
  color: #B0B0B0;
}
.footer .footer-row .footer-about .text-about .span-about {
  font-weight: 600;
}
.footer .footer-row .nav-footer > ul > li {
  padding-bottom: 16px;
}
@media (max-width: 768px) {
  .footer .footer-row .nav-footer > ul > li {
    padding-bottom: 12px;
  }
}
.footer .footer-row .nav-footer > ul > li:last-child {
  padding-bottom: 0;
}
@media (max-width: 768px) {
  .footer .footer-row .nav-footer > ul > li:last-child {
    padding-bottom: 12px;
  }
}
.footer .footer-row .nav-footer > ul > li:last-child .nav-footer-sub {
  padding-bottom: 0;
}
.footer .footer-row .nav-footer > ul > li > a {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  display: block;
}
@media (max-width: 768px) {
  .footer .footer-row .nav-footer > ul > li > a {
    font-weight: 400;
  }
}
.footer .footer-row .nav-footer > ul > li > a:hover {
  color: rgba(255, 255, 255, 0.6);
  text-decoration: underline;
}
.footer .footer-row .nav-footer > ul > li .nav-nameTitle {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  display: block;
}
@media (max-width: 768px) {
  .footer .footer-row .nav-footer > ul > li .nav-nameTitle {
    font-weight: 400;
  }
}
.footer .footer-row .nav-footer .nav-footer-sub {
  padding-bottom: 8px;
}
@media (min-width: 769px) {
  .footer .footer-row .nav-footer .nav-footer-sub {
    display: block !important;
    padding: initial !important;
    height: initial !important;
  }
}
@media (max-width: 768px) {
  .footer .footer-row .nav-footer .nav-footer-sub {
    display: none;
  }
}
.footer .footer-row .nav-footer .nav-footer-sub li {
  padding-top: 16px;
}
.footer .footer-row .nav-footer .nav-footer-sub li a {
  font-size: 16px;
  color: #B0B0B0;
}
.footer .footer-row .nav-footer .nav-footer-sub li a:hover {
  opacity: 0.6;
  text-decoration: underline;
}
.footer .footer-row .nav-footer--mobi > ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.footer .footer-row .nav-footer--mobi > ul > li {
  width: calc(100% - 190px);
  padding-right: 24px;
}
@media (max-width: 768px) {
  .footer .footer-row .nav-footer--mobi > ul > li {
    padding-right: 0;
    width: 100%;
  }
}
.footer .footer-row .nav-footer--mobi > ul > li:nth-of-type(2n + 2) {
  width: 190px;
  padding-right: 0;
}
@media (max-width: 768px) {
  .footer .footer-row .nav-footer--mobi > ul > li:nth-of-type(2n + 2) {
    width: 100%;
  }
}
.footer .footer-row .nav-footer--mobi > ul > li > a:hover {
  color: #fff;
  text-decoration: none;
}
@media (max-width: 768px) {
  .footer .footer-row .nav-footer--mobi > ul > li > .nav-nameTitle {
    position: relative;
    padding-right: 24px;
    cursor: pointer;
  }
  .footer .footer-row .nav-footer--mobi > ul > li > .nav-nameTitle::after {
    position: absolute;
    top: 2px;
    right: 0;
    content: "";
    width: 20px;
    height: 20px;
    background: url(../../images/common/icon-arrow-down.svg) no-repeat;
    -webkit-transition: 400ms all;
    transition: 400ms all;
  }
  .footer .footer-row .nav-footer--mobi > ul > li > .nav-nameTitle.active-nav::after {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
}
@media (max-width: 768px) {
  .footer .footer-row .address {
    margin-top: 4px;
    padding-top: 16px;
    border-top: 1px solid #454A75;
  }
}
.footer .footer-row .address ul li {
  padding-bottom: 16px;
}
@media (max-width: 768px) {
  .footer .footer-row .address ul li {
    padding-bottom: 12px;
  }
}
.footer .footer-row .address ul li:last-child {
  padding-bottom: 0;
}
.footer .footer-row .address ul li .address-title {
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  color: #fff;
  padding: 0 24px 0 0;
  position: relative;
  -webkit-transition: 400ms all;
  transition: 400ms all;
}
@media (max-width: 768px) {
  .footer .footer-row .address ul li .address-title {
    font-weight: 400;
  }
}
.footer .footer-row .address ul li .address-title:hover {
  opacity: 0.6;
  text-decoration: underline;
}
.footer .footer-row .address ul li .address-title::after {
  position: absolute;
  top: 2px;
  right: 0;
  content: "";
  width: 20px;
  height: 20px;
  background: url(../../images/common/icon-arrow-down.svg) no-repeat;
  -webkit-transition: 400ms all;
  transition: 400ms all;
}
.footer .footer-row .address ul li .address-title.address-title--active::after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.footer .footer-row .address ul li .address-content {
  padding: 16px 0 8px;
  display: none;
}
.footer .footer-row .address ul li .address-content .address-text {
  color: #6C6F77;
}
.footer .footer-dflex {
  margin-top: 32px;
  padding: 24px 0 0;
  border-top: 1px solid rgba(220, 227, 245, 0.2);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 768px) {
  .footer .footer-dflex {
    margin-top: 16px;
    padding: 16px 0 0;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
.footer .footer-dflex .footer-email {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 768px) {
  .footer .footer-dflex .footer-email {
    width: 100%;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}
.footer .footer-dflex .footer-email .label-email,
.footer .footer-dflex .footer-email .add-email {
  font-size: 20px;
  color: #fff;
  text-decoration: none;
}
@media (max-width: 768px) {
  .footer .footer-dflex .footer-email .label-email,
  .footer .footer-dflex .footer-email .add-email {
    font-size: 14px;
  }
}
.footer .footer-dflex .footer-email .label-email {
  font-weight: 700;
  white-space: nowrap;
  margin-right: 20px;
}
@media (max-width: 768px) {
  .footer .footer-dflex .footer-email .label-email {
    font-weight: 400;
  }
}
.footer .footer-dflex .footer-share {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 2px;
}
@media (max-width: 768px) {
  .footer .footer-dflex .footer-share {
    width: 100%;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-top: 16px;
  }
}
.footer .footer-dflex .footer-share .label-share {
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  margin-right: 15px;
}
@media (max-width: 768px) {
  .footer .footer-dflex .footer-share .label-share {
    font-size: 14px;
    font-weight: 400;
  }
}
.footer .footer-dflex .footer-share ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.footer .footer-dflex .footer-share ul li {
  padding-right: 16px;
}
@media (max-width: 768px) {
  .footer .footer-dflex .footer-share ul li {
    padding-right: 8px;
  }
}
.footer .footer-dflex .footer-share ul li img {
  max-height: 26px;
  min-height: 26px;
}
@media (max-width: 768px) {
  .footer .footer-dflex .footer-share ul li img {
    min-height: initial;
  }
}
.footer .footer-dflex .footer-share ul li:last-child {
  padding-right: 0;
}
.footer .footer-dflex .footer-share ul li a {
  display: block;
}
.footer .footer-dflex .footer-share ul li a:hover {
  opacity: 0.6;
}
.footer .footer-copyright {
  border-top: 1px solid rgba(220, 227, 245, 0.2);
  padding-top: 32px;
  margin-top: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 768px) {
  .footer .footer-copyright {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    padding-top: 24px;
    margin-top: 16px;
  }
}
.footer .footer-copyright .copyright {
  font-weight: 600;
  font-size: 16px;
  color: #fff;
}
@media (max-width: 768px) {
  .footer .footer-copyright .copyright {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
  }
}
.footer .footer-copyright .cookies {
  font-size: 16px;
  color: #949494;
  padding-left: 10px;
}
@media (max-width: 768px) {
  .footer .footer-copyright .cookies {
    width: 100%;
    font-size: 12px;
    padding: 16px 0 0;
  }
}
.footer .footer-copyright .cookies a {
  text-decoration: underline;
}